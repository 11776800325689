import { SVG, SVGProps } from "./svg"
import * as theme from "layouts/theme"
const Icon = (props: SVGProps & { fillText?: string }) => {
	const { fill, fillText, stroke, className, ...rest } = props

	return (
		<SVG viewBox="0 0 206 50" fill={fill} className={className} xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M102.162 39.9663C99.103 36.8372 97.5903 33.0508 97.5903 28.6378C97.5903 24.2249 99.103 20.4385 102.162 17.3094C105.221 14.1802 108.922 12.6328 113.266 12.6328C117.61 12.6328 121.248 14.1802 124.306 17.3094C127.365 20.4385 128.908 24.1907 128.908 28.6378C128.908 33.085 127.362 36.8372 124.306 39.9663C121.248 43.0954 117.58 44.6428 113.266 44.6428C108.952 44.6428 105.217 43.0954 102.162 39.9663ZM119.705 35.2589C121.509 33.4479 122.409 31.2431 122.409 28.6412C122.409 26.0394 121.509 23.8346 119.705 22.0236C117.934 20.1783 115.779 19.2573 113.266 19.2573C110.753 19.2573 108.567 20.1783 106.763 22.0236C104.993 23.8346 104.093 26.0394 104.093 28.6412C104.093 31.2431 104.993 33.4479 106.763 35.2589C108.567 37.07 110.723 37.9909 113.266 37.9909C115.809 37.9909 117.934 37.07 119.705 35.2589Z"
				fill={fillText}
			/>
			<path
				d="M131.716 16.094C132.777 15.5017 133.841 15.2039 134.966 15.2039C137.188 15.2039 139.052 16.3234 140.568 18.5281L147.844 31.3698L155.086 18.5281C156.599 16.3234 158.466 15.2039 160.718 15.2039C161.813 15.2039 162.873 15.5017 163.938 16.094L147.844 44.6428L131.716 16.094Z"
				fill={fillText}
			/>
			<path
				d="M193.472 17.2032C192.859 16.6109 192.247 16.0494 191.574 15.5564L191.477 15.4914C190.51 14.7998 189.482 14.2076 188.355 13.7146C187.839 13.4852 187.294 13.2866 186.681 13.1223C186.199 12.958 185.62 12.8245 184.877 12.7286C184.329 12.6293 183.75 12.5643 183.204 12.53H182.401C178.087 12.53 174.386 14.0775 171.327 17.2066C168.268 20.3357 166.755 24.0879 166.755 28.535C166.755 32.0236 167.753 35.1527 169.717 37.9189C172.806 42.1093 176.765 44.3073 181.624 44.5161V44.5332H182.217C182.277 44.5332 182.337 44.5366 182.401 44.5366H183.599H188.977C188.977 43.5815 188.783 42.7256 188.428 41.9039C187.11 39.237 185.145 37.9189 182.475 37.9189H182.401C179.857 37.9189 177.702 36.998 175.932 35.187C174.161 33.3417 173.261 31.1369 173.261 28.535C173.261 25.9332 174.161 23.7284 175.932 21.9174C177.702 20.0721 179.857 19.1511 182.401 19.1511C184.944 19.1511 187.1 20.0721 188.87 21.9174C190.674 23.7284 191.574 25.9332 191.574 28.535V37.9189C191.574 39.0042 191.798 40.0278 192.283 40.9488C193.602 43.3521 195.533 44.5366 198.046 44.5366V28.535C198.046 24.0913 196.534 20.3357 193.475 17.2066L193.472 17.2032Z"
				fill={fillText}
			/>
			<path
				d="M87.7615 16.2823C84.7495 13.2011 81.1452 11.6263 76.9184 11.5784C76.9184 11.5784 76.9117 11.5784 76.9083 11.5784C76.9083 11.5784 76.9017 11.5784 76.8983 11.5784C72.6715 11.6263 69.0672 13.2045 66.0552 16.2823C62.9964 19.4114 61.4536 23.1636 61.4536 27.5765V43.5815C63.224 43.5815 64.7701 42.9242 66.0251 41.6404C67.2801 40.3566 67.9226 38.7749 67.9226 36.9639V27.58C67.9226 24.9473 68.8229 22.7391 70.6602 20.928C72.3971 19.2163 74.482 18.3364 76.9083 18.2474C79.3347 18.3398 81.4196 19.2163 83.1565 20.928C84.9905 22.7391 85.8941 24.9438 85.8941 27.58V36.9639C85.8941 38.7749 86.5366 40.3566 87.7916 41.6404C89.0466 42.9242 90.5927 43.5815 92.3631 43.5815V27.5765C92.3631 23.1636 90.8169 19.4114 87.7615 16.2823Z"
				fill={fillText}
			/>
			<path
				d="M45.9185 42.3559C45.9586 42.3559 45.9988 42.3559 46.0423 42.3559L45.962 23.9339C45.9386 18.8157 44.1782 14.502 40.6442 10.9176C39.938 10.2397 39.2319 9.59611 38.4488 9.03123L38.3384 8.95591C37.2239 8.16508 36.0359 7.48722 34.734 6.92576C34.1383 6.66215 33.5092 6.43962 32.803 6.25132C32.2475 6.06303 31.5782 5.91582 30.7248 5.80626C30.0956 5.69671 29.4263 5.62139 28.7971 5.58716H27.8701C22.9037 5.61455 18.6501 7.41532 15.1462 11.0306C11.6456 14.6561 9.92212 18.9834 9.94555 24.1016C9.96228 28.1208 11.1269 31.7156 13.4026 34.8892C16.9768 39.6992 21.5483 42.2053 27.1439 42.4209V42.4381H27.8868C27.937 42.4381 27.9872 42.4415 28.0374 42.4381L29.3192 42.4312L45.9252 42.4038C45.9252 42.4038 45.9252 42.3696 45.9218 42.3525L45.9185 42.3559Z"
				stroke={stroke}
				strokeWidth="3.5"
				strokeMiterlimit="10"
			/>
			<path
				d="M39.8178 24.3174C39.8178 23.8655 39.8278 23.417 39.8178 22.9651C39.791 22.0373 39.2321 21.4588 38.3252 21.4211C37.9905 21.4074 37.6558 21.4006 37.3212 21.4211C37.0066 21.4451 36.8192 21.3732 36.682 21.0377C36.5448 20.7022 36.6853 20.5481 36.8861 20.353C37.1438 20.1065 37.3948 19.8531 37.6324 19.5895C38.2114 18.9493 38.2181 18.1414 37.6291 17.508C37.0099 16.8438 36.3741 16.1934 35.7248 15.56C35.1291 14.978 34.3326 14.9883 33.7269 15.5532C33.4659 15.7962 33.2249 16.0564 32.9773 16.3166C32.7798 16.5255 32.6259 16.7035 32.2644 16.5426C31.913 16.3851 31.9097 16.1626 31.9164 15.8681C31.9264 15.5258 31.9298 15.1834 31.9164 14.8411C31.8796 13.9441 31.314 13.3621 30.4372 13.3416C29.9586 13.3313 29.48 13.3279 29.0015 13.3279V19.4765C29.0483 19.4765 29.0952 19.4731 29.142 19.4765C31.7223 19.48 33.8307 21.6539 33.8206 24.3037C33.8106 26.9433 31.6821 29.0898 29.0885 29.0761C29.0584 29.0761 29.0316 29.0761 29.0015 29.0761V34.1498C29.0383 34.1498 29.0751 34.1498 29.1086 34.1498C29.2658 34.1498 29.4265 34.1498 29.5838 34.1498C29.4265 34.1498 29.2658 34.1498 29.1086 34.1498C29.0717 34.1498 29.0349 34.1498 29.0015 34.1498V35.2214C29.47 35.2214 29.9385 35.2214 30.407 35.2111C31.3173 35.194 31.8796 34.6154 31.9164 33.6876C31.9298 33.3282 31.9398 32.9653 31.9164 32.6092C31.893 32.2942 32 32.1333 32.2945 32.0101C32.5824 31.8868 32.743 31.969 32.9237 32.1778C33.1714 32.4586 33.4357 32.7256 33.7068 32.9824C34.3126 33.5575 35.1124 33.5849 35.7048 33.0132C36.3841 32.3593 37.0434 31.6814 37.6826 30.9865C38.208 30.4147 38.1947 29.6102 37.6893 29.0248C37.445 28.7406 37.1739 28.4839 36.9096 28.2202C36.7054 28.0183 36.5347 27.8608 36.692 27.491C36.846 27.135 37.0635 27.1281 37.3547 27.1384C37.6893 27.1487 38.024 27.1555 38.3586 27.1384C39.2388 27.0939 39.791 26.5222 39.8211 25.6218C39.8345 25.1904 39.8211 24.7591 39.8211 24.3243L39.8178 24.3174Z"
				fill="white"
			/>
			<path
				d="M18.6804 17.6345H21.6857L22.6763 15.933C22.8336 15.666 23.1147 15.5016 23.4192 15.5016H25.0557C25.5343 15.5016 25.9225 15.8988 25.9225 16.3883C25.9225 16.8779 25.5343 17.275 25.0557 17.275H23.9112L22.9206 18.9765C22.7633 19.2436 22.4822 19.4079 22.1776 19.4079H17.6195C16.9268 20.856 16.5386 22.4856 16.5386 24.2077C16.5386 24.7383 16.5754 25.2621 16.649 25.7757H20.2065C20.5244 25.7757 20.8156 25.9537 20.9662 26.2413L21.8497 27.9119H24.9453C25.4239 27.9119 25.8121 28.3091 25.8121 28.7986C25.8121 29.2882 25.4239 29.6853 24.9453 29.6853H21.3343C21.0164 29.6853 20.7252 29.5073 20.5746 29.2197L19.6911 27.5491H17.0473C18.3692 31.7668 22.1241 34.872 26.6253 35.1287V32.9137H22.2948C21.8162 32.9137 21.428 32.5166 21.428 32.027C21.428 31.5375 21.8162 31.1403 22.2948 31.1403H26.6253V25.9263H23.8175C23.5029 25.9263 23.2118 25.7483 23.0578 25.4675L22.007 23.5024H19.0218C18.5432 23.5024 18.155 23.1053 18.155 22.6157C18.155 22.1262 18.5432 21.729 19.0218 21.729H22.519C22.8336 21.729 23.1247 21.9071 23.2787 22.1878L24.3295 24.1529H26.6253V21.3627H23.7071C23.2285 21.3627 22.8403 20.9656 22.8403 20.476C22.8403 19.9865 23.2285 19.5893 23.7071 19.5893H26.6253V13.29C23.3791 13.4749 20.5211 15.1422 18.6838 17.6379L18.6804 17.6345Z"
				fill="white"
			/>
		</SVG>
	)
}

Icon.defaultProps = {
	fillText: theme.colors.black.lightest,
	fill: theme.colors.blue,
	stroke: theme.colors.blue,
}

export default Icon
