import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import * as brandguardapi from "brandguard/api"
import * as sessions from "sessions"
import * as errors from "errors"
import * as layouts from "layouts"
import * as brands from "brands"
import * as debugx from "x/debugx"
import * as uuid from "uuid"
import { TabPrimaryButton, TabOutlineButton } from "styleguard/display/layouts"
import * as routing from "../routing"
import { default as Upload } from "./upload"
import { default as DisplayImage } from "./image"
import { FileRejection } from "react-dropzone"
import { default as ImageDetails } from "./image.details"
import * as inputs from "inputs"
import styled from "@emotion/styled"
import * as icons from "icons"

const acceptedTypes = {
	"image/png": [".png"],
	"image/jpeg": [".jpeg", ".jpg"],
	"image/gif": [".gif"],
}

export const StyledBtn = styled(layouts.buttons.outline)`
	background: ${layouts.theme.colors.white};
	border: 1px solid ${layouts.theme.colors.grey.light20};
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
	height: 48px;
	border-radius: 48px;
	padding: 0 23px;
	&:hover {
		border: 1px solid ${layouts.theme.colors.blue};
		background: ${layouts.theme.colors.white};
		color: ${layouts.theme.colors.blue};
		box-shadow: 2px 2px 4px ${layouts.theme.colors.blue}7E;
	}
`

export default function DisplayImages(): JSX.Element {
	const brand = brands.caching.useCached()
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [selectedItem, setSelectedItem] = useState(-1)
	const navigate = useNavigate()

	const [images, setImages] = useState({
		items: [],
	} as brandguardapi.ImageSearchResponse)

	const bearertoken = sessions.useToken()
	const [searchReq, setSearchReq] = useState({
		offset: uuid.NIL,
		brand_id: brand.id,
	} as brandguardapi.ImageSearchRequest)

	useEffect(() => {
		if (searchReq.brand_id === uuid.NIL) return
		setLoading(true)
		const piu = brandguardapi.images.training.search(searchReq, brand.id, bearertoken)
		Promise.all([piu])
			.then((result) => {
				const [uploads] = result
				setImages({ items: uploads.items || [] })
			})
			.catch((c: unknown) => {
				setCause(
					<errors.Inline>
						<errors.Textual onClick={() => setCause(undefined)}>Unable to retrieve Images</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [searchReq])

	useEffect(() => {
		setSearchReq({ ...searchReq, brand_id: brand.id })
	}, [brand.id])

	const onImageUpload = (item: brandguardapi.ImageSearchResponseItem): void => {
		setImages((prevState) => ({
			items: [item, ...(prevState.items || [])],
		}))
	}

	function UploadFiles(accepted: File[], rejected: FileRejection[]): void {
		console.log(rejected)
		Array.from(accepted).forEach((file) => {
			brandguardapi.images.training
				.upload(file, brand.id, bearertoken)
				.then((result) => onImageUpload(result))
				.catch((e) => console.log(e))
		})
	}

	const onImageUpdate = (item: brandguardapi.ImageSearchResponseItem): void => {
		setImages((prevState) => ({
			items: prevState.items.map((i) => (i.media?.id === item.media?.id ? item : i)),
		}))
	}

	const onDeleteFile = (item: brandguardapi.ImageSearchResponseItem): void => {
		const upd = item
		upd.media!.tombstoned_at = new Date().toISOString()
		brandguardapi.images.training
			.patch(item.media!.brand_id, item.media!.id, upd, bearertoken)
			.then((r) => {
				setImages((prevState) => ({
					items: prevState.items.filter((i) => i.media?.id !== item.media?.id),
				}))
			})
			.finally(() => setLoading(false))
	}

	return (
		<layouts.loading.pending loading={loading}>
			{debugx.alpha.enabled() && cause}
			<layouts.containers.flex justifyContent="center" alignItems="start" flexDirection="row">
				{/* Ability to add new images while the design is not complete */}
				<layouts.containers.flex className="left-sidebar" width="10%" flexDirection="column">
					<layouts.containers.flex
						flexDirection="column"
						height="100%"
						mr="8px"
						borderRadius="10px"
						alignItems="center"
						display={images.items.length !== 0 ? "flex" : "none"}
					>
						<inputs.Dropwell
							accept={acceptedTypes}
							onDrop={(accepted, rejected, evt) => UploadFiles(accepted, rejected)}
							width="100%"
							multiple
						>
							<StyledBtn>
								<icons.Upload fill={layouts.theme.colors.blue} width="20" height="15" mr="8px" mb="-2px" />
								Upload Image
							</StyledBtn>
						</inputs.Dropwell>
					</layouts.containers.flex>
				</layouts.containers.flex>
				{/* end */}
				<layouts.containers.flex width="1440px" flexDirection="column">
					<layouts.containers.flex className="training-tabs" flexDirection="row" pl="25px">
						<TabPrimaryButton>Images</TabPrimaryButton>
						<TabOutlineButton onClick={() => navigate(routing.training.text(brand.id))}>Text</TabOutlineButton>
					</layouts.containers.flex>
					<layouts.containers.flex className="training-working-area" flexDirection="row" height="100%">
						{images.items.length === 0 ? (
							<Upload
								acceptedTypes={acceptedTypes}
								onUpload={(accepted, rejected) => UploadFiles(accepted, rejected)}
							/>
						) : (
							<>
								<layouts.containers.flex flexDirection="column" className="main-container" width="80%">
									<layouts.containers.flex
										p="25px"
										background={layouts.theme.colors.white}
										borderRadius="10px"
										flexDirection="column"
										textAlign="center"
										marginBottom="45px"
										overflowY="auto"
										maxHeight="75vh"
									>
										<layouts.containers.grid
											justifyContent="center"
											display="grid"
											gridGap="50px"
											px="10px"
											py="30px"
											gridTemplateColumns="repeat(auto-fill, 380px)"
											gridTemplateRows="max-content"
										>
											{images.items.map((img, index) => (
												<DisplayImage
													key={img.media?.id}
													item={img}
													active={index === selectedItem}
													onCardClick={() => setSelectedItem(index)}
													onImageUpdate={() => onImageUpdate}
												/>
											))}
										</layouts.containers.grid>
									</layouts.containers.flex>
								</layouts.containers.flex>
								<layouts.containers.flex className="right-sidebar" width="24%" flexDirection="column" p="0 10px">
									<ImageDetails item={images.items[selectedItem]} onDelete={(item) => onDeleteFile(item)} />
								</layouts.containers.flex>
							</>
						)}
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.loading.pending>
	)
}
