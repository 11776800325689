import { SVG, SVGProps } from "./svg"
import classnames from "classnames"
import * as theme from "layouts/theme"
import * as layouts from "layouts"

interface props extends SVGProps {
	checked?: boolean
	disabled?: boolean
}

interface unstyledProps extends SVGProps {
	className?: string
}

const Icon = (props: props) => {
	const { fill, stroke, checked, disabled, className, ...rest } = props

	let color = stroke ? stroke : checked || disabled ? theme.colors.white : theme.colors.grey.medium
	let bg = fill
		? fill
		: checked && disabled
		? theme.colors.grey.medium
		: disabled
		? theme.colors.grey.medium
		: checked
		? theme.colors.blue
		: "none"

	return (
		<SVG
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill={bg}
			xmlns="http://www.w3.org/2000/svg"
			className={classnames("icon.checkbox", className)}
			{...rest}
		>
			<path
				d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M17.51 8L10 15.51L7 12.51" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</SVG>
	)
}

export const unstyled = (props: unstyledProps) => {
	let { className } = props
	return (
		<SVG className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" />
			<path d="M17.51 8L10 15.51L7 12.51" />
		</SVG>
	)
}

export const approve = (props: unstyledProps & { fill?: string }) => {
	let { className, fill } = props
	return (
		<SVG
			className={className}
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 4C0 1.79086 1.79086 0 4 0H10C12.2091 0 14 1.79086 14 4V14H0V4Z"
				fill={fill || layouts.theme.colors.grey.dark10}
			/>
			<path d="M9.66634 5L5.99967 8.66667L4.33301 7" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		</SVG>
	)
}

export const dismiss = (props: unstyledProps & { fill?: string }) => {
	let { className, fill } = props
	return (
		<SVG
			className={className}
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 4C0 1.79086 1.79086 0 4 0H10C12.2091 0 14 1.79086 14 4V14H0V4Z"
				fill={fill || layouts.theme.colors.grey.dark10}
			/>
			<path d="M9 5L5 9" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M5 5L9 9" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		</SVG>
	)
}

export default Icon
