import * as layouts from "layouts"
import * as titlebars from "titlebars"
import * as profiles from "profiles"
import { Context } from "./profiles.table.context"
import * as theme from "layouts/theme"

interface props extends layouts.containers.FlexProps {
	context: Context
}

export function Profiles(props: props): JSX.Element {
	const { context, ...rest } = props

	return (
		<titlebars.Container {...rest}>
			<titlebars.Heading cursor="pointer" pr="25px" fontWeight="bold">
				<layouts.links.unstyled to="#" disabled color={theme.colors.blue}>
					Users
				</layouts.links.unstyled>
			</titlebars.Heading>
			<titlebars.Heading cursor="pointer">
				<layouts.links.unstyled to={"/u12t/pending"} color={theme.colors.grey.medium} disabled={false}>
					Pending Users
				</layouts.links.unstyled>
			</titlebars.Heading>
			<titlebars.Expand />
			<layouts.buttons.primary
				px="10px"
				onClick={() => {
					context.replace({
						creating: profiles.zero(),
					})
				}}
			>
				Invite
			</layouts.buttons.primary>
		</titlebars.Container>
	)
}

export function Pending(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props

	return (
		<titlebars.Container {...rest}>
			<titlebars.Heading pr="25px" cursor="pointer">
				<layouts.links.unstyled to={"/u12t"} disabled={false}>
					Users
				</layouts.links.unstyled>
			</titlebars.Heading>
			<titlebars.Heading cursor="pointer" fontWeight="bold">
				<layouts.links.unstyled to="#" disabled>
					Pending Users
				</layouts.links.unstyled>
			</titlebars.Heading>
			<titlebars.Expand />
		</titlebars.Container>
	)
}
