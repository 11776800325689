import * as httputils from "httpx"

export interface CropRequest {
	order_id: number
	product_id: number
	product_variant_id: number
	line_item_id: number
	image_url: string
	background_url: string
	effect: number
	crop_type: string
	smooth_edges_threshold: number
}
export interface CropResponse {
	status: string
}
export function crop(req: CropRequest): Promise<CropResponse> {
	return httputils.post(`${httputils.urlstorage.host()}/demos/cc/crop`, req)
}

export interface RetrievalResponse {
	crop_url: string
	print_url: string
}
export function retrieve(req: CropRequest): Promise<RetrievalResponse> {
	return httputils.post(`${httputils.urlstorage.host()}/demos/cc/crop/order`, req)
}

export interface HistoryRequest {
	offset: string
}
export interface HistoryResponse {
	items: string[]
	cursor: string
}

export function history(req: HistoryRequest): Promise<HistoryResponse> {
	return httputils.get(`${httputils.urlstorage.host()}/demos/cc/crop/history`, req, httputils.content.urlencoded)
}

export interface ReplayRequest {
	metadata: string
}
export interface ReplayResponse {
	status: string
}
export function replay(req: ReplayRequest): Promise<ReplayResponse> {
	return httputils.post(`${httputils.urlstorage.host()}/demos/cc/crop/replay`, req)
}

export interface MetadataRequest {
	metadata: string
}
export interface MetadataResponse {
	metadata: CropRequest
}
export function metadata(req: MetadataRequest): Promise<MetadataResponse> {
	return httputils.get(`${httputils.urlstorage.host()}/demos/cc/crop/metadata`, req, httputils.content.urlencoded)
}
