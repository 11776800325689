const storagekey = "nsuite.host.urls.api"

export function host(): string {
	// TODO default to production host
	return load(storagekey, process.env.REACT_APP_NSUITE_API_HTTP_ENDPOINT || "https://localhost:3001")
}

export function update(urlkey: string, v: string): string {
	window?.localStorage?.setItem(urlkey, v)
	return v
}

export function load(urlkey: string, fallback: string = ""): string {
	return window?.localStorage?.getItem(urlkey) || fallback
}
