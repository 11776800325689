import React, { useState, useEffect } from "react"
import Tabs from "./tabs/tabs"
import Tab from "./tabs/tab"
import { default as Upload } from "./display/upload"
import { default as Inprogress } from "./display/inprogress"
import { default as TabElement } from "./display/tab.element"
import * as api from "./api"
import * as httpx from "httpx"
import * as sessions from "sessions"
import * as errors from "errors"
import * as layouts from "layouts"
import * as brands from "brands"
import JSZip from "jszip"

export interface cached {
	filename: string
	data: string
}

export interface context {
	imgs: cached[]
	pages: cached[]
}

export function zero(d: Partial<context> = {}): context {
	return {
		imgs: [],
		pages: [],
		...d,
	}
}

export const Context = React.createContext(zero())
export const Provider = Context.Provider
export const Consumer = Context.Consumer

const baseTabs = [
	["Brand Description", "Brand Identity.html"],
	["Logos", "Logo.html"],
	["Color Palette", "Color Palette.html"],
	["Typography", "Typography.html"],
	["Voice & Tone", "Tone and Voice.html"],
	["Brand Usage", "Merchandising.html"],
	["Other", "Guidelines.html"],
]

function ShowStyleGuide(props: { styleguide: api.StyleGuide }): JSX.Element {
	const { styleguide } = props
	const [ctx, setCtx] = useState(zero())
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setLoading(true)
		JSZip.loadAsync(styleguide.content)
			.then((zip) => {
				// Filter the files to only include html files
				const htmlFiles = Object.keys(zip.files).filter((filename) => /\.(html)$/i.test(filename))

				if (htmlFiles.length === 0) return
				const htmlResult = [] as cached[]
				const ppromises = [] as Promise<string>[]
				htmlFiles.forEach((filename, index) => {
					const zipfile = zip.file(filename)
					if (!zipfile) return
					ppromises.push(zipfile.async("text"))
				})
				Promise.all(ppromises)
					.then((results) => {
						results.forEach((r, i) => htmlResult.push({ filename: htmlFiles[i], data: r }))
					})
					.then(() => setCtx({ ...ctx, pages: htmlResult }))
				// Filter the files to only include image files
				const imageFiles = Object.keys(zip.files).filter((filename) => /\.(jpe?g|png|gif)$/i.test(filename))
				const imgResult = [] as cached[]
				const ipromises = [] as Promise<Blob>[]
				imageFiles.forEach((filename, index) => {
					const zipfile = zip.file(filename)
					if (!zipfile) return
					ipromises.push(zipfile.async("blob"))
				})
				Promise.all(ipromises)
					.then((results) => {
						results.forEach((r, i) => imgResult.push({ filename: imageFiles[i], data: URL.createObjectURL(r) }))
					})
					.then(() => {
						setCtx({ imgs: imgResult, pages: htmlResult })
						setLoading(false)
					})
			})
			.catch(console.error)
	}, [])

	const existingTabs = baseTabs.filter((x) => ctx.pages.map((i) => i.filename).includes(x[1]))

	return (
		<layouts.loading.pending loading={loading}>
			<Provider value={ctx}>
				<Tabs titles={existingTabs.map((i) => i[0])}>
					{existingTabs.map((a, i) => (
						<Tab key={i} title={a[0]}>
							<TabElement html={ctx.pages.find((e) => e.filename.endsWith(a[1]))?.data} />
						</Tab>
					))}
				</Tabs>
			</Provider>
		</layouts.loading.pending>
	)
}

export default function Display(): JSX.Element {
	const brand = brands.caching.useCached()
	const [styleguide, setStyleguide] = useState(api.zero())
	const [loading, setLoading] = useState(true)
	const [uploading, setUploading] = useState(false)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const bearertoken = sessions.useToken()

	useEffect(() => {
		setLoading(true)
		api
			.download(brand.id, bearertoken)
			.then((resp) => resp.arrayBuffer())
			.then((buf) => {
				setCause(undefined)
				setStyleguide(
					api.zero({
						content: buf,
						parse_status: 2,
					}),
				)
			})
			.catch(
				httpx.errors.notFound((cause) => {
					setStyleguide(api.zero())
					// ignore 404s
				}),
			)
			.catch((c: unknown) => {
				setStyleguide(api.zero())
				setCause(
					<errors.Inline>
						<errors.Textual cause={c} onClick={() => setCause(undefined)}>
							Unable to retrieve Style Guide
						</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
	}, [brand.id])

	function UploadFiles(fs: File[]): void {
		if (fs.length > 1) {
			return setCause(
				<errors.Inline>
					<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
						only one style guide can be uplaoded at a time
					</errors.Textual>
				</errors.Inline>,
			)
		}
		setUploading(true)
		api
			.upload(brand.id, fs.at(0)!, bearertoken)
			.then((resp) => {
				setStyleguide(api.zero({ parse_status: 1 }))
			})
			.catch((cause) => {
				setCause(
					<errors.Inline>
						<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
							unable to upload style guide
						</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setUploading(false))
	}

	return (
		<layouts.loading.pending loading={loading}>
			{cause}
			<layouts.containers.flex justifyContent="center" alignItems="center">
				<layouts.containers.flex width="1440px">
					{
						[
							<Tabs key={0} disabled titles={baseTabs.map((i) => i[0])}>
								{baseTabs.map((a, i) => (
									<React.Fragment key={i}>
										<Tab title={a[0]} />
										{uploading ? <Inprogress /> : <Upload onUpload={(fs) => UploadFiles(fs)} />}
									</React.Fragment>
								))}
							</Tabs>,
							<Tabs key={1} disabled titles={baseTabs.map((i) => i[0])}>
								{baseTabs.map((a, i) => (
									<React.Fragment key={i}>
										<Tab title={a[0]} />
										<Inprogress />
									</React.Fragment>
								))}
							</Tabs>,
							<ShowStyleGuide key={2} styleguide={styleguide} />,
							<></>, // error case
						][styleguide.parse_status]
					}
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.loading.pending>
	)
}
