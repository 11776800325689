import { useEffect, useState } from "react"
import classnames from "classnames"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as profiles from "./profile"

interface props {
	current: profiles.Profile
	onChange(updated: profiles.Profile | undefined): Promise<profiles.Profile | undefined>
	cancel: JSX.Element
	submit: JSX.Element
}

export default function Edit(props: props & layouts.containers.FlexProps): JSX.Element {
	const { current, onChange, className, ...rest } = props

	const [cause, setCause] = useState(undefined as unknown | undefined)
	const [loading, setLoading] = useState(false)
	const [modified, setModified] = useState(current)

	useEffect(() => {
		setCause(undefined)
	}, [modified])

	return (
		<layouts.forms.Container
			className={classnames("profile modify", className)}
			padding="10px"
			cause={cause}
			loading={loading}
			{...rest}
		>
			<layouts.forms.Group mb="10px">
				<layouts.forms.Label width="10ch">Display</layouts.forms.Label>
				<inputs.Text
					placeholder="john doe"
					defaultValue={modified.display}
					onChange={(e) =>
						setModified({
							...modified,
							display: e.target.value,
						})
					}
					autoFocus
				/>
			</layouts.forms.Group>
			<layouts.forms.Group mb="10px">
				<layouts.forms.Label width="10ch">Email</layouts.forms.Label>
				<inputs.Text
					placeholder="john@example.com"
					defaultValue={modified.email}
					onChange={(e) =>
						setModified({
							...modified,
							email: e.target.value,
						})
					}
					autoFocus
				/>
			</layouts.forms.Group>
			<layouts.forms.Group mt="10px" justifyContent="center" flexDirection="row-reverse">
				<layouts.buttons.primary
					onClick={() => {
						setLoading(true)
						onChange(modified)
							.catch((cause) => {
								setCause(cause)
							})
							.finally(() => setLoading(false))
					}}
				>
					{props.submit}
				</layouts.buttons.primary>
				<layouts.buttons.styled
					mr="10px"
					onClick={() => {
						setLoading(true)
						onChange(undefined)
							.catch((cause) => {
								setCause(cause)
							})
							.finally(() => setLoading(false))
					}}
				>
					{props.cancel}
				</layouts.buttons.styled>
			</layouts.forms.Group>
		</layouts.forms.Container>
	)
}
