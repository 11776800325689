import React, { useState } from "react"
import * as layouts from "layouts"
import { Context } from "./profiles.table.context"
import { withClient as Table } from "./profiles.table"
import { Profiles as ProfilesTitlebar, Pending as PendingTitlebar } from "./profiles.table.titlebar"
import { default as PendingTable } from "./pending.profiles.table"

export function ProfilesDisplay(props: React.PropsWithChildren<unknown>): JSX.Element {
	const [ctxstate, setContext] = useState({
		creating: undefined,
	} as Context)

	const ctx = {
		...ctxstate,
		replace(upd: Partial<Context>): void {
			setContext({
				...ctxstate,
				...upd,
			})
		},
	}

	return (
		<layouts.containers.flex className="usermanagement" p="50px" width="100%" flexDirection="column">
			<ProfilesTitlebar my="20px" mx="0px" py="10px" context={ctx} />
			<Table flex="1" context={ctx} />
		</layouts.containers.flex>
	)
}

export function PendingDisplay(): JSX.Element {
	return (
		<layouts.containers.flex className="usermanagement" p="50px" width="100%" flexDirection="column">
			<PendingTitlebar my="20px" mx="0px" py="10px" />
			<PendingTable flex="1" />
		</layouts.containers.flex>
	)
}
