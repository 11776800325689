import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as errors from "errors"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as debugx from "x/debugx"
import * as pools from "ads/pools"
import * as brands from "brands"
import * as api from "./api"
import * as typography from "typography"
import { TabPrimaryButton, TabOutlineButton } from "ads/adgen/adgen.content.layouts"
import ReadOnly from "./adgen.responsive.search.readonly"
import Keywordgen from "./ads.responsive.keywordgen"
interface props {
	search(
		req: api.ResponsiveSearchSearchRequest,
		...options: httpx.option[]
	): Promise<api.ResponsiveSearchSearchResponse>
	content(id: string, ...options: httpx.option[]): Promise<api.ResponsiveSearchContentResponse>
}

export default function Table(props: props & layouts.containers.FlexProps): JSX.Element {
	const { search, content, ...rest } = props
	const pool = pools.caching.useCached()
	const brand = brands.caching.useCached()
	const bearertoken = sessions.useToken()
	const navigate = useNavigate()
	const [sidecar, setSidecar] = useState(undefined as JSX.Element | undefined)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [loading, setLoading] = useState(true)
	const [focused, setFocused] = useState(api.ads.responsive.metadata())
	const [retargetting, setRetargetting] = useState("")
	const [previewload, setPreviewLoading] = useState("")
	const [keywordgen, setKeywordGen] = useState("")
	const [sidecarLoading, setSidecarLoading] = useState(false)

	const [googleadreq, setGoogleAdRequest] = useState(api.ads.responsive.search.request({ pool_id: pool.id }))
	const [results, setResults] = useState(api.ads.responsive.search.response({ cursor: googleadreq }))

	useEffect(() => {
		setLoading(true)
		search(googleadreq, bearertoken)
			.then((resp) => {
				setResults(resp)
				setCause(undefined)
			})
			.catch(
				httpx.errors.forbidden((cause) => {
					console.warn("insufficient priviledges unable to view ad pools", cause)
					setCause(
						<layouts.containers.box styled m="auto">
							<errors.Textual>you do not have permission to view ad pools</errors.Textual>
						</layouts.containers.box>,
					)
				}),
			)
			.catch((c: unknown) => {
				console.error("unable to retrieve ad pools", c)
				setCause(<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve ad pools</errors.Textual>)
			})
			.finally(() => setLoading(false))
	}, [googleadreq, search, pool])

	return (
		<layouts.containers.flex className="google" p="10px" overflow="hidden" flex="1" flexDirection="column" {...rest}>
			<layouts.containers.flex p="25px">
				<typography.h3 color={layouts.theme.colors.grey.medium}>Google Ads - Responsive Search</typography.h3>
			</layouts.containers.flex>
			<layouts.containers.flex>
				<TabOutlineButton onClick={() => navigate(pools.routing.ads.google.published(brand.id, pool.id))}>
					Published
				</TabOutlineButton>
				<TabPrimaryButton>Responsive</TabPrimaryButton>
			</layouts.containers.flex>
			<layouts.tables.container pb="10px" overflow="auto" flex="1">
				<errors.overlay styled cause={cause}>
					<layouts.loading.screen loading={loading} flexDirection="row">
						<table style={{ flex: "4" }}>
							<thead>
								<tr>
									<th>Ad ID</th>
									<th>Description</th>
									<th>Updated At</th>
									<th>Created At</th>
								</tr>
							</thead>
							<tbody>
								{results.items.map((c) => (
									<React.Fragment key={c.id}>
										<layouts.tables.Row onClick={() => setFocused(c)}>
											<td>{c.id}</td>
											<td>{c.description}</td>
											<td>{layouts.tables.Timestamp(c.updated_at)}</td>
											<td>{layouts.tables.Timestamp(c.created_at)}</td>
										</layouts.tables.Row>
										{c.id === focused.id && (
											<layouts.tables.inline>
												<layouts.containers.grid gridGap="35px" width="100%" justifyContent="end">
													{debugx.alpha.enabled() && (
														<>
															<layouts.buttons.outline
																disabled={retargetting === focused.id}
																onClick={() => {
																	setRetargetting(focused.id)
																	api.ads.responsive.retarget(focused.id, bearertoken).catch((cause) => {
																		console.warn("unable to retarget", cause)
																		setCause(
																			<layouts.containers.box styled m="auto" onClick={() => setCause(undefined)}>
																				<errors.Textual>unable to retarget ad</errors.Textual>
																			</layouts.containers.box>,
																		)
																		setRetargetting("")
																	})
																}}
															>
																Retarget
															</layouts.buttons.outline>
														</>
													)}
													<layouts.buttons.outline
														onClick={() => {
															if (previewload === c.id) {
																setPreviewLoading("")
																setSidecar(undefined)
																return
															}

															setPreviewLoading(c.id)
															content(c.id, bearertoken)
																.then((r) => {
																	setSidecar(
																		<layouts.containers.flex flex="1" flexDirection="column" px="10px">
																			<typography.h3>Preview</typography.h3>
																			<ReadOnly metadata={c} content={api.ads.responsive.content.zero(r.content)} />
																		</layouts.containers.flex>,
																	)
																})
																.finally(() => setPreviewLoading(c.id))
														}}
													>
														Preview
													</layouts.buttons.outline>
													<layouts.buttons.outline
														onClick={() => {
															if (keywordgen === c.id) {
																setKeywordGen("")
																setSidecar(undefined)
																return
															}

															setKeywordGen(c.id)
															content(c.id, bearertoken)
																.then((r) => {
																	setSidecar(
																		<layouts.containers.flex flex="1" flexDirection="column" p="10px">
																			<Keywordgen
																				onChange={(keywords) => {
																					if (keywords.length === 0) return
																					setSidecarLoading(true)
																					const perform = async (
																						req: api.KeywordOneshotGenRequest,
																					): Promise<api.KeywordOneshotGenResponse> => {
																						return api.ads.metadata.keywords.oneshot(req, bearertoken).then((resp) => {
																							if (resp.cursor === undefined || resp.cursor?.offset === "") {
																								return resp
																							}
																							return perform(resp.cursor)
																						})
																					}
																					const i = {
																						pool_id: c.pool_id,
																						tid: c.id,
																						keywords: keywords,
																						offset: uuid.NIL,
																						limit: 10,
																					}
																					perform(i)
																						.then((resp) => console.log("done", resp))
																						.catch((cause) => {
																							setCause(<errors.UnknownCause cause={cause} />)
																						})
																						.finally(() => {
																							setSidecarLoading(false)
																						})
																				}}
																			/>
																		</layouts.containers.flex>,
																	)
																})
																.finally(() => setKeywordGen(c.id))
														}}
													>
														Keywords
													</layouts.buttons.outline>
												</layouts.containers.grid>
											</layouts.tables.inline>
										)}
									</React.Fragment>
								))}
							</tbody>
						</table>

						<layouts.loading.screen loading={sidecarLoading} flex={sidecar === undefined ? undefined : "1"}>
							{sidecar}
						</layouts.loading.screen>
					</layouts.loading.screen>
				</errors.overlay>
			</layouts.tables.container>
			<layouts.pagination.Cursor
				ml="auto"
				current={googleadreq.offset}
				advance={results.cursor?.offset === "" ? undefined : results.cursor?.offset}
				onChange={(next) => next && setGoogleAdRequest({ ...googleadreq, offset: next })}
			/>
		</layouts.containers.flex>
	)
}

Table.defaultProps = {
	search: api.ads.responsive.search.get,
	content: api.ads.responsive.content.get,
}
