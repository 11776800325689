import * as httputils from "httpx"

export interface Grade {
	brand: string
	emotion: string
	value: number
}

export function scoring(id: number, ...options: httputils.option[]): Promise<Grade[]> {
	return Promise.resolve([
		{ brand: "cuddleclones", emotion: "Unknown", value: 0.1875 },
		{ brand: "custompetgifts.co", emotion: "Unknown", value: 0.0125 },
		{ brand: "photopajamas.com", emotion: "Unknown", value: 0.1125 },
		{ brand: "www.patiopetlife.com", emotion: "Unknown", value: 0.0375 },
		{ brand: "cuddleclones", emotion: "Neutral", value: 0.0625 },
		{ brand: "custompetgifts.co", emotion: "Neutral", value: 0.1375 },
		{ brand: "photopajamas.com", emotion: "Neutral", value: 0.1625 },
		{ brand: "www.patiopetlife.com", emotion: "Neutral", value: 0.375 },
		{ brand: "cuddleclones", emotion: "Love", value: 0.225 },
		{ brand: "custompetgifts.co", emotion: "Love", value: 0.3 },
		{ brand: "photopajamas.com", emotion: "Love", value: 0.0625 },
		{ brand: "www.patiopetlife.com", emotion: "Love", value: 0.225 },
		{ brand: "cuddleclones", emotion: "Gratitude", value: 0.075 },
		{ brand: "custompetgifts.co", emotion: "Gratitude", value: 0.125 },
		{ brand: "photopajamas.com", emotion: "Gratitude", value: 0.025 },
		{ brand: "www.patiopetlife.com", emotion: "Gratitude", value: 0.1 },
		{ brand: "cuddleclones", emotion: "Disapproval", value: 0.0 },
		{ brand: "custompetgifts.co", emotion: "Disapproval", value: 0.0125 },
		{ brand: "photopajamas.com", emotion: "Disapproval", value: 0.0875 },
		{ brand: "www.patiopetlife.com", emotion: "Disapproval", value: 0.0125 },
		{ brand: "cuddleclones", emotion: "Amusement", value: 0.0 },
		{ brand: "custompetgifts.co", emotion: "Amusement", value: 0.0 },
		{ brand: "photopajamas.com", emotion: "Amusement", value: 0.0 },
		{ brand: "www.patiopetlife.com", emotion: "Amusement", value: 0.0 },
		{ brand: "cuddleclones", emotion: "Disappointment", value: 0.0 },
		{ brand: "custompetgifts.co", emotion: "Disappointment", value: 0.025 },
		{ brand: "photopajamas.com", emotion: "Disappointment", value: 0.125 },
		{ brand: "www.patiopetlife.com", emotion: "Disappointment", value: 0.0125 },
		{ brand: "cuddleclones", emotion: "Admiration", value: 0.575 },
		{ brand: "custompetgifts.co", emotion: "Admiration", value: 0.625 },
		{ brand: "photopajamas.com", emotion: "Admiration", value: 0.275 },
		{ brand: "www.patiopetlife.com", emotion: "Admiration", value: 0.4125 },
		{ brand: "cuddleclones", emotion: "Annoyance", value: 0.0125 },
		{ brand: "custompetgifts.co", emotion: "Annoyance", value: 0.0 },
		{ brand: "photopajamas.com", emotion: "Annoyance", value: 0.2625 },
		{ brand: "www.patiopetlife.com", emotion: "Annoyance", value: 0.0125 },
		{ brand: "cuddleclones", emotion: "Confusion", value: 0.0125 },
		{ brand: "custompetgifts.co", emotion: "Confusion", value: 0.0 },
		{ brand: "photopajamas.com", emotion: "Confusion", value: 0.0 },
		{ brand: "www.patiopetlife.com", emotion: "Confusion", value: 0.025 },
		{ brand: "cuddleclones", emotion: "Excitement", value: 0.025 },
		{ brand: "custompetgifts.co", emotion: "Excitement", value: 0.025 },
		{ brand: "photopajamas.com", emotion: "Excitement", value: 0.0125 },
		{ brand: "www.patiopetlife.com", emotion: "Excitement", value: 0.0 },
		{ brand: "cuddleclones", emotion: "Caring", value: 0.0 },
		{ brand: "custompetgifts.co", emotion: "Caring", value: 0.0 },
		{ brand: "photopajamas.com", emotion: "Caring", value: 0.0 },
		{ brand: "www.patiopetlife.com", emotion: "Caring", value: 0.0125 },
		{ brand: "cuddleclones", emotion: "Disgust", value: 0.0 },
		{ brand: "custompetgifts.co", emotion: "Disgust", value: 0.0 },
		{ brand: "photopajamas.com", emotion: "Disgust", value: 0.0625 },
		{ brand: "www.patiopetlife.com", emotion: "Disgust", value: 0.0 },
		{ brand: "cuddleclones", emotion: "Remorse", value: 0.0 },
		{ brand: "custompetgifts.co", emotion: "Remorse", value: 0.0 },
		{ brand: "photopajamas.com", emotion: "Remorse", value: 0.0 },
		{ brand: "www.patiopetlife.com", emotion: "Remorse", value: 0.0 },
		{ brand: "cuddleclones", emotion: "Joy", value: 0.1125 },
		{ brand: "custompetgifts.co", emotion: "Joy", value: 0.05 },
		{ brand: "photopajamas.com", emotion: "Joy", value: 0.0125 },
		{ brand: "www.patiopetlife.com", emotion: "Joy", value: 0.05 },
		{ brand: "cuddleclones", emotion: "Approval", value: 0.0375 },
		{ brand: "custompetgifts.co", emotion: "Approval", value: 0.0125 },
		{ brand: "photopajamas.com", emotion: "Approval", value: 0.05 },
		{ brand: "www.patiopetlife.com", emotion: "Approval", value: 0.1625 },
		{ brand: "cuddleclones", emotion: "Surprise", value: 0.0 },
		{ brand: "custompetgifts.co", emotion: "Surprise", value: 0.0 },
		{ brand: "photopajamas.com", emotion: "Surprise", value: 0.0 },
		{ brand: "www.patiopetlife.com", emotion: "Surprise", value: 0.0 },
		{ brand: "cuddleclones", emotion: "Anger", value: 0.0 },
		{ brand: "custompetgifts.co", emotion: "Anger", value: 0.0 },
		{ brand: "photopajamas.com", emotion: "Anger", value: 0.0 },
		{ brand: "www.patiopetlife.com", emotion: "Anger", value: 0.0 },
		{ brand: "cuddleclones", emotion: "Grief", value: 0.0 },
		{ brand: "custompetgifts.co", emotion: "Grief", value: 0.0125 },
		{ brand: "photopajamas.com", emotion: "Grief", value: 0.0 },
		{ brand: "www.patiopetlife.com", emotion: "Grief", value: 0.0 },
		{ brand: "cuddleclones", emotion: "Pride", value: 0.0 },
		{ brand: "custompetgifts.co", emotion: "Pride", value: 0.0 },
		{ brand: "photopajamas.com", emotion: "Pride", value: 0.0 },
		{ brand: "www.patiopetlife.com", emotion: "Pride", value: 0.0 },
		{ brand: "cuddleclones", emotion: "Desire", value: 0.025 },
		{ brand: "custompetgifts.co", emotion: "Desire", value: 0.0 },
		{ brand: "photopajamas.com", emotion: "Desire", value: 0.0125 },
		{ brand: "www.patiopetlife.com", emotion: "Desire", value: 0.0 },
		{ brand: "cuddleclones", emotion: "Relief", value: 0.0 },
		{ brand: "custompetgifts.co", emotion: "Relief", value: 0.0 },
		{ brand: "photopajamas.com", emotion: "Relief", value: 0.0 },
		{ brand: "www.patiopetlife.com", emotion: "Relief", value: 0.0 },
	])
}
