import { default as _Cache } from "./cache"

export { default as Cache } from "./cache"
export { default as Autoflush } from "./autoflush"

export default _Cache

export namespace numeric {
	export function set(key: string, n: number): number {
		localStorage.setItem(key, String(n))
		return n
	}

	export function get(key: string, fallback: number = 0): number {
		const found = localStorage.getItem(key)
		if (found) return parseInt(found, 10)
		return fallback
	}
}

export namespace boolean {
	export function set(key: string, n: boolean): boolean {
		localStorage.setItem(key, String(n))
		return n
	}

	export function get(key: string, fallback: boolean = false): boolean {
		const found = localStorage.getItem(key)
		if (found) return JSON.parse(found)
		return fallback
	}
}
