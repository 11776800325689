import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { stroke, className, ...rest } = props
	return (
		<svg.SVG
			className={className}
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M12 4L4 12"
				stroke={stroke ? stroke : "white"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 4L12 12"
				stroke={stroke ? stroke : "white"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg.SVG>
	)
}

export default Icon
