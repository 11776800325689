import styled from "@emotion/styled"
import * as layouts from "layouts"

export const TabPrimaryButton = styled(layouts.buttons.primary)`
	width: 120px;
	height: 28px;
	padding: 0;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;

	text-align: center;

	color: ${layouts.theme.colors.white};
	background: ${layouts.theme.colors.blue};
	box-shadow: ${layouts.theme.boxshadow.black.medium};
	border-radius: 6px 6px 0px 0px;
	margin-right: 5px;

	&:disabled {
		color: ${layouts.theme.colors.grey.dark10};
		border-color: ${layouts.theme.colors.white};
		box-shadow: ${layouts.theme.boxshadow.grey.lightest};
		background: ${layouts.theme.colors.white};
	}
`

export const TabOutlineButton = styled(layouts.buttons.outline)`
	width: 120px;
	height: 28px;
	padding: 0;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	margin-right: 5px;
	color: ${layouts.theme.colors.grey.dark10};
	border-color: ${layouts.theme.colors.white};
	box-shadow: ${layouts.theme.boxshadow.grey.lightest};

	&:hover {
		border-color: ${layouts.theme.colors.blue};
		background: ${layouts.theme.colors.blue};
	}
	&:focus {
		border-color: ${layouts.theme.colors.blue};
		background: ${layouts.theme.colors.blue};
	}
`

export const Select = styled.select`
	width: 176px;
	height: 50px;
	background: ${layouts.theme.colors.white};
	color: ${layouts.theme.colors.grey.dark10};
	border: none;
	border-radius: 30px;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 20px;
	padding-left: 20px;

	&:hover {
		box-shadow: none;
		border: none;
	}
	&:focus {
		outline: 0;
	}
`
