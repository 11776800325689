import React from "react"
import * as layouts from "../layouts"

export function File(
	props: React.PropsWithChildren<
		{
			onChange?: React.ChangeEventHandler<HTMLInputElement>
			className?: string
			multiple?: boolean
		} & layouts.containers.ContainerProps
	>,
): JSX.Element {
	const { multiple, children, className, onChange, ...rest } = props

	const inputref = React.useRef<HTMLInputElement>(null)
	return (
		<layouts.containers.box className={className} onClick={() => inputref?.current?.click()} {...rest}>
			<input type="file" ref={inputref} onChange={onChange} hidden multiple={multiple} />
			{children}
		</layouts.containers.box>
	)
}

File.defaultProps = {
	multiple: false,
}
