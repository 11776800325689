import * as layouts from "layouts"
import * as context from "./context"

interface props extends layouts.containers.ContainerProps {
	ctx: context.default
	current?: context.UI
}

export default function Editor(props: props): JSX.Element {
	return (
		<layouts.containers.flex className="editor" {...props}>
			<layouts.containers.box styled flex="1">
				{props.current?.properties(props) || <></>}
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}
