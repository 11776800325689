import * as theme from "layouts/theme"
import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { fill, stroke, ...rest } = props
	return (
		<svg.SVG width="14" height="14" viewBox="0 0 14 14" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path d="M0 4C0 1.79086 1.79086 0 4 0H10C12.2091 0 14 1.79086 14 4V14H0V4Z" fill="#5F5F71" />
			<circle cx="7" cy="7" r="4" fill="white" />
			<path d="M7 7L7 9" stroke={stroke || theme.colors.grey.dark10} strokeLinecap="round" />
			<path d="M7 5.5V5" stroke={stroke || theme.colors.grey.dark10} strokeLinecap="round" />
		</svg.SVG>
	)
}

export default Icon
