import styled from "@emotion/styled"
import * as system from "styled-system"

export interface TypographyProps
	extends system.LayoutProps,
		system.TypographyProps,
		system.SpaceProps,
		system.PositionProps,
		system.WidthProps,
		system.BackgroundProps,
		system.ColorProps {
	className?: string
}

export const h1 = styled.h1<TypographyProps>`
	font-size: 36px;
	font-weight: 800;
	line-height: 48px;
	letter-spacing: -0.02em;
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
export const h2 = styled.h2<TypographyProps & system.ColorProps>`
	font-size: 18px;
	font-weight: 700;
	line-height: 25.5px;
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`

export const h3 = styled.h3<TypographyProps & system.ColorProps>`
	font-size: 18px;
	font-weight: 600;
	line-height: 36px;
	letter-spacing: -0.02em;
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
export const h4 = styled.h4<TypographyProps & system.ColorProps>`
	font-size: 18px;
	font-weight: 400;
	line-height: 25.5px;
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
export const h5 = styled.h5<TypographyProps & system.ColorProps>`
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
export const h6 = styled.h6<TypographyProps & system.ColorProps>`
	font-size: 12px;
	font-weight: 700;
	line-height: 20px;
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
