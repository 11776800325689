import * as uuid from "uuid"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as icons from "icons"
import * as discountopt from "ecommerce/discountopt/runtime/discountopt"
import * as context from "./context"
import * as discounts from "./discounts"

function uioffer<T extends context.Offer>(c: T): context.UI {
	return {
		preview: (props: { ctx: context.default }) => <></>,
		properties: (props: { ctx: context.default }) => c.display({ ctx: props.ctx, current: c }),
	}
}

interface constantfields extends context.Offer {
	discount?: context.Display
}

interface dynamicfields extends context.Offer {
	discounts: context.Display[]
}

// zero offer is used to initiate the creation of a new offer.
export function zero(): context.UI {
	return {
		preview: (props: { ctx: context.default }) => <></>,
		properties: (props: { ctx: context.default }) => (
			<layouts.containers.grid className="offer-creation" gridGap="10px" justifyContent="center">
				<layouts.containers.box
					styled
					onClick={() => {
						const n = {
							offer: {
								...discountopt.offers.constant.offer("", 0, discountopt.offers.noop()),
								id: uuid.v4(),
							},
							discount: undefined,
							display: constant,
						} as constantfields

						props.ctx.offers.insert(n)
						props.ctx.edit(uioffer(n))
					}}
				>
					Constant
				</layouts.containers.box>
				<layouts.containers.box
					styled
					onClick={() => {
						const n = {
							offer: {
								...discountopt.offers.dynamic.offer("", 0),
								id: uuid.v4(),
							},
							display: dynamic,
						} as dynamicfields
						props.ctx.offers.insert(n)
						props.ctx.edit(uioffer(n))
					}}
				>
					Dynamic
				</layouts.containers.box>
			</layouts.containers.grid>
		),
	}
}

export function constant(props: { ctx: context.default; current: constantfields }): JSX.Element {
	const { current, ctx } = props

	const onChange = (o: constantfields): void => {
		ctx.offers.insert(o)
		props.ctx.edit(uioffer(o))
	}

	return (
		<layouts.containers.flex flexDirection="column">
			<layouts.forms.Group>
				<layouts.forms.Label>Name</layouts.forms.Label>
				<inputs.Text
					my="2px"
					defaultValue={current.offer.name}
					onChange={(evt) => {
						const updated = { ...current, offer: { ...current.offer, name: evt.target.value } }
						onChange(updated)
					}}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label>Weight</layouts.forms.Label>
				<inputs.Numeric
					min={0}
					max={100}
					my="2px"
					defaultValue={current.offer.weight}
					onChange={(evt) => {
						const updated = { ...current, offer: { ...current.offer, weight: parseInt(evt.target.value, 10) } }
						onChange(updated)
					}}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label>Discount</layouts.forms.Label>
				<layouts.containers.flex width="100%">
					{current.discount ? (
						<layouts.containers.flex flex="1" mt="5px">
							<discounts.Display current={current.discount} />
							<layouts.containers.span
								height="16px"
								ml="auto"
								onClick={() => {
									const updated = { ...current, discount: undefined }
									onChange(updated)
								}}
							>
								<icons.Trash />
							</layouts.containers.span>
						</layouts.containers.flex>
					) : (
						<></>
					)}
					{current.discount ? (
						<></>
					) : (
						ctx.program.displays.map((d) => (
							<layouts.containers.flex
								key={d.properties.discount.id}
								flex="1"
								mt="5px"
								onClick={() => {
									const updated = { ...current, discount: d }
									onChange(updated)
								}}
							>
								<discounts.Display current={d} />
							</layouts.containers.flex>
						))
					)}
				</layouts.containers.flex>
			</layouts.forms.Group>
		</layouts.containers.flex>
	)
}

export function dynamic(props: { ctx: context.default; current: dynamicfields }): JSX.Element {
	return <></>
}
