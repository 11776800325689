import * as httputils from "httpx"
export interface Placement {
	photo: string
	influencer: {
		name: string
		x: number
		y: number
	}
	product: {
		name: string
		x: number
		y: number
	}
}

export function products(id: number, ...options: httputils.option[]): Promise<Placement[]> {
	return Promise.resolve([
		{ photo: "", influencer: { name: "sam", x: 0, y: 0 }, product: { name: "", x: 0, y: 0 } },
		{ photo: "", influencer: { name: "sam", x: 0, y: 0 }, product: { name: "", x: 10, y: 110 } },
		{ photo: "", influencer: { name: "sam", x: 0, y: 0 }, product: { name: "", x: 10, y: 200 } },
		{ photo: "", influencer: { name: "sam", x: 0, y: 0 }, product: { name: "", x: 10, y: 200 } },
		{ photo: "", influencer: { name: "sam", x: 0, y: 0 }, product: { name: "", x: 10, y: 200 } },
		{ photo: "", influencer: { name: "sam", x: 0, y: 0 }, product: { name: "", x: 10, y: 200 } },
		{ photo: "", influencer: { name: "sam", x: 0, y: 0 }, product: { name: "", x: 10, y: 200 } },
		{ photo: "", influencer: { name: "sam", x: 0, y: 0 }, product: { name: "", x: 10, y: 200 } },
		{ photo: "", influencer: { name: "sam", x: 0, y: 0 }, product: { name: "", x: 10, y: 200 } },
		{ photo: "", influencer: { name: "sam", x: 0, y: 0 }, product: { name: "", x: 10, y: 200 } },
	])
}
