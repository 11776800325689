import * as layouts from "layouts"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import * as api from "./api"
import * as icons from "icons"

interface locationState {
	name: string
	photo: string
	processes: { name: string; background: string; photo: string; date: string; status: string }
}
export default function Products() {
	const [prods, setProds] = useState([] as api.Placement[])
	const [loading, setLoading] = useState(true)
	const location = useLocation()
	const infstate = (location.state as locationState) || {
		name: "",
		photo: "",
		processes: { name: "", background: "", photo: "", date: "", status: "" },
	}
	useEffect(() => {
		api
			.products(0)
			.then((prods) => {
				let productImage = prods.map((item) => ({
					...item,
				}))
				setProds(productImage)
			})
			.finally(() => setLoading(false))
	}, [])
	return (
		<layouts.loading.pending loading={loading}>
			<layouts.containers.box styled width="75%" m="auto">
				<layouts.containers.span fontWeight="bold" fontSize="large">
					List of Products
				</layouts.containers.span>
				<layouts.containers.grid
					display="grid"
					grid-gap="10px"
					padding="10px"
					width="100%"
					height="600px"
					overflowX="auto"
					overflowY="auto"
					textAlign="justify"
				>
					{prods.map((prod, idx) => (
						<layouts.containers.box p="5px" key={idx}>
							{prod.photo === "" ? (
								<icons.Person fill="grey" height="150px" />
							) : (
								<img height="150px" src={prod.photo} alt="influencer" />
							)}
							<Link
								to="dragNscale"
								state={{
									background: infstate.processes.background,
									influencer: {
										name: infstate.name,
										photo: infstate.photo,
										x: prod.influencer.x,
										y: prod.influencer.y,
									},
									product: {
										name: infstate.processes.name,
										photo: infstate.processes.photo,
										x: prod.product.x,
										y: prod.product.y,
									},
								}}
							>
								<layouts.buttons.primary width="50%">Select</layouts.buttons.primary>
							</Link>
							<input type="radio" className="btn-check" name={prod.product.name + idx} id={idx + "a"} />
							<label className="btn btn-outline-success" htmlFor={idx + "a"}>
								Yes
							</label>
							<input type="radio" className="btn-check" name={prod.product.name + idx} id={idx + "b"} />
							<label className="btn btn-outline-danger" htmlFor={idx + "b"}>
								No
							</label>
						</layouts.containers.box>
					))}
				</layouts.containers.grid>
			</layouts.containers.box>
		</layouts.loading.pending>
	)
}
