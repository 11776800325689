import React, { useState, useEffect } from "react"
import * as httpx from "httpx"
import * as errors from "errors"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as authzgsuite from "authz/gsuite"
import * as api from "./api"

interface props {
	credential: authzgsuite.api.Credential
	onChange(upd: undefined | api.GAAccountInfo): Promise<unknown>
	search(req: api.AccessibleRequest, ...options: httpx.option[]): Promise<api.AccessibleResponse>
}

export function Search(props: React.PropsWithChildren<props & layouts.containers.FlexProps>): JSX.Element {
	const { credential, onChange, search, ...rest } = props
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [loading, setLoading] = useState(true)
	const [accounts, setAccounts] = useState([] as api.GAAccountInfo[])
	const bearertoken = sessions.useToken()

	useEffect(() => {
		if (credential === undefined) {
			return
		}
		setLoading(true)
		search({ authz_credentials_id: credential.id }, bearertoken)
			.then((resp) => {
				return resp.items.reduce(async (pending, id) => {
					return pending.then(async (n) => {
						return api.accounts
							.info({ id: id, authz_credentials_id: credential.id, offset: "" }, bearertoken)
							.then((r) => {
								return [...n, ...(r.items || [])]
							})
					})
				}, Promise.resolve([] as api.GAAccountInfo[]))
			})
			.then((result) => setAccounts(result))
			.catch((c: unknown) => {
				setCause(
					<errors.Textual cause={c} onClick={() => setCause(undefined)}>
						unable to retrieve accounts
					</errors.Textual>,
				)
			})
			.finally(() => setLoading(false))
	}, [credential])

	return (
		<layouts.containers.flex {...rest}>
			<errors.overlay styled cause={cause} flex="1">
				<layouts.loading.screen loading={loading} flex="1">
					<layouts.tables.container flex="1">
						<table>
							<thead>
								<tr>
									<th>ID</th>
									<th>Description</th>
									<th>Test Account</th>
									<th>Managed By</th>
									<th>Hidden</th>
								</tr>
							</thead>

							<tbody>
								{/* hack to ensure the table doesn't jitter once content is loaded */}
								<layouts.tables.Row style={{ visibility: "collapse" }}>
									<td>0000000000000000000</td>
									<td>0000000000000000000</td>
									<td>false</td>
									<td>0000000000000000000</td>
									<td>false</td>
								</layouts.tables.Row>
								{accounts.map((c) => (
									<React.Fragment key={c.id}>
										<layouts.tables.Row
											onClick={(evt) => {
												setLoading(true)
												onChange(c).finally(() => setLoading(false))
											}}
										>
											<td>{c.id}</td>
											<td>{c.description}</td>
											<td>{(c.test_account || false).toString()}</td>
											<td>{c.managed_by}</td>
											<td>{(c.hidden || false).toString()}</td>
										</layouts.tables.Row>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</layouts.tables.container>
				</layouts.loading.screen>
			</errors.overlay>
		</layouts.containers.flex>
	)
}

Search.defaultProps = {
	search: api.accounts.accessible,
}

export default Search
