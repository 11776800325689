import * as layouts from "layouts"
import styled from "@emotion/styled"

interface inputProps extends layouts.containers.ContainerProps {
	disabled?: boolean
}

const Input = styled.select<inputProps>`
	box-sizing: border-box;
	width: 100%;
	height: 48px;
	top: 26px;
	gap: 10px;
	border-radius: 6px;
	background: ${(props) => props.disabled && "#F5F5F5"};
	background-color: ${(props) => props.disabled && "#F5F5F5"};
`

export default Input
