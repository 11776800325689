import React, { useEffect, useState } from "react"
import * as httpx from "httpx"
import * as uuid from "uuid"
import * as icons from "icons"
import * as sessions from "sessions"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as oauth2 from "authz/oauth2"
import * as api from "./api"
import Textual from "./textual"

interface props {
	current?: api.Credential
	onCreate?(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<api.Credential>
	onChange(upd: undefined | api.Credential): void
}

export async function authorize(bearertoken: httpx.option, scopes: string[]): Promise<api.Credential> {
	return api.authorize_url({ cid: uuid.v4(), scopes: scopes }, bearertoken).then(async (auth) => {
		return oauth2.pendingauth(auth).then((r) => api.find(r.cid, bearertoken).then((r) => r.credential))
	})
}

export default function Search(props: props & layouts.containers.FlexProps): JSX.Element {
	const { current, onChange, onCreate, ...rest } = props
	const [display, setDisplay] = useState(false)
	const [initialLoad, setInitialLoad] = useState(true)
	const [loading, setLoading] = useState(true)
	const bearertoken = sessions.useToken()
	const [search, setSearch] = useState({
		query: "",
	} as api.SearchRequest)

	const [credentials, setCredentials] = useState({
		items: [],
	} as api.SearchResponse)

	useEffect(() => {
		setLoading(true)
		api
			.search(search, bearertoken)
			.then((creds) => {
				setCredentials(creds)
				if (initialLoad && creds.items.length <= 1) {
					onChange(creds.items.find(() => true))
				}
			})
			.catch(console.error)
			.finally(() => {
				setInitialLoad(false)
				setLoading(false)
			})
	}, [search])

	const _onCreate =
		onCreate &&
		function (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
			setLoading(true)
			onCreate(evt)
				.then((creds) => onChange(creds))
				.finally(() => setLoading(false))
		}

	const prompt = current ? (
		<layouts.containers.flex m="auto">
			<Textual mr="7px" current={current} />
			<layouts.containers.span px="5px" height="20px" onClick={() => onChange(undefined)}>
				<layouts.containers.span ml="auto" width="14px" height="14px">
					<icons.Trash />
				</layouts.containers.span>
			</layouts.containers.span>
		</layouts.containers.flex>
	) : (
		<layouts.containers.flex width="100%">
			<inputs.Text
				placeholder="search google authorizations"
				defaultValue={search.query}
				onBlur={(evt) => _displayed && evt.stopPropagation()}
				onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
					setSearch({ ...search, query: evt.currentTarget.value })
				}
			/>
			<layouts.buttons.unstyled
				mx="5px"
				width="164px"
				borderRadius="0.25em"
				padding="0px"
				cursor="pointer"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.hinting.darkest}
				color={layouts.theme.colors.black.medium}
				onBlur={(evt) => _displayed && evt.stopPropagation()}
				onClick={_onCreate}
			>
				<layouts.containers.span verticalAlign="middle" display="inline-block">
					<icons.Google width="32px" height="32px" ml="-10px" />
				</layouts.containers.span>
				<layouts.containers.span verticalAlign="middle" display="inline-block">
					Google
				</layouts.containers.span>
			</layouts.buttons.unstyled>
		</layouts.containers.flex>
	)

	const _displayed = current === undefined && credentials.items.length > 0

	return (
		<layouts.containers.flex
			{...rest}
			flexDirection="column"
			onFocus={(evt) => setDisplay(true)}
			onBlur={(evt) => setDisplay(false)}
		>
			<layouts.containers.flex flexDirection="column" width="100%">
				{prompt}
				{_displayed && display && (
					<layouts.loading.pending loading={loading} icon={undefined}>
						<layouts.containers.flex position="relative">
							<layouts.containers.flex className="dropdown" position="absolute" mt="5px" width="100%">
								<layouts.containers.flex
									styled
									p="5px"
									flex="1"
									borderRadius={layouts.theme.borders.radius.standard}
									flexDirection="column"
								>
									{credentials.items.map((c, idx) => (
										<Textual
											tabIndex={idx}
											key={c.id}
											current={c}
											onKeyUp={(evt) => {
												if (evt.key !== "Enter") return
												onChange(c)
											}}
											onClick={() => {
												onChange(c)
											}}
											display="block"
											p="5px"
											borderRadius={layouts.theme.borders.radius.standard}
											flex="1"
										/>
									))}
								</layouts.containers.flex>
							</layouts.containers.flex>
						</layouts.containers.flex>
					</layouts.loading.pending>
				)}
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
