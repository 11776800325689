import { useRef } from "react"
import interact from "interactjs"
import * as layouts from "layouts"
import { useLocation } from "react-router-dom"
import styled from "@emotion/styled"
import { LayoutProps } from "styled-system"
import { exportComponentAsPNG } from "react-component-export-image"

const Img = styled.img<LayoutProps>`
	width: 50vw;
	height: 50vh;
	margin: auto;
	background: #ededed;
	border-radius: 15px;
	border: none;
	position: absolute;
`
interface locationState {
	background: string
	influencer: {
		name: string
		photo: string
		x: number
		y: number
	}
	product: {
		name: string
		photo: string
		x: number
		y: number
	}
}
export default function DragNscale(): JSX.Element {
	const location = useLocation()
	var infstate = (location.state as locationState) || {
		background: "",
		influencer: { name: "", photo: "", x: 0, y: 0 },
		product: { name: "", photo: "", x: 0, y: 0 },
	}
	const background = infstate.background
	const product = infstate.product.photo
	const influencer = infstate.influencer.photo
	console.log(background)
	const panelRef = useRef<HTMLDivElement>(null)

	interact(".resize-drag").resizable({
		edges: { left: true, right: true, bottom: true, top: true },
		listeners: {
			move(event) {
				var target = event.target
				var x = parseFloat(target.getAttribute("data-x")) || 0
				var y = parseFloat(target.getAttribute("data-y")) || 0
				target.style.width = event.rect.width + "px"
				target.style.height = event.rect.height + "px"
				// translate when resizing from top or left edges
				x += event.deltaRect.left
				y += event.deltaRect.top
				target.style.transform = "translate(" + x + "px," + y + "px)"
				target.setAttribute("data-x", x)
				target.setAttribute("data-y", y)
			},
		},
		modifiers: [
			interact.modifiers.restrict({
				restriction: "parent",
				endOnly: true,
			}),
		],
		inertia: false,
	})
	interact(".resize-drag").draggable({
		listeners: {
			start(event) {
				//event.target.setAttribute("data-x", props.x)
				//event.target.setAttribute("data-y", props.y)
				//event.target.style.transform = `translate(${props.x}px, ${props.y}px)`
			},
			move(event) {
				var target = event.target
				var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx
				var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy
				event.target.setAttribute("data-x", x)
				event.target.setAttribute("data-y", y)
				// causes imgage to move
				event.target.style.transform = `translate(${x}px, ${y}px)`
			},
		},
		modifiers: [
			interact.modifiers.restrictRect({
				restriction: "parent",
				endOnly: true,
			}),
		],
	})
	return (
		<layouts.containers.box styled width="75%" m="auto">
			<layouts.containers.span fontWeight="bold" fontSize="100%" p="20px">
				Product Placement
			</layouts.containers.span>
			<layouts.containers.flex text-align="center" height="60vh"></layouts.containers.flex>
			<layouts.containers.absolute top="30vh" left="25vw" width="50vw" height="50vh" ref={panelRef}>
				<layouts.containers.absolute zIndex={0} height="50vh">
					<Img src={background} alt="background" />
				</layouts.containers.absolute>
				<layouts.containers.absolute
					className="resize-drag"
					top={infstate.product.y}
					left={infstate.product.x}
					zIndex={2}
					height="20vh"
					width="20vh"
				>
					<img src={product} height="100%" width="100%" alt="prod" />
				</layouts.containers.absolute>
				<layouts.containers.absolute
					className="resize-drag"
					top={infstate.influencer.y}
					left={infstate.influencer.x}
					zIndex={1}
					height="20vh"
					width="20vh"
				>
					<img src={influencer} height="100%" width="100%" alt="infl" />
				</layouts.containers.absolute>
			</layouts.containers.absolute>
			<layouts.containers.flex pl="15%" pr="15%">
				<layouts.containers.flex ml="auto">
					<layouts.buttons.primary onClick={() => exportComponentAsPNG(panelRef)}>Download</layouts.buttons.primary>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.box>
	)
}
