import * as layouts from "layouts"
import * as icons from "icons"
import * as api from "./api"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

type infl = api.Influencer
export default function Influencers(): JSX.Element {
	const [infls, setInfls] = useState([] as infl[])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		api
			.index(0)
			.then((data) => {
				let influencerImage = data.map(
					(item) =>
						({
							...item,
						} as infl),
				)
				setInfls(influencerImage)
			})
			.finally(() => setLoading(false))
	}, [])
	return (
		<layouts.loading.pending loading={loading}>
			<layouts.containers.box styled width="75%" m="auto">
				<layouts.containers.span fontWeight="bold" fontSize="100%" p="20px">
					List of Influencers
				</layouts.containers.span>
				<layouts.containers.grid
					display="grid"
					grid-gap="10px"
					padding="10px"
					width="100%"
					height="600px"
					overflowX="auto"
					overflowY="auto"
					textAlign="justify"
				>
					{infls.map((infl, idx) => (
						<layouts.containers.box p="5px" key={idx}>
							<h3>{infl.name}</h3>
							{infl.photo === "" ? (
								<icons.Person fill="grey" height="150px" />
							) : (
								<img height="150px" src={infl.photo} alt="influencer" />
							)}
							<Link to="upload" state={{ name: infl.name, photo: infl.photo, processes: infl.processes }}>
								<layouts.buttons.primary width="65%">Create</layouts.buttons.primary>
							</Link>
							<icons.Trash pl="10px" />
						</layouts.containers.box>
					))}
				</layouts.containers.grid>
			</layouts.containers.box>
		</layouts.loading.pending>
	)
}
