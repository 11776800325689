import { useEffect, useState } from "react"
import styled from "@emotion/styled"
import * as api from "./api"
import * as layouts from "layouts"
import * as errors from "errors"
import * as sessions from "sessions"

const NameTD = styled.td`
	padding-left: 10px;
	padding-top: -10px;
`

const StyledHeader = styled.th`
	padding-left: 12px;
	padding-top: 12px;
`

export function Table(props: { job: { id: string } }): JSX.Element {
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)
	const bearertoken = sessions.useToken()

	const [results, setResults] = useState({
		items: [],
		cursor: {
			offset: "",
		},
	} as api.HistorySearchResponse)

	useEffect(() => {
		api.history
			.search(props.job.id, { offset: "00000000-0000-0000-0000-000000000000" }, bearertoken)
			.then((r) => {
				setResults(r)
			})
			.catch((c: unknown) => {
				console.error("unable to retrieve history results", c)
				setCause(<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve promotions</errors.Textual>)
			})
			.finally(() => setLoading(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<layouts.containers.flex className="competitorpromotions" flexDirection="column" flex="1" p="20px">
			<layouts.loading.pending loading={loading}>
				<errors.overlay styled cause={cause}>
					<layouts.tables.container>
						<table>
							<thead>
								<tr>
									<StyledHeader>Id</StyledHeader>
									<StyledHeader>Source</StyledHeader>
									<StyledHeader>Title</StyledHeader>
									<StyledHeader>Retrieved At</StyledHeader>
									<StyledHeader>Price</StyledHeader>
								</tr>
							</thead>
							<tbody>
								{results.items.map((g: api.GoogleShoppingIngestionResults) => (
									<layouts.tables.Row key={g.id}>
										<NameTD>{g.id}</NameTD>
										<NameTD>{g.source}</NameTD>
										<NameTD>{g.title}</NameTD>
										<StyledHeader>{g.created_at}</StyledHeader>
										<NameTD>{g.price}</NameTD>
									</layouts.tables.Row>
								))}
							</tbody>
						</table>
					</layouts.tables.container>
				</errors.overlay>
			</layouts.loading.pending>
		</layouts.containers.flex>
	)
}
