import React from "react"
import * as luxon from "luxon"
import styled from "@emotion/styled"
import { css } from "@emotion/css"
import * as system from "styled-system"
import classnames from "classnames"
import * as containers from "./containers"
import * as theme from "./theme"
import { Boundary } from "errors"

const defaults = {
	className: "",
}

const outer = css`
	box-shadow: ${theme.boxshadow.default};
	background-color: ${theme.colors.white};
`

const content = css`
	flex: 1;

	table {
		height: fit-content;
		width: 100%;
		color: ${theme.colors.navy};
		background-color: ${theme.colors.white};

		thead {
			// CSS 3+ sticky headers.
			position: sticky;
			top: 0px;

			tr {
				background-color: ${theme.colors.grey.lightest};
				height: 48px;
				font-weight: bold;
				text-transform: capitalize;

				// CSS 2.1+ sticky headers. (chrome)
				th {
					position: sticky;
					top: 0px;
				}

				th {
					padding: 12px;
					text-align: start;
				}
			}
		}

		tbody {
			animation: fadeIn 0.5s;
			overflow: auto;

			td {
				padding: 12px;
			}
		}
	}
`

export const Row = styled.tr<
	system.TextAlignProps & system.ColorProps & system.BackgroundProps & system.BorderProps & system.LayoutProps
>`
	height: 48px;
	text-align: start;
	cursor: ${(props) => (props.onClick ? "pointer" : "default")};
	&:hover {
		color: ${theme.colors.grey.lightest};
		background: ${theme.colors.hinting.lightest};
	}

	${system.background}
	${system.color}
	${system.textAlign}
	${system.border}
	${system.layout}
`

const inlinestyle = css`
	&.inlined:hover {
		color: unset;
		background: unset;
	}
`
interface inlineprops extends containers.ContainerProps {
	hover?: boolean
}
// inline row it'll span the entire width of the table. this component is useful
// for displaying content above/below a row inline of a table.
export function inline(props: React.PropsWithChildren<inlineprops>): JSX.Element {
	// a colSpan of 1000 is the maximum value allowed for colSpan per https://developer.mozilla.org/en-US/docs/Web/HTML/Element/td
	return (
		<Row className={classnames("inlined", props.hover ? undefined : inlinestyle, props.className)}>
			<td colSpan={1000}>{props.children}</td>
		</Row>
	)
}

interface checkedProps extends containers.ContainerProps {
	checked: boolean
	onChange(val: boolean): void
	className?: string
}

export function checkedRow(props: React.PropsWithChildren<checkedProps>): JSX.Element {
	return (
		<Row>
			<td>
				<input type="checkbox" checked={props.checked} onChange={() => props.onChange(!props.checked)} />
			</td>
			{props.children}
		</Row>
	)
}

// implements a sticky header table.
export function container(
	props: React.PropsWithChildren<containers.ContainerProps & system.TextAlignProps>,
): JSX.Element {
	props = {
		...defaults,
		...props,
	}

	const { className, children, ...rest } = props

	return (
		<containers.flex position="relative" className={classnames("table-container", className)} {...rest}>
			<containers.box
				width="100%"
				height="100%"
				borderRadius={theme.borders.radius.standard}
				overflow="auto"
				className={classnames("table-content-outer", outer)}
			>
				<containers.flex
					width="inherit"
					height="inherit"
					flexDirection="column"
					className={classnames("table-content", content)}
				>
					<Boundary>{children}</Boundary>
				</containers.flex>
			</containers.box>
		</containers.flex>
	)
}

export function TableDate(date: string) {
	return luxon.DateTime.fromISO(date).toFormat("LLL dd yyyy")
}

export function Timestamp(date: string) {
	return luxon.DateTime.fromISO(date).toFormat("LLL dd yyyy HH:mm:ss")
}
