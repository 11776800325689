import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import * as uuid from "uuid"
import * as typography from "typography"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as authzgsuite from "authz/gsuite"
import * as api from "./api"
import * as clientaccount from "./client.account.search"
import * as gaaccounts from "./client.account.available"
import * as brands from "brands"
import * as adsrouting from "ads/pools/routing"

interface props {
	current: Promise<api.ClientAccount>
	onChange(c: undefined | api.ClientAccount): void
}

export function InitClient(props: props & layouts.containers.FlexProps): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const location = useLocation()
	const { current, onChange, ...rest } = props
	const [loading, setLoading] = useState(true)
	const brand = brands.caching.useCached()
	const [caccount, setClientAccount] = useState(undefined as undefined | api.ClientAccount)
	const [credential, setCredential] = useState(undefined as undefined | authzgsuite.api.Credential)
	const bearertoken = sessions.useToken()

	useEffect(() => {
		current
			.then((ca) => {
				if (ca.id === uuid.NIL) {
					setClientAccount(undefined)
					return
				}

				setClientAccount(ca)
				return new Promise((resolve, reject) => {
					if (ca.authz_credentials_id === uuid.NIL) {
						return resolve(ca)
					}

					authzgsuite.api
						.find(ca.authz_credentials_id, bearertoken)
						.then((r) => setCredential(r.credential))
						.then(() => resolve(ca))
						.catch(reject)
				})
			})
			.finally(() => setLoading(false))
	}, [current])

	const onCreate = () => {
		if (credential === undefined) {
			return
		}

		mtoggle(
			<gaaccounts.Search
				minWidth="512px"
				minHeight="512px"
				credential={credential}
				onChange={async (info) => {
					if (info === undefined) return Promise.resolve()
					return api.accounts
						.create(
							{
								client_account: api.accounts.zero({
									customer_id: BigInt(info.id),
									manager_id: BigInt(info.managed_by),
									authz_credentials_id: credential.id,
									description: info.description,
								}),
							},
							bearertoken,
						)
						.then((resp) => {
							onChange(resp.client_account)
							mtoggle(undefined)
							return resp
						})
				}}
			/>,
		)
	}

	const onImport = () => {
		if (credential === undefined) {
			return
		}

		mtoggle(
			<gaaccounts.Search
				minWidth="512px"
				minHeight="512px"
				credential={credential}
				onChange={async (info) => {
					if (info === undefined) return Promise.resolve()
					return api.accounts.imports
						.create(
							{
								brand_id: brand.id,
								authz_credentials_id: credential.id,
								manager_id: BigInt(info.managed_by),
								customer_id: BigInt(info.id),
							},
							bearertoken,
						)
						.then((resp) => {
							mtoggle(undefined)
							return resp
						})
				}}
			/>,
		)
	}

	const onRetarget = () => {
		api.accounts.retarget_imports.create({}, bearertoken)
	}

	return (
		<layouts.containers.flex {...rest}>
			<layouts.containers.flex
				styled
				borderRadius={layouts.theme.borders.radius.standard}
				className="google clients"
				flexDirection="column"
				overflow="auto"
				flex="1"
				p="10px"
				zIndex={1}
			>
				<layouts.loading.screen loading={loading} icon={<></>} flex="1">
					<layouts.containers.flex flexDirection="row">
						<typography.h3 width="18ch">Authorize With</typography.h3>
						<authzgsuite.Search
							ml="auto"
							current={credential}
							onChange={(creds) => {
								setCredential(creds)
							}}
							onCreate={() => authzgsuite.authorize(bearertoken, [authzgsuite.api.scopes.adwords])}
						/>
					</layouts.containers.flex>
				</layouts.loading.screen>
				<layouts.loading.screen loading={credential === undefined} icon={<></>} flex="1">
					<layouts.containers.flex flexDirection="row" mb="20px">
						<typography.h3 width="18ch">Google Ad Account</typography.h3>
						<clientaccount.Search ml="auto" current={caccount} onChange={onChange} onCreate={onCreate} />
					</layouts.containers.flex>
				</layouts.loading.screen>
				<layouts.loading.screen loading={credential === undefined} icon={<></>} flex="1">
					<layouts.containers.flex flexDirection="row" mb="20px">
						<typography.h3 width="18ch">Import Ads</typography.h3>
						<layouts.buttons.outline onClick={onImport}>Import</layouts.buttons.outline>
						<layouts.buttons.outline onClick={onRetarget}>Retarget</layouts.buttons.outline>
					</layouts.containers.flex>
				</layouts.loading.screen>
				<layouts.containers.flex justifyContent="end">
					<Link to={adsrouting.display(location, "google/create")}>
						<layouts.buttons.primary disabled={!caccount || !credential}>Continue</layouts.buttons.primary>
					</Link>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export default InitClient
