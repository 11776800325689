import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import * as uuid from "uuid"
import * as typography from "typography"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as authzfacebook from "authz/facebook"
import * as api from "./api"
import * as clientaccount from "./client.account.search"
import * as accounts from "./client.account.create"
import { useLocation } from "react-router-dom"
import * as adsrouting from "ads/pools/routing"

interface props {
	current: Promise<api.ClientAccount>
	onChange(c: undefined | api.ClientAccount): void
}

export function InitClient(props: props & layouts.containers.FlexProps): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const location = useLocation()

	const { current, onChange, ...rest } = props
	const [loading, setLoading] = useState(true)
	const [caccount, setClientAccount] = useState(undefined as undefined | api.ClientAccount)
	const [credential, setCredential] = useState(undefined as undefined | authzfacebook.api.Credential)
	const bearertoken = sessions.useToken()

	useEffect(() => {
		current
			.then((ca) => {
				if (ca.id === uuid.NIL) {
					setClientAccount(undefined)
					return
				}

				setClientAccount(ca)
				return new Promise((resolve, reject) => {
					if (ca.authz_credentials_id === uuid.NIL) {
						return resolve(ca)
					}

					authzfacebook.api
						.find(ca.authz_credentials_id, bearertoken)
						.then((r) => setCredential(r.credential))
						.then(() => resolve(ca))
						.catch(reject)
				})
			})
			.finally(() => setLoading(false))
	}, [current])

	const onCreate = () => {
		if (credential === undefined) {
			return
		}

		mtoggle(
			<accounts.Create
				p="10px"
				credential={credential}
				onChange={async (info) => {
					if (info === undefined) {
						mtoggle(undefined)
						return Promise.resolve(info)
					}

					return api.accounts.create({ client_account: info }, bearertoken).then((resp) => {
						onChange(resp.client_account!)
						mtoggle(undefined)
						return resp.client_account!
					})
				}}
			/>,
		)
	}

	return (
		<layouts.containers.flex {...rest}>
			<layouts.containers.flex
				styled
				borderRadius={layouts.theme.borders.radius.standard}
				className="facebook clients"
				flexDirection="column"
				overflow="auto"
				flex="1"
				p="10px"
				zIndex={1}
			>
				<layouts.loading.screen loading={loading} flex="1" icon={<></>}>
					<layouts.containers.flex flexDirection="row">
						<typography.h3 width="18ch">Authorize With</typography.h3>
						<authzfacebook.Search
							ml="auto"
							current={credential}
							onChange={(creds) => {
								setCredential(creds)
							}}
							onCreate={() => {
								return new Promise((resolve, reject) => {
									mtoggle(
										<authzfacebook.Modal
											onChange={(creds) => {
												if (creds === undefined) return reject("cancelled")
												resolve(creds)
											}}
											scopes={[
												authzfacebook.api.scopes.business_management,
												authzfacebook.api.scopes.ads_management,
												authzfacebook.api.scopes.page_engagement,
												authzfacebook.api.scopes.page_manage_ads,
											]}
										/>,
									)
								})
							}}
						/>
					</layouts.containers.flex>
				</layouts.loading.screen>
				<layouts.loading.screen loading={credential === undefined} icon={<></>} flex="1">
					<layouts.containers.flex flexDirection="row" mb="20px">
						<typography.h3 width="18ch">Facebook Ad Account</typography.h3>
						<clientaccount.Search ml="auto" current={caccount} onChange={onChange} onCreate={onCreate} />
					</layouts.containers.flex>
				</layouts.loading.screen>
				<layouts.containers.flex justifyContent="end">
					<Link to={adsrouting.display(location, "facebook/create")}>
						<layouts.buttons.primary disabled={!caccount || !credential}>Continue</layouts.buttons.primary>
					</Link>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export default InitClient
