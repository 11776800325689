import React, { useEffect, useState } from "react"
import * as sessions from "sessions"
import * as brands from "brands"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import * as bg_layouts from "./layouts"
import { Image } from "media/image"
import * as mediaapi from "media/api"

interface props {
	item: api.ImageSearchResponseItem
	active?: boolean
	onCardClick(): void
	onImageUpdate(hitem: api.ImageSearchResponseItem): void
}

interface propsUpdatableItem {
	item: api.ImageSearchResponseItem
	onChange(item: api.ImageSearchResponseItem): void
}

function inverseprediction(p: api.Prediction): api.Prediction {
	if (p === api.Prediction.NONE) {
		return p
	}

	return p === api.Prediction.APPROVED ? api.Prediction.REJECTED : api.Prediction.APPROVED
}

function ActionPanel(props: propsUpdatableItem): JSX.Element {
	const { item } = props
	const bearertoken = sessions.useToken()
	const [loading, setLoading] = useState(false)
	const prediction = item.event?.approved || api.Prediction.NONE
	const observation = item.event?.observation || api.Prediction.NONE
	const inversed = inverseprediction(prediction)
	const status = api.predictionString(prediction)
	const agreement = prediction === observation

	return (
		<bg_layouts.ActionPanelStyle backgroundColor={bg_layouts.StatusColor(props)}>
			<bg_layouts.StatusTextStyle>
				<bg_layouts.IconStatus item={item} />
				<layouts.containers.span ml="10px">{status}</layouts.containers.span>
			</bg_layouts.StatusTextStyle>
			{!api.isProcessing(item) && (
				<layouts.loading.screen loading={loading} cursor="not-allowed" opacity="0" icon={<></>} justifyContent="center">
					<bg_layouts.FeedbackContainerStyle>
						<layouts.containers.span mr="10px">Do you agree?</layouts.containers.span>
						<bg_layouts.FeedbackYes
							active={observation === api.Prediction.NONE ? false : agreement}
							onClick={(evt) => {
								if (item.media === undefined || item.event === undefined) {
									return
								}

								setLoading(true)
								api.images.uploads
									.patch(
										item.media.brand_id,
										item.media.id,
										{
											...item,
											event: { ...item.event, observation: prediction },
										},
										bearertoken,
									)
									.then((r) => {
										props.onChange(r)
									})
									.finally(() => setLoading(false))
							}}
						/>
						<bg_layouts.FeedbackNo
							active={observation === api.Prediction.NONE ? false : !agreement}
							onClick={(evt) => {
								if (item.media === undefined || item.event === undefined) {
									return
								}

								setLoading(true)
								api.images.uploads
									.patch(
										item.media.brand_id,
										item.media.id,
										{ ...item, event: { ...item.event, observation: inversed } },
										bearertoken,
									)
									.then((r) => {
										props.onChange(r)
									})
									.finally(() => setLoading(false))
							}}
						/>
					</bg_layouts.FeedbackContainerStyle>
				</layouts.loading.screen>
			)}
		</bg_layouts.ActionPanelStyle>
	)
}

export default function DisplayImage(props: props & React.PropsWithChildren<unknown>): JSX.Element {
	const { item, active, onImageUpdate, onCardClick } = props
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()

	useEffect(() => {
		let mounted = true
		if (api.isProcessing(item)) {
			setTimeout(() => {
				api.images.uploads
					.find(item.media?.id!, brand.id, bearertoken)
					.then((result) => {
						if (mounted) onImageUpdate(result)
					})
					.catch((e) => console.log(e))
			}, 5000)
		}
		return () => {
			mounted = false
		}
	}, [item])

	return (
		<bg_layouts.Card key={item.media?.id} active={active} onClick={onCardClick}>
			<ActionPanel item={item} onChange={onImageUpdate}></ActionPanel>
			<Image
				media={mediaapi.zero({ content_digest: item.media?.md5 })}
				background={layouts.theme.colors.grey.lightest}
				opacity={api.isProcessing(item) ? 0.3 : 1}
			/>
		</bg_layouts.Card>
	)
}
