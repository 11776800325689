import React, { useState } from "react"
import * as layouts from "layouts"
import styled from "@emotion/styled"
import { Link, useLocation } from "react-router-dom"
import * as inputs from "inputs"

const Img = styled.img`
	height: inherit;
	width: inherit;
	margin: auto;
	background: #ededed;
	border-radius: 15px;
	border: none;
`
interface locationState {
	name: string
	photo: string
	processes: { name: string; background: string; photo: string; date: string; status: string }[]
}
interface tempState {
	infstate: locationState
	name: string
	background: string
}

export default function Upload(): JSX.Element {
	const [product, setProductInner] = useState("")
	const location = useLocation()
	var uploadState = (location.state as tempState) || {
		name: "",
		photo: "",
		processes: { name: "", background: "", photo: "", date: "", status: "" },
	}
	var today = new Date()
	const todaysDate = today.getMonth() + "/" + today.getDate() + "/" + today.getFullYear()
	function setProduct(f: File | undefined) {
		f && setProductInner(URL.createObjectURL(f))
		//console.log({ product })
	}
	const BtnGroup = () => {
		if (!product) {
			return (
				<inputs.File
					onChange={(e) => {
						setProduct(e.currentTarget.files?.length ? e.currentTarget.files[0] : undefined)
					}}
				>
					{" "}
					<layouts.buttons.primary>Upload</layouts.buttons.primary>
				</inputs.File>
			)
		}
		return (
			<>
				<layouts.containers.box textAlign="right">
					<Link
						to="processes"
						state={{
							name: uploadState.infstate.name,
							photo: uploadState.infstate.photo,
							processes: [
								...uploadState.infstate.processes,
								{
									name: uploadState.name,
									background: uploadState.background,
									photo: product,
									date: todaysDate,
									status: "Completed",
								},
							],
						}}
					>
						<layouts.buttons.primary>Confirm</layouts.buttons.primary>
					</Link>
				</layouts.containers.box>
			</>
		)
	}

	return (
		<layouts.containers.box styled width="75%" m="auto">
			<layouts.containers.span fontWeight="bold" fontSize="100%" p="20px">
				Upload Product
			</layouts.containers.span>
			<layouts.containers.box m="auto" p="10px">
				<inputs.Dropwell onDrop={(accepted, rejected, evt) => (accepted.length ? setProduct(accepted[0]) : undefined)}>
					<layouts.containers.flex flex="1" flexDirection="column" m="auto" height="50vh" width="50vw">
						<Img src={product} />
					</layouts.containers.flex>
				</inputs.Dropwell>
			</layouts.containers.box>
			<layouts.containers.box m="auto" textAlign="center">
				<BtnGroup />
			</layouts.containers.box>
		</layouts.containers.box>
	)
}
