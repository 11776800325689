import React, { useState } from "react"
import * as errors from "errors"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as api from "./api"
import Edit from "./image.crop.modify"

// Regenerate will cycle through the history of processed pets and regenerate the outputs
// with the latest settings.
export default function Regenerate(props: React.PropsWithChildren<unknown>): JSX.Element {
	let [cause, setCause] = useState(undefined as JSX.Element | undefined)
	let [loading, setLoading] = useState(false)
	let [crop, setCrop] = useState({
		image_url: "",
		background_url: "",
		product_id: 0,
		product_variant_id: 0,
		line_item_id: 0,
		effect: 0,
		order_id: 0,
		smooth_edges_threshold: 0,
	} as api.CropRequest)
	let [historyReq, sethistoryReq] = useState({
		offset: "results",
	} as api.HistoryRequest)
	let [progress, setProgress] = useState({
		current: "",
		total: 0,
	})

	function recurse(req: api.HistoryRequest): Promise<api.HistoryResponse> {
		return api.history(req).then((resp) => {
			let done = resp.items.reduce(async (pending, item) => {
				const attempt = () =>
					api.metadata({ metadata: item }).then((r) => {
						const upd = { ...r.metadata, smooth_edges_threshold: crop.smooth_edges_threshold, effect: crop.effect }
						return api.crop(upd)
					})
				return pending.then(attempt).finally(() => {
					progress.total = progress.total + 1
					setProgress({ current: item, total: progress.total })
				})
			}, Promise.resolve({ status: "" }))

			return done.then(() => {
				return resp.cursor === "" ? resp : recurse({ ...req, offset: resp.cursor })
			})
		})
	}

	function perform(req: api.HistoryRequest): void {
		console.log("history initiated")
		setLoading(true)
		setProgress({ current: "", total: 0 })
		recurse(req)
			.catch((cause) => {
				setCause(
					<layouts.containers.box m="auto">
						<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
							Image Crop Regen Failed
						</errors.Textual>
					</layouts.containers.box>,
				)
			})
			.finally(() => {
				console.log("history completed")
				setLoading(false)
			})
	}

	return (
		<layouts.containers.flex m="auto">
			<layouts.containers.box styled width="64ch">
				<layouts.overlays.Container>
					<errors.overlay styled cause={cause}>
						<fieldset>
							<layouts.forms.Group className="mb-3">
								<layouts.forms.Label>Offset</layouts.forms.Label>
								<inputs.Text
									defaultValue={historyReq.offset}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										sethistoryReq({ ...historyReq, offset: evt.currentTarget.value })
									}
								/>
							</layouts.forms.Group>
							<Edit current={crop} onChange={(upd) => setCrop(upd)} />
							<layouts.buttons.primary onClick={() => perform(historyReq)}>Submit</layouts.buttons.primary>
						</fieldset>
					</errors.overlay>
					<layouts.overlays.Screen enabled={loading} styled>
						<layouts.containers.flex m="auto" flexDirection="column">
							<layouts.containers.span m="auto">processed {progress.total}</layouts.containers.span>
							<layouts.containers.span m="auto">
								{progress.current.replace(/^results\//, "").replace(/\.metadata\.json$/, "")}
							</layouts.containers.span>
						</layouts.containers.flex>
					</layouts.overlays.Screen>
				</layouts.overlays.Container>
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}
