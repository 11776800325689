import React, { useState, useContext } from "react"
import styled from "@emotion/styled"
import * as theme from "./theme"
import * as errors from "errors"
import * as containers from "./containers"

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${theme.colors.black}CC;
`
const Outer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
`

const Inner = styled.div`
	margin: auto;
`

export const Context = React.createContext({
	current: undefined as JSX.Element | undefined,
	toggle: (m: JSX.Element | undefined) => {},
})
export const Provider = Context.Provider
export const Consumer = Context.Consumer

export function useToggle(): (m: JSX.Element | undefined) => void {
	const { toggle } = useContext(Context)
	return toggle
}

export function Root(props: React.PropsWithChildren<containers.FlexProps>): JSX.Element {
	const { className, children, ...rest } = props
	const [m, setModal] = useState(undefined as JSX.Element | undefined)
	const modal = m ? (
		<Container>
			<Outer onClick={() => setModal(undefined)}>
				<Inner className="modal inner" onClick={(evt) => evt.stopPropagation()}>
					<errors.Boundary>{m}</errors.Boundary>
				</Inner>
			</Outer>
		</Container>
	) : undefined

	const ctx = {
		current: m,
		toggle: setModal,
	}

	return (
		<Provider value={ctx}>
			<containers.flex className={className} flex="1" position="relative" {...rest}>
				{children}
				{modal}
			</containers.flex>
		</Provider>
	)
}
