import { SVG, SVGProps } from "./svg"
import * as theme from "layouts/theme"

interface props extends SVGProps {
	width?: string
	height?: string
	stroke?: string
}

export const processing = (props: props) => {
	let { ...rest } = props
	return (
		<SVG width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6 3.28571V1M6 11V8.71429M8.71429 6H11M1 6H3.28571M7.91947 4.08081L9.53571 2.46456M2.46411 9.53561L4.08036 7.91936M7.91947 7.91919L9.53571 9.53544M2.46411 2.46439L4.08036 4.08064"
				stroke={theme.colors.white}
				strokeLinecap="round"
				strokeLinejoin="round"
				{...rest}
			/>
		</SVG>
	)
}

export const approved = (props: props) => {
	let { ...rest } = props
	return (
		<SVG width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.74556 4.87944L5.375 7.25L4.56694 6.44194M6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1Z"
				stroke={theme.colors.white}
				strokeLinecap="round"
				strokeLinejoin="round"
				{...rest}
			/>
		</SVG>
	)
}

export const rejected = (props: props) => {
	let { ...rest } = props
	return (
		<SVG width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.12132 4.58579C8.31658 4.39052 8.31658 4.07394 8.12132 3.87868C7.92606 3.68342 7.60948 3.68342 7.41421 3.87868L8.12132 4.58579ZM3.87868 7.41421C3.68342 7.60948 3.68342 7.92606 3.87868 8.12132C4.07394 8.31658 4.39052 8.31658 4.58579 8.12132L3.87868 7.41421ZM7.41421 8.12132C7.60948 8.31658 7.92606 8.31658 8.12132 8.12132C8.31658 7.92606 8.31658 7.60948 8.12132 7.41421L7.41421 8.12132ZM4.58579 3.87868C4.39052 3.68342 4.07394 3.68342 3.87868 3.87868C3.68342 4.07394 3.68342 4.39052 3.87868 4.58579L4.58579 3.87868ZM10.5 6C10.5 8.48528 8.48528 10.5 6 10.5V11.5C9.03757 11.5 11.5 9.03757 11.5 6H10.5ZM6 10.5C3.51472 10.5 1.5 8.48528 1.5 6H0.5C0.5 9.03757 2.96243 11.5 6 11.5V10.5ZM1.5 6C1.5 3.51472 3.51472 1.5 6 1.5V0.5C2.96243 0.5 0.5 2.96243 0.5 6H1.5ZM6 1.5C8.48528 1.5 10.5 3.51472 10.5 6H11.5C11.5 2.96243 9.03757 0.5 6 0.5V1.5ZM7.41421 3.87868L5.64645 5.64645L6.35355 6.35355L8.12132 4.58579L7.41421 3.87868ZM5.64645 5.64645L3.87868 7.41421L4.58579 8.12132L6.35355 6.35355L5.64645 5.64645ZM8.12132 7.41421L6.35355 5.64645L5.64645 6.35355L7.41421 8.12132L8.12132 7.41421ZM6.35355 5.64645L4.58579 3.87868L3.87868 4.58579L5.64645 6.35355L6.35355 5.64645Z"
				fill={theme.colors.white}
				{...rest}
			/>
		</SVG>
	)
}
