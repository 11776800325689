/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "ads.pool.demographics.proto" (package "ads.pool", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message ads.pool.Demographic
 */
export interface Demographic {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string pool_id = 3 [json_name = "pool_id"];
     */
    pool_id: string;
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string;
    /**
     * @generated from protobuf field: string created_at = 6 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 7 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: ads.pool.Gender gender = 8;
     */
    gender: Gender;
    /**
     * @generated from protobuf field: int32 age_min = 9 [json_name = "age_min"];
     */
    age_min: number;
    /**
     * @generated from protobuf field: int32 age_max = 10 [json_name = "age_max"];
     */
    age_max: number;
    /**
     * @generated from protobuf field: int32 income_min = 11 [json_name = "income_min"];
     */
    income_min: number;
    /**
     * @generated from protobuf field: int32 income_max = 12 [json_name = "income_max"];
     */
    income_max: number;
}
/**
 * @generated from protobuf message ads.pool.DemographicSearchRequest
 */
export interface DemographicSearchRequest {
    /**
     * @generated from protobuf field: string offset = 1;
     */
    offset: string;
    /**
     * @generated from protobuf field: string query = 2;
     */
    query: string;
}
/**
 * @generated from protobuf message ads.pool.DemographicSearchResponse
 */
export interface DemographicSearchResponse {
    /**
     * @generated from protobuf field: ads.pool.DemographicSearchRequest cursor = 1 [json_name = "next"];
     */
    cursor?: DemographicSearchRequest;
    /**
     * @generated from protobuf field: repeated ads.pool.Demographic items = 2;
     */
    items: Demographic[];
}
/**
 * @generated from protobuf message ads.pool.DemographicCreateRequest
 */
export interface DemographicCreateRequest {
    /**
     * @generated from protobuf field: ads.pool.Demographic demographic = 1;
     */
    demographic?: Demographic;
}
/**
 * @generated from protobuf message ads.pool.DemographicCreateResponse
 */
export interface DemographicCreateResponse {
    /**
     * @generated from protobuf field: ads.pool.Demographic demographic = 1;
     */
    demographic?: Demographic;
}
/**
 * starting with a small subset of gender identities using the wikipedia list https://en.wikipedia.org/wiki/List_of_gender_identities
 *
 * @generated from protobuf enum ads.pool.Gender
 */
export enum Gender {
    /**
     * @generated from protobuf enum value: UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: CISMALE = 1;
     */
    CISMALE = 1,
    /**
     * @generated from protobuf enum value: CISFEMALE = 2;
     */
    CISFEMALE = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Demographic$Type extends MessageType<Demographic> {
    constructor() {
        super("ads.pool.Demographic", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "pool_id", kind: "scalar", localName: "pool_id", jsonName: "pool_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "gender", kind: "enum", T: () => ["ads.pool.Gender", Gender] },
            { no: 9, name: "age_min", kind: "scalar", localName: "age_min", jsonName: "age_min", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "age_max", kind: "scalar", localName: "age_max", jsonName: "age_max", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "income_min", kind: "scalar", localName: "income_min", jsonName: "income_min", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "income_max", kind: "scalar", localName: "income_max", jsonName: "income_max", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Demographic>): Demographic {
        const message = { id: "", account_id: "", pool_id: "", description: "", created_at: "", updated_at: "", gender: 0, age_min: 0, age_max: 0, income_min: 0, income_max: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Demographic>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Demographic): Demographic {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string pool_id = 3 [json_name = "pool_id"];*/ 3:
                    message.pool_id = reader.string();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* string created_at = 6 [json_name = "created_at"];*/ 6:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 7 [json_name = "updated_at"];*/ 7:
                    message.updated_at = reader.string();
                    break;
                case /* ads.pool.Gender gender */ 8:
                    message.gender = reader.int32();
                    break;
                case /* int32 age_min = 9 [json_name = "age_min"];*/ 9:
                    message.age_min = reader.int32();
                    break;
                case /* int32 age_max = 10 [json_name = "age_max"];*/ 10:
                    message.age_max = reader.int32();
                    break;
                case /* int32 income_min = 11 [json_name = "income_min"];*/ 11:
                    message.income_min = reader.int32();
                    break;
                case /* int32 income_max = 12 [json_name = "income_max"];*/ 12:
                    message.income_max = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Demographic, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string pool_id = 3 [json_name = "pool_id"]; */
        if (message.pool_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pool_id);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* string created_at = 6 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 7 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.updated_at);
        /* ads.pool.Gender gender = 8; */
        if (message.gender !== 0)
            writer.tag(8, WireType.Varint).int32(message.gender);
        /* int32 age_min = 9 [json_name = "age_min"]; */
        if (message.age_min !== 0)
            writer.tag(9, WireType.Varint).int32(message.age_min);
        /* int32 age_max = 10 [json_name = "age_max"]; */
        if (message.age_max !== 0)
            writer.tag(10, WireType.Varint).int32(message.age_max);
        /* int32 income_min = 11 [json_name = "income_min"]; */
        if (message.income_min !== 0)
            writer.tag(11, WireType.Varint).int32(message.income_min);
        /* int32 income_max = 12 [json_name = "income_max"]; */
        if (message.income_max !== 0)
            writer.tag(12, WireType.Varint).int32(message.income_max);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.Demographic
 */
export const Demographic = new Demographic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DemographicSearchRequest$Type extends MessageType<DemographicSearchRequest> {
    constructor() {
        super("ads.pool.DemographicSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DemographicSearchRequest>): DemographicSearchRequest {
        const message = { offset: "", query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DemographicSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DemographicSearchRequest): DemographicSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string offset */ 1:
                    message.offset = reader.string();
                    break;
                case /* string query */ 2:
                    message.query = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DemographicSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string offset = 1; */
        if (message.offset !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.offset);
        /* string query = 2; */
        if (message.query !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.query);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.DemographicSearchRequest
 */
export const DemographicSearchRequest = new DemographicSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DemographicSearchResponse$Type extends MessageType<DemographicSearchResponse> {
    constructor() {
        super("ads.pool.DemographicSearchResponse", [
            { no: 1, name: "cursor", kind: "message", jsonName: "next", T: () => DemographicSearchRequest },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Demographic }
        ]);
    }
    create(value?: PartialMessage<DemographicSearchResponse>): DemographicSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DemographicSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DemographicSearchResponse): DemographicSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ads.pool.DemographicSearchRequest cursor = 1 [json_name = "next"];*/ 1:
                    message.cursor = DemographicSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.cursor);
                    break;
                case /* repeated ads.pool.Demographic items */ 2:
                    message.items.push(Demographic.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DemographicSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ads.pool.DemographicSearchRequest cursor = 1 [json_name = "next"]; */
        if (message.cursor)
            DemographicSearchRequest.internalBinaryWrite(message.cursor, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated ads.pool.Demographic items = 2; */
        for (let i = 0; i < message.items.length; i++)
            Demographic.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.DemographicSearchResponse
 */
export const DemographicSearchResponse = new DemographicSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DemographicCreateRequest$Type extends MessageType<DemographicCreateRequest> {
    constructor() {
        super("ads.pool.DemographicCreateRequest", [
            { no: 1, name: "demographic", kind: "message", T: () => Demographic }
        ]);
    }
    create(value?: PartialMessage<DemographicCreateRequest>): DemographicCreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DemographicCreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DemographicCreateRequest): DemographicCreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ads.pool.Demographic demographic */ 1:
                    message.demographic = Demographic.internalBinaryRead(reader, reader.uint32(), options, message.demographic);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DemographicCreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ads.pool.Demographic demographic = 1; */
        if (message.demographic)
            Demographic.internalBinaryWrite(message.demographic, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.DemographicCreateRequest
 */
export const DemographicCreateRequest = new DemographicCreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DemographicCreateResponse$Type extends MessageType<DemographicCreateResponse> {
    constructor() {
        super("ads.pool.DemographicCreateResponse", [
            { no: 1, name: "demographic", kind: "message", T: () => Demographic }
        ]);
    }
    create(value?: PartialMessage<DemographicCreateResponse>): DemographicCreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DemographicCreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DemographicCreateResponse): DemographicCreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ads.pool.Demographic demographic */ 1:
                    message.demographic = Demographic.internalBinaryRead(reader, reader.uint32(), options, message.demographic);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DemographicCreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ads.pool.Demographic demographic = 1; */
        if (message.demographic)
            Demographic.internalBinaryWrite(message.demographic, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.DemographicCreateResponse
 */
export const DemographicCreateResponse = new DemographicCreateResponse$Type();
