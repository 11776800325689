import React, { useEffect, useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"
import * as api from "./api"
import * as errors from "errors"

function Logo(props: icons.SVGProps & { name: string }): JSX.Element {
	const { name, ...rest } = props
	if (name === "GSuite") return <icons.Google width="32px" height="32px" {...rest} />
	if (props.name === "Facebook") return <icons.Facebook width="26px" height="26px" {...rest} />
	return <></>
}

function Description(props: { name: string }): JSX.Element {
	if (props.name === "GSuite") {
		return <layouts.containers.span>Sign in with Google</layouts.containers.span>
	}
	if (props.name === "Facebook") return <layouts.containers.span>Sign in with Facebook</layouts.containers.span>
	return <></>
}

export function Login(): JSX.Element {
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)
	const [login, setLogin] = useState({
		endpoints: [],
	} as api.Login)

	function request() {
		api
			.login()
			.then((l) => {
				setCause(undefined)
				setLogin(l)
			})
			.catch((c) => {
				console.error("failed to load login endpoints", c)
				setCause(
					<errors.Textual onClick={() => setCause(undefined)}>
						<icons.loading.Ring1 m="auto" width="80px" height="80px" foreground="black" />
					</errors.Textual>,
				)
				setTimeout(request, 3000)
			})
	}

	useEffect(request, [])

	if (cause) {
		return cause
	}

	const endpoints = login.endpoints.map((endpoint, idx) => {
		return (
			<layouts.containers.box key={idx} mx="auto" border="unset" width="231px" height="57px" my="16px">
				<layouts.links.external href={endpoint.url}>
					<layouts.buttons.unstyled
						mx="auto"
						height="100%"
						width="220px"
						background={layouts.theme.colors.white}
						boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
						cursor="inherit"
						color={layouts.theme.colors.grey.dark10}
					>
						<layouts.containers.span verticalAlign="middle" display="inline-block" ml="16px">
							<Logo name={endpoint.display} />
						</layouts.containers.span>
						<layouts.containers.span
							verticalAlign="middle"
							mr="auto"
							display="inline-flex"
							ml="16px"
							minWidth="140px"
							alignContent="flex-start"
						>
							<Description name={endpoint.display} />
						</layouts.containers.span>
					</layouts.buttons.unstyled>
				</layouts.links.external>
			</layouts.containers.box>
		)
	})

	return (
		<layouts.backgrounds.Grey flex="0 1 100%">
			<layouts.containers.flex
				height="100vh"
				width="100vw"
				justifyContent="center"
				alignItems="center"
				background={layouts.theme.colors.white}
			>
				<layouts.containers.flex
					className="login"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					background={layouts.theme.colors.white}
				>
					<layouts.containers.flex className="navigation" mx="auto" mb="126px">
						<icons.Application height="78px" />
					</layouts.containers.flex>
					<typography.h1 color={layouts.theme.colors.grey.medium} margin="0 25px 25px">
						Welcome To Nova
					</typography.h1>
					<layouts.containers.flex
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						width="608px"
						borderRadius="20px"
					>
						<layouts.containers.box mb="28px">Please login or create an account</layouts.containers.box>
						<layouts.containers.box width="100%">{endpoints}</layouts.containers.box>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}
