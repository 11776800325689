import React from "react"
import styled from "@emotion/styled"
import * as httpx from "httpx"
import * as typography from "typography"
import * as layouts from "layouts"
import * as api from "./api"

interface props extends layouts.containers.FlexProps {
	accounts: api.AvailableResponse
	onChange(c: Promise<api.Authn>): void
}

const AccountItem = styled(layouts.containers.box)`
	border-left: 5px solid transparent;
	padding: 10px;
	font-size: 24px;
	font-weight: 600;
	line-height: 36px;
	letter-spacing: -0.02em;
	text-overflow: ellipsis;
	overflow-x: hidden;
	white-space: nowrap;
	min-height: 36px;
	max-width: 100%;
	margin: 10px auto 10px 0px;
	&:hover {
		border-left: 5px solid ${layouts.theme.colors.blue};
		border-radius: unset;
	}
`

export default function Available(props: React.PropsWithChildren<props>): JSX.Element {
	const { accounts, onChange, ...rest } = props
	if (accounts.items.length === 0) return <></>

	return (
		<layouts.containers.flex className="available" flexDirection="column" overflow="hidden" {...rest}>
			<typography.h3 mx="auto">Accounts you&apos;ve been invited to join</typography.h3>
			<layouts.containers.flex flexDirection="column" overflow="auto">
				{accounts.items.map((a) => (
					<AccountItem
						key={a.account?.id}
						onClick={() => {
							onChange(api.accounts.accept(httpx.options.bearer(a.token)).then((joined) => joined.login!))
						}}
					>
						{a.account?.description} - {a.account?.id.slice(0, 4)}
					</AccountItem>
				))}
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
