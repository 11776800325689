import React, { useState } from "react"
import { useParams } from "react-router-dom"
import * as sessions from "sessions"
import * as errors from "errors"
import * as layouts from "layouts"
import * as api from "./api"
import { Bar, Line } from "charts"
import { DateTime } from "luxon"

interface props {
	store_id: string
}

export function FromURL(): JSX.Element {
	const params = useParams() as {
		id: string
	}

	return <Charts store_id={params.id} />
}

export function Charts(props: props): JSX.Element {
	const [sdate, setStartDate] = useState(new Date(Date.now() - 1000 * 60 * 60 * 24 * 7))
	const [edate, setEndDate] = useState(new Date())
	let [cause, setCause] = useState(undefined as JSX.Element | undefined)
	let [lineChartData, setLineChartData] = useState(api.initialLineChartData())
	let [barChartData, setBarChartData] = useState(api.initialBarChartData())
	const bearertoken = sessions.useToken()

	let [impressionRequset, setImpressionsRequest] = useState({
		offset: "",
		created: {
			begin: DateTime.fromJSDate(sdate, { zone: "UTC" }).startOf("day").toISO(),
			end: DateTime.fromJSDate(edate, { zone: "UTC" }).endOf("day").toISO(),
		},
	} as api.ImpressionsRequest)

	const lineChartOptions = {
		plugins: {
			title: {
				display: true,
				text: "Impressions by dates",
			},
		},
		responsive: true,
		interaction: {
			mode: "index" as const,
			intersect: false,
		},
	}
	const barChartOptions = {
		plugins: {
			title: {
				display: true,
				text: "impressions and conversions",
			},
		},
		responsive: true,
		interaction: {
			mode: "index" as const,
			intersect: false,
		},
		scales: {
			x: {
				stacked: true,
			},
		},
	}

	function fetchImpressionsAndPrepareChartData(
		request: api.ImpressionsRequest,
		line_chart_data: api.chartData,
		bar_chart_data: api.chartData,
	) {
		api
			.index(request, bearertoken)
			.then((response) => {
				if (response.impressions.length > 0) {
					const new_line_chart_data = api.prepareLineChartData(response, line_chart_data)
					// as we haven't labels in initial data, we need to update it first time when data comes
					// if (line_chart_data.labels.length !== new_line_chart_data.labels.length) {
					// 	updateLineChart(new_line_chart_data)
					// }
					setLineChartData(new_line_chart_data)

					const new_bar_chart_data = api.prepareBarChartData(response, bar_chart_data)
					setBarChartData(new_bar_chart_data)

					setImpressionsRequest(response.next)
					fetchImpressionsAndPrepareChartData(response.next, new_line_chart_data, new_bar_chart_data)
				} else {
					setLineChartData(line_chart_data)
					setBarChartData(bar_chart_data)
				}
			})
			.catch((c: unknown) => {
				setCause(<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve impressions</errors.Textual>)
			})
	}

	// function updateLineChart(chart_data: api.chartData) {
	// 	const line_chart = lineChartRef.current
	// 	if (line_chart) {
	// 		line_chart.data = chart_data
	// 		line_chart.update()
	// 	}
	// }

	function updateStartDate(date: Date): void {
		setStartDate(date)
		setImpressionsRequest({
			...impressionRequset,
			created: {
				begin: DateTime.fromJSDate(date, { zone: "UTC" }).startOf("day").toISO(),
				end: impressionRequset.created.end,
			},
		})
	}

	function updateEndDate(date: Date): void {
		setEndDate(date)
		setImpressionsRequest({
			...impressionRequset,
			created: {
				begin: impressionRequset.created.begin,
				end: DateTime.fromJSDate(date, { zone: "UTC" }).endOf("day").toISO(),
			},
		})
	}

	function handleSubmit(): void {
		fetchImpressionsAndPrepareChartData(
			{ ...impressionRequset, offset: "" },
			api.initialLineChartData(),
			api.initialBarChartData(),
		)
	}

	return (
		<layouts.containers.flex m="auto" width="80%">
			<layouts.containers.box styled width="100%">
				<layouts.containers.box textAlign={"center"} fontSize={"2.5em"} fontWeight={"bold"}>
					Cuddle Clones
				</layouts.containers.box>
				<fieldset>
					<layouts.containers.grid width="100%">
						<layouts.containers.box p="5px">
							<layouts.calendars.DateRange
								startDate={sdate}
								endDate={edate}
								changeStart={updateStartDate}
								changeEnd={updateEndDate}
							/>
						</layouts.containers.box>
						<layouts.containers.box p="5px">
							<layouts.buttons.primary onClick={() => handleSubmit()}>Submit</layouts.buttons.primary>
							{impressionRequset.offset.length > 0 && (
								<layouts.containers.span p="10px">
									Progress: {Math.round((parseInt(impressionRequset.offset.substring(0, 2), 16) / 255) * 100)}%
								</layouts.containers.span>
							)}
						</layouts.containers.box>
					</layouts.containers.grid>
				</fieldset>
				<layouts.containers.box>
					{lineChartData.labels.length === 0 ? (
						<layouts.containers.box textAlign={"center"} fontSize={"2em"}>
							No data!
						</layouts.containers.box>
					) : (
						<errors.overlay styled cause={cause}>
							<Line options={lineChartOptions} data={lineChartData} />
							<Bar options={barChartOptions} data={barChartData} />
						</errors.overlay>
					)}
				</layouts.containers.box>
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}
