import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { className, fill, ...rest } = props
	return (
		<svg.SVG
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="10px"
			height="10px"
			viewBox="0 0 10 10"
			version="1.1"
			fill={fill}
			{...rest}
		>
			<path d="M 9.332031 6.03125 C 9.324219 6.207031 9.246094 6.375 9.113281 6.492188 L 5.449219 9.828125 C 5.328125 9.941406 5.167969 10.007812 5 10.011719 C 4.832031 10.007812 4.671875 9.941406 4.550781 9.828125 L 0.886719 6.492188 C 0.613281 6.246094 0.59375 5.824219 0.839844 5.550781 C 1.085938 5.277344 1.507812 5.257812 1.78125 5.507812 L 4.332031 7.828125 L 4.332031 0.65625 C 4.332031 0.289062 4.632812 -0.0117188 5 -0.0117188 C 5.367188 -0.0117188 5.667969 0.289062 5.667969 0.65625 L 5.667969 7.828125 L 8.21875 5.507812 C 8.492188 5.257812 8.914062 5.277344 9.160156 5.550781 C 9.277344 5.683594 9.339844 5.855469 9.332031 6.03125 Z M 9.332031 6.03125 " />
		</svg.SVG>
	)
}

export default Icon
