import React from "react"
import * as layouts from "layouts"
import Tabs from "./tabs"

interface props {
	className?: string
	width?: string
}

export default function MainContainer(
	props: props & React.PropsWithChildren<layouts.containers.ContainerProps>,
): JSX.Element {
	const { className, width, children, ...rest } = props

	return (
		<layouts.containers.flex flexDirection="column" className={className} width={width} height="100%" {...rest}>
			<Tabs />
			<layouts.containers.flex
				height="100%"
				p="25px"
				background={layouts.theme.colors.white}
				borderRadius="10px"
				flexDirection="column"
				textAlign="center"
				overflowY="auto"
				{...rest}
			>
				{children}
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
