import { useContext, useState, useEffect } from "react"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as icons from "icons"
import * as errors from "errors"
import * as httpx from "httpx"
import * as pools from "ads/pools"
import * as adgen from "ads/adgen"
import * as textgen from "ads/textgen/api"
import * as adslayouts from "ads/adgen/adgen.content.layouts"
import * as api from "ads/facebook/api"
import * as context from "./context"
import { Card } from "ads/facebook/adgen.link.preview"

interface props {
	onChange(...mut: context.mutation[]): void
}

export default function Queue(props: props & layouts.containers.FlexProps): JSX.Element {
	const { onChange, ...rest } = props
	const [cause, setCause] = useState(undefined as errors.Cause)
	const pool = pools.caching.useCached()
	const genctx = useContext(context.Context)
	const [loading, setLoading] = useState(false)
	const [req, setNextRequest] = useState(api.ads.suggestions.zero({ pool_id: pool.id }))
	const bearertoken = sessions.useToken()

	async function refill() {
		setLoading(true)
		return api.ads.suggestions
			.get(req, bearertoken)
			.then((cont) => {
				const updreq = { ...req, offset: "", ...cont.cursor }
				setNextRequest(updreq)

				onChange(context.mutate.buffered.add(...(cont.items || []).map((v) => context.suggestions.zero(v))))
				setCause(undefined)
			})
			.catch(
				httpx.errors.forbidden((cause) => {
					console.warn("insufficient priviledges unable to view suggestions", cause)
					setCause(
						<layouts.containers.box styled m="auto">
							<errors.Textual>you do not have permission to view suggestions</errors.Textual>
						</layouts.containers.box>,
					)
				}),
			)
			.catch((cause) => {
				setCause(
					<errors.Inline>
						<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
							unable to retrieve suggestions
						</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		if (genctx.buffered.length >= req.limit || req.offset === "" || loading) {
			return
		}
		refill()
	}, [genctx.buffered])

	useEffect(() => {
		if (genctx.ads.length >= req.limit && genctx.buffered.length > 0) {
			return
		}
		const [updcurrent, updbuffer] = adgen.buffered.refill(genctx.ads, genctx.buffered)
		onChange(context.mutate.ads.replace(updcurrent), context.mutate.buffered.replace(updbuffer))
	}, [genctx.ads, genctx.buffered])

	return (
		<layouts.containers.flex {...rest}>
			{cause}
			<layouts.containers.grid
				justifyContent="center"
				flex="1"
				display="grid"
				gridGap="15px"
				pt="10px"
				pb="30px"
				gridTemplateColumns="repeat(auto-fill, 350px)"
			>
				{genctx.ads.map((item, i) => {
					return (
						<Card
							key={item.content!.id}
							tabIndex={i}
							headline={item!.content!.name}
							message={item!.content!.message}
							media={{ id: item!.content!.media_id, url: "" }}
							description={item!.content!.description}
							call_to_action={item!.content!.call_to_action!}
							onCardClick={() => {
								const updated = {
									...item!,
									selected: !item!.selected,
								}
								onChange(context.mutate.current.update(updated), context.mutate.replace(updated))
							}}
							active={item.selected}
						>
							<layouts.containers.absolute bottom="-4px" right="0">
								<layouts.containers.flex flexDirection="row">
									<layouts.containers.flex paddingRight="5px">
										<layouts.containers.flex>
											<layouts.containers.box onClick={() => onChange(context.mutate.current.update(item))}>
												<icons.Info />
											</layouts.containers.box>
										</layouts.containers.flex>
									</layouts.containers.flex>
									<layouts.containers.flex>
										<adslayouts.Reject
											clickReject={() => {
												api.ads.suggestions.destroy(item.metadata!.id, bearertoken).finally(() => {
													textgen.create.record(
														{
															samples: [
																adgen.text.sample(item!.content!.name),
																adgen.text.sample(item!.content!.description),
																adgen.text.sample(item!.content!.message),
															],
														},
														bearertoken,
													)
													onChange(context.mutate.reject(item!))
												})
											}}
										/>
									</layouts.containers.flex>
								</layouts.containers.flex>
							</layouts.containers.absolute>
							<adslayouts.GuardScore
								grammar={item?.metadata?.grammar || 0.0}
								profanity={item?.metadata?.profanity || 0.0}
								racism={item?.metadata?.racism || 0.0}
								onbrand={item?.metadata?.onbrand || 0.0}
								brandguard={item?.metadata?.brandguard || 0.0}
							/>
						</Card>
					)
				})}
			</layouts.containers.grid>
		</layouts.containers.flex>
	)
}
