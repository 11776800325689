import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as typography from "typography"
import * as brands from "brands"
import fetchFavicon from "favicon-fetch"
import * as facebookapi from "./api"
import { Image } from "media/image"
import * as mediaapi from "media/api"

const StyledContainer = styled(layouts.containers.flex)`
	position: relative;
	flex-direction: column;
	width: 345px;
	min-height: 444px;
	background: ${layouts.theme.colors.white};
	border: 1px solid ${layouts.theme.colors.grey.light30};
	box-shadow: ${layouts.theme.boxshadow.grey.lightest};
	&.active {
		border: 1px solid ${layouts.theme.colors.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}
	&:hover {
		outline: 1.5px solid ${layouts.theme.colors.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}
`

const StyledHeaderContainer = styled(layouts.containers.flex)`
	flex-direction: column;
	min-height: 103px;
	overflow: hidden;
	padding: 0 10px 8px;
`

const StyledFooterContainer = styled(layouts.containers.flex)`
	min-height: 113px;
	overflow: hidden;
	padding: 0 10px;
	justify-content: space-between;
	align-items: center;
`

const AccountImage = styled(layouts.images.Base)<{ alt: string }>`
	width: 46px;
	height: 46px;
	border-radius: 46px;
	position: relative;
	background-color: ${layouts.theme.colors.grey.light10};
	overflow: hidden;
	&::before {
		content: "${(props) => props.alt}";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 2em;
		font-weight: 500;
		line-height: 1.45em;
		text-transform: capitalize;
		background: ${layouts.theme.colors.grey.light10};
		color: ${layouts.theme.colors.black.dark};
	}
`

const SmallText = styled(layouts.containers.span)`
	font-size: 9px;
	line-height: 16px;
	color: ${layouts.theme.colors.grey.dark5};
`

const PrimaryText = styled(layouts.containers.flex)`
	margin-top: 11px;
	font-size: 12px;
	text-align: left;
`

const PreviewDescription = styled(layouts.containers.flex)`
	font-weight: 600;
	font-size: 11px;
	line-height: 16px;
	text-align: left;
	color: ${layouts.theme.colors.grey.dark5};
`

const FooterBtn = styled(layouts.buttons.styled)`
	height: 38px;
	min-width: 84px;
	background: ${layouts.theme.colors.grey.light30};
	border-radius: 6px;
	color: ${layouts.theme.colors.black.dark};
`

interface cardProps {
	tabIndex: number
	message: string
	media: facebookapi.Media
	headline: string
	description: string
	call_to_action: facebookapi.CallToAction
	onCardClick(): void
	active: boolean
}

export function Card(props: React.PropsWithChildren<cardProps>): JSX.Element {
	const brand = brands.caching.useCached()
	const { tabIndex, message, media, headline, description, call_to_action, onCardClick, active, children } = props

	return (
		<StyledContainer className={active ? "active" : ""} tabIndex={tabIndex} onClick={() => onCardClick()}>
			<StyledHeaderContainer className="preview-header-container">
				<layouts.containers.flex mt="16px">
					<layouts.containers.flex>
						<AccountImage src={fetchFavicon({ uri: brand.domain })} alt={brand.description[0]}></AccountImage>
					</layouts.containers.flex>
					<layouts.containers.flex flexDirection="column" justifyContent="center" alignItems="flex-start" ml="7px">
						<typography.h5>{brand.description}</typography.h5>
						<SmallText>Sponsored</SmallText>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<PrimaryText>{message || "Not set"}</PrimaryText>
			</StyledHeaderContainer>

			<Image
				media={mediaapi.zero({ content_digest: media.id })}
				background={layouts.theme.colors.grey.lightest}
				noImage={<></>}
			/>
			<StyledFooterContainer className="preview-footer-container">
				<layouts.containers.flex flexDirection="column" alignItems="flex-start">
					<SmallText>{brand.domain}</SmallText>
					<typography.h5 className="preview-headline" my="4px" fontSize="10px" textAlign="left">
						{headline || "Not set"}
					</typography.h5>
					<PreviewDescription className="preview-description">{description || "Not set"}</PreviewDescription>
				</layouts.containers.flex>
				{call_to_action.type?.length > 0 && (
					<layouts.containers.flex>
						<FooterBtn>{call_to_action.type}</FooterBtn>
					</layouts.containers.flex>
				)}
			</StyledFooterContainer>
			{children}
		</StyledContainer>
	)
}

interface previewProps {
	content: facebookapi.AdLinkContent
	media: facebookapi.Media
}

export default function Preview(props: previewProps & layouts.containers.FlexProps): JSX.Element {
	const { content, media, ...rest } = props
	return (
		<layouts.containers.flex flexDirection="column" alignItems="center" {...rest}>
			<Card
				tabIndex={0}
				message={content.message}
				media={media}
				headline={content.name}
				description={content.description}
				call_to_action={{ type: "", ...content.call_to_action }}
				active={false}
				onCardClick={() => undefined}
			/>
		</layouts.containers.flex>
	)
}
