import React, { useEffect, useState } from "react"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as Sentry from "@sentry/browser"
import { Standard as Navigation } from "navigation"
import * as cache from "./cache"

export default function Debug(): JSX.Element {
	const session = sessions.useSession()
	const [alpha, setAlpha] = useState(cache.alpha.enabled)
	const [recording, setSessionRecording] = useState(session.recording)

	useEffect(() => {
		cache.alpha.toggle(alpha)
	}, [alpha])

	useEffect(() => {
		const client = Sentry.getCurrentHub().getClient()
		if (client === undefined || client.addIntegration === undefined) return
		console.warn("session recording initiated; to disable reload tab")
		client.addIntegration(new Sentry.Replay())
		session.replace({ ...session, recording: true })
	}, [recording])

	return (
		<layouts.backgrounds.Grey height="100vh" width="100vw">
			<layouts.containers.flex className="navigation-container" flex="0">
				<Navigation />
			</layouts.containers.flex>
			<layouts.containers.flex className="body-container" flex="1 1">
				<layouts.forms.Container m="auto" styled p="10px">
					<layouts.forms.Group>
						<layouts.forms.Label>Version {process.env.REACT_APP_VERSION || "development"}</layouts.forms.Label>
					</layouts.forms.Group>
					<layouts.forms.Group>
						<layouts.forms.Label>
							Account {session.account.id} - {session.account.description}
						</layouts.forms.Label>
					</layouts.forms.Group>
					<layouts.forms.Group>
						<layouts.forms.Label>
							Profile {session.profile.id} - {session.profile.email}
						</layouts.forms.Label>
					</layouts.forms.Group>
					<layouts.forms.Group>
						<layouts.forms.Label mr="auto">Alpha Features</layouts.forms.Label>
						<input type="checkbox" checked={alpha} onChange={(evt) => setAlpha(!alpha)} />
					</layouts.forms.Group>
					<layouts.forms.Group>
						<layouts.forms.Label mr="auto">Enable session recording</layouts.forms.Label>
						<input
							type="checkbox"
							disabled={recording}
							checked={recording}
							onChange={(evt) => setSessionRecording(true)}
						/>
					</layouts.forms.Group>
				</layouts.forms.Container>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}
