import * as uuid from "uuid"
import * as httpx from "httpx"
import * as proto from "./meta.profile"

export interface AuthzResponse {
	token: string
	view: boolean
	edit: boolean
}

// authz returns the authorization credentials for the current profile.
export function authz(c: httpx.Client): Promise<AuthzResponse> {
	return c.get(`${httpx.urlstorage.host()}/u12t/authz`)
}

export function ZeroSearch(partial: Partial<proto.SearchRequest> = {}): proto.SearchRequest {
	return {
		query: "",
		offset: uuid.NIL,
		limit: 0n,
		enabled: 0,
		created: undefined,
	}
}

export function PendingZeroSearch(partial: Partial<proto.SearchRequest> = {}): proto.SearchRequest {
	return {
		query: "",
		offset: uuid.NIL,
		limit: 0n,
		enabled: 3,
		created: undefined,
	}
}

// search profiles
export function search(req: proto.SearchRequest, ...options: httpx.option[]): Promise<proto.SearchResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/u12t`, req, ...options)
}

export function create(req: proto.CreateRequest, ...options: httpx.option[]): Promise<proto.CreateResponse> {
	return httpx.post(`${httpx.urlstorage.host()}/u12t`, req, ...options)
}

// update a profile
export function update(req: proto.UpdateRequest, ...options: httpx.option[]): Promise<proto.UpdateResponse> {
	return httpx.put(`${httpx.urlstorage.host()}/u12t/${req.profile?.id}`, req, ...options)
}
