import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { className, ...rest } = props
	return (
		<svg.SVG
			viewBox="0 0 45 45"
			width="45px"
			height="45px"
			fill="white"
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			verticalAlign="middle"
			{...rest}
		>
			<path
				d="M21.9998 40.3333C32.1251 40.3333 40.3332 32.1252 40.3332 22C40.3332 11.8747 32.1251 3.66663 21.9998 3.66663C11.8746 3.66663 3.6665 11.8747 3.6665 22C3.6665 32.1252 11.8746 40.3333 21.9998 40.3333Z"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M22 14.6666V29.3333" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M14.6665 22H29.3332" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg.SVG>
	)
}

export default Icon
