import React from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as api from "./api"

interface props {
	current: api.CropRequest
	onChange(upd: api.CropRequest): void
}

// reduced subset for modifying the visual parameters when cropping images.
// for use when replaying orders
export default function Edit(props: React.PropsWithChildren<props>): JSX.Element {
	const { current, onChange } = props

	return (
		<>
			<layouts.forms.Group className="mb-3">
				<layouts.forms.Label>Effect</layouts.forms.Label>
				<inputs.Text
					defaultValue={current.effect}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...current, effect: parseInt(evt.currentTarget.value, 10) })
					}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group className="mb-3">
				<layouts.forms.Label>Edges Smooth</layouts.forms.Label>
				<inputs.Text
					defaultValue={current.smooth_edges_threshold}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...current, smooth_edges_threshold: parseInt(evt.currentTarget.value, 10) || 0 })
					}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group className="mb-3">
				<layouts.forms.Label>Crop Type</layouts.forms.Label>
				<select
					value={current.crop_type}
					onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
						onChange({ ...current, crop_type: evt.currentTarget.value })
					}
				>
					<option value="head">Head</option>
					<option value="bust">Bust</option>
					<option value="square-bust">Square Bust</option>
				</select>
			</layouts.forms.Group>
		</>
	)
}
