import React from "react"
import styled from "@emotion/styled"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as api from "./api"

interface props extends layouts.containers.FlexProps {
	authn: api.Authn[]
	onChange(c: Promise<api.CurrentSession>): void
}

const AccountItem = styled(layouts.containers.box)`
	border-left: 5px solid transparent;
	padding: 10px;
	font-size: 24px;
	font-weight: 600;
	line-height: 36px;
	letter-spacing: -0.02em;
	text-overflow: ellipsis;
	overflow-x: hidden;
	white-space: nowrap;
	min-height: 36px;
	max-width: 100%;
	margin: 10px auto 10px 0px;
	&:hover {
		border-left: 5px solid ${layouts.theme.colors.blue};
		border-radius: unset;
	}
`

export default function Mutliaccount(props: React.PropsWithChildren<props>): JSX.Element {
	const { authn, onChange, ...rest } = props
	return (
		<layouts.containers.flex className="multiaccount" flexDirection="column" {...rest}>
			{authn.map((a) => (
				<AccountItem
					key={a.profile?.id}
					onClick={() => {
						onChange(api.current(httpx.options.bearer(a.token)))
					}}
				>
					{a.profile?.display} - {a.account?.description} - {a.account?.id.slice(0, 4)}
				</AccountItem>
			))}
		</layouts.containers.flex>
	)
}
