import * as md5x from "md5x"
import * as uuid from "uuid"
import { Feedback, Sample } from "ads/textgen/textgen.training.sample"
export { default as BrandDetails } from "./adgen.brand.details"

export namespace text {
	export function sample(content: string): Sample {
		return {
			id: md5x.string(content),
			account_id: "",
			prompt: "",
			generated: content,
			accepted: content,
			source: "",
			brand_id: "",
			pool_id: "",
			feedback: Feedback.IGNORED,
		}
	}

	export function empty(): Sample {
		return {
			id: uuid.v4(),
			account_id: "",
			prompt: "",
			generated: "",
			accepted: "",
			source: "",
			brand_id: "",
			pool_id: "",
			feedback: Feedback.IGNORED,
		}
	}
}

export namespace buffered {
	// append to the current set from the buffer
	export function refill<T>(current: T[], buffer: T[]): [T[], T[]] {
		const dup = [...buffer]
		const replacement = dup.shift()
		return replacement === undefined ? [current, buffer] : [[...current, replacement], dup]
	}

	// replace a specific instance within the current set
	export function replace<T>(at: number, current: T[], buffer: T[]): T[] {
		if (buffer.length === 0) return buffer
		const bufdup = [...buffer]
		const replacement = bufdup.shift()!
		current[at] = replacement
		return bufdup
	}
}
