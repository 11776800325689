import React, { useState, useContext, useEffect } from "react"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as httpx from "httpx"
import * as errors from "errors"
import * as md5x from "md5x"
import * as brands from "brands"
import * as pools from "ads/pools"
import * as caching from "caching"
import * as timex from "timex"
import * as adgen from "ads/adgen"
import * as api from "./api"
import * as context from "./adgen.responsive.search.context"
import { HeadLines } from "./adgen.responsive.search.headline"
import Instructions from "./adgen.responsive.search.instructions"
import { StyledTextArea } from "ads/adgen/adgen.content.layouts"

interface prompt {
	digest: string
	current: string
}

export const cache = new caching.Cache({
	namespace: "novacloud.adgen.responsive",
	ttl: timex.duration.iso("PT720H").toMillis(),
})

interface props {
	onChange(...mut: context.mutation[]): void
	adgenConstants: context.AdgenConstants
}

export default function Container(props: props): JSX.Element {
	const minimumDescriptions = 10
	const minimumHeadlines = 10
	const brand = brands.caching.useCached()
	const pool = pools.caching.useCached()
	const bearertoken = sessions.useToken()
	const promptcachedkey = `${pool.id}.prompt`
	const { onChange, adgenConstants } = props
	const genctx = useContext(context.Context)
	const [refilling, setRefilling] = useState(false)
	const [previousPrompt, setPreviousPrompt] = useState({
		digest: "",
		current: "",
	} as prompt)
	const [currentPrompt, setPrompt] = useState(() =>
		cache.maybeSync<prompt>(
			promptcachedkey,
			() =>
				({
					digest: "",
					current: "",
				} as prompt),
		),
	)

	function genprompt(s: string): prompt {
		return {
			current: s,
			digest: md5x.string(s),
		}
	}

	async function getbuffered(p: prompt): Promise<context.cached> {
		return api.ads.responsive
			.generate(
				{
					query: api.ads.query({
						prompt: p.current,
						brand: brand.description,
					}),
				},
				bearertoken,
			)
			.then((resp): context.cached => {
				return resp.items.reduce(
					(content, gen) =>
						context.merge(content, {
							headlines: gen.headlines.map(adgen.text.sample),
							descriptions: gen.descriptions.map(adgen.text.sample),
						} as context.cached),
					context.buffered.zero(),
				) as context.cached
			})
	}

	function generate() {
		onChange(context.mutate.generated(<layouts.loading.screen loading flex="1" />))
		getbuffered(currentPrompt)
			.then((content) => {
				setPreviousPrompt(currentPrompt)
				onChange(
					context.mutate.current({
						headlines: content.headlines.splice(0, 6),
						descriptions: content.descriptions.splice(0, 6),
					}),
					context.mutate.buffered(content),
					context.mutate.generated(
						<HeadLines key="headlines" onChange={onChange} adgenConstants={adgenConstants}></HeadLines>,
					),
					context.mutate.generating(false),
				)
			})
			.catch(
				httpx.errors.forbidden((cause) => {
					console.warn("insufficient priviledges to generate an ad", cause)
					onChange(
						context.mutate.generated(
							<layouts.containers.box styled m="auto">
								<errors.Textual onClick={() => onChange(context.mutate.generated(<Instructions />))}>
									you do not have permission to generate content
								</errors.Textual>
							</layouts.containers.box>,
						),
					)
				}),
			)
			.catch((cause) => {
				console.error("unable to create content", cause)
				onChange(
					context.mutate.generated(
						<layouts.containers.box styled m="auto">
							<errors.Textual onClick={() => onChange(context.mutate.generated(<Instructions />))}>
								unable to generate content
							</errors.Textual>
						</layouts.containers.box>,
					),
				)
			})
	}

	async function refill() {
		return getbuffered(previousPrompt).then((content) => {
			onChange(context.mutate.buffered_merge(content))
		})
	}

	useEffect(() => {
		if (refilling || previousPrompt.current.length === 0) {
			return
		}

		if (
			genctx.buffered.descriptions.length >= minimumDescriptions &&
			genctx.buffered.headlines.length >= minimumHeadlines
		) {
			return
		}

		setRefilling(true)
		refill().finally(() => setRefilling(false))
	}, [genctx.buffered])

	const generateDisabled = currentPrompt.current.length === 0 || genctx.generating

	return (
		<>
			<layouts.containers.flex width="55%">
				<StyledTextArea
					value={currentPrompt.current}
					onChange={(evt) => {
						const gen = genprompt(evt.target.value)
						cache.set(promptcachedkey, gen)
						setPrompt(gen)
					}}
				/>
			</layouts.containers.flex>
			<layouts.containers.flex
				width="50%"
				justifyContent="center"
				paddingTop="10px"
				flexDirection="column"
				alignItems="center"
			>
				<layouts.buttons.primary
					disabled={generateDisabled}
					mt="25px"
					onClick={generate}
					borderRadius="37px"
					width="150px"
					height="50px"
				>
					Generate
				</layouts.buttons.primary>
			</layouts.containers.flex>
		</>
	)
}
