import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import styled from "@emotion/styled"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as errors from "errors"
import * as sessions from "sessions"
import * as layouts from "layouts"
import * as api from "./api"
import * as cache from "./cache"
import * as google from "ads/google"
import * as facebook from "ads/facebook"
import Overview from "./pool.overview"

export const PoolDesc = styled.div`
	position: relative;
	font-size: 24px;
	font-weight: 600;
	margin-right: 36px;
	display: block;
	color: ${layouts.theme.colors.white};
	text-decoration: none;
`

interface props extends layouts.containers.FlexProps {
	find(id: string, ...options: httpx.option[]): Promise<api.Pool>
}

export function FromURL(props: props): JSX.Element {
	const params = useParams() as {
		id: string
	}
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [loading, setLoading] = useState(true)
	const [pool, setPool] = useState(api.pools.zero())
	const [facebookAccount, setFacebookAccount] = useState(new Promise(() => {}) as Promise<facebook.api.ClientAccount>)
	const [googleAccount, setGoogleAccount] = useState(new Promise(() => {}) as Promise<google.api.ClientAccount>)
	const bearertoken = sessions.useToken()
	const { find, ...rest } = props

	useEffect(() => {
		if (pool.id === uuid.NIL) return
		cache.cache.set(pool.id, pool)
	}, [pool])

	useEffect(() => {
		setLoading(true)
		find(params.id, bearertoken)
			.then((p) => {
				setPool(p)
				return p
			})
			.catch(
				httpx.errors.forbidden((cause) => {
					console.warn("insufficient priviledges unable to view ad pools", cause)
					setCause(
						<layouts.containers.box styled m="auto">
							<errors.Textual>you do not have permission to view ad pool</errors.Textual>
						</layouts.containers.box>,
					)
				}),
			)
			.catch((c: unknown) => {
				setCause(
					<errors.Textual cause={c} onClick={() => setCause(undefined)}>
						unable to retrieve ad pool
					</errors.Textual>,
				)
			})
			.finally(() => setLoading(false))
	}, [])

	// detect google setup
	useEffect(() => {
		if (pool.google_ads_client_account_id === uuid.NIL) {
			setGoogleAccount(Promise.resolve(google.api.accounts.zero()))
			return
		}
		setGoogleAccount(
			google.api.accounts.find(pool.google_ads_client_account_id, bearertoken).then((r) => r.client_account!),
		)
	}, [pool.id, pool.google_ads_client_account_id])

	// detect facebook setup
	useEffect(() => {
		if (pool.facebook_client_account_id === uuid.NIL) {
			setFacebookAccount(Promise.resolve(facebook.api.accounts.zero()))
			return
		}
		setFacebookAccount(
			facebook.api.accounts.find(pool.facebook_client_account_id, bearertoken).then((r) => r.client_account!),
		)
	}, [pool.id, pool.facebook_client_account_id])

	return (
		<layouts.containers.flex className="pool" p="50px" width="100%" flexDirection="column" {...rest}>
			<errors.overlay cause={cause}>
				<layouts.loading.screen flex="1" loading={loading}>
					<layouts.containers.flex mt="10px" width="100%" height="256px">
						<Overview current={pool} />
						<google.ClientSetup
							ml="10px"
							flex="1"
							current={googleAccount}
							onChange={(ca) => {
								ca = google.api.accounts.zero(ca)
								if (pool.id === uuid.NIL) return // handle zero state.
								setGoogleAccount(Promise.resolve(ca))
								if (pool.google_ads_client_account_id === ca.id) return // nothing to do.

								api.pools
									.update(pool.id, { pool: { ...pool, google_ads_client_account_id: ca.id } }, bearertoken)
									.then((r) => {
										const upd = cache.update(api.pools.zero(r.pool || pool))
										setPool(upd)
										return r
									})
									.catch((cause: unknown) => {
										setCause(
											<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
												failed to update pool, click to retry
											</errors.Textual>,
										)
									})
							}}
						/>
						<facebook.ClientSetup
							ml="10px"
							flex="1"
							current={facebookAccount}
							onChange={(ca) => {
								ca = facebook.api.accounts.zero(ca)
								if (pool.id === uuid.NIL) return // handle zero state.
								setFacebookAccount(Promise.resolve(ca))
								if (pool.facebook_client_account_id === ca.id) return // nothing to do.
								api.pools
									.update(pool.id, { pool: { ...pool, facebook_client_account_id: ca.id } }, bearertoken)
									.then((r) => {
										const upd = api.pools.zero(r.pool || pool)
										setPool(cache.update(upd))
										return r
									})
									.catch((cause: unknown) => {
										setCause(
											<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
												failed to update pool, click to retry
											</errors.Textual>,
										)
									})
							}}
						/>
					</layouts.containers.flex>
				</layouts.loading.screen>
			</errors.overlay>
		</layouts.containers.flex>
	)
}

FromURL.defaultProps = {
	find: cache.maybe,
}
