import React, { useState } from "react"
import { FileRejection } from "react-dropzone"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as typography from "typography"
import * as icons from "icons"
import * as sessions from "sessions"
import * as brands from "brands"
import * as api from "../api"
import * as bg_layouts from "./layouts"

interface props {
	onUpload(item: api.ImageSearchResponseItem): void
	asButton?: boolean
}

export default function ImagesUpload(props: props & React.PropsWithChildren<unknown>): JSX.Element {
	const { asButton, onUpload } = props
	interface loadStatus {
		[fileName: string]: {
			loaded: boolean
			loading: boolean
			error: boolean
			errorMessage: string
		}
	}
	const [rejectedItems, setRejectedItems] = useState([] as FileRejection[])
	const [acceptedItems, setAcceptedItems] = useState([] as File[])
	const [loadStatuses, setLoadStatuses] = useState({} as loadStatus)
	const acceptedTypes = {
		"image/png": [".png"],
		"image/jpeg": [".jpeg", ".jpg"],
		"image/gif": [".gif"],
	}
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()

	const onDropHandle = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
		setRejectedItems(fileRejections)
		setAcceptedItems(acceptedFiles)
		if (acceptedFiles.length === 0) return

		Array.from(acceptedFiles).forEach((file) => {
			setLoadStatuses((prevState) => ({
				...prevState,
				[file.name]: { loaded: false, loading: true, error: false, errorMessage: "" },
			}))
			api.images.uploads
				.create(file, brand.id, bearertoken)
				.then((result) => {
					onUpload(result)
					setLoadStatuses((prevState) => ({
						...prevState,
						[file.name]: { loaded: true, loading: false, error: false, errorMessage: "" },
					}))
				})
				.catch((e) => {
					setLoadStatuses((prevState) => ({
						...prevState,
						[file.name]: { loaded: false, loading: false, error: true, errorMessage: e.message },
					}))
				})
		})
	}

	// TODO: while we have not UI, put it here, later, if need, will move in component
	const renderAcceptedItems = () => {
		if (acceptedItems.length === 0) return

		return (
			<layouts.containers.flex flexDirection="column">
				<typography.h3 color={layouts.theme.colors.grey.medium} textAlign="left">
					Accepted Files:
				</typography.h3>
				{acceptedItems.map((file) => (
					<layouts.containers.flex key={file.name} my="2px">
						<layouts.containers.span>- {file.name}: </layouts.containers.span>
						{loadStatuses[file.name]?.loaded && <layouts.containers.span> uploaded!</layouts.containers.span>}
						{loadStatuses[file.name]?.loading && <layouts.containers.span> uploading...</layouts.containers.span>}
						{loadStatuses[file.name]?.error && (
							<layouts.containers.span> ERROR: {loadStatuses[file.name].errorMessage}</layouts.containers.span>
						)}
					</layouts.containers.flex>
				))}
			</layouts.containers.flex>
		)
	}

	// TODO: while we have not UI, put it here, later, if need, will move in component
	const renderRejectedItems = () => {
		if (rejectedItems.length === 0) return

		return (
			<layouts.containers.flex flexDirection="column">
				<typography.h3 color={layouts.theme.colors.grey.medium} textAlign="left">
					Rejected Files:
				</typography.h3>
				{rejectedItems.map(({ file, errors }) => (
					<layouts.containers.flex key={file.name} my="2px">
						<layouts.containers.box mr="8px">- {file.name}:</layouts.containers.box>
						{errors.map((e) => (
							<layouts.containers.box key={e.code}>{e.message}</layouts.containers.box>
						))}
					</layouts.containers.flex>
				))}
			</layouts.containers.flex>
		)
	}

	const filesLoading = () => Object.keys(loadStatuses).some((filename) => loadStatuses[filename].loading)

	if (asButton) {
		return (
			<inputs.Dropwell
				multiple={true}
				accept={acceptedTypes}
				onDrop={(accepted, rejected, evt) => onDropHandle(accepted, rejected)}
			>
				<bg_layouts.uploads.StyledBtn>
					<icons.Upload fill={layouts.theme.colors.blue} width="20" height="15" mr="8px" mb="-2px" />
					Upload Images
				</bg_layouts.uploads.StyledBtn>
			</inputs.Dropwell>
		)
	}

	return (
		<>
			<typography.h3 color={layouts.theme.colors.grey.medium} my="30px">
				Please add the items you would like to review
			</typography.h3>
			{filesLoading() ? (
				<bg_layouts.uploads.DragAndDropArea className="styled-drop-area" m="20px 50px">
					<icons.Upload fill={layouts.theme.colors.grey.medium} width="40" height="30" mx="auto" />
					<typography.h3>Processing files...</typography.h3>
				</bg_layouts.uploads.DragAndDropArea>
			) : (
				<layouts.containers.box m="20px 50px">
					<inputs.Dropwell
						multiple={true}
						accept={acceptedTypes}
						onDrop={(accepted, rejected, evt) => onDropHandle(accepted, rejected)}
					>
						<bg_layouts.uploads.DragAndDropArea className="styled-drop-area">
							<icons.Upload fill={layouts.theme.colors.grey.medium} width="40" height="30" mx="auto" />
							<typography.h3>Drag & drop files here, or click to select files</typography.h3>
						</bg_layouts.uploads.DragAndDropArea>
					</inputs.Dropwell>
				</layouts.containers.box>
			)}
			{renderAcceptedItems()}
			{renderRejectedItems()}
		</>
	)
}
