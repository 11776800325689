import * as httpx from "httpx"
import * as uuid from "uuid"
import { Prediction, Event } from "./brandguard_pb"
import {
	ImageSearchRequest,
	ImageSearchResponse,
	ImageSearchResponseItem,
	ImageFindResponse,
	ImagePatchRequest,
	ImagePatchResponse,
	ImageUploadResponse,
} from "./brandguard.image_pb"
import {
	TextSearchRequest,
	TextSearchResponse,
	TextSearchResponseItem,
	TextFindResponse,
	TextPatchRequest,
	TextPatchResponse,
	TextUploadResponse,
} from "./brandguard.text_pb"

export { Event, Prediction, SampleRequest, SampleResponse } from "./brandguard_pb"
export { ImageSearchRequest, ImageSearchResponse, ImageSearchResponseItem } from "./brandguard.image_pb"
export { TextSearchRequest, TextSearchResponse, TextSearchResponseItem, TextUploadResponse } from "./brandguard.text_pb"

interface scored {
	brandguard: number
	profanity: number
	racism: number
	grammar: number
	onbrand: number
}

export function clamp(v: number): number {
	return Math.round((v || 0) * 10000) / 100
}

export function human(v: scored): number {
	return 0.6 * ((v.profanity + v.grammar + v.racism) / 3) + 0.4 * v.onbrand
}

export namespace observations {
	export function zero(d: Partial<Event> = {}): Event {
		return {
			id: uuid.NIL,
			account_id: uuid.NIL,
			brand_id: uuid.NIL,
			prediction: Prediction.REJECTED,
			observation: Prediction.REJECTED,
			brandguard: 0.0,
			profanity: 0.0,
			racism: 0.0,
			grammar: 0.0,
			onbrand: 0.0,
			...d,
		}
	}

	// export function request(d: Partial<SampleRequest> = {}): SampleRequest {
	// 	return {
	// 		samples: [],
	// 		...d,
	// 	}
	// }

	// export async function record(req: SampleRequest, ...options: httpx.option[]): Promise<SampleResponse> {
	// 	return httpx.post(`${httpx.urlstorage.host()}/brandguard/observations/`, req, ...options)
	// }
}
export function predictionString(p: Prediction): string {
	if (p === Prediction.APPROVED) return "approved"
	if (p === Prediction.REJECTED) return "rejected"
	return "processing"
}

export function isProcessing(item: ImageSearchResponseItem | TextSearchResponseItem): boolean {
	return item.event?.approved !== Prediction.REJECTED && item.event?.approved !== Prediction.APPROVED
}

export namespace images {
	export namespace uploads {
		export function create(file: File, bid: string, ...options: httpx.option[]): Promise<ImageUploadResponse> {
			const data = new FormData()
			data.append("content", file)
			return httpx.upload(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/upload`, data, ...options)
		}

		export function search(
			bid: string,
			req: ImageSearchRequest,
			...options: httpx.option[]
		): Promise<ImageSearchResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/upload`, req, ...options)
		}

		export function find(id: string, bid: string, ...options: httpx.option[]): Promise<ImageFindResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/upload/${id}`, {}, ...options)
		}

		export function patch(
			bid: string,
			id: string,
			req: ImagePatchRequest,
			...options: httpx.option[]
		): Promise<ImagePatchResponse> {
			return httpx.patch(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/upload/${id}`, req, ...options)
		}
	}

	export namespace approvallog {
		export function create(file: File, bid: string, ...options: httpx.option[]): Promise<ImageUploadResponse> {
			const data = new FormData()
			data.append("content", file)
			return httpx.upload(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/approvallog`, data, ...options)
		}

		export function search(
			bid: string,
			req: ImageSearchRequest,
			...options: httpx.option[]
		): Promise<ImageSearchResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/approvallog`, req, ...options)
		}

		export function find(id: string, ...options: httpx.option[]): Promise<ImageFindResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/bg/image/approvallog/${id}`, {}, ...options)
		}

		export function patch(id: string, req: ImagePatchRequest, ...options: httpx.option[]): Promise<ImagePatchResponse> {
			return httpx.patch(`${httpx.urlstorage.host()}/bg/image/approvallog/${id}`, req, ...options)
		}
	}

	export namespace training {
		export function upload(file: File, bid: string, ...options: httpx.option[]): Promise<ImageUploadResponse> {
			const data = new FormData()
			data.append("content", file)
			return httpx.upload(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training`, data, ...options)
		}

		export function search(
			req: ImageSearchRequest,
			bid: string,
			...options: httpx.option[]
		): Promise<ImageSearchResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training`, req, ...options)
		}

		export function find(id: string, bid: string, ...options: httpx.option[]): Promise<ImageFindResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training/${id}`, {}, ...options)
		}

		export function patch(
			id: string,
			bid: string,
			req: ImagePatchRequest,
			...options: httpx.option[]
		): Promise<ImagePatchResponse> {
			return httpx.patch(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training/${id}`, req, ...options)
		}
	}
}

export namespace text {
	export namespace uploads {
		export function create(file: File, bid: string, ...options: httpx.option[]): Promise<TextUploadResponse> {
			const data = new FormData()
			data.append("content", file)
			return httpx.upload(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/upload`, data, ...options)
		}

		export function search(
			bid: string,
			req: TextSearchRequest,
			...options: httpx.option[]
		): Promise<TextSearchResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/upload`, req, ...options)
		}

		export function find(id: string, bid: string, ...options: httpx.option[]): Promise<TextFindResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/upload/${id}`, {}, ...options)
		}
	}
	export namespace approvallog {
		export function create(file: File, bid: string, ...options: httpx.option[]): Promise<TextUploadResponse> {
			const data = new FormData()
			data.append("content", file)
			return httpx.upload(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/approvallog`, data, ...options)
		}

		export function search(
			bid: string,
			req: TextSearchRequest,
			...options: httpx.option[]
		): Promise<TextSearchResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/approvallog`, req, ...options)
		}

		export function find(id: string, bid: string, ...options: httpx.option[]): Promise<TextFindResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/approvallog/${id}`, {}, ...options)
		}
	}

	export namespace training {
		export function upload(file: File, bid: string, ...options: httpx.option[]): Promise<TextUploadResponse> {
			const data = new FormData()
			data.append("content", file)
			return httpx.upload(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training`, data, ...options)
		}

		export function search(
			req: TextSearchRequest,
			bid: string,
			...options: httpx.option[]
		): Promise<TextSearchResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training`, req, ...options)
		}

		export function find(id: string, bid: string, ...options: httpx.option[]): Promise<TextFindResponse> {
			return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training/${id}`, {}, ...options)
		}

		export function patch(
			id: string,
			bid: string,
			req: TextPatchRequest,
			...options: httpx.option[]
		): Promise<TextPatchResponse> {
			return httpx.patch(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training/${id}`, req, ...options)
		}
	}

	export function patch(id: string, req: TextPatchRequest, ...options: httpx.option[]): Promise<TextPatchResponse> {
		return httpx.patch(`${httpx.urlstorage.host()}/bg/text/${id}`, req, ...options)
	}
}
