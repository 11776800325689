import * as caching from "caching"
import * as timex from "timex"
import * as httpx from "httpx"
import * as api from "./api"

export const cache = new caching.Cache({
	namespace: "novacloud.media.urls",
	ttl: timex.duration.iso("PT10M").toMillis(),
})

export async function cachable(mid: string, ...options: httpx.option[]): Promise<string> {
	return api.url(mid, ...options).then((r) => r.url)
}

// get or load a media url by id.
export async function current(aid: string, mid: string, ...options: httpx.option[]): Promise<string> {
	return cache.maybe(`${aid}.${mid}`, () => cachable(mid, ...options))
}

export default cache
