import React from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import classnames from "classnames"
import * as api from "./api"
import * as context from "./adgen.responsive.search.context"
import { FormContainer, AccordionHeader, inlined, EditField } from "ads/adgen/adgen.content.layouts"

interface props {
	content: api.AdResponsiveSearchContent
	metadata: api.AdResponsiveSearchMetadata
}

export default function AdgenForm(props: props): JSX.Element {
	const adgenConstants = context.adgenconstants()
	const { content, metadata } = props
	return (
		<>
			<layouts.accordions.Container mb="16px" minHeight="25px" borderBottom={layouts.theme.borders.grey.light}>
				<layouts.containers.box tabIndex={0}>
					<layouts.accordions.Description fontSize="30px" textAlign="right">
						<AccordionHeader>
							Headlines {content.headlines.length}/{adgenConstants.maxHeadlinesCount}
						</AccordionHeader>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.containers.flex className="accordion-content" flexDirection="column" width="100%">
							{content.headlines.map((headline, idx) => {
								return (
									<layouts.containers.flex key={idx} fontSize="10px" fontWeight="400">
										<EditField
											required={idx <= adgenConstants.minHeadlinesCount - 1}
											content={headline}
											maxLength={adgenConstants.maxHeadlineLength}
											index={idx}
											onChange={(v) => {}}
										/>
									</layouts.containers.flex>
								)
							})}
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</layouts.containers.box>
			</layouts.accordions.Container>
			<layouts.accordions.Container mb="16px" minHeight="25px" borderBottom={layouts.theme.borders.grey.light}>
				<layouts.containers.box tabIndex={1}>
					<layouts.accordions.Description>
						<AccordionHeader>
							Descriptions {content.descriptions.length}/{adgenConstants.maxDescriptionsCount}
						</AccordionHeader>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.containers.flex className="accordion-content" flexDirection="column" width="100%">
							{content.descriptions.map((description, idx) => {
								return (
									<layouts.containers.flex key={idx} fontSize="10px" fontWeight="400">
										<EditField
											required={idx <= adgenConstants.minDescriptionCount - 1}
											content={description}
											maxLength={adgenConstants.maxDescriptionLength}
											index={idx}
											onChange={(val) => {}}
										/>
									</layouts.containers.flex>
								)
							})}
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</layouts.containers.box>
			</layouts.accordions.Container>
			<FormContainer className="fields">
				<layouts.containers.flex flexDirection="column" mt="12px">
					<inputs.Text
						placeholder="Name"
						defaultValue={metadata.description}
						className={classnames(inlined)}
						onChange={(val) => {}}
					/>
				</layouts.containers.flex>
				<layouts.containers.flex flexDirection="column" mt="12px">
					<layouts.containers.flex flexWrap="wrap" overflowY="auto" overflowX="hidden">
						{content.urls.map((u, idx) => (
							<layouts.pills.Primary key={idx} mr="8px" mt="10px">
								{u}
							</layouts.pills.Primary>
						))}
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex flexDirection="column" mt="12px">
					<inputs.Text
						defaultValue={content.path1}
						className={classnames(inlined)}
						placeholder="Path"
						onChange={(val) => {}}
					/>
					<layouts.containers.span mb="10px" />
					<inputs.Text
						defaultValue={content.path2}
						className={classnames(inlined)}
						placeholder="Path2"
						onChange={(val) => {}}
					/>
				</layouts.containers.flex>
			</FormContainer>
		</>
	)
}
