import React from "react"
import { Route, Routes } from "react-router-dom"
import * as pools from "ads/pools"
import * as googleads from "ads/google"
import * as mgoogle from "./google"
import * as facebook from "./facebook"
import Layout from "./display"

namespace approvals {
	export function FacebookFromURL(): JSX.Element {
		return (
			<pools.CacheRoot>
				<Layout>
					<facebook.Display />
				</Layout>
			</pools.CacheRoot>
		)
	}

	export function GoogleFromURL(): JSX.Element {
		return (
			<pools.CacheRoot>
				<Layout>
					<mgoogle.Display />
				</Layout>
			</pools.CacheRoot>
		)
	}
}

namespace creative {
	export namespace google {
		export function Responsive(): JSX.Element {
			return (
				<pools.CacheRoot>
					<googleads.Display publish={mgoogle.Publisher} />
				</pools.CacheRoot>
			)
		}
	}
}

export default function Routing(): JSX.Element {
	return (
		<Routes>
			<Route path="/c/google/responsive" element={<creative.google.Responsive />} />
			<Route path="/q/facebook" element={<approvals.FacebookFromURL />} />
			<Route path="/q/google" element={<approvals.GoogleFromURL />} />
			<Route path="*" element={<pools.Container />} />
		</Routes>
	)
}
