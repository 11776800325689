import React from "react"

interface CTX {
	screens: number
	enabled: boolean
	increment(delta: number): number
}

export function create(d: Partial<CTX>): CTX {
	return {
		screens: 0,
		enabled: false,
		increment(d: number): number {
			return d
		},
	}
}

export const Context = React.createContext(create({}))

export const Provider = Context.Provider

export const Consumer = Context.Consumer

export default Context
