import React from "react"
import styled from "@emotion/styled"
import * as icons from "icons"
import * as layouts from "layouts"
import * as theme from "layouts/theme"

interface props {
	approve: boolean
	reject: boolean
	clickApprove(): void
	clickReject(): void
	disabled?: boolean
}

interface approve_props {
	approve?: boolean
	clickApprove(): void
	disabled?: boolean
}

interface reject_props {
	reject?: boolean
	clickReject(): void
	disabled?: boolean
}

const Checkbox = styled(icons.checkboxes.unstyled)`
	fill: white;
	transition: fill 0.3s ease;
	path {
		stroke: ${theme.colors.grey.light30};
		stroke-width: 2;
		stroke-linecap: round;
		stroke-linejoin: round;
		transition: stroke 0.3s ease;
	}

	&:hover {
		fill: ${theme.colors.blue};
	}
	&:hover path {
		stroke: white;
	}
`

const Xbox = styled(icons.xboxes.unstyled)`
	fill: white;
	transition: fill 0.3s ease;
	path {
		stroke: ${theme.colors.grey.light30};
		stroke-width: 2;
		stroke-linecap: round;
		stroke-linejoin: round;
		transition: stroke 0.3s ease;
	}
	&:hover {
		fill: ${theme.colors.blue};
	}
	&:hover path {
		stroke: white;
	}
`

export function Container(props: props): JSX.Element {
	const { approve, reject, disabled, clickApprove, clickReject } = props

	return (
		<layouts.containers.flex>
			<layouts.containers.box onClick={() => (disabled ? undefined : clickReject())} mr="8px">
				<icons.Xbox checked={reject} disabled={disabled} />
			</layouts.containers.box>
			<layouts.containers.box onClick={() => (disabled ? undefined : clickApprove())}>
				<icons.Checkbox checked={approve} disabled={disabled} />
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}

export function Approve(props: approve_props): JSX.Element {
	const { disabled, clickApprove } = props

	return (
		<layouts.containers.flex>
			<layouts.containers.box onClick={() => (disabled ? undefined : clickApprove())}>
				<Checkbox />
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}

export function Reject(props: reject_props): JSX.Element {
	const { disabled, clickReject } = props

	return (
		<layouts.containers.flex>
			<layouts.containers.box onClick={() => (disabled ? undefined : clickReject())} mr="8px">
				<Xbox />
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}
