import { Location } from "react-router-dom"

export function display(location: Location, id: string): string {
	return `${location.pathname}/${id}`
}

export function root(bid: string, pid: string): string {
	return `/brands/${bid}/ads/${pid}`
}

export namespace ads {
	export function index(bid: string): string {
		return `/brands/${bid}/ads`
	}

	export function metrics(bid: string, pid: string): string {
		return `${root(bid, pid)}/metrics`
	}

	export namespace google {
		export function published(bid: string, pid: string): string {
			return `${root(bid, pid)}/google/published`
		}

		export function responsive(bid: string, pid: string): string {
			return `${root(bid, pid)}/google/responsive`
		}
	}

	export function facebook(bid: string, pid: string): string {
		return `${root(bid, pid)}/facebook/links`
	}
}
