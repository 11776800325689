import * as httputils from "httpx"
export interface Influencer {
	name: string
	photo: string
	processes: {
		name: string
		background: string
		photo: string
		date: string
		status: string
	}[]
}

export function index(id: number, ...options: httputils.option[]): Promise<Influencer[]> {
	return Promise.resolve([
		{
			name: "sam",
			photo: "",
			processes: [
				{ name: "slippers", background: "", photo: "", date: "02/09/2022", status: "In Progress (3 out of 10)" },
				{ name: "pajamas", background: "", photo: "", date: "02/09/2022", status: "Queued" },
				{ name: "plush Clones", background: "", photo: "", date: "02/09/2022", status: "Canceled" },
				{ name: "Golf Head Cover", background: "", photo: "", date: "02/09/2022", status: "Completed" },
			],
		},
		{
			name: "Bam",
			photo: "",
			processes: [
				{ name: "slippers", background: "", photo: "", date: "02/09/2022", status: "In Progress (3 out of 10)" },
				{ name: "pajamas", background: "", photo: "", date: "02/09/2022", status: "Queued" },
				{ name: "plush Clones", background: "", photo: "", date: "02/09/2022", status: "Canceled" },
				{ name: "Golf Head Cover", background: "", photo: "", date: "02/09/2022", status: "Completed" },
			],
		},
		{
			name: "Dam",
			photo: "",
			processes: [
				{ name: "slippers", background: "", photo: "", date: "02/09/2022", status: "In Progress (3 out of 10)" },
				{ name: "pajamas", background: "", photo: "", date: "02/09/2022", status: "Queued" },
				{ name: "plush Clones", background: "", photo: "", date: "02/09/2022", status: "Canceled" },
				{ name: "Golf Head Cover", background: "", photo: "", date: "02/09/2022", status: "Completed" },
			],
		},
		{
			name: "Wam",
			photo: "",
			processes: [
				{ name: "slippers", background: "", photo: "", date: "02/09/2022", status: "In Progress (3 out of 10)" },
				{ name: "pajamas", background: "", photo: "", date: "02/09/2022", status: "Queued" },
				{ name: "plush Clones", background: "", photo: "", date: "02/09/2022", status: "Canceled" },
				{ name: "Golf Head Cover", background: "", photo: "", date: "02/09/2022", status: "Completed" },
			],
		},
		{
			name: "Lamb",
			photo: "",
			processes: [
				{ name: "slippers", background: "", photo: "", date: "02/09/2022", status: "In Progress (3 out of 10)" },
				{ name: "pajamas", background: "", photo: "", date: "02/09/2022", status: "Queued" },
				{ name: "plush Clones", background: "", photo: "", date: "02/09/2022", status: "Canceled" },
				{ name: "Golf Head Cover", background: "", photo: "", date: "02/09/2022", status: "Completed" },
			],
		},
		{
			name: "Cram",
			photo: "",
			processes: [
				{ name: "slippers", background: "", photo: "", date: "02/09/2022", status: "In Progress (3 out of 10)" },
				{ name: "pajamas", background: "", photo: "", date: "02/09/2022", status: "Queued" },
				{ name: "plush Clones", background: "", photo: "", date: "02/09/2022", status: "Canceled" },
				{ name: "Golf Head Cover", background: "", photo: "", date: "02/09/2022", status: "Completed" },
			],
		},
		{
			name: "Jam",
			photo: "",
			processes: [
				{ name: "slippers", background: "", photo: "", date: "02/09/2022", status: "In Progress (3 out of 10)" },
				{ name: "pajamas", background: "", photo: "", date: "02/09/2022", status: "Queued" },
				{ name: "plush Clones", background: "", photo: "", date: "02/09/2022", status: "Canceled" },
				{ name: "Golf Head Cover", background: "", photo: "", date: "02/09/2022", status: "Completed" },
			],
		},
		{
			name: "Cam",
			photo: "",
			processes: [
				{ name: "slippers", background: "", photo: "", date: "02/09/2022", status: "In Progress (3 out of 10)" },
				{ name: "pajamas", background: "", photo: "", date: "02/09/2022", status: "Queued" },
				{ name: "plush Clones", background: "", photo: "", date: "02/09/2022", status: "Canceled" },
				{ name: "Golf Head Cover", background: "", photo: "", date: "02/09/2022", status: "Completed" },
			],
		},
		{
			name: "Zam",
			photo: "",
			processes: [
				{ name: "slippers", background: "", photo: "", date: "02/09/2022", status: "In Progress (3 out of 10)" },
				{ name: "pajamas", background: "", photo: "", date: "02/09/2022", status: "Queued" },
				{ name: "plush Clones", background: "", photo: "", date: "02/09/2022", status: "Canceled" },
				{ name: "Golf Head Cover", background: "", photo: "", date: "02/09/2022", status: "Completed" },
			],
		},
		{
			name: "Sham",
			photo: "",
			processes: [
				{ name: "slippers", background: "", photo: "", date: "02/09/2022", status: "In Progress (3 out of 10)" },
				{ name: "pajamas", background: "", photo: "", date: "02/09/2022", status: "Queued" },
				{ name: "plush Clones", background: "", photo: "", date: "02/09/2022", status: "Canceled" },
				{ name: "Golf Head Cover", background: "", photo: "", date: "02/09/2022", status: "Completed" },
			],
		},
	])
}
