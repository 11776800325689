import React from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import * as layouts from "layouts"
import * as petcrop from "./petcrop"
import * as simulation from "./discountopt/simulation"
import * as codegen from "./discountopt/codegen"
import Boom from "./boom"
import { Standard as Navigation } from "navigation"

export default function Routing(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<layouts.containers.flex height="100vh" overflowY="hidden">
			<layouts.containers.flex className="navigation-container" flex="0">
				<Navigation></Navigation>
			</layouts.containers.flex>
			<layouts.containers.flex className="body-container" flex="1 1">
				<Routes>
					<Route
						path="/cc/petcrop/regen"
						element={
							<layouts.backgrounds.Splash height="100vh">
								<petcrop.Regen />
							</layouts.backgrounds.Splash>
						}
					/>
					<Route
						path="/cc/petcrop"
						element={
							<layouts.backgrounds.Splash height="100vh">
								<petcrop.Demo />
							</layouts.backgrounds.Splash>
						}
					/>
					<Route
						path="/discountopt/simulation"
						element={
							<layouts.backgrounds.Splash height="100vh">
								<simulation.Demo />
							</layouts.backgrounds.Splash>
						}
					/>
					<Route
						path="/discountopt/codegen"
						element={
							<layouts.backgrounds.Splash height="100vh" width="100vw">
								<codegen.editor.Editor />
							</layouts.backgrounds.Splash>
						}
					/>
					<Route
						path="/exception"
						element={
							<layouts.backgrounds.Splash height="100vh">
								<Boom />
							</layouts.backgrounds.Splash>
						}
					/>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
