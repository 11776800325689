import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { ...rest } = props
	return (
		<svg.SVG width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.99946 10.874C8.6916 10.874 10.874 8.6916 10.874 5.99946C10.874 3.30731 8.6916 1.1249 5.99946 1.1249C3.30731 1.1249 1.1249 3.30731 1.1249 5.99946C1.1249 8.6916 3.30731 10.874 5.99946 10.874ZM5.99946 11.9989C7.45395 11.9989 8.78756 11.4813 9.82613 10.6203L10.5376 11.3317C10.4374 11.7069 10.5345 12.1239 10.8289 12.4183L13.0811 14.6705C13.5204 15.1098 14.2327 15.1098 14.672 14.6705C15.1113 14.2312 15.1113 13.519 14.672 13.0797L12.4197 10.8274C12.1253 10.533 11.7082 10.4359 11.3329 10.5362L10.6214 9.82473C11.4818 8.78636 11.9989 7.4533 11.9989 5.99946C11.9989 2.68605 9.31286 0 5.99946 0C2.68605 0 0 2.68605 0 5.99946C0 9.31286 2.68605 11.9989 5.99946 11.9989Z"
				fill="#5F5F71"
			/>
		</svg.SVG>
	)
}

export default Icon
