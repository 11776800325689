import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as brands from "brands"
import * as api from "./api"
import * as caching from "./cache"

export default function CacheRoot(props: React.PropsWithChildren<unknown>): JSX.Element {
	const { children } = props
	const params = useParams() as {
		bid: string
		id: string
	}
	const bearertoken = sessions.useToken()
	const [brand, setBrand] = useState(brands.api.zero())
	const [pool, setPool] = useState(api.pools.zero())

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		let pbrand = brands.caching.current(params.bid, bearertoken)
		let ppool = caching.maybe(params.id, bearertoken)

		Promise.all([pbrand, ppool])
			.then((result) => {
				const [b, p] = result
				setBrand(brands.api.zero(b))
				setPool(api.pools.zero(p))
			})
			.finally(() => {
				setLoading(false)
				return
			})
	}, [])

	return (
		<layouts.loading.pending loading={loading}>
			<brands.caching.Provider value={brand}>
				<caching.Provider value={pool}>{children}</caching.Provider>
			</brands.caching.Provider>
		</layouts.loading.pending>
	)
}
