import React from "react"
import * as adgencontext from "ads/google/adgen.responsive.search.context"
import * as adgen from "ads/google/ads.google.adgen"
import * as api from "ads/google/api"

export interface suggested extends adgen.Suggested {
	selected: boolean
	currenturl: string
}

export namespace suggestions {
	export function zero(d: Partial<suggested> = {}): suggested {
		return {
			content: api.ads.responsive.content.zero(),
			metadata: api.ads.responsive.metadata(),
			selected: false,
			currenturl: "",
			...d,
		}
	}
}

export interface context {
	adgenctx: adgencontext.context
	ads: suggested[]
	buffered: suggested[]
}

export interface mutation {
	(c: context): context
}

export namespace mutate {
	export function apply(gctx: context, ...mset: mutation[]): context {
		return mset.reduce((ctx, m) => m(ctx), gctx)
	}

	export function replace(r: suggested): mutation {
		return (c: context): context => {
			const mut = c.adgenctx?.content?.id === r.content?.id ? mutate.current.update(r)(c) : c
			return {
				...mut,
				ads: c.ads.map((i) => (i?.content?.id === r.content?.id ? r : i)),
			}
		}
	}

	export function reject(r: suggested): mutation {
		return (c: context): context => {
			const mut = c.adgenctx?.content?.id === r.content?.id ? mutate.current.update(undefined)(c) : c
			return {
				...mut,
				ads: c.ads.filter((i) => i?.content?.id !== r.content?.id),
			}
		}
	}

	export const ads = {
		replace(upd: suggested[]): mutation {
			return (c: context): context => {
				return {
					...c,
					ads: upd,
				}
			}
		},
		modify(ctx: adgencontext.context): mutation {
			return (c: context): context => {
				const idx = c.ads.findIndex((i) => i.content?.id === ctx.content.id)
				if (idx < 0) {
					return c
				}
				const adsupd = c.ads
				adsupd[idx].content = adgencontext.form.content(ctx.content)
				adsupd[idx].metadata = ctx.metadata
				return {
					...c,
					ads: adsupd,
				}
			}
		},
	}

	export const buffered = {
		add(...suggested: suggested[]): mutation {
			return (c: context): context => {
				return {
					...c,
					buffered: [...c.buffered, ...suggested],
				}
			}
		},

		replace(upd: suggested[]): mutation {
			return (c: context): context => {
				return {
					...c,
					buffered: upd,
				}
			}
		},
	}

	export const current = {
		update(upd: suggested | undefined): mutation {
			return (c: context): context => {
				const mut = adgencontext.mutate.apply(
					c.adgenctx,
					adgencontext.mutate.content(adgencontext.form.fromContent(upd?.content)),
					adgencontext.mutate.metadata(upd?.metadata || {}),
				)
				return {
					...c,
					adgenctx: mut,
				}
			}
		},
		modify(...muts: adgencontext.mutation[]): mutation {
			return (c: context): context => {
				if (c.adgenctx === undefined) return c
				const upd = adgencontext.mutate.apply(c.adgenctx, ...muts)
				return {
					...c,
					adgenctx: upd,
				}
			}
		},
	}
}

export function zero(proto: Partial<context> = {}): context {
	return {
		adgenctx: adgencontext.zero(),
		ads: [] as suggested[],
		buffered: [] as suggested[],
		...proto,
	}
}

export const Context = React.createContext(zero())

export const Provider = Context.Provider
export const Consumer = Context.Consumer
