import React, { useContext, useEffect } from "react"
import * as layouts from "layouts"
import { card as Card, description as Description, Approve, Reject } from "ads/adgen/adgen.content.layouts"
import * as adgen from "ads/adgen"
import * as textgen from "ads/textgen"
import * as context from "./adgen.link.context"

interface props {
	onChange(...updates: context.mutation[]): void
}

export default function Container(props: props & layouts.containers.FlexProps): JSX.Element {
	const { onChange, ...rest } = props
	const genctx = useContext(context.Context)

	const disabled = genctx.content.message.accepted.length > 0 || genctx.buffered.messages.length === 0

	function replace(ctx: context.context, idx: number): [textgen.Sample[], textgen.Sample[]] {
		const updated = [...ctx.current.messages]
		const buffered = adgen.buffered.replace(idx, updated, ctx.buffered.messages)
		return [updated, buffered]
	}

	// refill as needed
	useEffect(() => {
		if (genctx.current.messages.length >= 4) return // nothing to do
		const [updcurrent, updbuffer] = adgen.buffered.refill(genctx.current.messages, genctx.buffered.messages)
		onChange(context.mutate.current({ messages: updcurrent }), context.mutate.buffered({ messages: updbuffer }))
	}, [genctx.current.messages.length, genctx.buffered.messages.length])

	return (
		<layouts.containers.grid
			justifyContent="center"
			overflowY="auto"
			display="grid"
			gridGap="25px"
			px="10px"
			py="30px"
			gridTemplateColumns="repeat(auto-fill, 300px)"
			gridTemplateRows="min-content"
			{...rest}
		>
			{genctx.current.messages.map((sample, idx) => {
				return (
					<Card key={`${idx}.${sample.id}`}>
						<layouts.containers.flex flexDirection="row" height="100%" alignItems="center">
							<Description>{sample.generated}</Description>
							<layouts.containers.absolute bottom="-4px" right="0">
								<layouts.containers.flex flexDirection="row">
									<layouts.containers.flex paddingRight="5px">
										<Approve
											disabled={disabled}
											clickApprove={() => {
												const [upd, buffered] = replace(genctx, idx)
												onChange(
													context.mutate.buffered({ messages: buffered }),
													context.mutate.current({ messages: upd }),
													context.mutate.content({ message: { ...sample, feedback: textgen.Feedback.USED } }),
												)
											}}
										/>
									</layouts.containers.flex>
									<layouts.containers.flex>
										<Reject
											disabled={disabled}
											clickReject={() => {
												const [upd, buffered] = replace(genctx, idx)
												onChange(
													context.mutate.buffered({ messages: buffered }),
													context.mutate.current({ messages: upd }),
													context.mutate.rejected({ ...sample, feedback: textgen.Feedback.IGNORED }),
												)
											}}
										/>
									</layouts.containers.flex>
								</layouts.containers.flex>
							</layouts.containers.absolute>
						</layouts.containers.flex>
					</Card>
				)
			})}
		</layouts.containers.grid>
	)
}
