import React from "react"
import * as textgen from "ads/textgen"
import * as adgen from "ads/adgen"
import * as api from "./api"
import * as uuid from "uuid"

export interface cached {
	headlines: textgen.Sample[]
	descriptions: textgen.Sample[]
	messages: textgen.Sample[]
}

export namespace cache {
	export function zero(d: Partial<cached> = {}): cached {
		return {
			headlines: [] as textgen.Sample[],
			descriptions: [] as textgen.Sample[],
			messages: [] as textgen.Sample[],
			...d,
		}
	}

	export function merge(a: Partial<cached>, b: Partial<cached>): cached {
		return zero({
			headlines: [...(a.headlines || []), ...(b.headlines || [])],
			descriptions: [...(a.descriptions || []), ...(b.descriptions || [])],
			messages: [...(a.messages || []), ...(b.messages || [])],
		})
	}
}

export interface mutation {
	(c: context): context
}

export namespace mutate {
	export function apply(gctx: context, ...mset: mutation[]): context {
		return mset.reduce((ctx, m) => m(ctx), gctx)
	}

	export function composed(...mset: mutation[]): mutation {
		return (c: context): context => {
			return mset.reduce((ctx, m) => m(ctx), c)
		}
	}

	export function content(upd: Partial<CurrentAdFields>): mutation {
		return (c: context): context => {
			return {
				...c,
				content: {
					...c.content,
					...upd,
				},
			}
		}
	}

	export function metadata(upd: Partial<api.Metadata>): mutation {
		return (c: context): context => {
			return {
				...c,
				metadata: {
					...c.metadata,
					...upd,
				},
			}
		}
	}

	export function rejected(...upd: textgen.Sample[]): mutation {
		return (c: context): context => {
			return {
				...c,
				rejected: [...c.rejected, ...upd],
			}
		}
	}

	export function rejected_reset(c: context): context {
		return {
			...c,
			rejected: [],
		}
	}

	export function current(upd: Partial<cached>): mutation {
		return (c: context): context => {
			return {
				...c,
				current: {
					...c.current,
					...upd,
				},
			}
		}
	}

	export function buffered_merge(upd: Partial<cached>): mutation {
		return (c: context): context => {
			return {
				...c,
				buffered: cache.merge(c.buffered, upd),
			}
		}
	}

	export function buffered(upd: Partial<cached>): mutation {
		return (c: context): context => {
			return {
				...c,
				buffered: {
					...c.buffered,
					...upd,
				},
			}
		}
	}

	export function generated(upd: JSX.Element): mutation {
		return (c: context): context => {
			return {
				...c,
				generated: upd,
			}
		}
	}
}

export namespace form {
	export function zero(d: Partial<CurrentAdFields> = {}): CurrentAdFields {
		return {
			id: uuid.NIL,
			description: adgen.text.sample(""),
			message: adgen.text.sample(""),
			name: adgen.text.sample(""),
			link: "",
			media: { id: "", url: "" },
			call_to_action: {
				type: "",
				value: { link: "" },
			},
			...d,
		}
	}

	export function adlinkcontent(c: CurrentAdFields): api.AdLinkContent {
		return {
			id: c.id,
			name: c.name.accepted,
			message: c.message.accepted,
			description: c.description.accepted,
			link: c.link,
			call_to_action: c.call_to_action,
			media_id: c.media.id,
		}
	}
}

export interface CurrentAdFields {
	id: string
	message: textgen.Sample
	description: textgen.Sample
	name: textgen.Sample
	link: string
	media: { id: string; url: string }
	call_to_action: api.CallToAction
}

export interface context {
	buffered: cached
	current: cached
	content: CurrentAdFields
	rejected: textgen.Sample[]
	generated: JSX.Element
	metadata: api.Metadata
}

export function zero(d: Partial<context> = {}): context {
	return {
		buffered: cache.zero(),
		current: cache.zero(),
		content: form.zero(),
		rejected: [],
		generated: <></>,
		metadata: api.ads.metadata.zero(),
		...d,
	}
}

export const Context = React.createContext(zero())

export interface AdgenConstants {
	maxPrimaryTextLength: number
	maxHeadlineLength: number
	maxDescriptionLength: number
}

export function adgenconstants(): AdgenConstants {
	return {
		maxPrimaryTextLength: 125,
		maxHeadlineLength: 27,
		maxDescriptionLength: 27,
	}
}

export const Provider = Context.Provider
export const Consumer = Context.Consumer
