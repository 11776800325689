import * as uuid from "uuid"
import * as timex from "timex"
import * as httpx from "httpx"
import * as brandguard from "brandguard"

import {
	AdLinkContent,
	AdLinkMetadata,
	Query,
	Metadata,
	GenerateRequest,
	GenerateResponse,
	AdLinkCreateRequest,
	AdLinkCreateResponse,
	SearchRequest,
	SearchResponse,
	RetargetResponse,
	SuggestedRequest,
	SuggestedResponse,
	SuggestedDeleteResponse,
	AdLinkContentResponse,
} from "./ads.facebook.adgen"

import {
	ClientAccount,
	ClientAccountSearchRequest,
	ClientAccountSearchResponse,
	ClientAccountCreateRequest,
	ClientAccountCreateResponse,
	ClientAccountFindResponse,
	FBBusinessPageResponse,
	FBBusinessPortfolioResponse,
	FBAdAccountResponse,
	FBBusinessPage,
	FBAdAccount,
	FBBusinessPortfolio,
	FBBusiness,
	FBBusinesses,
	FBAdCampaign,
} from "./ads.facebook.clients"

export type {
	CallToAction,
	Metadata,
	Query,
	AdLinkContent,
	AdLinkCreateRequest,
	AdLinkCreateResponse,
	AdLinkMetadata,
	SearchRequest,
	SearchResponse,
	SuggestedRequest,
	SuggestedResponse,
	RetargetRequest,
	RetargetResponse,
	AdLinkContentResponse,
} from "./ads.facebook.adgen"

export type {
	ClientAccount,
	ClientAccountSearchRequest,
	ClientAccountSearchResponse,
	ClientAccountCreateRequest,
	ClientAccountCreateResponse,
	ClientAccountFindRequest,
	ClientAccountFindResponse,
	FBBusinessPageResponse,
	FBBusinessPortfolioResponse,
	FBAdAccountResponse,
	FBBusinessPage,
	FBAdAccount,
	FBBusinessPortfolio,
	FBBusiness,
	FBBusinesses,
	FBAdCampaign,
} from "./ads.facebook.clients"

export namespace accounts {
	export function zero(d: Partial<ClientAccount> = {}): ClientAccount {
		return {
			id: uuid.NIL,
			account_id: uuid.NIL,
			fb_business_id: 0n,
			fb_account_id: 0n,
			fb_page_id: 0n,
			fb_campaign_id: 0n,
			manual_access_token: "",
			authz_credentials_id: uuid.NIL,
			description: "",
			created_at: "",
			updated_at: "",
			...d,
		}
	}

	// manual testing data.
	export function testdata(d: Partial<ClientAccount> = {}): ClientAccount {
		return zero({
			description: "Test",
			fb_page_id: 110627811957273n,
			fb_account_id: 851715812769371n,
			...d,
		})
	}

	// export function accessible(req: AccessibleRequest, ...options: httpx.option[]): Promise<AccessibleResponse> {
	// 	return httpx.get(`${httpx.urlstorage.host()}/ad/facebook/accounts/retrieve/`, req, ...options)
	// }

	// export function info(req: AccountsRequest, ...options: httpx.option[]): Promise<AccountsResponse> {
	// 	return httpx.get(`${httpx.urlstorage.host()}/ad/facebook/accounts/retrieve/${req.id}`, req, ...options)
	// }

	export namespace searches {
		export function zero(proto: Partial<ClientAccountSearchRequest> = {}): ClientAccountSearchRequest {
			return {
				offset: uuid.NIL,
				query: "",
				authz_credentials_id: "",
				...proto,
			}
		}
	}

	export async function search(
		req: ClientAccountSearchRequest,
		...options: httpx.option[]
	): Promise<ClientAccountSearchResponse> {
		return httpx
			.get<ClientAccountSearchResponse>(`${httpx.urlstorage.host()}/ad/facebook/accounts/`, req, ...options)
			.then((resp) => ({
				...resp,
				items: resp.items || [],
			}))
	}

	export function create(
		req: ClientAccountCreateRequest,
		...options: httpx.option[]
	): Promise<ClientAccountCreateResponse> {
		return httpx.post(`${httpx.urlstorage.host()}/ad/facebook/accounts/`, req, ...options)
	}

	export function find(id: string, ...options: httpx.option[]): Promise<ClientAccountFindResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/ad/facebook/accounts/${id}`, {}, ...options)
	}
}
export interface Media {
	id: string
	url: string
}

export namespace ads {
	export namespace media {
		export function zero(d: Partial<Media> = {}): Media {
			return {
				id: "",
				url: "",
				...d,
			}
		}
	}

	export namespace metadata {
		export function zero(d: Partial<Metadata> = {}): Metadata {
			const ts = timex.local()
			const tsiso = ts.toISO()
			return {
				id: uuid.NIL,
				account_id: uuid.NIL,
				profile_id: uuid.NIL,
				accepted_by: uuid.NIL,
				pool_id: uuid.NIL,
				tid: uuid.NIL,
				fb_manager_id: 0n,
				fb_account_id: 0n,
				fb_campaign_id: 0n,
				fb_adset_id: 0n,
				fb_page_id: 0n,
				uid: 0n,
				authz_credentials_id: uuid.NIL,
				description: "",
				created_at: tsiso,
				updated_at: tsiso,
				disabled_at: timex.infinity().toISO(),
				bounce_rate: 0,
				click_through_rate: 0,
				conversion_rate: 0,
				...d,
			}
		}
	}

	export namespace suggestions {
		export function zero(proto: Partial<SuggestedRequest> = {}): SuggestedRequest {
			return {
				pool_id: uuid.NIL,
				offset: uuid.NIL,
				limit: 100,
				...proto,
			}
		}

		export async function get(req: SuggestedRequest, ...options: httpx.option[]): Promise<SuggestedResponse> {
			return httpx
				.get<SuggestedResponse>(`${httpx.urlstorage.host()}/ad/facebook/suggest/`, req, ...options)
				.then((resp) => ({ ...resp, items: resp.items || [] }))
		}

		export async function destroy(id: string, ...options: httpx.option[]): Promise<SuggestedDeleteResponse> {
			return httpx.destroy<SuggestedDeleteResponse>(
				`${httpx.urlstorage.host()}/ad/facebook/suggest/${id}`,
				{},
				...options,
			)
		}
	}

	export namespace link {
		export function metadata(d: Partial<AdLinkMetadata> = {}): AdLinkMetadata {
			return {
				id: uuid.NIL,
				account_id: uuid.NIL,
				profile_id: uuid.NIL,
				pool_id: uuid.NIL,
				facebook_client_id: uuid.NIL,
				description: "",
				autogenerated: false,
				created_at: "",
				updated_at: "",
				disabled_at: "",
				profanity: 0.0,
				grammar: 0.0,
				racism: 0.0,
				onbrand: 0.0,
				brandguard: 0.0,
				brandguard_prediction: brandguard.api.Prediction.REJECTED,
				...d,
			}
		}

		export function query(d: Partial<Query> = {}): Query {
			return {
				account_id: uuid.NIL,
				profile_id: uuid.NIL,
				prompt: "",
				brand: "",
				bloom: new Uint8Array(),
				...d,
			}
		}

		export function zero(d: Partial<AdLinkContent> = {}): AdLinkContent {
			return {
				id: uuid.NIL,
				name: "",
				description: "",
				message: "",
				link: "",
				media_id: "",
				...d,
			}
		}

		// utility function to development
		export function testdata(proto: Partial<AdLinkContent> = {}): AdLinkContent {
			return zero({
				name: "Test headline",
				description: "Test description",
				message: "Test message",
				link: "Test Link",
				media_id: "",
				call_to_action: {
					type: "Test call_to_action type",
					value: { link: "Test call_to_action link" },
				},
				...proto,
			})
		}

		export namespace create {
			export function request(d: Partial<AdLinkCreateRequest> = {}): AdLinkCreateRequest {
				return {
					metadata: ads.link.metadata(),
					content: ads.link.zero(),
					...d,
				}
			}

			export async function post(req: AdLinkCreateRequest, ...options: httpx.option[]): Promise<AdLinkCreateResponse> {
				return httpx.post<AdLinkCreateResponse>(`${httpx.urlstorage.host()}/ad/facebook/link/`, req, ...options)
			}
		}

		export namespace generate {
			export function request(d: Partial<GenerateRequest> = {}): GenerateRequest {
				return {
					query: ads.link.query(),
					...d,
				}
			}

			export async function get(req: GenerateRequest, ...options: httpx.option[]): Promise<GenerateResponse> {
				return httpx
					.get<GenerateResponse>(`${httpx.urlstorage.host()}/ad/facebook/link/gen`, req, ...options)
					.then((resp) => ({ ...resp, items: resp.items || [] })) // ensure we always have an empty array to operate on.
			}
		}

		export async function content(id: string, ...options: httpx.option[]): Promise<AdLinkContentResponse> {
			return httpx.get<AdLinkContentResponse>(`${httpx.urlstorage.host()}/ad/facebook/link/${id}`, {}, ...options)
		}

		export async function retarget(id: string, ...options: httpx.option[]): Promise<RetargetResponse> {
			return httpx.post<RetargetResponse>(`${httpx.urlstorage.host()}/ad/facebook/link/${id}/retarget`, {}, ...options)
		}
	}
}

export async function search(req: SearchRequest, ...options: httpx.option[]): Promise<SearchResponse> {
	return httpx
		.get<SearchResponse>(`${httpx.urlstorage.host()}/ad/facebook/link`, req, ...options)
		.then((resp) => ({ ...resp, items: resp.items || [] })) // ensure we always have an empty array to operate on.
}

export namespace graphql {
	export namespace zeros {
		export function portfolio(proto: Partial<FBBusinessPortfolio> = {}): FBBusinessPortfolio {
			return {
				id: "",
				name: "",
				...proto,
			}
		}

		export function page(proto: Partial<FBBusinessPage> = {}): FBBusinessPage {
			return {
				id: "",
				name: "",
				...proto,
			}
		}

		export function account(proto: Partial<FBAdAccount> = {}): FBAdAccount {
			return {
				id: "",
				name: "",
				account_id: "",
				...proto,
			}
		}

		export function campaign(proto: Partial<FBAdCampaign> = {}): FBAdCampaign {
			return {
				id: "",
				name: "",
				special_ad_category: "",
				objective: "",
				status: "",
				special_ad_categories: [] as string[],
				...proto,
			}
		}

		export function business(proto: Partial<FBBusiness> = {}): FBBusiness {
			return {
				id: "",
				name: "",
				owned_ad_accounts: { data: [] as FBAdAccount[] },
				owned_pages: { data: [] as FBBusinessPage[] },
				...proto,
			}
		}

		export function businesses(proto: Partial<FBBusinesses> = {}): FBBusinesses {
			return {
				data: [] as FBBusiness[],
				...proto,
			}
		}
	}

	export function businesses(...options: httpx.option[]): Promise<FBBusinessPortfolioResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/ad/facebook/api/businesses`, {}, ...options)
	}

	export function pages(id: string, ...options: httpx.option[]): Promise<FBBusinessPageResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/ad/facebook/api/businesses/${id}/pages`, {}, ...options)
	}

	export function acсounts(id: string, ...options: httpx.option[]): Promise<FBAdAccountResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/ad/facebook/api/businesses/${id}/ad_accounts`, {}, ...options)
	}

	export function my_business(...options: httpx.option[]): Promise<FBBusinesses> {
		return httpx.get(`${httpx.urlstorage.host()}/ad/facebook/api/my_businesses`, {}, ...options)
	}

	export function create_compaign(ad_id: string, req: FBAdCampaign, ...options: httpx.option[]): Promise<FBAdCampaign> {
		return httpx.post(`${httpx.urlstorage.host()}/ad/facebook/api/ad_accounts/${ad_id}/campaigns`, req, ...options)
	}
}
