export const colors = {
	black: {
		lightest: "#292934",
		light: "#171616",
		medium: "#0F0F0F",
		darkest: "#0B0B0B",
		dark: "#040404",
	},
	white: "#FFFFFF",
	navy: "#16133F",
	orange: "#EF463B",
	blue: "#3E6EE4",
	grey: {
		lightest: "#F5F5F5",
		light05: "#F3F4F5",
		light10: "#EEEFF0",
		light20: "#EAEAEA",
		light30: "#C2C2C2",
		light40: "#7E8490",
		medium: "#4D5E80",
		dark5: "#464646",
		dark10: "#5F5F71",
		dark20: "#616161",
		dark20alpha: "#61616166",
		bg: "#F7F8FA",
	},
	gradient: {
		pink: "#EC1977",
		orange: "#F05E22",
	},
	hinting: {
		lightest: "#D92B4B",
		medium: "#A62139",
		darkest: "#73192A",
		darkestalpha: "#73192A66",
	},
	danger: "#A62139",
	success: "#14A44D",
	hover: "#3C54B70A",
	dianthus: "linear-gradient(85.94deg, #EC1977 1.81%, #F05E22 97.64%)",
	nova: "linear-gradient(54.62deg, #5C1AFF 0.61%, #800080 99.38%)",
	green: "#2B5D31",
	link: {
		hover: "#014BD0",
	},
	red: {
		light: "#F74C45",
		cinnabar: "#E43E3E",
		mandarin: "#E47A3E",
	},
	space_cadet: "#13244B",
	charts: {
		green: {
			base: "#54ddcf",
			alpha: "rgba(154, 234, 226, 0.5)",
		},
		blue: {
			base: "#50B1FA",
			alpha: "rgba(177, 222, 253, 0.5)",
		},
		violet: {
			base: "#7261B0",
			alpha: "rgba(193, 185, 222, 0.5)",
		},
		yellow: {
			base: "#F8CD44",
			alpha: "rgba(253, 237, 180, 0.5)",
		},
	},
}

export const borders = {
	grey: {
		darkest: "1px ridge #666666",
		darkestalpha: "1px ridge #66666666",
		light: "2px solid #b1b1ba",
		light_thin: "0.5px solid #b1b1ba",
	},
	hinting: {
		darkest: "1px ridge #73192A",
	},
	radius: {
		standard: "0.25em",
	},
}

export const boxshadow = {
	default: "0px 2px 4px rgba(0, 0, 0, 0.25)",
	grey: {
		lightest: "0px 2px 4px rgba(0, 0, 0, 0.25)",
	},
	black: {
		medium: "0px 2px 2px rgba(0, 0, 0, 0.25)",
		wide: "0px 4px 4px rgba(0, 0, 0, 0.25)",
	},
}

export const backgrounds = {
	danger: "#A6213977",
	bluealpha: "rgba(62, 110, 228, 0.04)",
	uploadicon: "rgba(62, 110, 228, 0.27)",
}

export const buttons = {
	colors: {
		enabled: colors.blue,
		disabled: colors.grey.light30,
	},
}
