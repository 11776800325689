import * as layouts from "layouts"
import { text as Text } from "ads/adgen/adgen.content.layouts"

export default function Promo(): JSX.Element {
	return (
		<layouts.containers.flex flexDirection="column" alignItems="center" verticalAlign="middle">
			<layouts.containers.flex justifyContent="center" pt="50px" flexDirection="column" alignItems="center">
				<Text fontWeight="600" color={layouts.theme.colors.blue} fontSize="22px">
					This is great!<br></br> What do I do now?
				</Text>
			</layouts.containers.flex>
			<layouts.containers.flex px="25px" pt="20px" flexDirection="column" alignItems="center">
				<Text fontWeight="700" color={layouts.theme.colors.grey.medium} fontSize="16px">
					Step One
				</Text>
				<Text fontWeight="400" color={layouts.theme.colors.grey.medium} fontSize="16px">
					Generate text <br></br> and select your favorite.
				</Text>
			</layouts.containers.flex>
			<layouts.containers.flex px="25px" pt="10px" flexDirection="column" alignItems="center">
				<Text fontWeight="700" color={layouts.theme.colors.grey.medium} fontSize="16px">
					Step Two
				</Text>
				<Text fontWeight="400" color={layouts.theme.colors.grey.medium} fontSize="16px">
					Upload or add an image <br></br> from your library.
				</Text>
			</layouts.containers.flex>
			<layouts.containers.flex px="25px" pt="10px" flexDirection="column" alignItems="center">
				<Text fontWeight="700" color={layouts.theme.colors.grey.medium} fontSize="16px">
					Step Three
				</Text>
				<Text fontWeight="400" color={layouts.theme.colors.grey.medium} fontSize="16px">
					Publish the ad
				</Text>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
