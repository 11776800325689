import * as uuid from "uuid"
import * as httpx from "httpx"
import { DateTime } from "luxon"

import {
	Media,
	SearchRequest,
	SearchResponse,
	CreateRequest,
	CreateResponse,
	RedirectResponse,
	DeleteResponse,
} from "./media"
export type {
	Media,
	SearchRequest,
	SearchResponse,
	CreateRequest,
	CreateResponse,
	RedirectResponse,
	DeleteResponse,
} from "./media"

export function zero(m: Partial<Media> = {}): Media {
	let ts = DateTime.now()
	return {
		id: uuid.NIL,
		account_id: uuid.NIL,
		group_id: uuid.NIL,
		uploaded_by: uuid.NIL,
		approved_by: uuid.NIL,
		content_digest: uuid.NIL,
		description: "",
		path: "",
		mimetype: "",
		width: 0,
		height: 0,
		created_at: ts.toISO(),
		updated_at: ts.toISO(),
		tombstoned_at: ts.toISO(),
		...m,
	}
}

export function mediaRedirect(media_id: string): string {
	return `${httpx.urlstorage.host()}/media/${media_id}`
}

export function uploadMedia(file: File, media_path: string, ...options: httpx.option[]): Promise<CreateResponse> {
	const data = new FormData()
	data.append("media_file", file)
	return httpx.upload(`${httpx.urlstorage.host()}${media_path}`, data, ...options)
}

export function url(contentdigest: string, ...options: httpx.option[]): Promise<RedirectResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/media/${contentdigest}`, {}, ...options)
}

export function search(req: SearchRequest, media_path: string, ...options: httpx.option[]): Promise<SearchResponse> {
	return httpx.get(`${httpx.urlstorage.host()}${media_path}`, req, ...options)
}

export function destroy(media_path: string, media_id: string, ...options: httpx.option[]): Promise<DeleteResponse> {
	return httpx.destroy(`${httpx.urlstorage.host()}${media_path}/${media_id}`, {}, ...options)
}

export function create(req: CreateRequest, ...options: httpx.option[]): Promise<CreateResponse> {
	return httpx.post(`${httpx.urlstorage.host()}/brands`, req, ...options)
}
