import { useEffect, useState } from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as brandapi from "brands/api"
import { Brand, SearchRequest } from "brands/account.brand"
import * as sessions from "sessions"
import { useNavigate } from "react-router-dom"
import * as caching from "./cache"
import * as api from "./api"

export const Select = styled.select`
	width: 30%;
	height: 48px;
	background: ${layouts.theme.colors.white};
	color: ${layouts.theme.colors.grey.dark20};
	box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
	border-radius: 30px;
	border: none;
	padding: 10px;
	&:hover {
		box-shadow: none;
		border: none;
	}
	&:focus {
		outline: 0;
	}
`

export interface props {
	brands?: Brand[]
	urlgen?(bid: string): string
}

export function zero(c: Partial<props> = {}): props {
	return {
		brands: [],
		...c,
	}
}

export function BrandSelector(props: props): JSX.Element {
	const { brands, urlgen } = { ...props }
	const navigate = useNavigate()
	const bearertoken = sessions.useToken()
	const [brand, setBrand] = useState(caching.useCached())
	const [rbrands, setBrands] = useState(zero({ brands: brands }))
	const breq = { offset: "00000000-0000-0000-0000-000000000000", query: "" } as SearchRequest

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (brands!.length > 0) return setBrands(zero({ brands: brands }))

		setLoading(true)
		brandapi
			.search(breq, bearertoken)
			.then((resp) => {
				if (resp.items.length > 0) {
					setBrands(zero({ brands: resp.items }))
				}
			})
			.finally(() => setLoading(false))
	}, [brands])

	return (
		<layouts.loading.pending loading={loading}>
			<Select
				value={brand.id}
				onChange={(evt) => {
					const newBrand = rbrands.brands!.find((e) => e.id === evt.target.value)
					api.setLastBrandId(newBrand!.account_id, newBrand!.id)
					caching.cache.set(newBrand!.id, newBrand!)
					setBrand(newBrand!)
					urlgen && navigate(urlgen(newBrand!.id))
				}}
			>
				{rbrands.brands!.map((b) => (
					<option key={b.id} value={b.id}>
						{b.description}
					</option>
				))}
			</Select>
		</layouts.loading.pending>
	)
}

BrandSelector.defaultProps = {
	brands: [],
	urlgen: (bid: string) => "",
}
