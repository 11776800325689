import React from "react"
import * as layouts from "layouts"
import * as debugx from "x/debugx"
import * as api from "brandguard/api"
import * as bg_layouts from "./layouts"

interface itemProps {
	item: api.ImageSearchResponseItem
}

export default function FileDetails(props: itemProps & React.PropsWithChildren<unknown>): JSX.Element {
	const { item } = props

	return (
		<layouts.containers.flex flexDirection="column">
			<layouts.accordions.Container initOpen minHeight="25px" borderRadius="10px">
				<layouts.containers.box tabIndex={0} className="accordion-containers-box">
					<layouts.accordions.Description borderBottom={`2px solid ${layouts.theme.colors.grey.light30}`}>
						<bg_layouts.fileDetails.AccordionHeader active={true}>File Details</bg_layouts.fileDetails.AccordionHeader>
					</layouts.accordions.Description>
					{item && (
						<layouts.accordions.Content>
							<layouts.containers.flex flexDirection="column" width="100%">
								<bg_layouts.fileDetails.InformationGroup>
									<bg_layouts.fileDetails.InformationGroupTitle>
										File Scores
									</bg_layouts.fileDetails.InformationGroupTitle>
									<layouts.containers.grid gridTemplateColumns="1fr 1fr">
										{item.event?.confidence && (
											<bg_layouts.fileDetails.ScoreBox originalScore={item.event?.confidence} title="Confidence" />
										)}
										{item.event?.brand_voice && (
											<bg_layouts.fileDetails.ScoreBox originalScore={item.event?.brand_voice} title="Brand Voice" />
										)}
										{item.event?.brand_uniqueness && (
											<bg_layouts.fileDetails.ScoreBox
												originalScore={item.event?.brand_uniqueness}
												title="Brand Uniqueness"
											/>
										)}
										{item.event?.sexually_explicit && (
											<bg_layouts.fileDetails.ScoreBox
												originalScore={item.event?.sexually_explicit}
												title="Sexually Explicit"
											/>
										)}
									</layouts.containers.grid>
								</bg_layouts.fileDetails.InformationGroup>
								{debugx.alpha.enabled() && (
									<bg_layouts.fileDetails.InformationGroup>
										<bg_layouts.fileDetails.InformationGroupTitle>
											BrandGuard Tone Map
										</bg_layouts.fileDetails.InformationGroupTitle>
										<layouts.containers.box background={layouts.theme.colors.grey.light20} minHeight="160px">
											Image Placeholder
										</layouts.containers.box>
									</bg_layouts.fileDetails.InformationGroup>
								)}
								{debugx.alpha.enabled() && (
									<bg_layouts.fileDetails.InformationGroup>
										<bg_layouts.fileDetails.InformationGroupTitle>
											BrandGuard Evaluation
										</bg_layouts.fileDetails.InformationGroupTitle>
										<layouts.containers.flex fontWeight="600">May 9, 2023</layouts.containers.flex>
										<layouts.containers.flex>Rejected by James</layouts.containers.flex>
									</bg_layouts.fileDetails.InformationGroup>
								)}
							</layouts.containers.flex>
						</layouts.accordions.Content>
					)}
				</layouts.containers.box>
			</layouts.accordions.Container>
		</layouts.containers.flex>
	)
}
