import React from "react"
import estyled from "@emotion/styled"
import * as system from "styled-system"
import * as theme from "./theme"

export interface ButtonProps
	extends React.DOMAttributes<HTMLButtonElement>,
		system.LayoutProps,
		system.TypographyProps,
		system.SpaceProps,
		system.PositionProps,
		system.WidthProps,
		system.BorderProps,
		system.BackgroundProps,
		system.BoxShadowProps {
	className?: string
	disabled?: boolean
	cursor?: string
}

export const unstyled = estyled.button<ButtonProps>`
	border: none;
	cursor: ${(props) => (props.cursor ? props.cursor : "inherit")};
	${system.border}
	${system.background}
	${system.boxShadow}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`

export const styled = estyled.button<ButtonProps>`
	font-weight: bold;
	text-align: center;
	padding: 0.75em;
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
	color: ${(props) => (props.disabled ? theme.buttons.colors.disabled : theme.buttons.colors.enabled)};
	border: none;
	a {
		color: unset;
		text-decoration: none;
	}
	${system.borderRadius}
	${system.background}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`

export const outline = estyled(styled)`
	background-color: ${theme.colors.white};
	border: 1px solid ${(props) => (props.disabled ? theme.buttons.colors.disabled : theme.buttons.colors.enabled)};
	${system.background}
	&:hover{
		background-color: ${theme.colors.blue};
		color: ${theme.colors.white};
	}
`

export const primary = estyled(styled)`
	background-color: ${(props) => (props.disabled ? theme.colors.grey.lightest : theme.colors.blue)};
	color: ${(props) => (props.disabled ? theme.colors.grey.light30 : theme.colors.white)};
	${system.background}
`

export const danger = estyled(styled)`
	background-color: ${theme.colors.danger};
	color: ${theme.colors.white};
	${system.background}
`
