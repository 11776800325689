/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandguard.proto" (package "brandguard", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Event
 */
export interface Event {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: brandguard.Prediction prediction = 4;
     */
    prediction: Prediction;
    /**
     * @generated from protobuf field: brandguard.Prediction observation = 5;
     */
    observation: Prediction;
    /**
     * @generated from protobuf field: double brandguard = 1000;
     */
    brandguard: number;
    /**
     * @generated from protobuf field: double profanity = 1001;
     */
    profanity: number;
    /**
     * @generated from protobuf field: double racism = 1002;
     */
    racism: number;
    /**
     * @generated from protobuf field: double grammar = 1003;
     */
    grammar: number;
    /**
     * @generated from protobuf field: double onbrand = 1004;
     */
    onbrand: number;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.SampleRequest
 */
export interface SampleRequest {
    /**
     * @generated from protobuf field: repeated brandguard.Event samples = 1;
     */
    samples: Event[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.SampleResponse
 */
export interface SampleResponse {
}
/**
 * @generated from protobuf enum brandguard.Prediction
 */
export enum Prediction {
    /**
     * @generated from protobuf enum value: NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: REJECTED = 1;
     */
    REJECTED = 1,
    /**
     * @generated from protobuf enum value: APPROVED = 2;
     */
    APPROVED = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Event$Type extends MessageType<Event> {
    constructor() {
        super("brandguard.Event", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "prediction", kind: "enum", T: () => ["brandguard.Prediction", Prediction] },
            { no: 5, name: "observation", kind: "enum", T: () => ["brandguard.Prediction", Prediction] },
            { no: 1000, name: "brandguard", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1001, name: "profanity", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1002, name: "racism", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1003, name: "grammar", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1004, name: "onbrand", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<Event>): Event {
        const message = { id: "", account_id: "", brand_id: "", prediction: 0, observation: 0, brandguard: 0, profanity: 0, racism: 0, grammar: 0, onbrand: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Event>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Event): Event {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* brandguard.Prediction prediction */ 4:
                    message.prediction = reader.int32();
                    break;
                case /* brandguard.Prediction observation */ 5:
                    message.observation = reader.int32();
                    break;
                case /* double brandguard */ 1000:
                    message.brandguard = reader.double();
                    break;
                case /* double profanity */ 1001:
                    message.profanity = reader.double();
                    break;
                case /* double racism */ 1002:
                    message.racism = reader.double();
                    break;
                case /* double grammar */ 1003:
                    message.grammar = reader.double();
                    break;
                case /* double onbrand */ 1004:
                    message.onbrand = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Event, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* brandguard.Prediction prediction = 4; */
        if (message.prediction !== 0)
            writer.tag(4, WireType.Varint).int32(message.prediction);
        /* brandguard.Prediction observation = 5; */
        if (message.observation !== 0)
            writer.tag(5, WireType.Varint).int32(message.observation);
        /* double brandguard = 1000; */
        if (message.brandguard !== 0)
            writer.tag(1000, WireType.Bit64).double(message.brandguard);
        /* double profanity = 1001; */
        if (message.profanity !== 0)
            writer.tag(1001, WireType.Bit64).double(message.profanity);
        /* double racism = 1002; */
        if (message.racism !== 0)
            writer.tag(1002, WireType.Bit64).double(message.racism);
        /* double grammar = 1003; */
        if (message.grammar !== 0)
            writer.tag(1003, WireType.Bit64).double(message.grammar);
        /* double onbrand = 1004; */
        if (message.onbrand !== 0)
            writer.tag(1004, WireType.Bit64).double(message.onbrand);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Event
 */
export const Event = new Event$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SampleRequest$Type extends MessageType<SampleRequest> {
    constructor() {
        super("brandguard.SampleRequest", [
            { no: 1, name: "samples", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value?: PartialMessage<SampleRequest>): SampleRequest {
        const message = { samples: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SampleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SampleRequest): SampleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.Event samples */ 1:
                    message.samples.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SampleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.Event samples = 1; */
        for (let i = 0; i < message.samples.length; i++)
            Event.internalBinaryWrite(message.samples[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.SampleRequest
 */
export const SampleRequest = new SampleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SampleResponse$Type extends MessageType<SampleResponse> {
    constructor() {
        super("brandguard.SampleResponse", []);
    }
    create(value?: PartialMessage<SampleResponse>): SampleResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SampleResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SampleResponse): SampleResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SampleResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.SampleResponse
 */
export const SampleResponse = new SampleResponse$Type();
