import React from "react"
import * as layouts from "layouts"
import * as api from "./api"

interface props
	extends layouts.containers.SpanProps,
		React.DOMAttributes<HTMLSpanElement>,
		React.HTMLAttributes<HTMLSpanElement> {
	current?: api.Credential
}

export default function Textual(props: React.PropsWithChildren<props>): JSX.Element {
	const { current, ...rest } = props

	if (current === undefined) return <></>
	return <layouts.containers.span {...rest}>{current.description}</layouts.containers.span>
}
