import React, { useState, useEffect } from "react"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as sessions from "sessions"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as icons from "icons"
import * as api from "./api"
import Textual from "./client.account.textual"

interface props {
	current?: api.ClientAccount
	onCreate?(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
	onChange(upd: undefined | api.ClientAccount): void
	search(req: api.ClientAccountSearchRequest, ...options: httpx.option[]): Promise<api.ClientAccountSearchResponse>
}

export function Search(props: props & layouts.containers.FlexProps): JSX.Element {
	const { search, current, onChange, onCreate, ...rest } = props
	const [display, setDisplay] = useState(false)
	const [initialLoad, setInitialLoad] = useState(true)
	const [loading, setLoading] = useState(true)
	const bearertoken = sessions.useToken()
	const [searchreq, setSearchReq] = useState({
		offset: uuid.NIL,
		query: "",
	} as api.ClientAccountSearchRequest)
	const [found, setFound] = useState({
		items: [],
	} as api.ClientAccountSearchResponse)

	useEffect(() => {
		setLoading(true)
		search(searchreq, bearertoken)
			.then((resp) => {
				setFound(resp)
				if (initialLoad && resp.items.length <= 1) {
					onChange(resp.items.find(() => true))
				}
			})
			.catch(console.error)
			.finally(() => {
				setInitialLoad(false)
				setLoading(false)
			})
	}, [searchreq])

	const prompt = current ? (
		<layouts.containers.flex m="auto">
			<Textual mr="7px" current={current} />
			<layouts.containers.span px="5px" height="20px" onClick={() => onChange(undefined)}>
				<layouts.containers.span ml="auto" width="14px" height="14px">
					<icons.Trash />
				</layouts.containers.span>
			</layouts.containers.span>
		</layouts.containers.flex>
	) : (
		<layouts.containers.flex width="100%">
			<inputs.Text
				placeholder="search google ads accounts"
				defaultValue={searchreq.query}
				onFocus={(evt) => setDisplay(true)}
				onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
					setSearchReq({ ...searchreq, query: evt.currentTarget.value })
				}
			/>
			<layouts.buttons.unstyled
				mx="5px"
				width="164px"
				borderRadius="0.25em"
				padding="0px"
				cursor="pointer"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.hinting.darkest}
				color={layouts.theme.colors.black.medium}
				onBlur={(evt) => _displayed && evt.stopPropagation()}
				onClick={(evt) => {
					onCreate && onCreate(evt)
					setDisplay(false)
				}}
			>
				Create
			</layouts.buttons.unstyled>
		</layouts.containers.flex>
	)

	const _displayed = current === undefined && found.items.length > 0

	return (
		<layouts.containers.flex {...rest} flexDirection="column">
			{prompt}
			{_displayed && display && (
				<layouts.loading.pending loading={loading} icon={undefined}>
					<layouts.containers.flex
						position="relative"
						onBlur={(evt) => {
							evt.stopPropagation()
							setDisplay(false)
						}}
					>
						<layouts.containers.flex className="dropdown" position="absolute" mt="5px" width="100%">
							<layouts.containers.flex
								styled
								p="5px"
								flex="1"
								borderRadius={layouts.theme.borders.radius.standard}
								flexDirection="column"
							>
								{found.items.map((c, idx) => (
									<Textual
										tabIndex={idx}
										key={c.id}
										current={c}
										onKeyUp={(evt) => {
											if (evt.key !== "Enter") return
											onChange(c)
										}}
										onClick={(evt) => {
											evt.stopPropagation()
											onChange(c)
										}}
										display="block"
										p="5px"
										borderRadius={layouts.theme.borders.radius.standard}
										flex="1"
									/>
								))}
							</layouts.containers.flex>
						</layouts.containers.flex>
					</layouts.containers.flex>
				</layouts.loading.pending>
			)}
		</layouts.containers.flex>
	)
}

Search.defaultProps = {
	search: api.accounts.search,
}

export default Search
