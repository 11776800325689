import React from "react"
import * as layouts from "layouts"
import * as icons from "icons"
import * as inputs from "inputs"
import classnames from "classnames"
import * as adgen from "ads/adgen"
import * as textgen from "ads/textgen"
import * as context from "./adgen.responsive.search.context"
import { HeadLines } from "./adgen.responsive.search.headline"
import { Descriptions } from "./adgen.responsive.search.description"
import { FormContainer, AccordionHeader, inlined, EditField } from "ads/adgen/adgen.content.layouts"

const PATH_MAX_SYMBOLS = 15

interface props {
	genctx: context.context
	onChange(...mut: context.mutation[]): void
}

export default function AdgenForm(props: props): JSX.Element {
	const adgenConstants = context.adgenconstants()
	const { genctx, onChange } = props

	const onGeneratedChange = (updgenerated: JSX.Element) => onChange(context.mutate.generated(updgenerated))

	const canAddHeadlineManually = (): boolean => genctx.content.headlines.length < adgenConstants.maxHeadlinesCount
	const canAddDescriptionsManually = (): boolean =>
		genctx.content.descriptions.length < adgenConstants.maxDescriptionsCount

	const validationMessage = (count: number, min_count: number) => {
		if (count >= min_count) return null

		return (
			<layouts.containers.box fontSize="10px">
				Add {min_count - count} or more to maximize performance
			</layouts.containers.box>
		)
	}
	const validateHeadlines = () => validationMessage(genctx.content.headlines.length, adgenConstants.minHeadlinesCount)
	const validateDescriptions = () =>
		validationMessage(genctx.content.descriptions.length, adgenConstants.minDescriptionCount)

	function onChangeHeadline(c: string, index: number): void {
		const headlines = [...genctx.content.headlines]
		headlines[index] = { ...headlines[index], accepted: c }

		onChange(context.mutate.content({ headlines: headlines }))
	}

	function onRemoveHeadline(sample: textgen.Sample, index: number): void {
		onChange(
			context.mutate.content({ headlines: genctx.content.headlines.filter((c, idx) => idx !== index) }),
			context.mutate.rejected({ ...sample, feedback: textgen.Feedback.IGNORED }),
		)
	}

	function onChangeDescription(c: string, index: number): void {
		const descriptions = [...genctx.content.descriptions]
		descriptions[index] = { ...descriptions[index], accepted: c }

		onChange(context.mutate.content({ descriptions: descriptions }))
	}

	function onRemoveDescription(sample: textgen.Sample, index: number): void {
		onChange(
			context.mutate.content({ descriptions: genctx.content.descriptions.filter((c, idx) => idx !== index) }),
			context.mutate.rejected({ ...sample, feedback: textgen.Feedback.IGNORED }),
		)
	}

	function addURL(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key !== "Enter") return
		let tmp = [...genctx.content.urls]

		tmp.push(e.currentTarget.value)
		onChange(context.mutate.form.url(""), context.mutate.content({ urls: tmp }))
	}

	function removeURL(i: number) {
		let tmp = [...(genctx.content.urls || [])]
		tmp.splice(i, 1)
		onChange(context.mutate.content({ urls: tmp }))
	}

	return (
		<>
			<layouts.accordions.Container mb="16px" minHeight="25px" borderBottom={layouts.theme.borders.grey.light}>
				<layouts.containers.box
					tabIndex={0}
					onFocus={(evt) =>
						onGeneratedChange(
							<HeadLines key="headlines" onChange={onChange} adgenConstants={adgenConstants}></HeadLines>,
						)
					}
				>
					<layouts.accordions.Description fontSize="30px" textAlign="right">
						<AccordionHeader active={genctx.generated.key === "headlines"}>
							Headlines {genctx.content.headlines.length}/{adgenConstants.maxHeadlinesCount}
						</AccordionHeader>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.containers.flex className="accordion-content" flexDirection="column" width="100%">
							{genctx.content.headlines.map((headline, idx) => {
								return (
									<layouts.containers.flex key={idx} fontSize="10px" fontWeight="400">
										<EditField
											required={idx <= adgenConstants.minHeadlinesCount - 1}
											content={headline.accepted}
											maxLength={adgenConstants.maxHeadlineLength}
											index={idx}
											onChange={(val) => onChangeHeadline(val, idx)}
											key={headline.id}
										/>
										<layouts.containers.box
											mt="32px"
											onClick={(e) => {
												onRemoveHeadline(headline, idx)
											}}
										>
											<icons.checkboxes.dismiss fill={layouts.theme.colors.grey.dark10}></icons.checkboxes.dismiss>
										</layouts.containers.box>
									</layouts.containers.flex>
								)
							})}
							{canAddHeadlineManually() && (
								<layouts.containers.flex
									onClick={() => {
										const upd = [...genctx.content.headlines, adgen.text.empty()]
										onChange(context.mutate.content({ headlines: upd }))
									}}
								>
									<icons.Add></icons.Add>
								</layouts.containers.flex>
							)}
							{validateHeadlines()}
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</layouts.containers.box>
			</layouts.accordions.Container>
			<layouts.accordions.Container mb="16px" minHeight="25px" borderBottom={layouts.theme.borders.grey.light}>
				<layouts.containers.box
					tabIndex={1}
					onFocus={(evt) =>
						onGeneratedChange(
							<Descriptions key="descriptions" onChange={onChange} adgenConstants={adgenConstants}></Descriptions>,
						)
					}
				>
					<layouts.accordions.Description>
						<AccordionHeader active={genctx.generated.key === "descriptions"}>
							Descriptions {genctx.content.descriptions.length}/{adgenConstants.maxDescriptionsCount}
						</AccordionHeader>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.containers.flex className="accordion-content" flexDirection="column" width="100%">
							{genctx.content.descriptions.map((description, idx) => {
								return (
									<layouts.containers.flex key={idx} fontSize="10px" fontWeight="400">
										<EditField
											required={idx <= adgenConstants.minDescriptionCount - 1}
											content={description.accepted}
											maxLength={adgenConstants.maxDescriptionLength}
											index={idx}
											onChange={(val) => onChangeDescription(val, idx)}
											key={description.id}
										/>
										<layouts.containers.box mt="32px" onClick={(e) => onRemoveDescription(description, idx)}>
											<icons.checkboxes.dismiss fill={layouts.theme.colors.grey.dark10}></icons.checkboxes.dismiss>
										</layouts.containers.box>
									</layouts.containers.flex>
								)
							})}
							{canAddDescriptionsManually() && (
								<layouts.containers.flex
									onClick={() => {
										const upd = [...genctx.content.descriptions, adgen.text.empty()]
										onChange(context.mutate.content({ descriptions: upd }))
									}}
								>
									<icons.Add />
								</layouts.containers.flex>
							)}
							{validateDescriptions()}
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</layouts.containers.box>
			</layouts.accordions.Container>
			<FormContainer className="fields">
				<layouts.containers.flex flexDirection="column" mt="12px">
					<inputs.Text
						placeholder="Name"
						defaultValue={genctx.metadata.description}
						className={classnames(inlined)}
						onFocus={(evt) => onGeneratedChange(<></>)}
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
							onChange(context.mutate.metadata({ description: evt.currentTarget.value }))
						}
					/>
				</layouts.containers.flex>
				<layouts.containers.flex flexDirection="column" mt="12px">
					<inputs.Text
						placeholder={genctx.content.urls.length > 0 ? "" : "Destination URL (https://example.com/) ⏎"}
						defaultValue={genctx.currenturl}
						className={classnames(inlined)}
						onFocus={(evt) => onGeneratedChange(<></>)}
						onChange={(evt) => onChange(context.mutate.form.url(evt.currentTarget.value))}
						onKeyPress={addURL}
					/>
					<layouts.containers.flex flexWrap="wrap" overflowY="auto" overflowX="hidden">
						{genctx.content.urls.map((u, idx) => (
							<layouts.pills.Primary key={idx} mr="8px" mt="10px" remove={() => removeURL(idx)}>
								{u}
							</layouts.pills.Primary>
						))}
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex flexDirection="column" mt="12px">
					<inputs.Text
						defaultValue={genctx.content.path1}
						className={classnames(inlined)}
						placeholder="Path"
						maxLength={PATH_MAX_SYMBOLS}
						onFocus={(evt) => onGeneratedChange(<></>)}
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
							onChange(context.mutate.content({ path1: evt.currentTarget.value }))
						}
					/>
					<layouts.containers.span mb="10px" />
					<inputs.Text
						defaultValue={genctx.content.path2}
						className={classnames(inlined)}
						placeholder="Path2"
						maxLength={PATH_MAX_SYMBOLS}
						onFocus={(evt) => onGeneratedChange(<></>)}
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
							onChange(context.mutate.content({ path2: evt.currentTarget.value }))
						}
					/>
				</layouts.containers.flex>
			</FormContainer>
		</>
	)
}
