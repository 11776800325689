import React, { useState, useEffect } from "react"
import { useLocation, Link } from "react-router-dom"
import classnames from "classnames"
import styled from "@emotion/styled"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as brands from "brands"
import * as api from "./api"
import * as errors from "errors"
import * as layouts from "layouts"
import * as icons from "icons"
import * as sessions from "sessions"
import * as debugx from "x/debugx"
import * as cache from "./cache"
import * as routing from "./routing"
import * as suggestions from "ads/suggestions"
import * as studio from "ads/studio"
import * as Create from "./create"
import * as Edit from "./edit"

export const StyledLink = layouts.css`
	color: ${layouts.theme.colors.blue};
	font-size: 14px;
	font-weight: 700;
	line-height: 30px;
	margin-right: 14px;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`

interface props {
	search(req: api.SearchRequest, ...options: httpx.option[]): Promise<api.SearchResponse>
}

export const inlined = layouts.css`
	background: ${layouts.theme.colors.grey.light10};
	&:hover {
		background: ${layouts.theme.colors.grey.light10} !important;
	}
`

const TableData = styled.td<{ textAlign?: string; active?: boolean }>`
	vertical-align: middle;
	text-align: ${(props) => props.textAlign};
	background: ${(props) => (props.active ? layouts.theme.colors.grey.light10 : "unset")};
	border-top-left-radius: ${(props) => (props.active ? "20px" : "unset")};
	border-top-right-radius: ${(props) => (props.active ? "20px" : "unset")};
`

const TableRow = styled(layouts.tables.Row)`
	&:hover {
		color: unset;
		background: ${layouts.theme.colors.grey.light10};
	}
`

export function Table(props: props): JSX.Element {
	const brand = brands.caching.useCached()
	const location = useLocation()
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)
	const [loading, setLoading] = useState(true)
	const [focused, setFocused] = useState(undefined as api.Pool | undefined)
	const bearertoken = sessions.useToken()

	const [poolreq, setPoolRequest] = useState({
		brand_id: brand.id,
		offset: uuid.NIL,
		limit: 10,
	} as api.SearchRequest)

	const [results, setResults] = useState({
		cursor: poolreq,
		items: [],
	} as api.SearchResponse)

	const [newpool, setNewpool] = useState(api.pools.zero({ brand_id: brand.id }))

	const modalToggle = layouts.modals.useToggle()

	useEffect(() => {
		setLoading(true)
		props
			.search(poolreq, bearertoken)
			.then((resp) => {
				setResults({ ...results, ...resp })
				setCause(undefined)
			})
			.catch(
				httpx.errors.forbidden((cause) => {
					console.warn("insufficient priviledges unable to view ad pools", cause)
					setCause(
						<layouts.containers.box styled m="auto">
							<errors.Textual>you do not have permission to view ad pools</errors.Textual>
						</layouts.containers.box>,
					)
				}),
			)
			.catch((c: unknown) => {
				console.error("unable to retrieve ad pools", c)
				setCause(<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve ad pools</errors.Textual>)
			})
			.finally(() => setLoading(false))
	}, [poolreq, newpool])

	return (
		<layouts.containers.flex className="ad-pools-display" py="50px" width="100%" flexDirection="column">
			<layouts.containers.flex textAlign="left" pl="50px" pb="5px">
				<layouts.containers.flex
					onClick={(evt) => {
						modalToggle(
							<Create.Modal
								pool={newpool}
								onChange={(newpool): Promise<api.Pool> => {
									return api.pools
										.create({ pool: newpool }, bearertoken)
										.then((resp) => {
											const p = api.pools.zero(resp.pool)
											setNewpool(p)
											return cache.cache.set(p.id, p)
										})
										.catch(
											httpx.errors.forbidden((cause) => {
												setCause(
													<layouts.containers.box styled m="auto">
														<errors.Textual logging={console.warn} cause={cause}>
															you do not have permission to create pools
														</errors.Textual>
													</layouts.containers.box>,
												)
											}),
										)
										.catch((c: unknown) => {
											console.error("unable to create pool", c)
											setCause(
												<errors.Textual onClick={() => setCause(undefined)}>unable to create pool</errors.Textual>,
											)
										})
										.finally(() => {
											setLoading(false)
										}) as Promise<api.Pool>
								}}
							/>,
						)
					}}
				>
					<icons.New></icons.New>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex className="pools" height="100%" flexDirection="column" overflow="auto" p="50px">
				<layouts.tables.container py="10px" flex="1">
					<layouts.loading.pending loading={loading}>
						<errors.overlay styled cause={cause}>
							<table>
								<thead>
									<tr>
										<th>Description</th>
										<th></th>
										<th>Budget</th>
										<th>Daily Target</th>
										<th>Created At</th>
										<th>Updated At</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{results.items.map((c) => (
										<React.Fragment key={c.id}>
											<TableRow>
												<TableData>
													<Link to={routing.display(location, c.id)}>
														<span onClick={() => cache.cache.set(c.id, c)}>{c.description || c.id}</span>
													</Link>
												</TableData>
												<TableData active={c.id === focused?.id} textAlign="center">
													<layouts.buttons.primary
														borderRadius="19px"
														onClick={() => (c.id === focused?.id ? setFocused(undefined) : setFocused(c))}
													>
														View
													</layouts.buttons.primary>
												</TableData>
												<TableData>$ {api.budgets.human(c.budget)}</TableData>
												<TableData>{c.daily_events_target.toString(10)}</TableData>
												<TableData>{layouts.tables.Timestamp(c.created_at)}</TableData>
												<TableData>{layouts.tables.Timestamp(c.updated_at)}</TableData>
												<TableData textAlign="center">
													<layouts.containers.box
														onClick={(evt) => {
															modalToggle(
																<layouts.containers.flex className="ad-platform" styled width="512px" height="256px">
																	<layouts.containers.flex
																		m="auto"
																		flexDirection="column"
																		onClick={(evt) => {
																			modalToggle(undefined)
																			evt.stopPropagation()
																		}}
																	>
																		<Link to={`${routing.display(location, c.id)}/google/create`}>
																			<layouts.buttons.primary width="100%">Google</layouts.buttons.primary>
																		</Link>
																		<Link to={`${routing.display(location, c.id)}/facebook/create`}>
																			<layouts.buttons.primary mt="10px" width="100%">
																				Facebook
																			</layouts.buttons.primary>
																		</Link>
																	</layouts.containers.flex>
																</layouts.containers.flex>,
															)
														}}
													>
														<icons.New />
													</layouts.containers.box>
												</TableData>
											</TableRow>
											{c.id === focused?.id && (
												<layouts.tables.inline className={classnames(inlined)}>
													<layouts.containers.flex flexDirection="row" width="100%" justifyContent="space-between">
														<layouts.containers.flex width="70%" justifyContent="start">
															<Link className={StyledLink} to={routing.ads.metrics(c.brand_id, c.id)}>
																Metrics
															</Link>
															<Link className={StyledLink} to={suggestions.routing.google(c.brand_id, c.id)}>
																Suggested Ads
															</Link>
															{debugx.alpha.enabled() && (
																<>
																	<Link className={StyledLink} to={routing.ads.google.responsive(c.brand_id, c.id)}>
																		Google Ads
																	</Link>
																	<Link className={StyledLink} to={routing.ads.facebook(c.brand_id, c.id)}>
																		Facebook Ads
																	</Link>
																	<Link className={StyledLink} to={studio.routing.creative.google(c.brand_id, c.id)}>
																		Studio
																	</Link>
																</>
															)}
														</layouts.containers.flex>
														<layouts.containers.flex width="30%" justifyContent="end">
															<Link
																className={StyledLink}
																to="#"
																onClick={(evt) => {
																	modalToggle(
																		<Edit.Modal
																			pool={c}
																			onChange={(focused): Promise<api.Pool> => {
																				return api.pools
																					.update(c.id, { pool: focused }, bearertoken)
																					.then((r) => {
																						setResults({
																							...results,
																							items: results.items.map((el) => (el.id === r.pool?.id ? r.pool : el)),
																						})
																						cache.cache.set(r.pool!.id, r.pool)
																						return r
																					})
																					.catch(
																						httpx.errors.forbidden((cause) => {
																							setCause(
																								<layouts.containers.box styled m="auto">
																									<errors.Textual logging={console.warn} cause={cause}>
																										you do not have permission to update pool
																									</errors.Textual>
																								</layouts.containers.box>,
																							)
																						}),
																					)
																					.catch((c: unknown) => {
																						console.error("unable to update pool", c)
																						setCause(
																							<errors.Textual onClick={() => setCause(undefined)}>
																								unable to update pool
																							</errors.Textual>,
																						)
																					})
																					.finally(() => {
																						setLoading(false)
																					}) as Promise<api.Pool>
																			}}
																		></Edit.Modal>,
																	)
																}}
															>
																Edit
															</Link>
														</layouts.containers.flex>
													</layouts.containers.flex>
												</layouts.tables.inline>
											)}
										</React.Fragment>
									))}
								</tbody>
							</table>
						</errors.overlay>
					</layouts.loading.pending>
				</layouts.tables.container>
				<layouts.pagination.Cursor
					ml="auto"
					current={poolreq.offset}
					advance={results.cursor!.offset === uuid.NIL ? undefined : results.cursor!.offset}
					onChange={(next) => next && setPoolRequest({ ...poolreq, offset: next })}
				/>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

Table.defaultProps = {
	search: api.pools.search,
}
