import { SVG, SVGProps } from "./svg"
import classnames from "classnames"
import * as theme from "layouts/theme"

interface props extends SVGProps {
	checked?: boolean
	disabled?: boolean
}

interface unstyledProps extends SVGProps {
	className?: string
}

const Icon = (props: props) => {
	const { fill, stroke, checked, disabled, className, ...rest } = props

	let color = stroke ? stroke : checked || disabled ? theme.colors.white : theme.colors.grey.medium
	let bg = fill
		? fill
		: checked && disabled
		? theme.colors.grey.medium
		: disabled
		? theme.colors.grey.medium
		: checked
		? theme.colors.blue
		: "none"

	return (
		<SVG
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill={bg}
			xmlns="http://www.w3.org/2000/svg"
			className={classnames("icon.xbox", className)}
			{...rest}
		>
			<path
				d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M9 9L15 15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M15 9L9 15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</SVG>
	)
}

export const unstyled = (props: unstyledProps) => {
	let { className } = props
	return (
		<SVG className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" />
			<path d="M9 9L15 15" />
			<path d="M15 9L9 15" />
		</SVG>
	)
}

export default Icon
