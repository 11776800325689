import * as uuidx from "x/uuidx"
import * as layouts from "layouts"
import * as context from "./context"
import * as discountopt from "ecommerce/discountopt/runtime/discountopt"

interface props {
	onChange(d: context.Display): void
}

export function NewDiscount(props: props): JSX.Element {
	return (
		<layouts.containers.grid className="discount-creation" gridGap="10px">
			<layouts.containers.box
				styled
				m="auto"
				onClick={() => {
					const discount = discountopt.discount(uuidx.v4(), discountopt.codes.none())
					props.onChange({
						type: "convertflow",
						properties: discountopt.convertflow.zero(discount),
					})
				}}
			>
				Convertflow
			</layouts.containers.box>
		</layouts.containers.grid>
	)
}

export function Display(props: { current: context.Display } & layouts.containers.ContainerProps): JSX.Element {
	const { current, ...rest } = props

	return (
		<layouts.containers.span {...rest}>{current.properties.discount.code.digest || "(none)"}</layouts.containers.span>
	)
}
