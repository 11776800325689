import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as sessions from "sessions"
import * as pools from "ads/pools"
import * as metasearch from "metasearch"
import * as brands from "brands"
import * as charts from "charts"
import * as api from "./api"
import * as httpx from "httpx"
import * as errors from "errors"
import { default as Chart } from "./chart"
import { card as Card } from "./metric.card"
import { SearchRequest, SearchResponse } from "./ads.metrics"
import * as icons from "icons"

export interface chartData {
	[date: string]: number
}

export interface chart {
	axisID: string
	position: charts.CartesianPosition
	borderColor: string
	backgroundColor: string
	labels: string[]
	data: chartData
}

export interface metric {
	title: string
	val: number
	prevVal: number
	chart: chart
}

export interface parsed {
	impressions: metric
	conversions: metric
}

export function parsedChartZero(proto: Partial<chart> = {}): chart {
	return {
		axisID: "",
		position: "left",
		borderColor: "",
		backgroundColor: "",
		labels: [],
		data: {},
		...proto,
	}
}

export function parsedMetricZero(proto: Partial<metric> = {}): metric {
	return {
		title: "",
		val: 0,
		prevVal: 0,
		chart: parsedChartZero(),
		...proto,
	}
}

export function parsedInitial(proto: Partial<parsed> = {}): parsed {
	// the titles, colors are identified with metrics, so we past to initial
	return {
		impressions: parsedMetricZero({
			title: "Impressions",
			chart: parsedChartZero({
				axisID: "y1",
				position: "right",
				borderColor: layouts.theme.colors.charts.blue.base,
				backgroundColor: layouts.theme.colors.charts.blue.alpha,
			}),
		}),
		conversions: parsedMetricZero({
			title: "Conversions",
			chart: parsedChartZero({
				axisID: "y2",
				position: "left",
				borderColor: layouts.theme.colors.charts.green.base,
				backgroundColor: layouts.theme.colors.charts.green.alpha,
			}),
		}),
		...proto,
	}
}

export const getDaysArray = function (startDate: Date, endDate: Date) {
	for (var result = [], d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
		result.push(new Date(d))
	}
	return result
}

export function parse(r: SearchResponse, dr: metasearch.DateRange): parsed {
	const data = parsedInitial()

	getDaysArray(new Date(dr.newest), new Date(dr.oldest)).forEach((i) => {
		const day = i.toISOString().slice(0, 10)
		data.impressions.chart.labels.push(day)
		Object.assign(data.impressions.chart.data, { [day]: 0 })
		data.conversions.chart.labels.push(day)
		Object.assign(data.conversions.chart.data, { [day]: 0 })
	})
	;(r.items || []).forEach((e) => {
		const day = new Date(e.range?.oldest).toISOString().slice(0, 10)
		data.impressions.val += Number(BigInt.asIntN(32, BigInt(e.impressions || 0)))
		data.impressions.chart.data[day] += Number(BigInt.asIntN(32, BigInt(e.impressions || 0)))

		data.conversions.val += Number(BigInt.asIntN(32, BigInt(e.conversions || 0)))
		data.conversions.chart.data[day] += Number(BigInt.asIntN(32, BigInt(e.conversions || 0)))
	})

	return data
}

export default function Display(props: unknown): JSX.Element {
	const pool = pools.caching.useCached()
	const brand = brands.caching.useCached()
	const bearertoken = sessions.useToken()

	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	const [req] = useState(
		api.SearchZero({
			brand_id: brand.id,
			pool_id: pool.id,
			range: metasearch.dateranges.days(30),
		}) as SearchRequest,
	)

	const [results, setResults] = useState(parsedInitial({}))

	useEffect(() => {
		setLoading(true)
		api
			.search(req, bearertoken)
			.then((resp) => {
				// setSearchRequest({ ...req, ...resp.cursor })
				setResults(parse(resp, req.range))
				setCause(undefined)
			})
			.catch(
				httpx.errors.forbidden((cause) => {
					console.warn("insufficient priviledges unable to view metrics", cause)
					setCause(
						<layouts.containers.box styled m="auto">
							<errors.Textual>you do not have permission to view metrics</errors.Textual>
						</layouts.containers.box>,
					)
				}),
			)
			.catch((c: unknown) => {
				console.error("unable to retrieve metrics", c)
				setCause(
					<layouts.containers.box styled m="auto" width="100%" height="100%">
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve metrics</errors.Textual>
					</layouts.containers.box>,
				)
			})
			.finally(() => setLoading(false))
	}, [req])

	return (
		<layouts.containers.flex
			pt="50px"
			pb="20px"
			px="25px"
			width="100%"
			flexDirection="column"
			overflowY="auto"
			className="metrics"
		>
			<layouts.containers.flex width="100%" textAlign="center">
				<layouts.containers.flex>
					<typography.h3 color={layouts.theme.colors.grey.medium}>Metrics</typography.h3>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.loading.screen loading={loading}>
				<errors.overlay cause={cause} flexDirection="column">
					<layouts.containers.box className="metrics" width="100%" background={layouts.theme.colors.white}>
						<Chart labels={results.impressions.chart.labels} dataset={[results.impressions, results.conversions]} />
						<layouts.containers.flex justifyContent="center">
							<Card
								icon={
									<icons.Dollar
										fill={results.conversions.chart.borderColor}
										width="30px"
										height="30px"
										verticalAlign="middle"
									/>
								}
								data={results.conversions}
							/>
							<Card
								icon={
									<icons.ImpressionsEye
										fill={results.impressions.chart.borderColor}
										width="30px"
										height="30px"
										verticalAlign="middle"
									/>
								}
								data={results.impressions}
							/>
						</layouts.containers.flex>
					</layouts.containers.box>
				</errors.overlay>
			</layouts.loading.screen>
		</layouts.containers.flex>
	)
}
