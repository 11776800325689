import React from "react"

import * as api from "./api"
import * as textgen from "ads/textgen"
import * as adgen from "ads/adgen"
import * as uuid from "uuid"

export interface cached {
	headlines: textgen.Sample[]
	descriptions: textgen.Sample[]
}

export namespace buffered {
	export function zero(d: Partial<cached> = {}): cached {
		return {
			headlines: [] as textgen.Sample[],
			descriptions: [] as textgen.Sample[],
			...d,
		}
	}
}

export function merge(a: Partial<cached>, b: Partial<cached>): cached {
	return buffered.zero({
		headlines: [...(a.headlines || []), ...(b.headlines || [])],
		descriptions: [...(a.descriptions || []), ...(b.descriptions || [])],
	})
}

export interface mutation {
	(c: context): context
}

export namespace mutate {
	export function apply(gctx: context, ...mset: mutation[]): context {
		return mset.reduce((ctx, m) => m(ctx), gctx)
	}

	export function composed(...mset: mutation[]): mutation {
		return (c: context): context => {
			return mset.reduce((ctx, m) => m(ctx), c)
		}
	}

	export function metadata(upd: Partial<api.Metadata>): mutation {
		return (c: context): context => {
			return {
				...c,
				metadata: {
					...c.metadata,
					...upd,
				},
			}
		}
	}

	export function content(upd: Partial<Form>): mutation {
		return (c: context): context => {
			return {
				...c,
				content: {
					...c.content,
					...upd,
				},
			}
		}
	}

	export function current(upd: Partial<cached>): mutation {
		return (c: context): context => {
			const updated = {
				...c.current,
				...upd,
			}
			return {
				...c,
				current: updated,
			}
		}
	}

	export function buffered_merge(upd: Partial<cached>): mutation {
		return (c: context): context => {
			return {
				...c,
				buffered: merge(c.buffered, upd),
			}
		}
	}

	export function buffered(upd: Partial<cached>): mutation {
		return (c: context): context => {
			return {
				...c,
				buffered: {
					...c.buffered,
					...upd,
				},
			}
		}
	}

	export function rejected(...upd: textgen.Sample[]): mutation {
		return (c: context): context => {
			return {
				...c,
				rejected: [...c.rejected, ...upd],
			}
		}
	}

	export function rejected_reset(c: context): context {
		return {
			...c,
			rejected: [],
		}
	}

	export function generated(upd: JSX.Element): mutation {
		return (c: context): context => {
			return {
				...c,
				generated: upd,
			}
		}
	}

	export function generating(upd: boolean): mutation {
		return (c: context): context => {
			return {
				...c,
				generating: upd,
			}
		}
	}

	export namespace form {
		export function url(upd: string): mutation {
			return (c: context): context => {
				return {
					...c,
					currenturl: upd,
				}
			}
		}
	}
}

export interface AdgenConstants {
	maxHeadlineLength: number
	maxHeadlinesCount: number
	minHeadlinesCount: number
	maxDescriptionLength: number
	maxDescriptionsCount: number
	minDescriptionCount: number
}

export function adgenconstants(): AdgenConstants {
	return {
		maxHeadlineLength: 30,
		maxHeadlinesCount: 15,
		minHeadlinesCount: 4,
		maxDescriptionLength: 90,
		maxDescriptionsCount: 4,
		minDescriptionCount: 3,
	}
}

export interface Form {
	id: string
	path1: string
	path2: string
	urls: string[]
	headlines: textgen.Sample[]
	descriptions: textgen.Sample[]
}

export namespace form {
	export function zero(d: Partial<Form> = {}): Form {
		return {
			id: uuid.v4(),
			descriptions: [],
			headlines: [],
			urls: [],
			path1: "",
			path2: "",
			...d,
		}
	}

	export function content(c: Form): api.AdResponsiveSearchContent {
		return {
			id: c.id,
			descriptions: c.descriptions.map((v) => v.accepted),
			headlines: c.headlines.map((v) => v.accepted),
			path1: c.path1,
			path2: c.path2,
			urls: c.urls,
		}
	}

	export function fromContent(c: Partial<api.AdResponsiveSearchContent> = {}): Form {
		return zero({
			id: c.id,
			descriptions: (c.descriptions || []).map((v) => adgen.text.sample(v)),
			headlines: (c.headlines || []).map((v) => adgen.text.sample(v)),
			path1: c.path1,
			path2: c.path2,
			urls: c.urls || [],
		})
	}
}

export interface context {
	generating: boolean
	buffered: cached
	current: cached
	currenturl: string
	content: Form
	metadata: api.AdResponsiveSearchMetadata
	rejected: textgen.Sample[]
	generated: JSX.Element
}

export function zero(d: Partial<context> = {}): context {
	return {
		generating: false,
		buffered: buffered.zero(),
		current: buffered.zero(),
		currenturl: "",
		content: form.zero(),
		metadata: api.ads.responsive.metadata(),
		rejected: [],
		generated: <></>,
		...d,
	}
}
export const Context = React.createContext(zero())

export const Provider = Context.Provider
export const Consumer = Context.Consumer
