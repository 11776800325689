import React, { useState, useEffect } from "react"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as errors from "errors"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as debugx from "x/debugx"
import * as pools from "ads/pools"
import * as api from "./api"
import * as typography from "typography"
import Preview from "./adgen.link.preview"

interface props {
	search(req: api.SearchRequest, ...options: httpx.option[]): Promise<api.SearchResponse>
	preview(id: string, ...options: httpx.option[]): Promise<api.AdLinkContentResponse>
}

export function Table(props: props & layouts.containers.FlexProps): JSX.Element {
	const { search, preview, ...rest } = props

	const pool = pools.caching.useCached()
	const bearertoken = sessions.useToken()
	const [sidecar, setSidecar] = useState(undefined as JSX.Element | undefined)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [loading, setLoading] = useState(true)
	const [focused, setFocused] = useState(api.ads.metadata.zero())
	const [retargetting, setRetargetting] = useState("")
	const [previewload, setPreviewLoading] = useState("")
	const [facebookadreq, setFacebookAdRequest] = useState({
		pool_id: pool.id,
		offset: uuid.NIL,
	} as api.SearchRequest)

	const [results, setResults] = useState({
		next: facebookadreq,
		items: [],
	} as api.SearchResponse)

	useEffect(() => {
		setLoading(true)
		search(facebookadreq, bearertoken)
			.then((resp) => {
				setResults(resp)
				setCause(undefined)
			})
			.catch(
				httpx.errors.forbidden((cause) => {
					console.warn("insufficient priviledges unable to view ads", cause)
					setCause(
						<layouts.containers.box styled m="auto">
							<errors.Textual>you do not have permission to view ads</errors.Textual>
						</layouts.containers.box>,
					)
				}),
			)
			.catch((c: unknown) => {
				console.error("unable to retrieve ads", c)
				setCause(<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve ads</errors.Textual>)
			})
			.finally(() => setLoading(false))
	}, [facebookadreq, search, pool])

	return (
		<layouts.containers.flex className="facebook" p="10px" overflow="hidden" flex="1" flexDirection="column" {...rest}>
			<layouts.containers.flex p="25px">
				<typography.h3 color={layouts.theme.colors.grey.medium}>Facebook Published Ads</typography.h3>
			</layouts.containers.flex>
			<layouts.tables.container py="10px" overflow="auto" flex="1">
				<errors.overlay styled cause={cause}>
					<layouts.loading.screen loading={loading} flexDirection="row">
						<table>
							<thead>
								<tr>
									<th>Ad ID</th>
									<th>Description</th>
									<th>Disabled At</th>
									<th>Updated At</th>
									<th>Created At</th>
								</tr>
							</thead>
							<tbody>
								{results.items.map((c) => (
									<React.Fragment key={c.id}>
										<layouts.tables.Row
											onClick={() => (c.id === focused.id ? setFocused(api.ads.metadata.zero()) : setFocused(c))}
										>
											<td>{c.id}</td>
											<td>{c.description}</td>
											<td>{layouts.tables.Timestamp(c.disabled_at)}</td>
											<td>{layouts.tables.Timestamp(c.updated_at)}</td>
											<td>{layouts.tables.Timestamp(c.created_at)}</td>
										</layouts.tables.Row>
										{c.id === focused.id && (
											<layouts.tables.inline>
												<layouts.containers.grid gridGap="35px" width="100%" justifyContent="end">
													{debugx.alpha.enabled() && (
														<layouts.buttons.outline
															disabled={retargetting === focused.id}
															onClick={() => {
																setRetargetting(focused.id)
																api.ads.link.retarget(focused.id, bearertoken).catch((cause) => {
																	console.warn("unable to retarget", cause)
																	setCause(
																		<layouts.containers.box styled m="auto" onClick={() => setCause(undefined)}>
																			<errors.Textual>unable to retarget ad</errors.Textual>
																		</layouts.containers.box>,
																	)
																	setRetargetting("")
																})
															}}
														>
															Retarget
														</layouts.buttons.outline>
													)}
													<layouts.buttons.outline
														onClick={() => {
															if (previewload === c.id) {
																setPreviewLoading("")
																setSidecar(undefined)
																return
															}
															setPreviewLoading(c.id)
															preview(c.id, bearertoken)
																.then((c) => {
																	setSidecar(
																		<layouts.containers.flex flex="1" flexDirection="column" px="10px">
																			<typography.h3>Preview</typography.h3>
																			<Preview
																				pt="10px"
																				content={api.ads.link.zero(c.content)}
																				media={{ id: c.content?.media_id || "", url: "" }}
																			/>
																		</layouts.containers.flex>,
																	)
																})
																.finally(() => setPreviewLoading(c.id))
														}}
													>
														Preview
													</layouts.buttons.outline>
												</layouts.containers.grid>
											</layouts.tables.inline>
										)}
									</React.Fragment>
								))}
							</tbody>
						</table>
						{sidecar}
					</layouts.loading.screen>
				</errors.overlay>
			</layouts.tables.container>
			<layouts.pagination.Cursor
				ml="auto"
				current={facebookadreq.offset}
				advance={results.cursor?.offset === "" ? undefined : results.cursor?.offset}
				onChange={(next) => next && setFacebookAdRequest({ ...facebookadreq, offset: next })}
			/>
		</layouts.containers.flex>
	)
}

Table.defaultProps = {
	search: api.search,
	preview: api.ads.link.content,
}
