import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as titlebars from "./index"

const LogoutButton = styled(layouts.buttons.primary)`
	height: 53px;
	width: 150px;
	font-size: 18px;
`

export function Header(props: React.PropsWithChildren<unknown>): JSX.Element {
	//TODO: Fill in actual routes
	return (
		<titlebars.containers.GradientContainer>
			<titlebars.logos.Logo />
			<titlebars.navigation.NavList>
				<titlebars.navigation.NavItem route="/">Home</titlebars.navigation.NavItem>
				<titlebars.navigation.NavItem route="/">Generate</titlebars.navigation.NavItem>
				<titlebars.navigation.NavItem route="/">Item Three</titlebars.navigation.NavItem>
			</titlebars.navigation.NavList>
			<LogoutButton>Log Out</LogoutButton>
		</titlebars.containers.GradientContainer>
	)
}
