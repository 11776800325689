import React, { useState } from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as api from "./api"
import * as typography from "typography"

interface props {
	pool: api.Pool
	onChange(b: api.Pool): Promise<api.Pool>
}

export function Modal(props: props): JSX.Element {
	const [currentPool, setPool] = useState(props.pool)
	const disabled = currentPool.description.length === 0

	const modalToggle = layouts.modals.useToggle()

	return (
		<layouts.containers.box
			styled
			width="calc(100vw * 0.8)"
			p="50px"
			pt="25px"
			background={layouts.theme.colors.grey.bg}
		>
			<typography.h3 color={layouts.theme.colors.grey.medium} pb="50px" textAlign="left">
				Update Ad Pool
			</typography.h3>
			<Edit current={currentPool} onChange={setPool} />
			<layouts.containers.flex justifyContent="center" pt="50px">
				<layouts.buttons.outline
					m="10px"
					width="220px"
					height="57px"
					paddingRight="20px"
					borderRadius="37px"
					disabled={disabled}
					onClick={() => {
						props.onChange(currentPool).then((pool) => {
							modalToggle(undefined)
						})
						modalToggle(undefined)
					}}
				>
					Save
				</layouts.buttons.outline>
				<layouts.buttons.outline
					m="10px"
					width="220px"
					height="57px"
					borderRadius="37px"
					onClick={() => modalToggle(undefined)}
				>
					Cancel
				</layouts.buttons.outline>
			</layouts.containers.flex>
		</layouts.containers.box>
	)
}

export default function Edit(props: { current: api.Pool; onChange(a: api.Pool): void }): JSX.Element {
	const { current, onChange } = props

	return (
		<layouts.forms.Container width="100%">
			<layouts.containers.flex flex="1" flexDirection="column" m="auto">
				<layouts.forms.Group width="400px" mb="50px">
					<inputs.Text
						className="outline"
						placeholder="Ad Pool Name"
						defaultValue={current.description}
						onChange={(evt) => onChange({ ...current, description: evt.currentTarget.value })}
						autoFocus
					/>
				</layouts.forms.Group>
				<layouts.forms.Group width="400px" mb="50px">
					<inputs.Numeric
						position="relative"
						icon={
							<layouts.containers.absolute
								bottom="14px"
								left="35px"
								fontSize="12px"
								fontWeight="700"
								lineHeight="20px"
								color={layouts.theme.colors.grey.dark10}
							>
								$
							</layouts.containers.absolute>
						}
						className="outline"
						placeholder="daily budget"
						// budgets are in cents so normalize
						defaultValue={BigInt(current.budget) === 0n ? "" : (BigInt(current.budget) / 100n).toString()}
						onChange={(evt) => onChange({ ...current, budget: BigInt(evt.currentTarget.value) * 100n })}
					/>
				</layouts.forms.Group>
				<layouts.forms.Group width="400px" mb="50px">
					<inputs.Numeric
						className="outline"
						placeholder="daily events target"
						defaultValue={BigInt(current.daily_events_target) === 0n ? "" : current.daily_events_target.toString()}
						onChange={(evt) => onChange({ ...current, daily_events_target: BigInt(evt.currentTarget.value) })}
					/>
				</layouts.forms.Group>
			</layouts.containers.flex>
		</layouts.forms.Container>
	)
}
