import { Route, Routes, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import * as sessions from "sessions"
import * as brands from "brands"
import * as layouts from "layouts"
import { default as Container } from "./container"
import { default as DisplayImages } from "./display/images.display"
import { default as DisplayText } from "./display/texts.display"

export function TrainingImages(): JSX.Element {
	return (
		<Container>
			<DisplayImages />
		</Container>
	)
}

export function TrainingText(): JSX.Element {
	return (
		<Container>
			<DisplayText />
		</Container>
	)
}

export default function Routing(): JSX.Element {
	const params = useParams() as {
		bid: string
	}
	const [brand, setBrand] = useState(brands.api.zero())
	const bearertoken = sessions.useToken()

	useEffect(() => {
		brands.caching.current(params.bid, bearertoken).then((result) => setBrand(brands.api.zero(result)))
	}, [])

	return (
		<layouts.backgrounds.Grey flex="0 1 100%" height="100%" overflowY="hidden">
			<brands.caching.Provider value={brand}>
				<Routes>
					<Route path="/images" element={<TrainingImages />} />
					<Route path="/text" element={<TrainingText />} />
					<Route path="*" element={<TrainingImages />} />
				</Routes>
			</brands.caching.Provider>
		</layouts.backgrounds.Grey>
	)
}
