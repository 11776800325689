import React from "react"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as api from "./api"
import Published from "./adgen.responsive.search.published"
import * as context from "./adgen.responsive.search.context"

export interface Publisher {
	disabled: boolean
	metadata: api.AdResponsiveSearchMetadata
	content: api.AdResponsiveSearchContent
	onChange(...mut: context.mutation[]): void
	onPublish(pending: Promise<unknown>): Promise<unknown>
}

export function Publish(props: Publisher): JSX.Element {
	const { metadata, content, disabled, onChange, onPublish } = props
	const bearertoken = sessions.useToken()
	return (
		<layouts.containers.flex alignItems="center" pb="50px" pt="25px" flexDirection="column">
			<layouts.buttons.primary
				width="150px"
				height="50px"
				borderRadius="37px"
				disabled={disabled}
				onClick={async () => {
					const req = api.ads.responsive.request({
						metadata: metadata,
						content: content,
					})
					return onPublish(
						api.ads.responsive.create(req, bearertoken).then(() => {
							onChange(context.mutate.generated(<Published onChange={onChange} />))
						}),
					)
				}}
			>
				Publish
			</layouts.buttons.primary>
		</layouts.containers.flex>
	)
}
