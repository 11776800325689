import React from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import styled from "@emotion/styled"

const Text = styled(typography.h3)`
	font-weight: 400;
	font-size: 12pt;
	line-height: 16px;
	text-align: center;
	color: ${layouts.theme.colors.grey.dark10};
	&:before {
		content: "To Begin: ";
		font-weight: 700;
	}
`

export function Container(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return (
		<layouts.containers.flex
			className="styled-drop-area"
			flexDirection="column"
			background={layouts.theme.backgrounds.bluealpha}
			border={`1px dashed ${layouts.theme.colors.blue}`}
			borderRadius="10px"
			p="50px"
			color={layouts.theme.colors.grey.medium}
			{...rest}
		>
			{children}
		</layouts.containers.flex>
	)
}

export function Textual(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return (
		<Text pt="25px" {...rest}>
			{children}
		</Text>
	)
}
