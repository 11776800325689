import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import * as layouts from "layouts"
import * as brands from "brands"
import * as brandguard from "brandguard"
import * as pools from "ads/pools"
import * as typography from "typography"
import * as routing from "ads/studio/routing"
import * as api from "ads/google/api"
import * as textgen from "ads/textgen/api"
import * as context from "./context"
import * as adgen from "ads/adgen"
import * as adgencontext from "ads/google/adgen.responsive.search.context"
import * as sessions from "sessions"
import * as debugx from "x/debugx"
import { TabPrimaryButton, TabOutlineButton } from "ads/adgen/adgen.content.layouts"
import Form from "ads/google/adgen.responsive.search.form"
import Generated from "./generated"

export default function Approval(): JSX.Element {
	const brand = brands.caching.useCached()
	const pool = pools.caching.useCached()
	const bearertoken = sessions.useToken()
	const navigate = useNavigate()
	const [selectAll, setSelectAll] = useState(false)
	const [genctx, setGenctx] = useState(context.zero() as context.context)
	const [mutations, setMutations] = useState([] as adgencontext.mutation[])
	const [bgscore] = useState(brandguard.api.observations.zero())

	const asyncupd = (...m: adgencontext.mutation[]) => {
		setMutations([...mutations, ...m])
	}

	useEffect(() => {
		if (mutations.length === 0) return
		const updated = adgencontext.mutate.apply(genctx.adgenctx, ...mutations)
		const updcontx = context.mutate.apply(genctx, ...[context.mutate.ads.modify(updated)])
		setGenctx({ ...genctx, adgenctx: updated, ads: updcontx.ads })
		setMutations([])
	}, [mutations])

	return (
		<context.Provider value={genctx}>
			<layouts.containers.flex className="studio" flexDirection="column" flex="1">
				<layouts.containers.flex flexDirection="row" pl="25px">
					<TabPrimaryButton>Google</TabPrimaryButton>
					{debugx.alpha.enabled() && (
						<TabOutlineButton onClick={() => navigate(routing.approvals.facebook(brand.id, pool.id))}>
							Facebook
						</TabOutlineButton>
					)}
				</layouts.containers.flex>
				<layouts.containers.flex
					width="100%"
					background={layouts.theme.colors.white}
					borderRadius="10px"
					flexDirection="column"
					flex="1"
				>
					<layouts.containers.flex flexDirection="row" width="100%">
						<layouts.containers.flex p="25px" flex="1">
							<typography.h3 color={layouts.theme.colors.grey.medium}>New Ads</typography.h3>
						</layouts.containers.flex>
						<layouts.containers.flex p="25px" flex="2" justifyContent="end">
							<layouts.buttons.outline
								width="115px"
								height="35px"
								borderRadius="37px"
								mr="10px"
								fontSize="12px"
								fontWeight="500"
								onClick={() => {
									const toggled = !selectAll
									const upd = genctx.ads.map((a) => ({ ...a, selected: toggled }))
									setGenctx({ ...genctx, ads: upd })
									setSelectAll(toggled)
								}}
								disabled={genctx.ads.length === 0}
							>
								Select All
							</layouts.buttons.outline>
							<layouts.buttons.primary
								width="115px"
								height="35px"
								borderRadius="37px"
								fontSize="12px"
								fontWeight="500"
								disabled={!genctx.ads.reduce((accum, i) => accum || i.selected, false)}
								onClick={() => {
									const muts = [] as context.mutation[]
									genctx.ads.forEach((item) => {
										if (!item.selected) {
											return
										}
										const md = api.ads.responsive.metadata({
											...item.metadata,
											google_ads_client_account_id: pool.google_ads_client_account_id,
										})

										const d = {
											metadata: md,
											content: api.ads.responsive.content.zero({
												...item!.content!,
												urls: item!.content?.urls,
												headlines: item!.content?.headlines,
												descriptions: item!.content?.descriptions,
											}),
										}

										// const obs = brandguard.api.observations.zero({
										// 	...api.ads.responsive.asBrandguardObservation(d.metadata),
										// 	brand_id: pool.brand_id,
										// 	prediction: md.brandguard_prediction,
										// 	observation: brandguard.api.Prediction.APPROVED,
										// })

										// brandguard.api.observations
										// 	.record(
										// 		{
										// 			samples: [obs],
										// 		},
										// 		bearertoken,
										// 	)
										// 	.catch(console.error)

										textgen.create
											.record(
												{
													samples: [
														...item!.content!.descriptions.map((v) => adgen.text.sample(v)),
														...item!.content!.headlines.map((v) => adgen.text.sample(v)),
														...genctx.adgenctx.rejected,
													],
												},
												bearertoken,
											)
											.catch(console.error)

										muts.push(context.mutate.reject(item))
										return api.ads.responsive.create(d, bearertoken)
									})
									if (muts.length > 0) setGenctx(context.mutate.apply(genctx, ...muts))
								}}
							>
								Approve Selected
							</layouts.buttons.primary>
						</layouts.containers.flex>
					</layouts.containers.flex>
					<layouts.containers.flex width="100%" flexDirection="row" flex="1">
						<layouts.containers.flex flexDirection="column" flex="2" p="10px" pl="25px" pb="25px" minWidth="315px">
							<brandguard.Score {...bgscore} />
							<Form genctx={genctx.adgenctx} onChange={asyncupd} />
						</layouts.containers.flex>
						<layouts.containers.flex flexDirection="column" flex="7">
							<Generated
								flex="7"
								verticalAlign="top"
								alignItems="start"
								onChange={(...mset: context.mutation[]) => setGenctx(context.mutate.apply(genctx, ...mset))}
							/>
						</layouts.containers.flex>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</context.Provider>
	)
}
