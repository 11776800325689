import React, { useEffect, useState } from "react"
import * as sessions from "sessions"
import * as layouts from "layouts"
import * as oauth2 from "authz/oauth2"
import * as api from "./api"

interface modalprops {
	scopes: string[]
	onChange(upd: api.Credential): void
}

export function Modal(props: React.PropsWithChildren<modalprops>): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const [loading, setLoading] = useState(false)
	const bearertoken = sessions.useToken()
	const [credential] = useState(api.authorization.requests.zero({ scopes: props.scopes }))

	useEffect(() => {
		setLoading(true)
		api.authorization
			.url(credential, bearertoken)
			.then(async (auth) => {
				return oauth2.pendingauth(auth).then((r) =>
					api.find(r.cid, bearertoken).then((r) => {
						props.onChange(r.credential)
						mtoggle(undefined)
					}),
				)
			})
			.catch(console.error)
			.finally(() => setLoading(false))
	}, [])

	return (
		<layouts.loading.screen loading={loading}>
			<layouts.containers.flex alignContent="center" justifyItems="center">
				Follow the facebook login instructions
			</layouts.containers.flex>
		</layouts.loading.screen>
	)
}
