import React, { useState } from "react"
import * as errors from "errors"
import * as httputils from "httpx"
import * as layouts from "layouts"
import * as api from "./api"
import Edit from "./image.crop.edit"

// Pet crop demo
export default function Demo(props: React.PropsWithChildren<unknown>): JSX.Element {
	let [cause, setCause] = useState(undefined as JSX.Element | undefined)
	let [loading, setLoading] = useState(false)
	let [cancelled, setCancelled] = useState(false)
	let [cropped, setCropped] = useState(undefined as undefined | api.RetrievalResponse)
	let [crop, setCrop] = useState({
		image_url:
			"https://dev-shopify-custom-integration.oss-accelerate.aliyuncs.com/profiles/2/2_face_1651473457.jpeg?x-oss-process=image/rotate,0",
		background_url:
			"https://prod-shopify-custom-integration.oss-accelerate.aliyuncs.com/print_backgrounds/oqx/qx9/0oq/OQXQx90OqJ8mwuMD.jpeg",
		product_id: 6712465916076,
		product_variant_id: 39787174789292,
		line_item_id: 11732267761836,
		effect: 0,
		order_id: 0,
		smooth_edges_threshold: 0,
		crop_type: "head",
	} as api.CropRequest)

	function cropresult(req: api.CropRequest, count: number): Promise<api.RetrievalResponse> {
		const retry = (cause: unknown): Promise<api.RetrievalResponse> => {
			if (cancelled) {
				return Promise.reject(cause)
			}

			console.debug("ratelimit event", count, cause)
			return new Promise((resolve) => {
				setTimeout(() => resolve(cropresult(req, count + 1)), 1000)
			})
		}

		return api
			.retrieve(req)
			.then((cropped) => {
				setCropped(cropped)
				return cropped
			})
			.catch(httputils.errors.unavailable(retry))
			.catch(httputils.errors.ratelimited(retry))
	}

	function gencropped(req: api.CropRequest): void {
		console.log("cropping initiated")
		setLoading(true)
		api
			.crop(req)
			.then((resp) => {
				console.log("crop request sent", resp)
				return cropresult(req, 0)
			})
			.catch((cause) => {
				setCause(
					<layouts.containers.box m="auto">
						<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
							Image Crop Failed
						</errors.Textual>
					</layouts.containers.box>,
				)
			})
			.finally(() => {
				console.log("cropping completed")
				setLoading(false)
			})
	}

	return (
		<layouts.containers.flex m="auto" width="80%">
			<layouts.containers.box styled width="100%">
				<layouts.overlays.Container>
					<errors.overlay styled cause={cause}>
						<Edit current={crop} onChange={(upd) => setCrop(upd)} pb="10px" />
						<layouts.containers.box>
							<layouts.buttons.primary onClick={() => gencropped(crop)}>Submit</layouts.buttons.primary>
						</layouts.containers.box>
					</errors.overlay>
					{cropped && (
						<layouts.containers.flex m="auto" alignItems="center">
							<img src={cropped.crop_url} alt="cropped pet" />
							<img src={cropped.print_url} alt="printable composite" />
						</layouts.containers.flex>
					)}
					<layouts.overlays.Screen enabled={loading} styled>
						<layouts.containers.flex height="inherit">
							<layouts.containers.flex m="auto" flexDirection="column">
								<layouts.containers.box>Processing</layouts.containers.box>
								<layouts.containers.box mt="5px">
									<layouts.buttons.primary width="100%" onClick={() => setCancelled(true)}>
										Cancel
									</layouts.buttons.primary>
								</layouts.containers.box>
							</layouts.containers.flex>
						</layouts.containers.flex>
					</layouts.overlays.Screen>
				</layouts.overlays.Container>
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}
