import classnames from "classnames"
import { css } from "@emotion/css"
import { useState, useContext } from "react"
import { NavLink } from "react-router-dom"
import * as uuid from "uuid"
import * as layouts from "layouts"
import * as brands from "brands"
import * as icons from "icons"
import { default as Link, alternates } from "./link"
import * as context from "./context"
import * as caching from "./cache"

const styling = css`
	&.navigation > *:not(:last-child) {
		margin-bottom: 25px;
	}
`
interface props {
	current: brands.Brand
}
function BrandsNavigation(props: props): JSX.Element {
	const { current } = props
	if (current.id === uuid.NIL) return <></>

	return (
		<>
			{/* <Link href={`/brands/${current.id}/styleguard`} className="icon-with-stroke">
				<icons.Styleguard width="1.3em" height="1.3em" />
				<layouts.containers.span ml="10px" pt="4px">
					StyleGuide
				</layouts.containers.span>
			</Link> */}
			{/* <Link href={`/brands/${current.id}/training`}>
				<icons.AssetsTraining width="1.3em" height="1.3em" />
				<layouts.containers.span ml="10px" pt="4px">
					Assets Training
				</layouts.containers.span>
			</Link>
			<Link href={`/brands/${current.id}/brandguard`} className="icon-with-stroke">
				<icons.Brandguard width="1.3em" height="1.3em" />
				<layouts.containers.span ml="10px" pt="4px">
					BrandGuard
				</layouts.containers.span>
			</Link> */}
			<Link href={`/brands/${current.id}/ads`}>
				<icons.Adpools width="1.3em" height="1.3em" />
				<layouts.containers.span ml="10px" pt="4px">
					Ad Pools
				</layouts.containers.span>
			</Link>
		</>
	)
}

function Inner(props: layouts.containers.FlexProps): JSX.Element {
	const { collapsed, collapse } = useContext(context.Context)
	const brand = brands.caching.useCached()

	return (
		<layouts.containers.flex
			className={classnames("navigation", styling)}
			styled
			p="20px"
			width={collapsed ? "40px" : "250px"}
			flexDirection="column"
			background={layouts.theme.colors.white}
			{...props}
		>
			<layouts.containers.box pb="50px" textAlign="left" ml="-10px">
				<NavLink to="/">
					{collapsed ? (
						<icons.Logo fill={layouts.theme.colors.blue} stroke={layouts.theme.colors.blue} height="50px" />
					) : (
						<icons.Application fill={layouts.theme.colors.blue} stroke={layouts.theme.colors.blue} height="50px" />
					)}
				</NavLink>
			</layouts.containers.box>

			<layouts.containers.box
				textAlign="left"
				pb="50px"
				ml="5px"
				onClick={() => {
					collapse(!collapsed)
				}}
			>
				<icons.MenuHamburger fill={layouts.theme.colors.blue} width="30px" />
			</layouts.containers.box>
			<Link href="/brands" alternates={alternates.by_path("/")}>
				<icons.Dashboard width="1.3em" height="1.3em" />
				<layouts.containers.span ml="10px" pt="4px">
					Brands
				</layouts.containers.span>
			</Link>
			<BrandsNavigation current={brand} />
			<Link href="/u12t">
				<icons.Person width="1.3em" height="1.3em" />
				<layouts.containers.span ml="10px" pt="4px">
					Profiles
				</layouts.containers.span>
			</Link>
			<layouts.containers.box mt="auto" />
			<Link href="/logout" mt="10px">
				<icons.Logout width="1.3em" height="1.3em" />
				<layouts.containers.span ml="10px" pt="4px">
					Logout
				</layouts.containers.span>
			</Link>
		</layouts.containers.flex>
	)
}

export default function Standard(props: layouts.containers.FlexProps): JSX.Element {
	const [root, setRoot] = useState(context.zero({ collapsed: caching.cache.get("collapsed") }))

	const ctx = {
		...root,
		focus: (path: string[]) => {
			setRoot({ ...ctx, current: path })
		},
		collapse(upd: boolean) {
			setRoot({ ...ctx, collapsed: upd })
			caching.cache.set("collapsed", upd)
		},
		update(d: Partial<context.ctx>) {
			setRoot({ ...ctx, ...d })
		},
	}

	return (
		<context.Provider value={ctx}>
			<Inner {...props} />
		</context.Provider>
	)
}
