import { useContext } from "react"
import * as layouts from "layouts"
import * as context from "../display"
import styled from "@emotion/styled"

interface props {
	html: string | undefined
}

export const DataContainer = styled(layouts.containers.flex)`
	padding: 50px;
	flex-direction: row;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	color: ${layouts.theme.colors.black};

	.content {
		max-height: 76vh;
		overflow-y: auto;
	}

	.column {
		padding-left: 20px;
		padding-right: 20px;
	}

	h2 {
		// margin-bottom: 10px;
		// color: ${layouts.theme.colors.black};
		// font-weight: 600;
		// font-size: 12px;
		display: none;
	}

	.item {
		margin-bottom: 10px;
	}
`

export default function TabElement(props: props): JSX.Element {
	const { html } = props
	const ctx = useContext(context.Context)

	if (!html) return <></>

	let el = document.createElement("div")
	el.innerHTML = html
	const imgList = el.querySelectorAll("img")
	if (imgList.length > 0) {
		imgList.forEach((imgEl) => {
			imgEl.src = ctx.imgs.find((e) => e.filename.split("/").pop() === imgEl.src.split("/").pop())?.data || ""
		})
	}

	return (
		<layouts.containers.flex flexDirection="column" className="styleguard-tab-show-component">
			<layouts.containers.flex
				width="100%"
				background={layouts.theme.colors.white}
				borderRadius="10px"
				flexDirection="column"
			>
				<DataContainer dangerouslySetInnerHTML={{ __html: el.innerHTML }}></DataContainer>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
