import React, { useState } from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as sessions from "sessions"
import * as api from "brandguard/api"
import * as brands from "brands"

export const TabPrimaryButton = styled(layouts.buttons.primary)`
	width: 100px;
	height: 40px;
	font-size: 10pt;
	border-radius: 24px;
	margin-top: 20px;
`

export const TextArea = styled(inputs.TextArea)`
	border: 2px solid ${layouts.theme.colors.grey};
	&:hover {
		border: 2px solid ${layouts.theme.colors.blue};
	}
	outline: none;
`

interface props {
	onUpload(item: api.TextSearchResponseItem): void
}

export default function TextInsert(props: props): JSX.Element {
	const { onUpload } = props
	const [loading, setLoading] = useState(false)
	const [text, setText] = useState("")
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()

	const maxLength = 32768

	return (
		<layouts.containers.flex flexDirection="column" width="80%" alignItems="center" justifyContent="center" pt="25px">
			<TextArea
				placeholder="Type in text you would like to review here..."
				value={text}
				onChange={(evt) => {
					if (evt.target.value.length > maxLength) {
						return
					}
					setText(evt.target.value)
				}}
			></TextArea>
			<TabPrimaryButton
				disabled={text.length === 0 || loading}
				onClick={() => {
					setLoading(true)
					const file = new File([new Blob([text])], "foo.txt", { type: "text/plain" })
					api.text.uploads
						.create(file, brand.id, bearertoken)
						.then((result) => {
							onUpload(result)
							setText("")
						})
						.finally(() => setLoading(false))
				}}
			>
				Review
			</TabPrimaryButton>
		</layouts.containers.flex>
	)
}
