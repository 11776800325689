export function root(bid: string): string {
	return `/brands/${bid}/training`
}

export namespace training {
	export function images(bid: string): string {
		return `${root(bid)}/images`
	}

	export function text(bid: string): string {
		return `${root(bid)}/text`
	}
}
