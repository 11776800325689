import React, { useEffect, useRef, useState } from "react"

import ReactDOM from "react-dom"
import ObjPath from "object-path"

import * as acorn from "acorn"
import * as escodegen from "escodegen"
import * as babel from "@babel/standalone"

import * as layouts from "layouts"
import * as inputs from "inputs"
import * as errors from "errors"

interface program extends acorn.Node {
	body: acorn.Node[]
}

function asProgram(n: acorn.Node): program | undefined {
	return (n as unknown as program).body ? (n as program) : undefined
}

function isReactNode(node: acorn.Node) {
	const type = node.type //"ExpressionStatement"
	const obj = ObjPath.get(node, "expression.callee.object.name")
	const func = ObjPath.get(node, "expression.callee.property.name")
	return type === "ExpressionStatement" && obj === "React" && func === "createElement"
}

function isDefaultExport(node: acorn.Node): boolean {
	const type = node.type
	const fndecl = ObjPath.get(node, "declaration.type")
	return type === "ExportDefaultDeclaration" && fndecl === "FunctionDeclaration"
}

export function findReactNode(ast: program): acorn.Node | undefined {
	return ast.body?.find(isReactNode)
}

export function findDefaultExport(ast: program): acorn.Node | undefined {
	return ast.body?.find(isDefaultExport)
}

function compile(code: string): Promise<string> {
	return new Promise((resolve, reject) => {
		try {
			// 1. transform code
			const tcode =
				babel.transform(code, {
					filename: "input.ts",
					presets: [
						babel.availablePresets.es2017,
						babel.availablePresets.react,
						[babel.availablePresets.typescript, { isTSX: true, allExtensions: true }],
					],
				})?.code || ""

			// 2. get AST
			const ast = asProgram(
				acorn.parse(tcode, {
					ecmaVersion: 2022,
					sourceType: "module",
				}),
			)

			if (ast === undefined) {
				resolve("")
				return
			}

			// 3. find React.createElement expression in the body of program
			const dnode = findDefaultExport(ast)

			if (dnode) {
				const nodeIndex = ast.body.indexOf(dnode)
				const fndecl = ObjPath.get(dnode, "declaration")
				const ident = (fndecl as unknown as { id: { name: string } }).id
				// 5. inject an expression that renders the default export (assuming its a react component)
				// with the provided environment
				const renderCallAst = asProgram(
					acorn.parse(`ReactDOM.render(${ident.name}(), runtime.parent)`, { ecmaVersion: 2022 }),
				)?.body[0]
				if (renderCallAst) {
					ast.body[nodeIndex] = fndecl
					ast.body = ast.body.concat(renderCallAst)
				}
			}

			resolve(escodegen.generate(ast))
		} catch (cause) {
			console.error(cause)
			reject(cause)
		}
	})
}
export function Editor(): JSX.Element {
	const defaultProgram = `
export default function Greetings() {
	return <span>Hello World!</span>
}
`

	const [cause, setCause] = useState(undefined as undefined | JSX.Element)
	const [literal, setLiteral] = useState(defaultProgram)
	const [code, setCode] = useState("")
	const preview = useRef(null)

	useEffect(() => {
		if (preview.current === undefined) return
		// eslint-disable-next-line no-new-func
		new Function("React", "ReactDOM", "runtime", code)(React, ReactDOM, { parent: preview.current })
	}, [code])

	return (
		<layouts.containers.flex flexDirection="column" className="codegen" width="inherit" height="auto" m="auto" p="20px">
			<layouts.containers.box styled flex="1" mr="10px">
				<inputs.TextArea
					value={literal}
					onChange={(evt) => {
						const current = evt.target.value
						setLiteral(current)
						compile(current)
							.then((compiled) => {
								setCode(compiled)
								setCause(undefined)
							})
							.catch((cause) => {
								setCause(<errors.Display>{cause.message}</errors.Display>)
							})
					}}
				/>
			</layouts.containers.box>
			<errors.overlay cause={cause} flex="1">
				<layouts.containers.box styled ref={preview} className="preview" />
			</errors.overlay>
		</layouts.containers.flex>
	)
}
