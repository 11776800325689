import React from "react"
import classnames from "classnames"
import styled from "@emotion/styled"
import * as system from "styled-system"
import * as layouts from "layouts"

interface Props extends system.BackgroundProps, layouts.containers.FlexProps {
	className?: string
}

const Basic = styled(layouts.containers.flex)`
	height: 100px;
	align-items: center;
	padding: 0px 120px;
	background: transparent;
	text-decoration: none;
	justify-content: space-between;
	${system.space}
`

export function Container(props: React.PropsWithChildren<Props>): JSX.Element {
	const { className, ...rest } = props

	return (
		<Basic className={classnames("navigation", className)} {...rest}>
			{props.children}
		</Basic>
	)
}

const Gradient = styled(Basic)`
	border-radius: 20px;
	box-shadow: -1px 5px 16px rgba(0, 0, 0, 0.13);
	background-image: ${layouts.theme.colors.nova};
	${system.space}
`

export function GradientContainer(props: React.PropsWithChildren<Props>): JSX.Element {
	const { className, ...rest } = props

	return (
		<Gradient className={classnames("navigation", className)} {...rest}>
			{props.children}
		</Gradient>
	)
}
