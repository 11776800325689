import * as fingerprint from "../fingerprint"
import * as offers from "../offers"
import Discount from "../discount"
import impression from "../impression"
import Client from "../client"

interface api {
	load(payload: { cta_id: number; callback?(cta: { id: number }, variant: unknown, embed: unknown): void }): void
}

interface globaltype {
	convertflow: api
}

export interface cfg {
	discount: Discount
	loadable: number[]
}

export interface option {
	(cfg: cfg): cfg
}

export function noop(d: Discount, ...options: option[]): offers.Display {
	return {
		discounts: () => [d],
		async impression(ctx: Client, imp: impression, uid: fingerprint.ID): Promise<impression> {
			return Promise.resolve({
				...imp,
				coupon: d.code.digest,
			} as impression)
		},
	}
}

export function zero(d: Discount, ...options: option[]): cfg {
	return options.reduce((cfg, opt) => opt(cfg), {
		discount: d,
		loadable: [],
	} as cfg)
}

export function discount(d: Discount, ...options: option[]): offers.Display {
	const cfg = zero(d, ...options)
	return {
		discounts: () => [d],
		async impression(ctx: Client, imp: impression, uid: fingerprint.ID): Promise<impression> {
			return new Promise((resolve: (value: api) => void) => {
				let ctx = globalThis as unknown as globaltype // eslint-disable-line  no-undef

				if (ctx.convertflow) {
					return resolve(ctx.convertflow)
				}

				window.addEventListener("cfReady", function (event) {
					// log latest conversion event in console
					resolve(ctx.convertflow)
				})
			}).then((ctx: api): Promise<impression> => {
				return new Promise((resolve, reject) => {
					let recorded = false // only record a single impression.
					window.addEventListener("cfView", function (event) {
						if (recorded) return
						recorded = true
						resolve({
							...imp,
							coupon: d.code.digest,
						} as impression)
					})

					cfg.loadable.forEach((id) => ctx.load({ cta_id: id }))
				})
			})
		},
	}
}

export const options = {
	cta(...loadable: number[]): option {
		return (cfg: cfg): cfg => {
			return {
				...cfg,
				loadable: loadable,
			}
		}
	},
}
