import { useEffect, useState } from "react"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as profiles from "profiles"
import * as errors from "errors"
import * as authzc from "authzcached"
import { Context } from "./profiles.table.context"
import { default as Row } from "./profiles.table.row"
import * as inputs from "inputs"
import * as timex from "timex"

interface props extends layouts.containers.FlexProps {
	context: Context
}

export function withClient(props: props): JSX.Element {
	return <Table {...props} />
}

export default function Table(props: props): JSX.Element {
	const { context, ...rest } = props
	const [metaauthz] = useState(authzc.useCache((cached) => cached.meta))
	const [focused, setFocused] = useState(undefined as profiles.Profile | undefined)
	const [cause, setCause] = useState(undefined as undefined | JSX.Element)

	const [nextReq, setNextReq] = useState(profiles.api.ZeroSearch())
	const [req, setReq] = useState(profiles.api.ZeroSearch())
	const [pset, setProfiles] = useState([] as profiles.Profile[])

	const infiso = timex.infinity().toISO()
	const neginfiso = timex.negInfinity().toISO()

	useEffect(() => {
		metaauthz.token().then((token) => {
			profiles.api
				.search(req, httpx.options.bearer(token.bearer))
				.then((resp) => {
					const pset = resp.items || ([] as profiles.Profile[])
					setProfiles(pset)
					setNextReq({
						...nextReq,
						offset: pset.length === 0 || pset.length < req.limit ? uuid.NIL : pset[pset.length - 1].id,
					})
				})
				.catch((cause) => {
					setCause(<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />)
				})
		})
	}, [req])

	return (
		<layouts.containers.flex flexDirection="column" {...rest}>
			<layouts.containers.flex py="25px" height="60px">
				<layouts.containers.flex width="30%" pr="15px">
					<inputs.Text
						placeholder="Search"
						defaultValue={req.query}
						onChange={(evt) => {
							setReq({ ...req, query: evt.currentTarget.value })
						}}
					/>
				</layouts.containers.flex>
				<layouts.containers.flex width="inherit" pb="18px" mr="15px">
					<layouts.calendars.DateRange
						startDate={
							req.created?.oldest && req.created?.oldest !== neginfiso ? new Date(req.created.oldest) : undefined
						}
						endDate={req.created?.newest && req.created?.newest !== infiso ? new Date(req.created?.newest) : undefined}
						changeStart={(d: Date) => {
							const upd = {
								newest: infiso,
								...req.created,
								oldest: d.toISOString(),
							}

							setReq({ ...req, created: upd })
						}}
						changeEnd={(d: Date) => {
							const upd = {
								oldest: neginfiso,
								...req.created,
								newest: d.toISOString(),
							}

							setReq({ ...req, created: upd })
						}}
					/>
				</layouts.containers.flex>
				<layouts.containers.flex width="inherit" display="inline" pr="15px">
					<inputs.Select
						value={req.enabled}
						onChange={(evt) => setReq({ ...req, enabled: parseInt(evt.currentTarget.value) })}
					>
						<option value={0}>All</option>
						<option value={1}>Enabled</option>
						<option value={2}>Disabled</option>
					</inputs.Select>
				</layouts.containers.flex>
				<layouts.containers.flex width="inherit" display="inline">
					<layouts.buttons.primary height="50px" width="100px" onClick={() => setReq(profiles.api.ZeroSearch())}>
						Reset
					</layouts.buttons.primary>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.tables.container flex="1">
				<errors.overlay styled cause={cause} width="inherit" height="inherit">
					{context.creating && (
						<layouts.containers.flex
							styled
							p="10px"
							mb="5px"
							flexDirection="column"
							borderRadius={layouts.theme.borders.radius.standard}
						>
							<layouts.containers.span mb="5px" mx="auto">
								Create New
							</layouts.containers.span>
							<profiles.Edit
								cancel={<span>Cancel</span>}
								submit={<span>Create</span>}
								current={context.creating}
								onChange={async (created) => {
									if (created === undefined) {
										return Promise.resolve(created).finally(() => {
											context.replace({
												...context,
												creating: undefined,
											})
										})
									}

									return metaauthz
										.token()
										.then((token) => profiles.api.create({ profile: created }, httpx.options.bearer(token.bearer)))
										.then((r) => {
											const tmp = r.profile || created
											setProfiles([...pset, tmp])
											context.replace({
												...context,
												creating: undefined,
											})
											return r.profile
										})
								}}
							/>
						</layouts.containers.flex>
					)}
					<table>
						<thead>
							<tr>
								<th>name</th>
								<th>email</th>
								<th>
									<layouts.containers.flex>
										<layouts.containers.span mx="auto">Enabled</layouts.containers.span>
									</layouts.containers.flex>
								</th>
								<th>created</th>
								<th>updated</th>
							</tr>
						</thead>
						<tbody>
							{pset.map((p) => (
								<Row
									key={p.id}
									current={p}
									focused={p.id === focused?.id}
									onClick={() => (p.id === focused?.id ? setFocused(undefined) : setFocused(p))}
									onChange={async (upd) => {
										if (upd === undefined) {
											setFocused(upd)
											return
										}

										return metaauthz
											.token()
											.then((token) => {
												return profiles.api.update(
													{
														profile: upd,
													},
													httpx.options.bearer(token.bearer),
												)
											})
											.then((r) => {
												const updated = r.profile || upd
												setProfiles(pset.map((p) => (p.id === upd.id ? updated : p)))
												return updated
											})
									}}
								/>
							))}
						</tbody>
					</table>
				</errors.overlay>
				<layouts.pagination.Cursor
					ml="auto"
					mt="auto"
					py="5px"
					pr="10px"
					current={req.offset}
					advance={nextReq.offset === uuid.NIL ? undefined : nextReq.offset}
					onChange={(next) => {
						setReq({
							...nextReq,
							offset: next,
						})
					}}
				/>
			</layouts.tables.container>
		</layouts.containers.flex>
	)
}
