import * as md5x from "md5x"

export interface Code {
	digest: string
}

export function none(): Code {
	return {
		digest: "",
	}
}

export function from(s: string): Code {
	return {
		digest: md5x.string(s),
	}
}

export function literal(s: string): Code {
	return {
		digest: s,
	}
}
