import * as httputils from "httpx"

export interface GoogleShoppingIngestion {
	created_at: string
	description: string
	device: string
	updated_at: string
	disabled_at: string
	duration: string
	filter_search: string
	gl: string
	hl: string
	id: string
	processed_at: string
	product_id: string
	query: string
}

export interface GoogleShoppingIngestionResults {
	created_at: string
	extensions: string[]
	id: string
	job_id: string
	link: string
	position: string
	price: string
	source: string
	title: string
}

export interface IndexResponse {
	items: GoogleShoppingIngestion[]
}

export interface CreateResponse {
	items: GoogleShoppingIngestion[]
}

export interface IndexRequest {
	offset: string
}

export interface CreateRequest {
	duration: number
	product_id: string
	query: string
}

export interface RunRequest {
	id: string
}

export interface RunResponse {
	items: GoogleShoppingIngestionResults[]
}

export function search(req: IndexRequest, ...options: httputils.option[]): Promise<IndexResponse> {
	return httputils.get(`${httputils.urlstorage.host()}/ca/promotions/google`, req, ...options)
}

export function create(req: CreateRequest, ...options: httputils.option[]): Promise<CreateResponse> {
	return httputils.post(`${httputils.urlstorage.host()}/ca/promotions/google/create`, req, ...options)
}

export function runjob(req: RunRequest, ...options: httputils.option[]): Promise<RunResponse> {
	return httputils.get(`${httputils.urlstorage.host()}/ca/promotions/google/${req.id}`, req, ...options)
}

export interface HistorySearchRequest {
	offset: string
}

export interface HistorySearchResponse {
	items: GoogleShoppingIngestionResults[]
	cursor: HistorySearchRequest
}

export const history = {
	search: (id: string, req: HistorySearchRequest, ...options: httputils.option[]): Promise<HistorySearchResponse> => {
		return httputils.get(`${httputils.urlstorage.host()}/ca/promotions/google/${id}/history/`, req, ...options)
	},
}
