import qs from "qs"
import _JSONBig from "json-bigint"
export { default as qs } from "query-string"

const JSONBig = _JSONBig({ useNativeBigInt: true })

// localfetch is used to wrap fetch
let localfetch = fetch as fetcher

interface xhrfields {
	withCredentials: boolean
}

export interface Request {
	method: string
	mode: string
	headers: { [key: string]: string }
	credentials: string
	xhrFields: xhrfields
	data: string | Record<string, unknown>
	processData: boolean
	dataType: string
}

export interface option {
	(request: Request): void
}

export const status = {
	BadRequest: 400,
	Unauthorized: 401,
	Forbidden: 403,
	NotFound: 404,
	TooManyRequests: 429,
	ServiceUnavailable: 503,
}

export const accept = {
	json: (request: Request) => (request.headers["Accept"] = "application/json; charset=utf-8"),
}

export const content = {
	json: (request: Request): void => {
		request.headers["Content-Type"] = "application/json"
	},
	urlencoded: (request: Request): void => {
		request.headers["Content-Type"] = "application/x-www-form-urlencoded"
	},
	formdata: (request: Request): void => {
		request.headers["Content-Type"] = "multipart/form-data"
	},
}

export const methods = {
	patch: (request: Request): void => {
		request.method = "PATCH"
	},
	put: (request: Request): void => {
		request.method = "PUT"
	},
	post: (request: Request): void => {
		request.method = "POST"
	},
	get: (request: Request): void => {
		request.method = "GET"
	},
	delete: (request: Request): void => {
		request.method = "DELETE"
	},
}

export const modes = {
	cors: (request: Request): void => {
		request.mode = "cors"
	},
}

export const options = {
	noop(request: Request): void {},
	bearer(token: string): option {
		return (request: Request): void => {
			if (token) {
				request.headers["Authorization"] = `BEARER ${token}`
			}
		}
	},
}
export function optionHeaders(headers: { [key: string]: string }) {
	return (request: Request): void => {
		request.headers = {
			...(request.headers || {}),
			...headers,
		}
	}
}

export function optionXHRFields(options: Partial<xhrfields>) {
	return (request: Request): void => {
		request.xhrFields = {
			...request.xhrFields,
			...options,
		}
	}
}

export function optionDataType(s: string) {
	return (request: Request): void => {
		request.dataType = s
	}
}

export function optionCORS(mode: string) {
	return (request: Request): void => {
		request.mode = mode
	}
}

export function get<T>(path: string, data = {}, ...options: option[]): Promise<T> {
	return request<T>(path, data, methods.get, content.urlencoded, ...options)
}

export function post<T>(path: string, data = {}, ...options: option[]): Promise<T> {
	return request<T>(path, data, methods.post, content.json, ...options)
}

export function put<T>(path: string, data = {}, ...options: option[]): Promise<T> {
	return request<T>(path, data, methods.put, content.json, ...options)
}

export function patch<T>(path: string, data = {}, ...options: option[]): Promise<T> {
	return request<T>(path, data, methods.patch, content.json, ...options)
}

export function destroy<T>(path: string, data = {}, ...options: option[]): Promise<T> {
	return request<T>(path, data, methods.delete, ...options)
}

// generic ajax requests
export function request<T>(path: string, data = {}, ...options: option[]): Promise<T> {
	const request = options.reduce(
		(request, opt) => {
			opt(request)
			return request
		},
		{
			headers: {},
		} as Request,
	)

	switch (request.headers["Content-Type"]) {
		case "application/x-www-form-urlencoded":
			path = `${path}?${qs.stringify(data)}`
			break
		case "application/json":
			;(request as unknown as { body: string }).body = JSONBig.stringify(data)
			break
		case "multipart/form-data":
			delete request.headers["Content-Type"]
			;(request as unknown as { body: unknown }).body = data
			break
		default:
			request.data = data
	}

	// eslint-disable-next-line no-undef
	return localfetch(path, request as RequestInit).then((response: { ok: boolean; json: () => unknown }) => {
		if (response.ok) {
			return response.json() as Promise<T>
		}
		throw response
	})
}

// escape hatch to native fetch.
export async function _fetch(path: string, ...options: option[]): Promise<Response> {
	const request = options.reduce(
		(request, opt) => {
			opt(request)
			return request
		},
		{
			headers: {},
		} as Request,
	)

	// eslint-disable-next-line no-undef
	return localfetch(path, request as RequestInit).then((response: Response) => {
		if (response.ok) {
			return response
		}
		throw response
	})
}

export interface middleware {
	// eslint-disable-next-line no-undef
	(original: fetcher): fetcher
}

interface fetcher {
	// eslint-disable-next-line no-undef
	(input: RequestInfo, init?: RequestInit): Promise<Response>
}

// eslint-disable-next-line no-undef
export function debugrequest(original: fetcher): fetcher {
	let reqcount = -1
	// eslint-disable-next-line no-undef
	return async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
		reqcount += 1
		console.log(`request initiated #${reqcount}`, input, init)
		return original(input, init).finally(() => console.log(`request completed #${reqcount}`, input, init))
	}
}

export function unauthenticatedrequest(error: (cause: unknown) => Response): middleware {
	// eslint-disable-next-line no-undef
	return (original: fetcher): fetcher => {
		// eslint-disable-next-line no-undef
		return (input: RequestInfo, init?: RequestInit): Promise<Response> => {
			return original(input, init).catch(errors.authorization(error))
		}
	}
}

/**
 * global intercept. *not* for general usage.
 * valid use cases: app wide request handling - unauthenticated. logging
 * @param middlewares a set of intercepters to apply
 */
export function intercept(...middlewares: middleware[]): void {
	localfetch = middlewares.reduce((handle, m) => m(handle), localfetch)
}

// upload file data to the given destination.
export function upload<T>(path: string, data: FormData, ...options: option[]): Promise<T> {
	options = [methods.post, content.formdata].concat(...options)

	return request(path, data, ...options)
}

export const errors = {
	notFound<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }): T => {
			if (error.status !== status.NotFound) {
				throw error
			}
			return onErr(error)
		}
	},
	authorization<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }): T => {
			if (error.status !== status.Unauthorized) {
				throw error
			}
			return onErr(error)
		}
	},
	forbidden<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }) => {
			if (error.status !== status.Forbidden) {
				throw error
			}
			return onErr(error)
		}
	},
	unavailable<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }) => {
			if (error.status !== status.ServiceUnavailable) {
				throw error
			}
			return onErr(error)
		}
	},
	ratelimited<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }) => {
			if (error.status !== status.TooManyRequests) {
				throw error
			}
			return onErr(error)
		}
	},
}

export * as urlstorage from "./urlstorage"
export { Client } from "./client"
