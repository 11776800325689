import { useEffect, useState } from "react"
import classnames from "classnames"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as timex from "timex"
import * as profiles from "./profile"

interface props {
	current: profiles.Profile
	readonly: boolean
	onChange(p: profiles.Profile): Promise<profiles.Profile>
}

export default function Edit(props: props & layouts.containers.FlexProps): JSX.Element {
	const { current, onChange, className, ...rest } = props

	const [cause, setCause] = useState(undefined as unknown | undefined)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setCause(undefined)
	}, [current])

	return (
		<layouts.forms.Container
			className={classnames(className, "profile disable toggle")}
			px="10px"
			pt="10px"
			borderRadius={layouts.theme.borders.radius.standard}
			border={layouts.theme.borders.grey.darkestalpha}
			cause={cause}
			loading={loading}
			{...rest}
		>
			<layouts.forms.Group mb="10px">
				<layouts.forms.Label mr="auto">Login</layouts.forms.Label>
				<inputs.toggles.Display
					checked={profiles.enabled(current)}
					onClick={() => {
						const disabled = profiles.enabled(current) ? timex.local().toISO() : timex.infinity().toISO()
						setLoading(true)
						onChange({
							...current,
							disabled_manually_at: disabled,
							disabled_pending_approval_at: profiles.enabled(current) ? current.disabled_pending_approval_at : disabled,
						}).finally(() => setLoading(false))
					}}
				/>
			</layouts.forms.Group>
		</layouts.forms.Container>
	)
}
