import md5 from "md5"

export function compute<T>(
	key: string,
	digest: (current: T) => string,
	m: (current: T, computed: number) => void,
	...v: T[]
): void {
	for (let i = 0; i < v.length; i++) {
		const current = v[i]
		const c = md5(key + digest(current))
		m(current, parseInt(c, 16))
	}
}

export function Max<T>(key: string, digest: (current: T) => string, ...values: T[]): T {
	let max = 0
	let v = values[0] // this result will be tossed out.

	compute(
		key,
		digest,
		(current: T, computed: number) => {
			if (max < computed) {
				max = computed
				v = current
			}
		},
		...values,
	)

	return v
}
