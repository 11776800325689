import React from "react"
import styled from "@emotion/styled"
import * as containers from "./containers"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

interface DateProps {
	selected: Date
	onChange(d: Date): void
}

export function Date(props: DateProps): JSX.Element {
	const { selected, onChange } = props

	return (
		<React.Fragment>
			<DatePicker selected={selected} onChange={onChange} />
		</React.Fragment>
	)
}

interface DateRangeProps {
	startDate?: Date
	endDate?: Date
	changeStart(d: Date): void
	changeEnd(d: Date): void
}

const Container = styled(containers.flex)`
	.react-datepicker-wrapper {
		flex: 1;

		.react-datepicker__input-container {
			height: 100%;

			input {
				border-radius: 0.5em;
				height: 100%;
			}
		}
	}
`
export function DateRange(props: DateRangeProps & containers.FlexProps): JSX.Element {
	const { startDate, endDate, changeStart, changeEnd, ...rest } = props

	return (
		<Container {...rest}>
			<DatePicker
				selected={startDate}
				onChange={(date: Date) => changeStart(date)}
				selectsStart
				startDate={startDate}
				endDate={endDate}
			/>
			<containers.span mx="5px" />
			<DatePicker
				selected={endDate}
				onChange={(date: Date) => changeEnd(date)}
				selectsEnd
				startDate={startDate}
				endDate={endDate}
				minDate={startDate}
			/>
		</Container>
	)
}
