/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandguard.text.proto" (package "brandguard.Text", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DateRange } from "./meta.search_pb";
import { Prediction } from "./brandguard_pb";
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Text.TextEvent
 */
export interface TextEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: brandguard.Prediction approved = 4;
     */
    approved: Prediction;
    /**
     * @generated from protobuf field: brandguard.Prediction observation = 5;
     */
    observation: Prediction;
    /**
     * @generated from protobuf field: double confidence = 1000;
     */
    confidence: number;
    /**
     * @generated from protobuf field: double onbrand = 1004;
     */
    onbrand: number;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Text.TextMedia
 */
export interface TextMedia {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string uploaded_by = 4 [json_name = "uploaded_by"];
     */
    uploaded_by: string;
    /**
     * @generated from protobuf field: string md5 = 5;
     */
    md5: string;
    /**
     * @generated from protobuf field: string mimetype = 6;
     */
    mimetype: string;
    /**
     * @generated from protobuf field: string mimetype_md5 = 7 [json_name = "mimetype_md5"];
     */
    mimetype_md5: string;
    /**
     * @generated from protobuf field: string created_at = 8 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 9 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: string tombstoned_at = 10 [json_name = "tombstoned_at"];
     */
    tombstoned_at: string;
    /**
     * @generated from protobuf field: string scored_at = 11 [json_name = "scored_at"];
     */
    scored_at: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Text.TextUploadRequest
 */
export interface TextUploadRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Text.TextUploadResponse
 */
export interface TextUploadResponse {
    /**
     * @generated from protobuf field: brandguard.Text.TextMedia media = 1;
     */
    media?: TextMedia;
    /**
     * @generated from protobuf field: brandguard.Text.TextEvent event = 2;
     */
    event?: TextEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Text.TextSearchRequest
 */
export interface TextSearchRequest {
    /**
     * @generated from protobuf field: string offset = 1;
     */
    offset: string;
    /**
     * @generated from protobuf field: string brand_id = 4 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: meta.DateRange created = 5;
     */
    created?: DateRange;
    /**
     * @generated from protobuf field: uint64 limit = 6;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Text.TextSearchResponseItem
 */
export interface TextSearchResponseItem {
    /**
     * @generated from protobuf field: brandguard.Text.TextMedia media = 1;
     */
    media?: TextMedia;
    /**
     * @generated from protobuf field: brandguard.Text.TextEvent event = 2;
     */
    event?: TextEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Text.TextSearchResponse
 */
export interface TextSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.Text.TextSearchResponseItem items = 1;
     */
    items: TextSearchResponseItem[];
    /**
     * @generated from protobuf field: brandguard.Text.TextSearchRequest next = 2;
     */
    next?: TextSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Text.TextFindRequest
 */
export interface TextFindRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Text.TextFindResponse
 */
export interface TextFindResponse {
    /**
     * @generated from protobuf field: brandguard.Text.TextMedia media = 1;
     */
    media?: TextMedia;
    /**
     * @generated from protobuf field: brandguard.Text.TextEvent event = 2;
     */
    event?: TextEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Text.TextPatchRequest
 */
export interface TextPatchRequest {
    /**
     * @generated from protobuf field: brandguard.Text.TextMedia media = 1;
     */
    media?: TextMedia;
    /**
     * @generated from protobuf field: brandguard.Text.TextEvent event = 2;
     */
    event?: TextEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Text.TextPatchResponse
 */
export interface TextPatchResponse {
    /**
     * @generated from protobuf field: brandguard.Text.TextMedia media = 1;
     */
    media?: TextMedia;
    /**
     * @generated from protobuf field: brandguard.Text.TextEvent event = 2;
     */
    event?: TextEvent;
}
// @generated message type with reflection information, may provide speed optimized methods
class TextEvent$Type extends MessageType<TextEvent> {
    constructor() {
        super("brandguard.Text.TextEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "approved", kind: "enum", T: () => ["brandguard.Prediction", Prediction] },
            { no: 5, name: "observation", kind: "enum", T: () => ["brandguard.Prediction", Prediction] },
            { no: 1000, name: "confidence", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1004, name: "onbrand", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<TextEvent>): TextEvent {
        const message = { id: "", account_id: "", brand_id: "", approved: 0, observation: 0, confidence: 0, onbrand: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextEvent): TextEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* brandguard.Prediction approved */ 4:
                    message.approved = reader.int32();
                    break;
                case /* brandguard.Prediction observation */ 5:
                    message.observation = reader.int32();
                    break;
                case /* double confidence */ 1000:
                    message.confidence = reader.double();
                    break;
                case /* double onbrand */ 1004:
                    message.onbrand = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* brandguard.Prediction approved = 4; */
        if (message.approved !== 0)
            writer.tag(4, WireType.Varint).int32(message.approved);
        /* brandguard.Prediction observation = 5; */
        if (message.observation !== 0)
            writer.tag(5, WireType.Varint).int32(message.observation);
        /* double confidence = 1000; */
        if (message.confidence !== 0)
            writer.tag(1000, WireType.Bit64).double(message.confidence);
        /* double onbrand = 1004; */
        if (message.onbrand !== 0)
            writer.tag(1004, WireType.Bit64).double(message.onbrand);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Text.TextEvent
 */
export const TextEvent = new TextEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextMedia$Type extends MessageType<TextMedia> {
    constructor() {
        super("brandguard.Text.TextMedia", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "uploaded_by", kind: "scalar", localName: "uploaded_by", jsonName: "uploaded_by", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "md5", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "mimetype", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "mimetype_md5", kind: "scalar", localName: "mimetype_md5", jsonName: "mimetype_md5", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "tombstoned_at", kind: "scalar", localName: "tombstoned_at", jsonName: "tombstoned_at", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "scored_at", kind: "scalar", localName: "scored_at", jsonName: "scored_at", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TextMedia>): TextMedia {
        const message = { id: "", account_id: "", brand_id: "", uploaded_by: "", md5: "", mimetype: "", mimetype_md5: "", created_at: "", updated_at: "", tombstoned_at: "", scored_at: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextMedia>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextMedia): TextMedia {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string uploaded_by = 4 [json_name = "uploaded_by"];*/ 4:
                    message.uploaded_by = reader.string();
                    break;
                case /* string md5 */ 5:
                    message.md5 = reader.string();
                    break;
                case /* string mimetype */ 6:
                    message.mimetype = reader.string();
                    break;
                case /* string mimetype_md5 = 7 [json_name = "mimetype_md5"];*/ 7:
                    message.mimetype_md5 = reader.string();
                    break;
                case /* string created_at = 8 [json_name = "created_at"];*/ 8:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 9 [json_name = "updated_at"];*/ 9:
                    message.updated_at = reader.string();
                    break;
                case /* string tombstoned_at = 10 [json_name = "tombstoned_at"];*/ 10:
                    message.tombstoned_at = reader.string();
                    break;
                case /* string scored_at = 11 [json_name = "scored_at"];*/ 11:
                    message.scored_at = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextMedia, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string uploaded_by = 4 [json_name = "uploaded_by"]; */
        if (message.uploaded_by !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.uploaded_by);
        /* string md5 = 5; */
        if (message.md5 !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.md5);
        /* string mimetype = 6; */
        if (message.mimetype !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.mimetype);
        /* string mimetype_md5 = 7 [json_name = "mimetype_md5"]; */
        if (message.mimetype_md5 !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mimetype_md5);
        /* string created_at = 8 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 9 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.updated_at);
        /* string tombstoned_at = 10 [json_name = "tombstoned_at"]; */
        if (message.tombstoned_at !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.tombstoned_at);
        /* string scored_at = 11 [json_name = "scored_at"]; */
        if (message.scored_at !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.scored_at);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Text.TextMedia
 */
export const TextMedia = new TextMedia$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextUploadRequest$Type extends MessageType<TextUploadRequest> {
    constructor() {
        super("brandguard.Text.TextUploadRequest", []);
    }
    create(value?: PartialMessage<TextUploadRequest>): TextUploadRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextUploadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextUploadRequest): TextUploadRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TextUploadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Text.TextUploadRequest
 */
export const TextUploadRequest = new TextUploadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextUploadResponse$Type extends MessageType<TextUploadResponse> {
    constructor() {
        super("brandguard.Text.TextUploadResponse", [
            { no: 1, name: "media", kind: "message", T: () => TextMedia },
            { no: 2, name: "event", kind: "message", T: () => TextEvent }
        ]);
    }
    create(value?: PartialMessage<TextUploadResponse>): TextUploadResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextUploadResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextUploadResponse): TextUploadResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.Text.TextMedia media */ 1:
                    message.media = TextMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.Text.TextEvent event */ 2:
                    message.event = TextEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextUploadResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.Text.TextMedia media = 1; */
        if (message.media)
            TextMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.Text.TextEvent event = 2; */
        if (message.event)
            TextEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Text.TextUploadResponse
 */
export const TextUploadResponse = new TextUploadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextSearchRequest$Type extends MessageType<TextSearchRequest> {
    constructor() {
        super("brandguard.Text.TextSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created", kind: "message", T: () => DateRange },
            { no: 6, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<TextSearchRequest>): TextSearchRequest {
        const message = { offset: "", brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextSearchRequest): TextSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string offset */ 1:
                    message.offset = reader.string();
                    break;
                case /* string brand_id = 4 [json_name = "brand_id"];*/ 4:
                    message.brand_id = reader.string();
                    break;
                case /* meta.DateRange created */ 5:
                    message.created = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* uint64 limit */ 6:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string offset = 1; */
        if (message.offset !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.offset);
        /* string brand_id = 4 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.brand_id);
        /* meta.DateRange created = 5; */
        if (message.created)
            DateRange.internalBinaryWrite(message.created, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint64 limit = 6; */
        if (message.limit !== 0n)
            writer.tag(6, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Text.TextSearchRequest
 */
export const TextSearchRequest = new TextSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextSearchResponseItem$Type extends MessageType<TextSearchResponseItem> {
    constructor() {
        super("brandguard.Text.TextSearchResponseItem", [
            { no: 1, name: "media", kind: "message", T: () => TextMedia },
            { no: 2, name: "event", kind: "message", T: () => TextEvent }
        ]);
    }
    create(value?: PartialMessage<TextSearchResponseItem>): TextSearchResponseItem {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextSearchResponseItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextSearchResponseItem): TextSearchResponseItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.Text.TextMedia media */ 1:
                    message.media = TextMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.Text.TextEvent event */ 2:
                    message.event = TextEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextSearchResponseItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.Text.TextMedia media = 1; */
        if (message.media)
            TextMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.Text.TextEvent event = 2; */
        if (message.event)
            TextEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Text.TextSearchResponseItem
 */
export const TextSearchResponseItem = new TextSearchResponseItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextSearchResponse$Type extends MessageType<TextSearchResponse> {
    constructor() {
        super("brandguard.Text.TextSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TextSearchResponseItem },
            { no: 2, name: "next", kind: "message", T: () => TextSearchRequest }
        ]);
    }
    create(value?: PartialMessage<TextSearchResponse>): TextSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextSearchResponse): TextSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.Text.TextSearchResponseItem items */ 1:
                    message.items.push(TextSearchResponseItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.Text.TextSearchRequest next */ 2:
                    message.next = TextSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.Text.TextSearchResponseItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            TextSearchResponseItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.Text.TextSearchRequest next = 2; */
        if (message.next)
            TextSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Text.TextSearchResponse
 */
export const TextSearchResponse = new TextSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextFindRequest$Type extends MessageType<TextFindRequest> {
    constructor() {
        super("brandguard.Text.TextFindRequest", []);
    }
    create(value?: PartialMessage<TextFindRequest>): TextFindRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextFindRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextFindRequest): TextFindRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TextFindRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Text.TextFindRequest
 */
export const TextFindRequest = new TextFindRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextFindResponse$Type extends MessageType<TextFindResponse> {
    constructor() {
        super("brandguard.Text.TextFindResponse", [
            { no: 1, name: "media", kind: "message", T: () => TextMedia },
            { no: 2, name: "event", kind: "message", T: () => TextEvent }
        ]);
    }
    create(value?: PartialMessage<TextFindResponse>): TextFindResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextFindResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextFindResponse): TextFindResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.Text.TextMedia media */ 1:
                    message.media = TextMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.Text.TextEvent event */ 2:
                    message.event = TextEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextFindResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.Text.TextMedia media = 1; */
        if (message.media)
            TextMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.Text.TextEvent event = 2; */
        if (message.event)
            TextEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Text.TextFindResponse
 */
export const TextFindResponse = new TextFindResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextPatchRequest$Type extends MessageType<TextPatchRequest> {
    constructor() {
        super("brandguard.Text.TextPatchRequest", [
            { no: 1, name: "media", kind: "message", T: () => TextMedia },
            { no: 2, name: "event", kind: "message", T: () => TextEvent }
        ]);
    }
    create(value?: PartialMessage<TextPatchRequest>): TextPatchRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextPatchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextPatchRequest): TextPatchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.Text.TextMedia media */ 1:
                    message.media = TextMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.Text.TextEvent event */ 2:
                    message.event = TextEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextPatchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.Text.TextMedia media = 1; */
        if (message.media)
            TextMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.Text.TextEvent event = 2; */
        if (message.event)
            TextEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Text.TextPatchRequest
 */
export const TextPatchRequest = new TextPatchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextPatchResponse$Type extends MessageType<TextPatchResponse> {
    constructor() {
        super("brandguard.Text.TextPatchResponse", [
            { no: 1, name: "media", kind: "message", T: () => TextMedia },
            { no: 2, name: "event", kind: "message", T: () => TextEvent }
        ]);
    }
    create(value?: PartialMessage<TextPatchResponse>): TextPatchResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextPatchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextPatchResponse): TextPatchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.Text.TextMedia media */ 1:
                    message.media = TextMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.Text.TextEvent event */ 2:
                    message.event = TextEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextPatchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.Text.TextMedia media = 1; */
        if (message.media)
            TextMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.Text.TextEvent event = 2; */
        if (message.event)
            TextEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Text.TextPatchResponse
 */
export const TextPatchResponse = new TextPatchResponse$Type();
