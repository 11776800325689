import React from "react"
import * as adgencontext from "ads/facebook/adgen.link.context"
import * as adgen from "ads/facebook/ads.facebook.adgen"
import * as api from "ads/facebook/api"
import * as adsadgen from "ads/adgen"

export interface suggested extends adgen.Suggested {
	selected: boolean
	imgurl: string
}

export namespace suggestions {
	export function zero(d: Partial<suggested> = {}): suggested {
		return {
			content: api.ads.link.zero(),
			metadata: api.ads.link.metadata(),
			selected: false,
			imgurl: "",
			...d,
		}
	}
}

export interface context {
	adgenctx: adgencontext.context
	ads: suggested[]
	buffered: suggested[]
}

export interface mutation {
	(c: context): context
}

export namespace mutate {
	export function apply(gctx: context, ...mset: mutation[]): context {
		return mset.reduce((ctx, m) => m(ctx), gctx)
	}

	export function replace(r: suggested): mutation {
		return (c: context): context => {
			const mut = c.adgenctx?.content?.id === r.content?.id ? mutate.current.update(r)(c) : c
			return {
				...mut,
				ads: c.ads.map((i) => (i?.content?.id === r.content?.id ? r : i)),
			}
		}
	}

	export function reject(r: suggested): mutation {
		return (c: context): context => {
			const mut = c.adgenctx?.content?.id === r.content?.id ? mutate.current.update(undefined)(c) : c
			return {
				...mut,
				ads: c.ads.filter((i) => i?.content?.id !== r.content?.id),
			}
		}
	}

	export const ads = {
		replace(upd: suggested[]): mutation {
			return (c: context): context => {
				return {
					...c,
					ads: upd,
				}
			}
		},
		modify(ctx: adgencontext.context): mutation {
			return (c: context): context => {
				const ads_i = c.ads.findIndex((i) => i.content?.id === ctx.content.id)
				if (ads_i < 0) {
					return c
				}
				const adsupd = c.ads
				const linkkupd = api.ads.link.zero({
					id: c.adgenctx.content.id,
					name: c.adgenctx.content.name.accepted,
					description: c.adgenctx.content.description.accepted,
					message: c.adgenctx.content.message.accepted,
					link: c.adgenctx.content.link,
					media_id: c.adgenctx.content.media.id,
					call_to_action: c.adgenctx.content.call_to_action,
				})
				adsupd[ads_i].content = linkkupd
				return {
					...c,
					ads: adsupd,
				}
			}
		},
	}

	export const buffered = {
		add(...suggested: suggested[]): mutation {
			return (c: context): context => {
				return {
					...c,
					buffered: [...c.buffered, ...suggested],
				}
			}
		},

		replace(upd: suggested[]): mutation {
			return (c: context): context => {
				return {
					...c,
					buffered: upd,
				}
			}
		},
	}

	export const current = {
		update(upd: suggested | undefined): mutation {
			return (c: context): context => {
				const cta = upd?.content?.call_to_action || { type: "" }
				const updcontent = adgencontext.form.zero({
					id: upd?.content?.id,
					description: adsadgen.text.sample(upd?.content?.description || ""),
					message: adsadgen.text.sample(upd?.content?.message || ""),
					name: adsadgen.text.sample(upd?.content?.name || ""),
					link: upd?.content?.link,
					media: {
						id: upd?.content?.media_id || "",
						url: "",
					},
					call_to_action: cta,
				})
				const mut = adgencontext.mutate.apply(
					c.adgenctx,
					adgencontext.mutate.content(updcontent),
					adgencontext.mutate.metadata(upd?.metadata || {}),
				)
				return {
					...c,
					adgenctx: mut,
				}
			}
		},
		modify(...muts: adgencontext.mutation[]): mutation {
			return (c: context): context => {
				if (c.adgenctx === undefined) return c
				const upd = adgencontext.mutate.apply(c.adgenctx, ...muts)
				return {
					...c,
					adgenctx: upd,
				}
			}
		},
	}
}

export function zero(proto: Partial<context> = {}): context {
	return {
		adgenctx: adgencontext.zero(),
		ads: [] as suggested[],
		buffered: [] as suggested[],
		...proto,
	}
}

export const Context = React.createContext(zero())

export const Provider = Context.Provider
export const Consumer = Context.Consumer
