import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { className, fill, ...rest } = props
	return (
		<svg.SVG
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="15px"
			height="15px"
			viewBox="0 0 15 15"
			version="1.1"
			fill={fill}
			{...rest}
		>
			<g id="surface1">
				<path d="M 11.601562 9.726562 C 11.601562 11.359375 10.441406 12.757812 8.328125 13.125 L 8.328125 15 L 6.523438 15 L 6.523438 13.253906 C 5.292969 13.199219 4.097656 12.867188 3.398438 12.464844 L 3.953125 10.3125 C 4.722656 10.738281 5.808594 11.121094 7.003906 11.121094 C 8.050781 11.121094 8.769531 10.71875 8.769531 9.980469 C 8.769531 9.285156 8.179688 8.839844 6.820312 8.382812 C 4.851562 7.71875 3.507812 6.800781 3.507812 5.019531 C 3.507812 3.398438 4.648438 2.132812 6.617188 1.746094 L 6.617188 0 L 8.417969 0 L 8.417969 1.617188 C 9.648438 1.671875 10.480469 1.929688 11.085938 2.222656 L 10.550781 4.304688 C 10.074219 4.101562 9.226562 3.675781 7.902344 3.675781 C 6.707031 3.675781 6.324219 4.191406 6.324219 4.707031 C 6.324219 5.3125 6.964844 5.699219 8.527344 6.289062 C 10.71875 7.058594 11.601562 8.070312 11.601562 9.726562 Z M 11.601562 9.726562 " />
			</g>
		</svg.SVG>
	)
}

export default Icon
