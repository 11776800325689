import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import * as uuid from "uuid"
import * as httputils from "httpx"
import * as errors from "errors"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as debugx from "x/debugx"
import * as pools from "ads/pools"
import * as brands from "brands"
import * as api from "./api"
import * as typography from "typography"
import { TabPrimaryButton, TabOutlineButton } from "ads/adgen/adgen.content.layouts"

interface props {
	search(req: api.SearchRequest, ...options: httputils.option[]): Promise<api.SearchResponse>
}

export function Table(props: props & layouts.containers.FlexProps): JSX.Element {
	const { search, ...rest } = props
	const pool = pools.caching.useCached()
	const brand = brands.caching.useCached()
	const navigate = useNavigate()
	const bearertoken = sessions.useToken()
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [loading, setLoading] = useState(true)
	const [focused, setFocused] = useState(api.ads.metadata.zero())
	const [rebid, setRebid] = useState("")
	const [keywords, setKeyword] = useState("")

	const [googleadreq, setGoogleAdRequest] = useState({
		pool_id: pool.id,
		offset: uuid.NIL,
	} as api.SearchRequest)

	const [results, setResults] = useState({
		next: googleadreq,
		items: [],
	} as api.SearchResponse)

	useEffect(() => {
		setLoading(true)
		search(googleadreq, bearertoken)
			.then((resp) => {
				setResults(resp)
				setCause(undefined)
			})
			.catch(
				httputils.errors.forbidden((cause) => {
					console.warn("insufficient priviledges unable to view ad pools", cause)
					setCause(
						<layouts.containers.box styled m="auto">
							<errors.Textual>you do not have permission to view ad pools</errors.Textual>
						</layouts.containers.box>,
					)
				}),
			)
			.catch((c: unknown) => {
				console.error("unable to retrieve ad pools", c)
				setCause(<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve ad pools</errors.Textual>)
			})
			.finally(() => setLoading(false))
	}, [googleadreq, search, pool])

	return (
		<layouts.containers.flex className="google" p="10px" overflow="hidden" flex="1" flexDirection="column" {...rest}>
			<layouts.containers.flex p="25px">
				<typography.h3 color={layouts.theme.colors.grey.medium}>Google Ads - Published</typography.h3>
			</layouts.containers.flex>
			<layouts.containers.flex>
				<TabPrimaryButton>Published</TabPrimaryButton>
				<TabOutlineButton onClick={() => navigate(pools.routing.ads.google.responsive(brand.id, pool.id))}>
					Responsive
				</TabOutlineButton>
			</layouts.containers.flex>
			<layouts.tables.container pb="10px" overflow="auto" flex="1">
				<errors.overlay styled cause={cause}>
					<layouts.loading.screen loading={loading}>
						<table>
							<thead>
								<tr>
									<th>Ad ID</th>
									<th>Description</th>
									<th>Updated At</th>
									<th>Created At</th>
								</tr>
							</thead>
							<tbody>
								{results.items.map((c) => (
									<React.Fragment key={c.id}>
										<layouts.tables.Row onClick={() => setFocused(c)}>
											<td>{c.id}</td>
											<td>{c.description}</td>
											<td>{layouts.tables.Timestamp(c.updated_at)}</td>
											<td>{layouts.tables.Timestamp(c.created_at)}</td>
										</layouts.tables.Row>
										{c.id === focused.id && (
											<layouts.tables.inline>
												<layouts.containers.grid gridGap="35px" width="100%" justifyContent="end">
													{debugx.alpha.enabled() && (
														<>
															<layouts.buttons.outline
																disabled={rebid === focused.id}
																onClick={() => {
																	setRebid(focused.id)
																	api.bid(focused.id, bearertoken).catch((cause) => {
																		console.warn("unable to rebid", cause)
																		setCause(
																			<layouts.containers.box styled m="auto" onClick={() => setCause(undefined)}>
																				<errors.Textual>unable to rebid</errors.Textual>
																			</layouts.containers.box>,
																		)
																		setRebid("")
																	})
																}}
															>
																Rebid
															</layouts.buttons.outline>
															<layouts.buttons.outline
																disabled={keywords === focused.id}
																onClick={() => {
																	setKeyword(focused.id)
																	api.ads.metadata.keywords.generate(focused.id, bearertoken).catch((cause) => {
																		console.warn("unable to initate keywords generation", cause)
																		setCause(
																			<layouts.containers.box styled m="auto" onClick={() => setCause(undefined)}>
																				<errors.Textual>unable to initate keywords generation</errors.Textual>
																			</layouts.containers.box>,
																		)
																		setKeyword("")
																	})
																}}
															>
																Keywords
															</layouts.buttons.outline>
														</>
													)}
												</layouts.containers.grid>
											</layouts.tables.inline>
										)}
									</React.Fragment>
								))}
							</tbody>
						</table>
					</layouts.loading.screen>
				</errors.overlay>
			</layouts.tables.container>
			<layouts.pagination.Cursor
				ml="auto"
				current={googleadreq.offset}
				advance={results.cursor?.offset === "" ? undefined : results.cursor?.offset}
				onChange={(next) => next && setGoogleAdRequest({ ...googleadreq, offset: next })}
			/>
		</layouts.containers.flex>
	)
}

Table.defaultProps = {
	search: api.search,
}
