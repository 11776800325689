import * as httpx from "httpx"

const sessionstoragekey = "egci.session"

export function localtoken(): string {
	return window.localStorage.getItem(sessionstoragekey) || ""
}

export function storelocaltoken(s: string): string {
	window.localStorage.setItem(sessionstoragekey, s)
	return s
}

interface Endpoint {
	display: string
	url: string
}

export interface Login {
	endpoints: Endpoint[]
}

/**
 * @returns the information required to build our login panel.
 */
export function login(): Promise<Login> {
	return httpx.get(`${httpx.urlstorage.host()}/authn/`)
}
