import React, { useEffect } from "react"
import styled from "@emotion/styled"
import * as sessions from "sessions"
import * as brands from "brands"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import { Image } from "media/image"
import * as mediaapi from "media/api"

interface props {
	item: api.ImageSearchResponseItem
	active?: boolean
	onCardClick(): void
	onImageUpdate(hitem: api.ImageSearchResponseItem): void
}

const Card = styled(layouts.containers.box)<{ active?: boolean }>`
	margin-bottom: auto; // to align by top
	min-width: 380px;
	min-height: 70px;
	border: ${(props) =>
		props.active ? `2px solid ${layouts.theme.colors.blue}` : `2px solid ${layouts.theme.colors.white}`};
	box-shadow: ${(props) => (props.active ? layouts.theme.boxshadow.black.wide : "unset")};
`

function isProcessing(item: api.ImageSearchResponseItem): boolean {
	return item.event?.approved !== api.Prediction.REJECTED && item.event?.approved !== api.Prediction.APPROVED
}

export default function DisplayImage(props: props & React.PropsWithChildren<unknown>): JSX.Element {
	const { item, active, onImageUpdate, onCardClick } = props
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()

	useEffect(() => {
		if (isProcessing(item)) {
			setTimeout(() => {
				api.images.training
					.find(item.media?.id!, brand.id, bearertoken)
					.then((result) => {
						onImageUpdate(result)
					})
					.catch((e) => console.log(e))
			}, 5000)
		}
	}, [item])

	return (
		<Card key={item.media?.id} active={active} onClick={onCardClick}>
			<Image
				media={mediaapi.zero({ content_digest: item.media?.md5 })}
				background={layouts.theme.colors.grey.lightest}
				opacity={isProcessing(item) ? 0.3 : 1}
			/>
		</Card>
	)
}
