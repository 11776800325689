import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import * as icons from "icons"

interface itemProps {
	item: api.ImageSearchResponseItem
	onDelete(item: api.ImageSearchResponseItem): void
}

const AccordionHeader = styled.span<{ active?: boolean }>`
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 1px;
	color: ${layouts.theme.colors.grey.light40};
`

export default function FileDetails(props: itemProps & React.PropsWithChildren<unknown>): JSX.Element {
	const { item, onDelete } = props

	return (
		<layouts.containers.flex flexDirection="column">
			<layouts.accordions.Container initOpen minHeight="25px">
				<layouts.containers.box tabIndex={0} className="accordion-containers-box">
					<layouts.accordions.Description borderBottom={`2px solid ${layouts.theme.colors.grey.light30}`}>
						<AccordionHeader active={true}>File Details</AccordionHeader>
					</layouts.accordions.Description>
					{item && (
						<layouts.accordions.Content>
							<layouts.containers.flex flexDirection="column" width="100%">
								<layouts.containers.flex py="50px" justifyContent="center">
									<layouts.buttons.unstyled
										mx="5px"
										width="150pt"
										height="34pt"
										borderRadius="37px"
										padding="0px"
										cursor="pointer"
										background={layouts.theme.colors.grey.dark10}
										color={layouts.theme.colors.white}
										onClick={() => onDelete(item)}
									>
										<layouts.containers.span verticalAlign="middle" display="inline-block">
											<icons.Trash
												fill={layouts.theme.colors.grey.dark10}
												stroke={layouts.theme.colors.white}
												width="15pt"
												height="15pt"
												ml="-30px"
											/>
										</layouts.containers.span>
										<layouts.containers.flex
											verticalAlign="middle"
											display="inline-block"
											color={layouts.theme.colors.white}
											fontSize="14px"
										>
											Remove Image
										</layouts.containers.flex>
									</layouts.buttons.unstyled>
								</layouts.containers.flex>
							</layouts.containers.flex>
						</layouts.accordions.Content>
					)}
				</layouts.containers.box>
			</layouts.accordions.Container>
		</layouts.containers.flex>
	)
}
