import React from "react"
import * as layouts from "layouts"
import * as brands from "brands"
import * as pools from "ads/pools"
import * as typography from "typography"

export default function BrandDetails(props: React.PropsWithChildren<unknown>): JSX.Element {
	const brand = brands.caching.useCached()
	const pool = pools.caching.useCached()

	return (
		<layouts.containers.flex className="details" flexDirection="column" marginY="15px" textAlign="left">
			<typography.h3 color={layouts.theme.colors.grey.medium}>Ad Pool Details</typography.h3>
			<layouts.containers.flex flexDirection="column" fontSize="12px" color={layouts.theme.colors.grey.medium}>
				<layouts.containers.flex fontWeight="400" pt="25px">
					Company Name:
				</layouts.containers.flex>
				<layouts.containers.flex fontSize="12px" fontWeight="700" pt="5px">
					{brand.description}
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex flexDirection="column" fontSize="12px" color={layouts.theme.colors.grey.medium}>
				<layouts.containers.flex fontWeight="400" pt="25px">
					Company URL:
				</layouts.containers.flex>
				<layouts.containers.flex fontWeight="700" pt="5px">
					{brand.domain}
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex flexDirection="column" fontSize="12px" color={layouts.theme.colors.grey.medium}>
				<layouts.containers.flex fontWeight="400" pt="25px">
					Budget:
				</layouts.containers.flex>
				<layouts.containers.flex fontWeight="700" pt="5px">
					<>$ {pools.api.budgets.human(pool.budget)}</>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex flexDirection="column" fontSize="12px" color={layouts.theme.colors.grey.medium}>
				<layouts.containers.flex fontWeight="400" pt="25px">
					Daily Target:
				</layouts.containers.flex>
				<layouts.containers.flex fontWeight="700" pt="5px">
					<>{pool.daily_events_target || 0}</>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex flexDirection="column" fontSize="12px" color={layouts.theme.colors.grey.medium}>
				<layouts.containers.flex fontWeight="400" pt="25px">
					Goal:
				</layouts.containers.flex>
				<layouts.containers.flex fontWeight="700" pt="5px"></layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
