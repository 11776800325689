import React, { useState, useEffect } from "react"
import * as errors from "errors"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as authzfacebook from "authz/facebook"
import * as api from "./api"
import * as sessions from "sessions"

interface props {
	credential: authzfacebook.api.Credential
	onChange(upd: undefined | api.ClientAccount): Promise<undefined | api.ClientAccount>
}

export function Create(props: React.PropsWithChildren<props & layouts.containers.FlexProps>): JSX.Element {
	const { credential, onChange, ...rest } = props

	const bearertoken = sessions.useToken()

	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as errors.Cause)

	const [businessess, setBusinessess] = useState(api.graphql.zeros.businesses())

	const [desc, setDesc] = useState("")
	const [selectedBusiness, setSelectedBusiness] = useState(undefined as api.FBBusiness | undefined)
	const [selectedAccount, setSelectedAccount] = useState(undefined as api.FBAdAccount | undefined)
	const [selectedCompaign, setSelectedCompaign] = useState(undefined as string | undefined)
	const [selectedPage, setSelectedPage] = useState(undefined as api.FBBusinessPage | undefined)

	const [adCompaignFocused, setAdCompaingFocused] = useState(false)

	const valid = desc.length > 0 && selectedBusiness && selectedAccount && selectedPage && selectedCompaign

	const handleBusinessChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const businessId = event.target.value
		const business = businessess.data.find((b) => b.id === businessId) || undefined
		setSelectedBusiness(business)
		setSelectedAccount(undefined)
		setSelectedPage(undefined)
		setAdCompaingFocused(false)
	}

	const handleAccountChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const accountId = event.target.value
		const account = (selectedBusiness?.owned_ad_accounts?.data || []).find((a) => a.id === accountId) || undefined
		setSelectedAccount(account)
		setSelectedCompaign(undefined)
		setAdCompaingFocused(false)
	}

	const handleCompaignCreate = (upd: api.FBAdCampaign) => {
		if (!selectedBusiness) return

		const account = selectedBusiness?.owned_ad_accounts?.data.find((acc) => acc.id === selectedAccount?.id!)
		if (account) {
			if (!account.campaigns) {
				account.campaigns = { data: [] }
			}
			account.campaigns.data.push(upd)
			setSelectedAccount(account)
			setSelectedCompaign(upd.id)
		}
		setAdCompaingFocused(false)
	}

	const addCompaignTrigger = "add_new_compaign"

	const handleCompaignChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const compaignId = event.target.value
		if (compaignId === addCompaignTrigger) {
			return adCompaignFocused ? undefined : setAdCompaingFocused(true)
		}
		const compaign = (selectedAccount?.campaigns?.data || []).find((a) => a.id === compaignId) || undefined
		setSelectedCompaign(compaign?.id)
		setAdCompaingFocused(false)
	}

	const handlePageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const pageId = event.target.value
		const page = (selectedBusiness?.owned_pages?.data || []).find((p) => p.id === pageId) || undefined
		setSelectedPage(page)
		setAdCompaingFocused(false)
	}

	useEffect(() => {
		api.graphql
			.my_business(bearertoken)
			.then((r) => setBusinessess(r))
			.catch((err) => console.error(err))
			.finally(() => setLoading(false))
	}, [])

	return (
		<layouts.containers.flex {...rest}>
			<layouts.containers.flex flex="1" styled>
				<layouts.forms.Container cause={cause} loading={loading} border={layouts.theme.borders.grey} {...rest}>
					<layouts.containers.flex m="auto" flexDirection="column">
						<layouts.forms.Group>
							<inputs.Text
								placeholder="Name"
								defaultValue={""}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setDesc(evt.target.value)}
							/>
						</layouts.forms.Group>

						<layouts.forms.Group height="40px">
							<select onChange={handleBusinessChange}>
								<option value="">Select a business portfolio</option>
								{businessess.data.map((b) => (
									<option key={b.id} value={b.id}>
										{b.name}
									</option>
								))}
							</select>
						</layouts.forms.Group>

						{selectedBusiness && (
							<layouts.forms.Group height="40px">
								<select onChange={handleAccountChange}>
									<option value="">Select ad account</option>
									{(selectedBusiness.owned_ad_accounts?.data || []).map((a) => (
										<option key={a.id} value={a.id}>
											{a.name}
										</option>
									))}
								</select>
							</layouts.forms.Group>
						)}
						{selectedAccount && (
							<layouts.containers.flex
								gridGap="5px"
								alignItems="start"
								background={adCompaignFocused ? layouts.theme.backgrounds.bluealpha : "unset"}
								p={adCompaignFocused ? "10px" : "unset"}
							>
								<layouts.forms.Group height="40px">
									<select onChange={handleCompaignChange} value={selectedCompaign}>
										<option value="">Select ad campaign</option>
										{(selectedAccount.campaigns?.data || []).map((a) => (
											<option key={a.id} value={a.id}>
												{a.name}
											</option>
										))}
										<option value={addCompaignTrigger}>Create new...</option>
									</select>
								</layouts.forms.Group>
								<layouts.containers.flex>
									{adCompaignFocused && (
										<layouts.containers.flex flexDirection="column">
											<layouts.containers.flex gridGap="5px" p="5px">
												<layouts.containers.flex ml="auto">
													<layouts.containers.box onClick={() => setAdCompaingFocused(false)} fontWeight="700">
														X
													</layouts.containers.box>
												</layouts.containers.flex>
											</layouts.containers.flex>
											<AddNewCompaign ad_account_id={selectedAccount.account_id} onCreate={handleCompaignCreate} />
										</layouts.containers.flex>
									)}
								</layouts.containers.flex>
							</layouts.containers.flex>
						)}

						{selectedBusiness && (
							<layouts.forms.Group height="40px">
								<select onChange={handlePageChange}>
									<option value="">Select page</option>
									{(selectedBusiness.owned_pages?.data || []).map((p) => (
										<option key={p.id} value={p.id}>
											{p.name}
										</option>
									))}
								</select>
							</layouts.forms.Group>
						)}

						<layouts.forms.Group mt="auto" mb="0px" justifyContent="right">
							<layouts.buttons.outline
								mr="10px"
								onClick={(evt) => {
									onChange(undefined)
								}}
							>
								Cancel
							</layouts.buttons.outline>
							<layouts.buttons.primary
								disabled={!valid}
								onClick={(evt) => {
									const upd = api.accounts.zero({
										authz_credentials_id: credential.id,
										description: desc,
										fb_business_id: BigInt(selectedBusiness?.id!),
										fb_account_id: BigInt(selectedAccount?.account_id!),
										fb_page_id: BigInt(selectedPage?.id!),
										fb_campaign_id: BigInt(selectedCompaign!),
									})

									setLoading(true)
									onChange(upd)
										.catch((cause) => setCause(<errors.UnknownCause cause={cause} />))
										.finally(() => setLoading(false))
								}}
							>
								Create
							</layouts.buttons.primary>
						</layouts.forms.Group>
					</layouts.containers.flex>
				</layouts.forms.Container>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

Create.defaultProps = {}

export default Create

interface addNewCompaignProps {
	ad_account_id: string
	onCreate(upd: api.FBAdCampaign): void
}

function AddNewCompaign(props: addNewCompaignProps): JSX.Element {
	const { ad_account_id, onCreate } = props
	const bearertoken = sessions.useToken()

	const [compaign, setCompaign] = useState(api.graphql.zeros.campaign({ id: ad_account_id, status: "PAUSED" }))

	const objectives = [
		"OUTCOME_APP_PROMOTION",
		"OUTCOME_AWARENESS",
		"OUTCOME_ENGAGEMENT",
		"OUTCOME_LEADS",
		"OUTCOME_SALES",
		"OUTCOME_TRAFFIC",
	]

	const categories = ["HOUSING", "FINANCIAL_PRODUCTS_SERVICES", "EMPLOYMENT", "ISSUES_ELECTIONS_POLITICS"]

	const statuses = [
		"ACTIVE",
		"PAUSED",
		"PENDING_REVIEW",
		"CREDIT_CARD_NEEDED",
		"PREAPPROVED",
		"DISABLED",
		"PENDING_PROCESS",
		"WITH_ISSUES",
	]

	const [loading, setLoading] = useState(false)

	const valid = compaign.name.length > 0 && compaign.status.length && compaign.objective.length

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = event.target

		if (checked) {
			setCompaign({ ...compaign, special_ad_categories: [...compaign.special_ad_categories, value] })
		} else {
			setCompaign({
				...compaign,
				special_ad_categories: compaign.special_ad_categories.filter((category) => category !== value),
			})
		}
	}

	return (
		<layouts.loading.screen loading={loading}>
			<layouts.containers.flex m="auto" flexDirection="column" gridGap="5px">
				<layouts.forms.Group>
					<inputs.Text
						placeholder="Name"
						defaultValue={""}
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
							setCompaign({ ...compaign, name: evt.target.value })
						}
					/>
				</layouts.forms.Group>

				<layouts.forms.Group height="40px">
					<select onChange={(evt) => setCompaign({ ...compaign, status: evt.target.value })} value={compaign.status}>
						<option value="">Select status</option>
						{statuses.map((b) => (
							<option key={b} value={b}>
								{b}
							</option>
						))}
					</select>
				</layouts.forms.Group>

				<layouts.forms.Group flexDirection="column" fontSize="12px">
					<layouts.containers.flex pb="5px">Ad categories:</layouts.containers.flex>
					<layouts.containers.flex flexDirection="column">
						<form>
							{categories.map((category) => (
								<layouts.containers.flex key={category}>
									<label>
										<input
											type="checkbox"
											value={category}
											checked={compaign.special_ad_categories.includes(category)}
											onChange={handleCheckboxChange}
										/>
										{category}
									</label>
								</layouts.containers.flex>
							))}
						</form>
					</layouts.containers.flex>
				</layouts.forms.Group>
				<layouts.forms.Group height="40px">
					<select
						onChange={(evt) => setCompaign({ ...compaign, objective: evt.target.value })}
						value={compaign.objective}
					>
						<option value="">Select objective</option>
						{objectives.map((b) => (
							<option key={b} value={b}>
								{b}
							</option>
						))}
					</select>
				</layouts.forms.Group>
				<layouts.forms.Group mt="auto" mb="0px" justifyContent="right">
					<layouts.buttons.primary
						disabled={!valid}
						onClick={(evt) => {
							setLoading(true)
							api.graphql
								.create_compaign(ad_account_id, compaign, bearertoken)
								.then(onCreate)
								.catch((e) => console.error(e))
								.finally(() => setLoading(false))
						}}
					>
						Create
					</layouts.buttons.primary>
				</layouts.forms.Group>
			</layouts.containers.flex>
		</layouts.loading.screen>
	)
}
