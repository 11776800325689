import React from "react"
import * as layouts from "layouts"
import * as api from "./api"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { Bar } from "react-chartjs-2"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

// TODO: Send chart options as props from parent
const options = {
	plugins: {
		title: {
			display: true,
			text: "Distribution of predictions grouped by offer(GID)",
		},
	},
	responsive: true,
	interaction: {
		mode: "index" as const,
		intersect: false,
	},
}

interface props {
	chartData: api.chartData
}

export default function Chart(props: React.PropsWithChildren<props>): JSX.Element {
	const { chartData } = props

	return (
		<layouts.containers.box styled width="100%">
			<Bar options={options} data={chartData} />
		</layouts.containers.box>
	)
}
