import React from "react"
import * as layouts from "layouts"

interface props extends layouts.containers.FlexProps {
	length: number
	maxLength: number
	required?: boolean
}

export function TextFieldValidation(props: props): JSX.Element {
	const { length, maxLength, required, ...rest } = props

	return (
		<layouts.containers.flex flexDirection="row" justifyContent="space-between" {...rest}>
			<layouts.containers.box>{required ? "Required" : ""}</layouts.containers.box>
			<layouts.containers.box>
				{length}/{maxLength}
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}
