import React, { useContext, useState } from "react"
import * as uuid from "uuid"

export interface ctx {
	id: string
	collapsed: boolean
	activated: boolean
	recording: number
	path: string[]
	current: string[]
	focus(path: string[]): void
	collapse(upd: boolean): void
	update(upd: Partial<ctx>): void
}

export function zero(partial: Partial<ctx> = {}): ctx {
	return {
		id: uuid.NIL,
		path: [] as string[],
		current: [] as string[],
		collapsed: false,
		activated: false,
		recording: 0,
		focus: (path: string[]) => {},
		collapse: (upd: boolean) => {},
		update: (upd: Partial<ctx>) => {},
		...partial,
	}
}

export const Context = React.createContext(zero())
export const Provider = Context.Provider

export function Navigable(props: React.PropsWithChildren<unknown>): JSX.Element {
	const [id] = useState(uuid.v4())
	const { current, path, focus, collapsed, collapse, recording, update } = useContext(Context)
	const ctx = {
		id: id,
		current: current,
		path: [...path, id],
		recording: recording,
		collapsed: collapsed,
		activated: current.includes(id),
		focus: focus,
		collapse: collapse,
		update: update,
	}

	return <Provider value={ctx}>{props.children}</Provider>
}
