import styled from "@emotion/styled"
import * as system from "styled-system"
import { Link, NavLink } from "react-router-dom"
import * as theme from "layouts/theme"

export interface LinkProps
	extends system.LayoutProps,
		system.TypographyProps,
		system.SpaceProps,
		system.PositionProps,
		system.WidthProps,
		system.BackgroundProps {
	disabled?: boolean
}

export const external = styled.a<LinkProps>`
	text-decoration: none;
	border: none;
	cursor: ${(props) => (props.href || props.onClick ? "pointer" : "default")};
	${system.color}
	${system.background}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`

external.defaultProps = {
	rel: "nofollow noopener noreferrer",
}

export const navlink = styled(NavLink)<LinkProps & { disabled: boolean }>`
	text-decoration: none;
	box-shadow: none;
	padding: 10px;
	border-radius: 5px;
	color: ${(props) => (props.disabled ? theme.colors.white : theme.colors.grey.dark10)};
	background-color: ${(props) => (props.disabled ? theme.colors.blue : "unset")};
	overflow: hidden;

	svg {
		fill: ${(props) => (props.disabled ? theme.colors.white : theme.colors.grey.dark10)};
	}
	&:hover {
		cursor: pointer;
		color: ${theme.colors.grey.dark10};
		background-color: ${theme.colors.grey.light10};
		svg {
			fill: ${theme.colors.grey.dark10};
		}
	}
	&.icon-with-stroke {
		svg {
			stroke: ${(props) => (props.disabled ? theme.colors.white : theme.colors.grey.dark10)};
		}
		&:hover {
			svg {
				stroke: ${theme.colors.grey.dark10};
			}
		}
	}
	&.collapsed {
		span {
			display: none;
		}
	}
`

export const unstyled = styled(Link)<LinkProps & { disabled: boolean }>`
	text-decoration: none;
	color: ${(props) => (props.disabled ? theme.colors.grey.dark10 : theme.colors.blue)};
	&:hover {
		cursor: pointer;
	}
`
