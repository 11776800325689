import { useNavigate, useParams } from "react-router-dom"
import * as layouts from "layouts"
import { TabPrimaryButton, TabOutlineButton } from "ads/adgen/adgen.content.layouts"
import * as routing from "../routing"

export default function Tabs(): JSX.Element {
	const navigate = useNavigate()
	const params = useParams()
	const bid = params.bid || ""
	const textTabActive = "text" === params["*"]

	return (
		<layouts.containers.flex className="brandguard-tabs" flexDirection="row" pl="25px">
			{!textTabActive ? (
				<>
					<TabPrimaryButton>Images</TabPrimaryButton>
					<TabOutlineButton onClick={() => navigate(routing.brandguard.text(bid))}>Text</TabOutlineButton>
				</>
			) : (
				<>
					<TabOutlineButton onClick={() => navigate(routing.brandguard.images(bid))}>Images</TabOutlineButton>
					<TabPrimaryButton>Text</TabPrimaryButton>
				</>
			)}
		</layouts.containers.flex>
	)
}
