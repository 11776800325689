import React, { useState } from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as debugx from "x/debugx"
import * as api from "./api"

const GuardScoreStyle = styled(layouts.containers.flex)`
	height: 15px;
	font-weight: 500;
	font-size: 10px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background: ${layouts.theme.colors.blue};
	color: ${layouts.theme.colors.white};
`

type scored = Omit<api.Event, "brand_id" | "prediction" | "observation">

export default function GuardScore(props: scored): JSX.Element {
	const { grammar, profanity, racism, brandguard, onbrand } = props
	const [focused, setFocused] = useState<boolean>(false)
	const bgs = api.clamp(api.human(props))
	return (
		<layouts.containers.flex flexDirection="column">
			<GuardScoreStyle onClick={(event) => setFocused(!focused)} cursor={focused ? "pointer" : "unset"} width="100%">
				<layouts.containers.flex m="auto">
					<layouts.containers.span verticalAlign="sub">BrandGuard {bgs}%</layouts.containers.span>
					<layouts.containers.span paddingLeft="5px" verticalAlign="bottom">
						{focused ? (
							<layouts.accordions.FeatherUp width="5px" stroke={layouts.theme.colors.white} />
						) : (
							<layouts.accordions.FeatherDown width="5px" stroke={layouts.theme.colors.white} />
						)}
					</layouts.containers.span>
				</layouts.containers.flex>
			</GuardScoreStyle>
			<layouts.containers.flex display={focused ? "flex" : "none"} flex="1" flexDirection="column">
				{debugx.alpha.enabled() && (
					<layouts.containers.flex
						flexDirection="row"
						background={layouts.theme.colors.white}
						fontSize="10px"
						fontWeight="500"
						color={layouts.theme.colors.grey.dark10}
						borderBottom={layouts.theme.borders.grey.light_thin}
					>
						<layouts.containers.flex p="5px" flex="3">
							Prediction
						</layouts.containers.flex>
						<layouts.containers.flex p="5px" flex="2" justifyContent="end">
							<>{api.clamp(brandguard)} %</>
						</layouts.containers.flex>
					</layouts.containers.flex>
				)}
				<layouts.containers.flex
					flexDirection="row"
					background={layouts.theme.colors.white}
					fontSize="10px"
					fontWeight="500"
					color={layouts.theme.colors.grey.dark10}
					borderBottom={layouts.theme.borders.grey.light_thin}
				>
					<layouts.containers.flex p="5px" flex="3">
						On Brand
					</layouts.containers.flex>
					<layouts.containers.flex p="5px" flex="2" justifyContent="end">
						<>{api.clamp(onbrand)} %</>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex
					flexDirection="row"
					background={layouts.theme.colors.white}
					fontSize="10px"
					fontWeight="500"
					color={layouts.theme.colors.grey.dark10}
					borderBottom={layouts.theme.borders.grey.light_thin}
				>
					<layouts.containers.flex p="5px" flex="3">
						Grammar
					</layouts.containers.flex>
					<layouts.containers.flex p="5px" flex="2" justifyContent="end">
						<>{api.clamp(grammar)} %</>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex
					flexDirection="row"
					background={layouts.theme.colors.white}
					fontSize="10px"
					fontWeight="500"
					color={layouts.theme.colors.grey.dark10}
					borderBottom={layouts.theme.borders.grey.light_thin}
				>
					<layouts.containers.flex p="5px" flex="3">
						Profanity
					</layouts.containers.flex>
					<layouts.containers.flex p="5px" flex="2" justifyContent="end">
						<>{api.clamp(profanity)} %</>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex
					flexDirection="row"
					background={layouts.theme.colors.white}
					fontSize="10px"
					fontWeight="500"
					color={layouts.theme.colors.grey.dark10}
				>
					<layouts.containers.flex p="5px" flex="3">
						Racism
					</layouts.containers.flex>
					<layouts.containers.flex p="5px" flex="2" justifyContent="end">
						<>{api.clamp(racism)} %</>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
