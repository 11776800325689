import { useContext, useEffect } from "react"
import * as adgen from "ads/adgen"
import * as layouts from "layouts"
import * as textgen from "ads/textgen"
import { card as Card, header as Header, Approve, Reject } from "ads/adgen/adgen.content.layouts"
import * as context from "./adgen.responsive.search.context"

interface props {
	adgenConstants: context.AdgenConstants
	onChange(...mut: context.mutation[]): void
}

export function HeadLines(props: props): JSX.Element {
	const maximumDisplay = 12
	const { adgenConstants, onChange } = props

	const genctx = useContext(context.Context)

	function onApproveHeadline(sample: textgen.Sample, index: number): void {
		if (genctx.content.headlines.length === adgenConstants.maxHeadlinesCount) return

		const remaining = [...genctx.buffered.headlines]
		const updated = [...genctx.current.headlines]
		const tmp = remaining.shift()
		tmp ? updated.splice(index, 1, tmp) : updated.splice(index, 1)
		onChange(
			context.mutate.current({ headlines: updated }),
			context.mutate.buffered({ headlines: remaining }),
			context.mutate.content({
				headlines: [...genctx.content.headlines, { ...sample, feedback: textgen.Feedback.USED }],
			}),
		)
	}

	function onRejectHeadline(sample: textgen.Sample, index: number): void {
		const remaining = [...genctx.buffered.headlines]
		const updated = [...genctx.current.headlines]
		const tmp = remaining.shift()
		tmp ? updated.splice(index, 1, tmp) : updated.splice(index, 1)
		onChange(
			context.mutate.buffered({ headlines: remaining }),
			context.mutate.current({ headlines: updated }),
			context.mutate.rejected({ ...sample, feedback: textgen.Feedback.IGNORED }),
		)
	}

	// refill as needed
	useEffect(() => {
		if (genctx.current.headlines.length >= maximumDisplay) return // nothing to do
		const [updcurrent, updbuffer] = adgen.buffered.refill(genctx.current.headlines, genctx.buffered.headlines)
		onChange(context.mutate.current({ headlines: updcurrent }), context.mutate.buffered({ headlines: updbuffer }))
	}, [genctx.current.headlines.length, genctx.buffered.headlines.length])

	return (
		<layouts.containers.grid
			justifyContent="center"
			overflowY="auto"
			display="grid"
			gridGap="25px"
			px="10px"
			py="30px"
			gridTemplateColumns="repeat(auto-fill, 300px)"
			gridTemplateRows="min-content"
		>
			{genctx.current.headlines.map((h, idx) => {
				return (
					<Card key={idx}>
						<layouts.containers.flex flexDirection="row" height="100%" alignItems="center">
							<Header>{h.accepted}</Header>
							<layouts.containers.absolute bottom="-4px" right="0">
								<layouts.containers.flex flexDirection="row">
									<layouts.containers.flex paddingRight="5px">
										<Approve clickApprove={() => onApproveHeadline(h, idx)} />
									</layouts.containers.flex>
									<layouts.containers.flex>
										<Reject clickReject={() => onRejectHeadline(h, idx)} />
									</layouts.containers.flex>
								</layouts.containers.flex>
							</layouts.containers.absolute>
						</layouts.containers.flex>
					</Card>
				)
			})}
		</layouts.containers.grid>
	)
}
