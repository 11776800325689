import React from "react"
import * as layouts from "layouts"
import styled from "@emotion/styled"

export const CardStyle = styled.div`
	position: relative;
	padding: 20px;
	width: 300px;
	height: 155px;
	border: 1px solid ${layouts.theme.colors.grey.light20};
	outline: none;
	&.active {
		border: 1px solid ${layouts.theme.colors.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}

	&:hover {
		outline: 1.5px solid ${layouts.theme.colors.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}
`
interface props {
	tabIndex: number
	headline: string
	link: string
	description: string
	onCardClick(): void
	active: boolean
}

export default function Card(props: React.PropsWithChildren<props>): JSX.Element {
	const { headline, link, description, children, onCardClick, active, tabIndex } = props
	return (
		<CardStyle className={active ? "active" : ""} tabIndex={tabIndex} onClick={() => onCardClick()}>
			<layouts.containers.flex
				color={layouts.theme.colors.link.hover}
				fontWeight="600"
				lineHeight="20px"
				fontSize="14px"
				py="10px"
			>
				{headline}
			</layouts.containers.flex>
			<layouts.containers.flex
				color={layouts.theme.colors.success}
				fontWeight="600"
				lineHeight="20px"
				fontSize="10px"
				pb="10px"
			>
				{link}
			</layouts.containers.flex>
			<layouts.containers.flex
				color={layouts.theme.colors.grey.light40}
				fontWeight="600"
				lineHeight="16px"
				fontSize="10px"
			>
				{description}
			</layouts.containers.flex>
			{children}
		</CardStyle>
	)
}
