import { SVG, SVGProps } from "./svg"

const Icon = (props: SVGProps) => {
	const { fill, stroke, className, ...rest } = props

	return (
		<SVG viewBox="0 0 206 50" fill={fill} className={className} xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M45.9185 42.3559C45.9586 42.3559 45.9988 42.3559 46.0423 42.3559L45.962 23.9339C45.9386 18.8157 44.1782 14.502 40.6442 10.9176C39.938 10.2397 39.2319 9.59611 38.4488 9.03123L38.3384 8.95591C37.2239 8.16508 36.0359 7.48722 34.734 6.92576C34.1383 6.66215 33.5092 6.43962 32.803 6.25132C32.2475 6.06303 31.5782 5.91582 30.7248 5.80626C30.0956 5.69671 29.4263 5.62139 28.7971 5.58716H27.8701C22.9037 5.61455 18.6501 7.41532 15.1462 11.0306C11.6456 14.6561 9.92212 18.9834 9.94555 24.1016C9.96228 28.1208 11.1269 31.7156 13.4026 34.8892C16.9768 39.6992 21.5483 42.2053 27.1439 42.4209V42.4381H27.8868C27.937 42.4381 27.9872 42.4415 28.0374 42.4381L29.3192 42.4312L45.9252 42.4038C45.9252 42.4038 45.9252 42.3696 45.9218 42.3525L45.9185 42.3559Z"
				stroke={stroke}
				strokeWidth="3.5"
				strokeMiterlimit="10"
			/>
			<path
				d="M39.8178 24.3174C39.8178 23.8655 39.8278 23.417 39.8178 22.9651C39.791 22.0373 39.2321 21.4588 38.3252 21.4211C37.9905 21.4074 37.6558 21.4006 37.3212 21.4211C37.0066 21.4451 36.8192 21.3732 36.682 21.0377C36.5448 20.7022 36.6853 20.5481 36.8861 20.353C37.1438 20.1065 37.3948 19.8531 37.6324 19.5895C38.2114 18.9493 38.2181 18.1414 37.6291 17.508C37.0099 16.8438 36.3741 16.1934 35.7248 15.56C35.1291 14.978 34.3326 14.9883 33.7269 15.5532C33.4659 15.7962 33.2249 16.0564 32.9773 16.3166C32.7798 16.5255 32.6259 16.7035 32.2644 16.5426C31.913 16.3851 31.9097 16.1626 31.9164 15.8681C31.9264 15.5258 31.9298 15.1834 31.9164 14.8411C31.8796 13.9441 31.314 13.3621 30.4372 13.3416C29.9586 13.3313 29.48 13.3279 29.0015 13.3279V19.4765C29.0483 19.4765 29.0952 19.4731 29.142 19.4765C31.7223 19.48 33.8307 21.6539 33.8206 24.3037C33.8106 26.9433 31.6821 29.0898 29.0885 29.0761C29.0584 29.0761 29.0316 29.0761 29.0015 29.0761V34.1498C29.0383 34.1498 29.0751 34.1498 29.1086 34.1498C29.2658 34.1498 29.4265 34.1498 29.5838 34.1498C29.4265 34.1498 29.2658 34.1498 29.1086 34.1498C29.0717 34.1498 29.0349 34.1498 29.0015 34.1498V35.2214C29.47 35.2214 29.9385 35.2214 30.407 35.2111C31.3173 35.194 31.8796 34.6154 31.9164 33.6876C31.9298 33.3282 31.9398 32.9653 31.9164 32.6092C31.893 32.2942 32 32.1333 32.2945 32.0101C32.5824 31.8868 32.743 31.969 32.9237 32.1778C33.1714 32.4586 33.4357 32.7256 33.7068 32.9824C34.3126 33.5575 35.1124 33.5849 35.7048 33.0132C36.3841 32.3593 37.0434 31.6814 37.6826 30.9865C38.208 30.4147 38.1947 29.6102 37.6893 29.0248C37.445 28.7406 37.1739 28.4839 36.9096 28.2202C36.7054 28.0183 36.5347 27.8608 36.692 27.491C36.846 27.135 37.0635 27.1281 37.3547 27.1384C37.6893 27.1487 38.024 27.1555 38.3586 27.1384C39.2388 27.0939 39.791 26.5222 39.8211 25.6218C39.8345 25.1904 39.8211 24.7591 39.8211 24.3243L39.8178 24.3174Z"
				fill="white"
			/>
			<path
				d="M18.6804 17.6345H21.6857L22.6763 15.933C22.8336 15.666 23.1147 15.5016 23.4192 15.5016H25.0557C25.5343 15.5016 25.9225 15.8988 25.9225 16.3883C25.9225 16.8779 25.5343 17.275 25.0557 17.275H23.9112L22.9206 18.9765C22.7633 19.2436 22.4822 19.4079 22.1776 19.4079H17.6195C16.9268 20.856 16.5386 22.4856 16.5386 24.2077C16.5386 24.7383 16.5754 25.2621 16.649 25.7757H20.2065C20.5244 25.7757 20.8156 25.9537 20.9662 26.2413L21.8497 27.9119H24.9453C25.4239 27.9119 25.8121 28.3091 25.8121 28.7986C25.8121 29.2882 25.4239 29.6853 24.9453 29.6853H21.3343C21.0164 29.6853 20.7252 29.5073 20.5746 29.2197L19.6911 27.5491H17.0473C18.3692 31.7668 22.1241 34.872 26.6253 35.1287V32.9137H22.2948C21.8162 32.9137 21.428 32.5166 21.428 32.027C21.428 31.5375 21.8162 31.1403 22.2948 31.1403H26.6253V25.9263H23.8175C23.5029 25.9263 23.2118 25.7483 23.0578 25.4675L22.007 23.5024H19.0218C18.5432 23.5024 18.155 23.1053 18.155 22.6157C18.155 22.1262 18.5432 21.729 19.0218 21.729H22.519C22.8336 21.729 23.1247 21.9071 23.2787 22.1878L24.3295 24.1529H26.6253V21.3627H23.7071C23.2285 21.3627 22.8403 20.9656 22.8403 20.476C22.8403 19.9865 23.2285 19.5893 23.7071 19.5893H26.6253V13.29C23.3791 13.4749 20.5211 15.1422 18.6838 17.6379L18.6804 17.6345Z"
				fill="white"
			/>
		</SVG>
	)
}

export default Icon
