import React, { useState } from "react"
import { Route, Routes, Navigate, useMatch, useNavigate } from "react-router-dom"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as sessions from "sessions"
import { Standard as Navigation } from "navigation"
import * as adsgoogle from "ads"
import * as styleguard from "styleguard"
import * as brandguard from "brandguard"
import * as training from "training"
import * as discountopt from "ecommerce/discountopt"
import * as grading from "./grading"
import * as api from "./api"
import * as caching from "./cache"
import * as routing from "./routing"
import { default as Grid } from "./grid"

function DetectBrandID(
	props: React.PropsWithChildren<{ brand: api.Brand; onChange: (b: api.Brand) => void }>,
): JSX.Element {
	const { brand, onChange } = props
	const bearertoken = sessions.useToken()
	const match_brand = useMatch("brands/:bid/*")
	if (match_brand?.params.bid && match_brand?.params.bid !== brand.id) {
		caching.current(match_brand?.params.bid, bearertoken).then((result) => onChange(api.zero(result)))
	}

	return <>{props.children}</>
}

export default function Routing(props: React.PropsWithChildren<unknown>): JSX.Element {
	const [brand, setBrand] = useState(caching.useCached())
	const navigate = useNavigate()

	return (
		<layouts.containers.flex className="brands" flex="1" overflowY="hidden">
			<layouts.containers.flex className="navigation-container" flex="0">
				<Navigation></Navigation>
			</layouts.containers.flex>
			<layouts.containers.flex className="body-container" flex="1 1">
				<caching.Provider value={brand}>
					<Routes>
						<Route
							path="/:bid/styleguard/*"
							element={
								<DetectBrandID brand={brand} onChange={setBrand}>
									<styleguard.Routes />
								</DetectBrandID>
							}
						/>
						<Route
							path="/:bid/brandguard/*"
							element={
								<DetectBrandID brand={brand} onChange={setBrand}>
									<brandguard.Routes />
								</DetectBrandID>
							}
						/>
						<Route
							path="/:bid/training/*"
							element={
								<DetectBrandID brand={brand} onChange={setBrand}>
									<training.Routes />
								</DetectBrandID>
							}
						/>

						<Route
							path="/:bid/ads/*"
							element={
								<DetectBrandID brand={brand} onChange={setBrand}>
									<adsgoogle.Routes />
								</DetectBrandID>
							}
						/>
						<Route
							path="/:bid/grading"
							element={
								<DetectBrandID brand={brand} onChange={setBrand}>
									<layouts.backgrounds.Grey flex="0 1 100%">
										<grading.Display />
									</layouts.backgrounds.Grey>
								</DetectBrandID>
							}
						/>
						<Route
							path="/:bid/promotions/*"
							element={
								<DetectBrandID brand={brand} onChange={setBrand}>
									<discountopt.Routes />
								</DetectBrandID>
							}
						/>
						<Route
							path="/unknown"
							element={
								<layouts.backgrounds.Grey
									className="background-grey"
									flex="0 1 100%"
									background={layouts.theme.colors.grey.bg}
								>
									<Grid
										search={async (req: api.SearchRequest, ...options: httpx.option[]): Promise<api.SearchResponse> => {
											return api.search(req, ...options).then((r) => {
												if (r.items.length === 1) {
													const brand = r.items.at(0)!
													api.setLastBrandId(brand.account_id, brand.id)
													// enqueue navigation away from the unknown page after the current task completes.
													setImmediate(() => navigate(routing.ads(brand!.id)))
													return { ...r, items: [] }
												}

												return r
											})
										}}
									/>
								</layouts.backgrounds.Grey>
							}
						/>
						<Route
							path="/"
							element={
								<layouts.backgrounds.Grey
									className="background-grey"
									flex="0 1 100%"
									background={layouts.theme.colors.grey.bg}
								>
									<Grid />
								</layouts.backgrounds.Grey>
							}
						/>
						<Route path="*" element={<Navigate to="/unknown" replace />} />
					</Routes>
				</caching.Provider>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
