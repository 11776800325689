import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { className, fill, ...rest } = props
	return (
		<svg.SVG
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="10px"
			height="10px"
			viewBox="0 0 10 10"
			version="1.1"
			fill={fill}
			{...rest}
		>
			<path d="M 9.332031 3.96875 C 9.324219 3.792969 9.246094 3.625 9.113281 3.507812 L 5.449219 0.171875 C 5.328125 0.0585938 5.167969 -0.0078125 5 -0.0117188 C 4.832031 -0.0078125 4.671875 0.0585938 4.550781 0.171875 L 0.886719 3.507812 C 0.613281 3.753906 0.59375 4.175781 0.839844 4.449219 C 1.085938 4.722656 1.507812 4.742188 1.78125 4.492188 L 4.332031 2.171875 L 4.332031 9.34375 C 4.332031 9.710938 4.632812 10.011719 5 10.011719 C 5.367188 10.011719 5.667969 9.710938 5.667969 9.34375 L 5.667969 2.171875 L 8.21875 4.492188 C 8.492188 4.742188 8.914062 4.722656 9.160156 4.449219 C 9.277344 4.316406 9.339844 4.144531 9.332031 3.96875 Z M 9.332031 3.96875 " />
		</svg.SVG>
	)
}

export default Icon
