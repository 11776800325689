import React from "react"
import * as layouts from "layouts"

// test for application errors.
export default function Boom(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<layouts.containers.flex m="auto">
			<layouts.buttons.primary
				onClick={() => {
					throw new Error("boom")
				}}
			>
				Explode
			</layouts.buttons.primary>
		</layouts.containers.flex>
	)
}
