import classnames from "classnames"
import * as charts from "charts"
import * as layouts from "layouts"
import { metric } from "./display"

const hover = layouts.css`
	&:hover {
		outline: 0.1rem solid;
		outline-color: ${layouts.theme.colors.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}

	canvas {
		width: 100% !important;
		height: 100% !important;
		max-height: 100% !important;
		max-width: 100% !important;
	}
`

interface props {
	labels: string[]
	dataset: metric[]
}

function scaleY(m: metric): charts.CartesianScaleOptions {
	return {
		type: "linear" as const,
		display: true,
		position: m.chart.position,
		min: 0,
		ticks: {
			color: layouts.theme.colors.grey.light30,
			maxTicksLimit: 5,
		} as charts.CartesianTickOptions,
		title: {
			display: true,
			text: m.title,
			color: layouts.theme.colors.grey.light30,
			font: {
				size: 14,
			},
			padding: 0,
			align: "center",
		},
		grid: {
			display: false,
		} as unknown as charts.GridLineOptions,
	} as unknown as charts.CartesianScaleOptions
}

export default function Chart(props: props): JSX.Element {
	const { labels, dataset } = props

	const scales = dataset.reduce(
		(scales, m) => {
			scales[m.chart.axisID] = scaleY(m)
			return scales
		},
		{
			xAxes: {
				ticks: {
					color: layouts.theme.colors.grey.light30,
				},
				grid: {
					display: false,
				},
			} as unknown as charts.CartesianScaleOptions,
		} as { [key: string]: charts.CartesianScaleOptions },
	)

	const dset = dataset.map((m) => ({
		yAxisID: m.chart.axisID,
		fill: true,
		label: m.title,
		data: Object.values(m.chart.data),
		borderColor: m.chart.borderColor,
		backgroundColor: m.chart.backgroundColor,
		tension: 0.2,
	}))

	const options = {
		maintainAspectRatio: true,
		scales: scales,
		layout: {
			padding: 20,
		},
		elements: {
			point: {
				radius: 0,
				pointHoverRadius: 5,
			},
		},
		plugins: {
			legend: {
				position: "top" as const,
				align: "end" as const,
				labels: {
					usePointStyle: true,
					boxWidth: 50,
					font: {
						size: 10,
						weight: "bold",
					},
				},
			},
		},
	}

	const data = {
		labels: labels,
		datasets: dset,
	}

	return (
		<layouts.containers.box className={classnames("primary", hover)} height="512px" maxHeight="512px">
			<charts.Line options={options} data={data} width={100} />
		</layouts.containers.box>
	)
}
