import * as httpx from "httpx"

export interface StyleGuide {
	content: ArrayBuffer
	parse_status: number // enum for example: [new, created, completed, error]
}

export interface SearchRequest {
	brand_id: string
}

export interface SearchResponse {
	styleguide: StyleGuide
}

export interface CreateResponse {
	styleguide: StyleGuide
}

export function zero(b: Partial<StyleGuide> = {}): StyleGuide {
	return {
		content: new ArrayBuffer(0),
		parse_status: 0,
		...b,
	}
}

export function upload(id: string, file: File, ...options: httpx.option[]): Promise<Response> {
	const data = new FormData()
	data.append("content", file)
	return httpx.upload(`${httpx.urlstorage.host()}/styleguard/${id}`, data, ...options)
}

export function download(id: string, ...options: httpx.option[]): Promise<Response> {
	return httpx._fetch(`${httpx.urlstorage.host()}/styleguard/${id}`, ...options)
}
