import * as httputils from "httpx"

export const chartColors = [
	"#47D7AC",
	"#5461C8",
	"#C964CF",
	"#FFBF3F",
	"#59CBE8",
	"#FCE300",
	"#F04E98",
	"#767899",
	"#B870B9",
	"#FF5964",
]

export interface Impression {
	browser_incognito: boolean
	browser_major_version: string
	browser_name: string
	browser_operating_system: string
	browser_operating_system_version: string
	browser_useragent: string
	cid: string
	converted_at: string
	coupon: string
	coupon_applied: boolean
	coupon_md5_digest: string
	created_at: string
	description: string
	fpjs_reqid: string
	fpjs_uid: string
	fpjs_uid_md5_digest: string
	geoip_ip: string
	geoip_latitude: number
	geoip_longitude: number
	geoip_zipcode: string
	gid: string
	id: string
	prediction: number
	probability: number
	profit: number
	shopify_order_id: number
}

interface DateRange {
	begin: string
	end: string
}

export interface ImpressionsRequest {
	offset: string
	created: DateRange
}

export interface ImpressionsResults {
	next: ImpressionsRequest
	impressions: Impression[]
}

export interface chartData {
	labels: string[]
	datasets: { label: string; data: number[]; borderColor?: string; backgroundColor?: string; stack?: string }[]
}

export function initialLineChartData(): chartData {
	return {
		labels: [],
		datasets: [],
	}
}

export function initialBarChartData(): chartData {
	return {
		labels: [],
		datasets: [
			{
				label: "conversion",
				data: [],
				backgroundColor: chartColors[0],
			},
			{
				label: "impressions",
				data: [],
				backgroundColor: chartColors[1],
			},
		],
	}
}

function generateLabelsByDateRange(date_begin: string, date_end: string): string[] {
	const labels: string[] = []
	let end_date = new Date(date_end)
	for (let d = new Date(date_begin); d <= end_date; d.setDate(d.getDate() + 1)) {
		labels.push(new Date(d).toDateString())
	}
	return labels
}

export function prepareLineChartData(data: ImpressionsResults, chart_data: chartData): chartData {
	const result: chartData = JSON.parse(JSON.stringify(chart_data))
	const temp_result: { [gid: string]: { [date: string]: number } } = {}
	if (result.labels.length === 0)
		result.labels = generateLabelsByDateRange(data.next.created.begin, data.next.created.end)

	data.impressions.forEach((impression) => {
		if (!temp_result[impression.gid]) {
			temp_result[impression.gid] = {}
			result.labels.forEach((l) => (temp_result[impression.gid][l] = 0))
		}
		const label = new Date(Date.parse(impression.created_at)).toDateString()
		temp_result[impression.gid][label] += 1
	})

	Object.keys(temp_result).forEach((gid) => {
		let dataset_index: number = result.datasets.findIndex((dataset) => dataset.label === gid)
		if (dataset_index === -1) {
			const color = chartColors[result.datasets.length]
			const init_data = result.labels.map(() => 0)
			result.datasets.push({ label: gid, data: init_data, borderColor: color, backgroundColor: color })
			dataset_index = result.datasets.length - 1
		}
		result.labels.forEach((l, i) => {
			result.datasets[dataset_index].data[i] += temp_result[gid][l]
		})
	})
	return result
}

export function prepareBarChartData(data: ImpressionsResults, chart_data: chartData): chartData {
	let result: chartData = JSON.parse(JSON.stringify(chart_data))
	data.impressions.forEach((impression) => {
		let ind = result.labels.findIndex((label) => label === impression.gid)
		if (ind === -1) {
			ind = result.labels.push(impression.gid) - 1
			result.datasets[0].data.push(0)
			result.datasets[1].data.push(0)
		}
		result.datasets[1].data[ind] += 1 // impressions
		if (new Date(impression.converted_at) < new Date() && impression.coupon_applied) result.datasets[0].data[ind] += 1 // conversion
	})
	return result
}

function normalizeparams(req: ImpressionsRequest): { offset: string; "created.begin": string; "created.end": string } {
	return { offset: req.offset, "created.begin": req.created.begin, "created.end": req.created.end }
}

export function index(req: ImpressionsRequest, ...options: httputils.option[]): Promise<ImpressionsResults> {
	return httputils.get(`${httputils.urlstorage.host()}/ecomm/promos/impressions`, normalizeparams(req), ...options)
}
