import React, { useEffect, useState } from "react"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as profiles from "profiles"
import * as errors from "errors"
import * as authzc from "authzcached"
import * as inputs from "inputs"

export default function PendingTable(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const [metaauthz] = useState(authzc.useCache((cached) => cached.meta))
	const [cause, setCause] = useState(undefined as undefined | JSX.Element)

	const [nextReq, setNextReq] = useState(profiles.api.ZeroSearch())
	const [req, setReq] = useState({ ...profiles.api.PendingZeroSearch() })
	const [pset, setProfiles] = useState([] as profiles.Profile[])

	useEffect(() => {
		metaauthz.token().then((token) => {
			profiles.api
				.search(req, httpx.options.bearer(token.bearer))
				.then((resp) => {
					const pset = resp.items || ([] as profiles.Profile[])
					setProfiles(pset)
					setNextReq({
						...nextReq,
						offset: pset.length === 0 || pset.length < req.limit ? uuid.NIL : pset[pset.length - 1].id,
					})
				})
				.catch((cause) => {
					setCause(<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />)
				})
		})
	}, [req])

	return (
		<layouts.containers.flex flexDirection="column" {...rest}>
			<layouts.containers.flex py="25px" height="60px">
				<layouts.containers.flex width="30%" pr="15px">
					<inputs.Text
						placeholder="Search"
						defaultValue={req.query}
						onChange={(evt) => {
							setReq({ ...req, query: evt.currentTarget.value })
						}}
					/>
				</layouts.containers.flex>
				<layouts.containers.flex width="inherit" display="inline">
					<layouts.buttons.primary height="50px" width="100px" onClick={() => setReq(profiles.api.PendingZeroSearch())}>
						Reset
					</layouts.buttons.primary>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.tables.container flex="1">
				<errors.overlay styled cause={cause} width="inherit" height="inherit">
					<table>
						<thead>
							<tr>
								<th>name</th>
								<th>email</th>
								<th>created</th>
							</tr>
						</thead>
						<tbody>
							{pset.map((p) => (
								<>
									<td>{p.display}</td>
									<td>{p.email}</td>
									<td>{p.created_at}</td>
								</>
							))}
						</tbody>
					</table>
				</errors.overlay>
				<layouts.pagination.Cursor
					ml="auto"
					mt="auto"
					py="5px"
					pr="10px"
					current={req.offset}
					advance={nextReq.offset === uuid.NIL ? undefined : nextReq.offset}
					onChange={(next) => {
						setReq({
							...nextReq,
							offset: next,
						})
					}}
				/>
			</layouts.tables.container>
		</layouts.containers.flex>
	)
}
