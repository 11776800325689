import * as httpx from "httpx"
import * as uuid from "uuid"
import * as timex from "timex"
import * as md5x from "md5x"
import * as brandguard from "brandguard"

import {
	Query,
	AdResponsiveSearchMetadata,
	AdResponsiveSearchContent,
	SearchRequest,
	SearchResponse,
	GenerateRequest,
	GenerateResponse,
	CreateRequest,
	CreateResponse,
	SuggestedRequest,
	SuggestedResponse,
	RetargetResponse,
	BidResponse,
	KeywordGenResponse,
	ResponsiveSearchSearchRequest,
	ResponsiveSearchSearchResponse,
	ResponsiveSearchContentResponse,
	ResponsiveSearchScoreRequest,
	ResponsiveSearchScoreResponse,
	SuggestedDeleteResponse,
	KeywordOneshotGenRequest,
	KeywordOneshotGenResponse,
} from "./ads.google.adgen"
import { Metadata } from "./ads.google"

import {
	ImportRequest,
	ImportResponse,
	AccessibleRequest,
	AccessibleResponse,
	AccountsRequest,
	AccountsResponse,
	ClientAccountSearchRequest,
	ClientAccountSearchResponse,
	ClientAccountCreateRequest,
	ClientAccountCreateResponse,
	ClientAccountFindResponse,
	ClientAccount,
	RetargetImportRequest,
	RetargetImportResponse,
} from "./ads.google.clients"

export type {
	ImportRequest,
	ImportResponse,
	GAAccountInfo,
	AccessibleRequest,
	AccessibleResponse,
	AccountsRequest,
	AccountsResponse,
	ClientAccount,
	ClientAccountSearchRequest,
	ClientAccountSearchResponse,
	ClientAccountCreateRequest,
	ClientAccountCreateResponse,
	ClientAccountFindResponse,
	RetargetImportRequest,
	RetargetImportResponse,
} from "./ads.google.clients"

export type { Metadata } from "./ads.google"
export type {
	Query,
	Generated,
	AdResponsiveSearchMetadata,
	AdResponsiveSearchContent,
	SearchRequest,
	SearchResponse,
	GenerateRequest,
	GenerateResponse,
	CreateRequest,
	CreateResponse,
	SuggestedRequest,
	SuggestedResponse,
	RetargetResponse,
	KeywordGenResponse,
	KeywordOneshotGenRequest,
	KeywordOneshotGenResponse,
	ResponsiveSearchSearchRequest,
	ResponsiveSearchSearchResponse,
	ResponsiveSearchContentResponse,
} from "./ads.google.adgen"

export namespace accounts {
	export function zero(d: Partial<ClientAccount> = {}): ClientAccount {
		return {
			id: uuid.NIL,
			account_id: uuid.NIL,
			customer_id: 0n,
			manager_id: 0n,
			authz_credentials_id: uuid.NIL,
			description: "",
			created_at: "",
			updated_at: "",
			...d,
		}
	}

	export function accessible(req: AccessibleRequest, ...options: httpx.option[]): Promise<AccessibleResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/ad/google/accounts/retrieve/`, req, ...options)
	}

	export function info(req: AccountsRequest, ...options: httpx.option[]): Promise<AccountsResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/ad/google/accounts/retrieve/${req.id}`, req, ...options)
	}

	export namespace searches {
		export function zero(proto: Partial<ClientAccountSearchRequest> = {}): ClientAccountSearchRequest {
			return {
				offset: uuid.NIL,
				query: "",
				...proto,
			}
		}
	}

	export async function search(
		req: ClientAccountSearchRequest,
		...options: httpx.option[]
	): Promise<ClientAccountSearchResponse> {
		return httpx
			.get<ClientAccountSearchResponse>(`${httpx.urlstorage.host()}/ad/google/accounts/`, req, ...options)
			.then((resp) => ({
				...resp,
				items: resp.items || [],
			}))
	}

	export function create(
		req: ClientAccountCreateRequest,
		...options: httpx.option[]
	): Promise<ClientAccountCreateResponse> {
		return httpx.post(`${httpx.urlstorage.host()}/ad/google/accounts/`, req, ...options)
	}

	export function find(id: string, ...options: httpx.option[]): Promise<ClientAccountFindResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/ad/google/accounts/${id}`, {}, ...options)
	}

	export namespace imports {
		export function create(req: ImportRequest, ...options: httpx.option[]): Promise<ImportResponse> {
			return httpx.post(`${httpx.urlstorage.host()}/ad/google/import/`, req, ...options)
		}
	}

	export namespace retarget_imports {
		export function create(req: RetargetImportRequest, ...options: httpx.option[]): Promise<RetargetImportResponse> {
			return httpx.post(`${httpx.urlstorage.host()}/ad/google/retarget_import/`, req, ...options)
		}
	}
}

export namespace ads {
	export function query(d: Partial<Query> = {}): Query {
		return {
			account_id: uuid.NIL,
			profile_id: uuid.NIL,
			prompt: "",
			brand: "",
			bloom: new Uint8Array(),
			...d,
		}
	}

	export namespace metadata {
		export function zero(proto: Partial<Metadata> = {}): Metadata {
			const ts = timex.local()
			const tsiso = ts.toISO()
			return {
				id: uuid.NIL,
				account_id: uuid.NIL,
				profile_id: uuid.NIL,
				pool_id: uuid.NIL,
				tid: uuid.NIL,
				manager_id: 0n,
				customer_id: 0n,
				campaign_id: 0n,
				group_id: 0n,
				uid: 0n,
				authz_credentials_id: uuid.NIL,
				description: "",
				created_at: tsiso,
				updated_at: tsiso,
				disabled_at: timex.infinity().toISO(),
				bounce_rate: 0,
				click_through_rate: 0,
				conversion_rate: 0,
				...proto,
			}
		}

		export namespace keywords {
			export async function oneshot(
				req: KeywordOneshotGenRequest,
				...options: httpx.option[]
			): Promise<KeywordOneshotGenResponse> {
				return httpx
					.post<KeywordOneshotGenResponse>(
						`${httpx.urlstorage.host()}/ad/google/meta/keywords/oneshot`,
						req,
						...options,
					)
					.then((resp) => ({ ...resp, cursor: { ...req, offset: "", ...resp.cursor } }))
			}

			export async function generate(id: string, ...options: httpx.option[]): Promise<KeywordGenResponse> {
				return httpx.post<KeywordGenResponse>(
					`${httpx.urlstorage.host()}/ad/google/meta/keywords/${id}`,
					{},
					...options,
				)
			}
		}
	}

	export namespace responsive {
		export async function retarget(id: string, ...options: httpx.option[]): Promise<RetargetResponse> {
			return httpx.post<RetargetResponse>(
				`${httpx.urlstorage.host()}/ad/google/responsive/retarget/${id}`,
				{},
				...options,
			)
		}

		export function request(d: Partial<CreateRequest> = {}): CreateRequest {
			return {
				metadata: ads.responsive.metadata(),
				content: ads.responsive.content.zero(),
				...d,
			}
		}

		export function create(req: CreateRequest, ...options: httpx.option[]): Promise<CreateResponse> {
			return httpx.post(`${httpx.urlstorage.host()}/ad/google/responsive/`, req, ...options)
		}

		export async function generate(req: GenerateRequest, ...options: httpx.option[]): Promise<GenerateResponse> {
			return httpx
				.get<GenerateResponse>(`${httpx.urlstorage.host()}/ad/google/responsive/gen`, req, ...options)
				.then((resp) => ({ ...resp, items: resp.items || [] })) // ensure we always have an empty array to operate on.
		}

		export namespace content {
			export function scorable(c: AdResponsiveSearchContent): boolean {
				return c.headlines.length + c.descriptions.length > 0
			}

			export function digest(c: AdResponsiveSearchContent): string {
				return md5x.string([...c.headlines, ...c.descriptions].join(""))
			}

			// utility function to development
			export function testdata(proto: Partial<AdResponsiveSearchContent> = {}): AdResponsiveSearchContent {
				return zero({
					headlines: ["headline 1", "headline 2", "headline 3", "headline 4"],
					descriptions: ["desc 1", "desc 2", "desc 3", "desc 4"],
					urls: ["https://novacloud.ai"],
					path1: "foo",
					path2: "bar",
					...proto,
				})
			}

			export function zero(proto: Partial<AdResponsiveSearchContent> = {}): AdResponsiveSearchContent {
				return {
					id: "",
					path1: "",
					path2: "",
					urls: [],
					headlines: [],
					descriptions: [],
					...proto,
				}
			}

			export async function get(id: string, ...options: httpx.option[]): Promise<ResponsiveSearchContentResponse> {
				return httpx
					.get<ResponsiveSearchContentResponse>(`${httpx.urlstorage.host()}/ad/google/responsive/${id}`, {}, ...options)
					.then((resp) => ({ ...resp, content: zero(resp.content) }))
			}
		}

		export namespace search {
			export function request(proto: Partial<ResponsiveSearchSearchRequest> = {}): ResponsiveSearchSearchRequest {
				return {
					offset: uuid.NIL,
					limit: 20,
					pool_id: uuid.NIL,
					...proto,
				}
			}

			export function response(proto: Partial<ResponsiveSearchSearchResponse> = {}): ResponsiveSearchSearchResponse {
				return {
					cursor: request(),
					items: [],
					...proto,
				}
			}

			export async function get(
				req: ResponsiveSearchSearchRequest,
				...options: httpx.option[]
			): Promise<ResponsiveSearchSearchResponse> {
				return httpx
					.get<ResponsiveSearchSearchResponse>(`${httpx.urlstorage.host()}/ad/google/responsive`, req, ...options)
					.then((resp) => ({ ...resp, items: resp.items || [] })) // ensure we always have an empty array to operate on.
			}
		}

		export async function score(
			req: ResponsiveSearchScoreRequest,
			...options: httpx.option[]
		): Promise<ResponsiveSearchScoreResponse> {
			return httpx.post<ResponsiveSearchScoreResponse>(
				`${httpx.urlstorage.host()}/ad/google/responsive/score`,
				req,
				...options,
			)
		}

		export function metadata(proto: Partial<AdResponsiveSearchMetadata> = {}): AdResponsiveSearchMetadata {
			const ts = timex.local()
			const tsiso = ts.toISO()
			return {
				id: uuid.NIL,
				account_id: uuid.NIL,
				profile_id: uuid.NIL,
				pool_id: uuid.NIL,
				google_ads_client_account_id: uuid.NIL,
				description: "",
				autogenerated: false,
				created_at: tsiso,
				updated_at: tsiso,
				disabled_at: timex.infinity().toISO(),
				profanity: 0.0,
				grammar: 0.0,
				racism: 0.0,
				onbrand: 0.0,
				brandguard: 0.0,
				brandguard_prediction: brandguard.api.Prediction.REJECTED,
				...proto,
			}
		}

		export function asBrandguardObservation(md: AdResponsiveSearchMetadata): Partial<brandguard.api.Event> {
			return brandguard.api.observations.zero({
				prediction: md.brandguard_prediction,
				brandguard: md.brandguard,
				profanity: md.profanity,
				racism: md.racism,
				grammar: md.grammar,
				onbrand: md.onbrand,
			})
		}
	}

	export namespace suggestions {
		export function zero(proto: Partial<SuggestedRequest> = {}): SuggestedRequest {
			return {
				pool_id: uuid.NIL,
				offset: uuid.NIL,
				limit: 20,
				...proto,
			}
		}

		export async function get(req: SuggestedRequest, ...options: httpx.option[]): Promise<SuggestedResponse> {
			return httpx
				.get<SuggestedResponse>(`${httpx.urlstorage.host()}/ad/google/suggest/`, req, ...options)
				.then((resp) => ({ ...resp, items: resp.items || [] }))
		}

		export async function destroy(id: string, ...options: httpx.option[]): Promise<SuggestedDeleteResponse> {
			return httpx.destroy<SuggestedDeleteResponse>(
				`${httpx.urlstorage.host()}/ad/google/suggest/${id}`,
				{},
				...options,
			)
		}
	}
}

export async function search(req: SearchRequest, ...options: httpx.option[]): Promise<SearchResponse> {
	return httpx
		.get<SearchResponse>(`${httpx.urlstorage.host()}/ad/google/meta/`, req, ...options)
		.then((resp) => ({ ...resp, items: resp.items || [] })) // ensure we always have an empty array to operate on.
}

export async function bid(id: string, ...options: httpx.option[]): Promise<BidResponse> {
	return httpx.post<BidResponse>(`${httpx.urlstorage.host()}/ad/google/meta/bid/${id}`, {}, ...options)
}
