import { Outlet } from "react-router-dom"

import * as layouts from "layouts"
import * as titlebars from "titlebars"
const ViLayout = () => {
	return (
		<layouts.backgrounds.Splash className="splash" flexDirection="column" height="100vh">
			<titlebars.containers.Container className="navigation" mt="66px">
				<titlebars.logos.Logo />
				<titlebars.Logout ml="auto" />
			</titlebars.containers.Container>
			<Outlet />
		</layouts.backgrounds.Splash>
	)
}
export default ViLayout
