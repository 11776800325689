import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import * as brandguardapi from "brandguard/api"
import * as sessions from "sessions"
import * as errors from "errors"
import * as layouts from "layouts"
import * as brands from "brands"
import * as debugx from "x/debugx"
import * as uuid from "uuid"
import { TabPrimaryButton, TabOutlineButton } from "styleguard/display/layouts"
import * as routing from "../routing"
import { default as Upload } from "./upload"
import { FileRejection } from "react-dropzone"
import { default as TextDetails } from "./text.details"
import * as inputs from "inputs"
import styled from "@emotion/styled"
import * as icons from "icons"
import Papa from "papaparse"
import { default as TextCard } from "./text"
import Image from "images/file.loading.gif"

const Processing = styled.img`
	width: 288px;
	height: 212px;
	margin: auto;
`

const acceptedTypes = {
	"text/csv": [".csv"],
	"text/plain": [".txt"],
}

export const StyledBtn = styled(layouts.buttons.outline)`
	background: ${layouts.theme.colors.white};
	border: 1px solid ${layouts.theme.colors.grey.light20};
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
	height: 48px;
	border-radius: 48px;
	padding: 0 23px;
	&:hover {
		border: 1px solid ${layouts.theme.colors.blue};
		background: ${layouts.theme.colors.white};
		color: ${layouts.theme.colors.blue};
		box-shadow: 2px 2px 4px ${layouts.theme.colors.blue}7E;
	}
`

function Inprocess(): JSX.Element {
	return (
		<layouts.containers.flex flexDirection="column" className="processing-component" width="100%">
			<layouts.containers.flex
				minHeight="600px"
				background={layouts.theme.colors.white}
				borderRadius="10px"
				flexDirection="column"
				pt="60px"
			>
				<layouts.containers.flex flexDirection="column" p="50px" my="20px" mx="100px">
					<Processing src={Image} />
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export default function DisplayText(): JSX.Element {
	const brand = brands.caching.useCached()
	const [loading, setLoading] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [selectedItem, setSelectedItem] = useState(-1)
	const navigate = useNavigate()

	const [texts, setTexts] = useState({
		items: [],
	} as brandguardapi.TextSearchResponse)

	const bearertoken = sessions.useToken()
	const [searchReq, setSearchReq] = useState({
		offset: uuid.NIL,
		brand_id: brand.id,
	} as brandguardapi.TextSearchRequest)

	useEffect(() => {
		if (searchReq.brand_id === uuid.NIL) return
		search()
	}, [searchReq])

	useEffect(() => {
		setSearchReq({ ...searchReq, brand_id: brand.id })
	}, [brand.id])

	const search = (): void => {
		setLoading(true)
		const piu = brandguardapi.text.training.search(searchReq, searchReq.brand_id, bearertoken)
		Promise.all([piu])
			.then((result) => {
				const [uploads] = result
				setTexts({ items: uploads.items || [] })
			})
			.catch((c: unknown) => {
				setCause(
					<errors.Inline>
						<errors.Textual onClick={() => setCause(undefined)}>Unable to retrieve Images</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const onTextUpload = (item: brandguardapi.TextSearchResponseItem): void => {
		setTexts((prevState) => ({
			items: [item, ...(prevState.items || [])],
		}))
	}

	const uploadFiles = (accepted: File[], rejected: FileRejection[]): void => {
		console.log(rejected)
		setProcessing(true)
		Array.from(accepted).forEach((file) => {
			Papa.parse(file, {
				complete: function (results) {
					const promises: Promise<brandguardapi.TextUploadResponse>[] = []
					results.data.forEach((line) => {
						const file = new File(line as string[], "foo.txt", { type: "text/plain" })
						promises.push(brandguardapi.text.training.upload(file, brand.id, bearertoken))
					})
					Promise.all(promises)
						.then((results) => {
							results.forEach((result) => {
								onTextUpload(result)
							})
						})
						.catch(console.error)
						.finally(() => setProcessing(false))
				},
			})
		})
	}

	const onTextUpdate = (item: brandguardapi.TextSearchResponseItem): void => {
		setTexts((prevState) => ({
			items: prevState.items.map((i) => (i.media?.id === item.media?.id ? item : i)),
		}))
	}

	const onDeleteFile = (item: brandguardapi.TextSearchResponseItem): void => {
		const upd = item
		upd.media!.tombstoned_at = new Date().toISOString()
		brandguardapi.text.training
			.patch(item.media!.brand_id, item.media!.id, upd, bearertoken)
			.then((r) => {
				const upditems = texts.items.filter((i) => i.media?.id !== item.media?.id)
				setTexts({ items: upditems })
				if (upditems.length === 0) search()
			})
			.finally(() => setLoading(false))
	}

	return (
		<layouts.loading.pending loading={loading}>
			{debugx.alpha.enabled() && cause}
			<layouts.containers.flex justifyContent="center" alignItems="start" flexDirection="row">
				{/* Ability to add new text file while the design is not complete */}
				<layouts.containers.flex className="left-sidebar" width="10%" flexDirection="column">
					<layouts.containers.flex
						flexDirection="column"
						height="100%"
						mr="8px"
						borderRadius="10px"
						alignItems="center"
						display={texts.items.length !== 0 ? "flex" : "none"}
					>
						<inputs.Dropwell
							accept={acceptedTypes}
							onDrop={(accepted, rejected, evt) => uploadFiles(accepted, rejected)}
							width="100%"
							multiple
						>
							<StyledBtn>
								<icons.Upload fill={layouts.theme.colors.blue} width="20" height="15" mr="8px" mb="-2px" />
								Upload CSV
							</StyledBtn>
						</inputs.Dropwell>
					</layouts.containers.flex>
				</layouts.containers.flex>
				{/* end */}
				<layouts.containers.flex width="1440px" flexDirection="column">
					<layouts.containers.flex className="training-tabs" flexDirection="row" pl="25px">
						<TabOutlineButton onClick={() => navigate(routing.training.images(brand.id))}>Images</TabOutlineButton>
						<TabPrimaryButton>Text</TabPrimaryButton>
					</layouts.containers.flex>
					<layouts.containers.flex className="training-working-area" flexDirection="row" height="100%">
						{texts.items.length === 0 ? (
							processing ? (
								<Inprocess />
							) : (
								<Upload
									acceptedTypes={acceptedTypes}
									onUpload={(accepted, rejected) => uploadFiles(accepted, rejected)}
								/>
							)
						) : (
							<>
								<layouts.containers.flex flexDirection="column" className="main-container" width="80%">
									<layouts.containers.flex
										p="25px"
										background={layouts.theme.colors.white}
										borderRadius="10px"
										flexDirection="column"
										textAlign="center"
										marginBottom="45px"
										overflowY="auto"
										maxHeight="75vh"
									>
										<layouts.containers.grid
											justifyContent="center"
											display="grid"
											gridGap="50px"
											px="10px"
											py="30px"
											gridTemplateColumns="repeat(auto-fill, 380px)"
											gridTemplateRows="max-content"
										>
											{texts.items.map((text, index) => (
												<TextCard
													key={text.media?.id}
													item={text}
													active={index === selectedItem}
													onCardClick={() => setSelectedItem(index)}
													onTextUpdate={onTextUpdate}
												/>
											))}
										</layouts.containers.grid>
									</layouts.containers.flex>
								</layouts.containers.flex>
								<layouts.containers.flex className="right-sidebar" width="24%" flexDirection="column" p="0 10px">
									<TextDetails item={texts.items[selectedItem]} onDelete={(item) => onDeleteFile(item)} />
								</layouts.containers.flex>
							</>
						)}
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.loading.pending>
	)
}
