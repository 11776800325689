/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "meta.search.proto" (package "meta", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message meta.DateRange
 */
export interface DateRange {
    /**
     * @generated from protobuf field: string oldest = 1;
     */
    oldest: string;
    /**
     * @generated from protobuf field: string newest = 2;
     */
    newest: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class DateRange$Type extends MessageType<DateRange> {
    constructor() {
        super("meta.DateRange", [
            { no: 1, name: "oldest", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "newest", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DateRange>): DateRange {
        const message = { oldest: "", newest: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DateRange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DateRange): DateRange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string oldest */ 1:
                    message.oldest = reader.string();
                    break;
                case /* string newest */ 2:
                    message.newest = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DateRange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string oldest = 1; */
        if (message.oldest !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.oldest);
        /* string newest = 2; */
        if (message.newest !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.newest);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.DateRange
 */
export const DateRange = new DateRange$Type();
