import React from "react"
import * as layouts from "layouts"

export default function Tab(props: { title: string } & React.PropsWithChildren<unknown>): JSX.Element {
	const { children } = props
	return (
		<layouts.containers.flex px="25px" overflowY="auto" width="100%">
			{children}
		</layouts.containers.flex>
	)
}
