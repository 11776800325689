import React, { useState, useEffect } from "react"
import * as uuid from "uuid"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as brands from "brands"
import * as api from "brandguard/api"
import MainContainer from "./main.container"
import TextsUpload from "./texts.upload"
import TextInsert from "./text.insert"
import DisplayText from "./text"
import FileDetails from "./text.details"

export default function Texts(props: React.PropsWithChildren<unknown>): JSX.Element {
	const [loading, setLoading] = useState(false)
	const [selectedItem, setSelectedItem] = useState(-1)
	const [texts, setTexts] = useState({
		items: [],
	} as api.TextSearchResponse)
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const [searchReq, setSearchReq] = useState({
		offset: uuid.NIL,
		brand_id: brand.id,
	} as api.TextSearchRequest)

	useEffect(() => {
		if (searchReq.brand_id === uuid.NIL) return
		setLoading(true)
		const piu = api.text.uploads.search(searchReq.brand_id, searchReq, bearertoken)
		const pia = api.text.approvallog.search(searchReq.brand_id, searchReq, bearertoken)
		Promise.all([piu, pia])
			.then((result) => {
				const [uploads, approvallog] = result
				const items = [...(uploads.items || []), ...(approvallog.items || [])].sort((a, b) =>
					a.media?.created_at! > b.media?.created_at! ? -1 : 1,
				)
				setTexts({ items })
			})
			.catch(console.error)
			.finally(() => {
				setLoading(false)
			})
	}, [searchReq])

	useEffect(() => {
		setSearchReq({ ...searchReq, brand_id: brand.id })
	}, [brand.id])

	const onTextUpload = (item: api.TextSearchResponseItem): void => {
		setTexts((prevState) => ({
			items: [...([item] || []), ...(prevState.items || [])],
		}))
	}

	const onTextUpdate = (item: api.TextSearchResponseItem): void => {
		setTexts((prevState) => ({
			items: prevState.items.map((i) => (i.media?.id === item.media?.id ? item : i)),
		}))
	}

	return (
		<layouts.loading.screen loading={loading} flex="1" height="100%" icon={<></>}>
			<layouts.containers.flex className="brandguard-working-area" flexDirection="row" height="100%">
				<layouts.containers.flex className="left-sidebar" width="15%" flexDirection="column">
					<layouts.containers.flex
						flexDirection="column"
						height="100%"
						m="30px 8px 70px 0"
						background={layouts.theme.colors.white}
						p="30px 15px"
						borderRadius="10px"
						alignItems="center"
					>
						{texts.items.length !== 0 && <TextsUpload asButton={true} onUpload={onTextUpload} />}
						<TextInsert onUpload={onTextUpload} />
					</layouts.containers.flex>
				</layouts.containers.flex>
				<MainContainer className="main-container" width="67%" mb="70px">
					{texts.items.length === 0 ? (
						<TextsUpload onUpload={onTextUpload} />
					) : (
						<layouts.containers.grid
							justifyContent="center"
							display="grid"
							gridGap="50px"
							px="10px"
							py="30px"
							gridTemplateColumns="repeat(auto-fill, 380px)"
							gridTemplateRows="max-content"
						>
							{texts.items.map((text, index) => (
								<DisplayText
									key={text.media?.id}
									item={text}
									active={index === selectedItem}
									onCardClick={() => setSelectedItem(index)}
									onTextUpdate={onTextUpdate}
								/>
							))}
						</layouts.containers.grid>
					)}
				</MainContainer>
				<layouts.containers.flex className="right-sidebar" width="18%" flexDirection="column" p="30px 0 30px 10px">
					<FileDetails item={texts.items[selectedItem]} />
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.loading.screen>
	)
}
