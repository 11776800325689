import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as sessions from "sessions"
import * as errors from "errors"
import * as layouts from "layouts"
import * as icons from "icons"
import * as api from "./api"
import * as caching from "./cache"
import * as edit from "./edit"
import { SearchRequest, SearchResponse } from "./account.brand"
import * as typography from "typography"
import * as inputs from "inputs"
import * as routing from "./routing"
import styled from "@emotion/styled"
import noImage from "images/no-image.svg"
import fetchFavicon from "favicon-fetch"

const Tile = styled.div`
	border-radius: 10px;
	padding: 20px;
	min-width: 300px;
	max-width: 350px;
	min-height: 350px;
	max-height: 350px;
	background: ${layouts.theme.colors.white};
	text-align: center;

	&:hover {
		outline: 0.1rem solid;
		outline-color: ${layouts.theme.colors.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}
`

const DomainLink = styled(typography.h4)`
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${layouts.theme.colors.grey.medium};
	text-align: left;
	padding: 2px 25px 0px 25px;
`

interface props {
	search(req: api.SearchRequest, ...options: httpx.option[]): Promise<api.SearchResponse>
}

export default function Grid(props: props): JSX.Element {
	const { search } = props
	const mtoggle = layouts.modals.useToggle()
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)
	const [breq, setBrandRequest] = useState({
		offset: uuid.NIL,
		query: "",
	} as SearchRequest)
	const [brands, setBrands] = useState({
		next: breq,
		items: [],
	} as SearchResponse)
	const bearertoken = sessions.useToken()

	useEffect(() => {
		setLoading(true)
		search(breq, bearertoken)
			.then((brands) => {
				setBrands(brands)
			})
			.catch((c: unknown) => {
				setCause(
					<errors.Inline>
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve brands</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
	}, [breq])

	return (
		<layouts.containers.flex className="brands-display" p="50px" pt="25px" width="100%" flexDirection="column">
			<layouts.containers.flex display="table" width="100%" flexDirection="row" textAlign="center" mb="50px">
				<layouts.containers.flex display="table-cell" textAlign="left" justifyContent="left">
					<typography.h3 color={layouts.theme.colors.grey.medium}>Brands</typography.h3>
				</layouts.containers.flex>
				<layouts.containers.flex display="table-cell">
					<layouts.containers.flex width="40%" display="inline-flex" justifyContent="center">
						<inputs.Text
							className="outline"
							icon={
								<inputs.icons.left>
									<icons.Search />
								</inputs.icons.left>
							}
							placeholder="Search"
							defaultValue={breq.query}
							onChange={(evt) => {
								setBrandRequest({ ...breq, query: evt.currentTarget.value })
							}}
						/>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex display="table" width="10%" textAlign="center" pb="5px">
				<layouts.containers.flex
					display="table-cell"
					onClick={(evt) => {
						mtoggle(
							<edit.New
								onChange={(brand) => {
									api.create({ brand: brand }, bearertoken).then((r) => {
										setBrands({
											...brands,
											items: [r.brand!].concat(...brands.items),
										})
										mtoggle(undefined)
										// mtoggle(<edit.AdConnect />) TODO reenable.
									})
								}}
							/>,
						)
					}}
				>
					<icons.New></icons.New>
				</layouts.containers.flex>
			</layouts.containers.flex>
			{cause}
			<layouts.loading.pending loading={loading}>
				<layouts.overlays.Container flex="1">
					<layouts.containers.grid
						justifyContent="center"
						flex="1"
						overflowY="auto"
						display="grid"
						gridGap="25px"
						px="50px"
						py="10px"
						gridTemplateColumns="repeat(auto-fill, 350px)"
					>
						{brands.items.map((b, index) => (
							<Tile key={b.id}>
								<layouts.containers.flex
									width="100%"
									height="220px"
									justifyContent="center"
									alignItems="center"
									background={layouts.theme.colors.grey.light05}
								>
									<layouts.images.Base
										alt="logo"
										src={fetchFavicon({ uri: b.domain })}
										height="64px"
										onError={(evt) => (evt.currentTarget.src = noImage)}
									/>
								</layouts.containers.flex>
								<typography.h2 color={layouts.theme.colors.blue} textAlign="left" pt="40px" pl="25px">
									<Link to={routing.ads(b.id)} className="ads" onClick={() => api.setLastBrandId(b.account_id, b.id)}>
										{b.description}
									</Link>
								</typography.h2>
								<DomainLink>{b.domain}</DomainLink>
								<layouts.containers.flex
									ml="auto"
									width="50px"
									onClick={(evt) => {
										mtoggle(
											<edit.Edit
												current={b}
												onChange={(upd) => {
													api.update({ brand: upd }, bearertoken).then((r) => {
														setBrands({
															...brands,
															items: brands.items.map((el) => (el.id === r.brand?.id ? r.brand : el)),
														})
														caching.cache.set(r.brand!.id, r.brand)
													})
													mtoggle(undefined)
												}}
											></edit.Edit>,
										)
									}}
								>
									<typography.h6 color={layouts.theme.colors.blue} fontWeight="400" fontSize="14px" pt="10px">
										Edit
									</typography.h6>
								</layouts.containers.flex>
							</Tile>
						))}
					</layouts.containers.grid>
					<layouts.overlays.Screen enabled={brands.items.length === 0 && breq.query === ""} display="flex">
						{/* handle zero case for a new accounts */}
						<layouts.containers.flex m="auto">
							<edit.New
								onChange={(b) => {
									api.create({ brand: b }, bearertoken).then((r) => {
										setBrands({
											...brands,
											items: [r.brand!].concat(...brands.items),
										})
										api.setLastBrandId(r.brand!.account_id, r.brand!.id)
										caching.cache.set(r.brand!.id, r.brand)
									})
								}}
							/>
						</layouts.containers.flex>
					</layouts.overlays.Screen>
				</layouts.overlays.Container>
			</layouts.loading.pending>
			<layouts.pagination.Cursor
				p="50px"
				justifyContent="center"
				current={breq.offset}
				advance={brands.next?.offset === "" ? undefined : brands.next?.offset}
				onChange={(next) => next && setBrandRequest({ ...breq, offset: next })}
			/>
		</layouts.containers.flex>
	)
}

Grid.defaultProps = {
	search: api.search,
}
