import { DateTime } from "luxon"
import * as timex from "timex"
import { Profile } from "./meta.profile"
export type { Profile } from "./meta.profile"

export const missing = zero({})

export function zero(p: Partial<Profile> = {}): Profile {
	const ts = DateTime.now().toISO()
	return {
		id: "00000000-0000-0000-0000-000000000000",
		account_id: "00000000-0000-0000-0000-000000000000",
		display: "",
		email: "",
		created_at: ts,
		updated_at: ts,
		disabled_at: timex.infinity().toISO(),
		disabled_manually_at: timex.infinity().toISO(),
		disabled_pending_approval_at: timex.infinity().toISO(),
		disabled_invited_at: timex.infinity().toISO(),
		session_watermark: "",
		...p,
	}
}

export function disabled(p: Profile): boolean {
	let ts = DateTime.now()
	let disabled = DateTime.min(
		DateTime.fromISO(p.disabled_at, { setZone: true }),
		DateTime.fromISO(p.disabled_manually_at, { setZone: true }),
		DateTime.fromISO(p.disabled_pending_approval_at, { setZone: true }),
		DateTime.fromISO(p.disabled_invited_at, { setZone: true }),
	)
	return disabled <= ts
}

export function enabled(p: Profile): boolean {
	return !disabled(p)
}
