import { useEffect, useState } from "react"
import { useLocation, useNavigate, Location } from "react-router-dom"
import styled from "@emotion/styled"
import * as uuid from "uuid"
import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"
import * as logos from "titlebars/logos"
import * as httpx from "httpx"
import * as errors from "errors"
import * as sessions from "./session"
import * as api from "./api"
import Multiaccount from "./multiaccount"
import Available from "./available"
import Signup from "./signup"

interface props {
	authd(location: Location): Promise<api.Authed>
	autoloading?: boolean
}

const SpinnerIcon = styled(icons.loading.Ring1)`
	margin: auto;
	width: 80px;
	height: 80px;
`

async function findsuggested(authd: Promise<api.Authed>): Promise<api.AvailableResponse> {
	return authd.then((authd) => api.accounts.available({ offset: uuid.NIL }, httpx.options.bearer(authd.signup_token)))
}

export function Auth(props: props): JSX.Element {
	const { autoloading = false } = props
	const session = sessions.useSession()
	const location = useLocation()
	const navigate = useNavigate()

	const [cause, setCause] = useState(undefined as undefined | JSX.Element)
	const [loading, setLoading] = useState(true)
	const [authd, setAuthd] = useState({
		user: {
			id: "",
			display: "",
			email: "",
		},
		redirect: "",
		signup_token: "",
		profiles: [],
	} as api.Authed)
	const [suggested, setSuggested] = useState({
		cursor: { offset: uuid.NIL },
		items: [],
	} as api.AvailableResponse)

	useEffect(() => {
		let pauthd = props.authd(location)
		let suggestions = findsuggested(pauthd)
		Promise.all([pauthd, suggestions])
			.then(async ([authd, suggestions]) => {
				setAuthd(authd)
				setSuggested(suggestions)
				// if autoloading is enabled and we only have a single profile available and no suggested accounts
				// then automatically log in.
				if (!(autoloading && authd.profiles.length === 1 && suggestions.items.length === 0)) {
					return
				}

				const p = authd.profiles[0]
				await api.current(httpx.options.bearer(p.token)).then((current) => {
					session.replace({
						...session,
						unauthenticated: false,
						credentials: api.login(current.token, current!.account!.id),
						profile: current.profile || session.profile,
					})
					navigate(authd.redirect)
				})
			})
			.catch((cause) => {
				console.error("unable to authenticate", cause)
				navigate("/")
			})
			.finally(() => setLoading(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<layouts.backgrounds.Grey>
			<layouts.containers.flex className="authenticated" flexDirection="column" width="100vw" height="100vh">
				<layouts.containers.flex className="navigation" mt="40px" mx="20px" px="120px">
					<logos.Logo />
				</layouts.containers.flex>
				<layouts.containers.flex
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					height="100vh"
					width="100vw"
				>
					<typography.h1 color={layouts.theme.colors.grey.medium} margin="0 25px 25px">
						Select Account to Sign in With
					</typography.h1>

					<errors.overlay styled cause={cause} width="100%">
						<layouts.containers.flex
							styled
							flexDirection="column"
							justifyContent="center"
							alignContent="space-around"
							width="608px"
							minHeight="240px"
							borderRadius="20px"
							padding="60px"
						>
							<layouts.loading.pending
								loading={loading}
								margin="auto"
								icon={<SpinnerIcon foreground={layouts.theme.colors.hinting.lightest} />}
							>
								<Multiaccount
									authn={authd.profiles}
									onChange={(pending) => {
										pending
											.then((current) => {
												session.replace({
													...session,
													unauthenticated: false,
													credentials: api.login(current.token, current!.account!.id),
													profile: current.profile || session.profile,
													account: current.account || session.account,
												})
											})
											.catch((cause) => {
												setCause(<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />)
											})
									}}
								/>
								<Available
									accounts={suggested}
									onChange={(pending) => {
										pending
											.then((current) => {
												console.log("joined", current)
												session.replace({
													...session,
													unauthenticated: false,
													credentials: api.login(current.token, current!.account!.id),
													profile: current.profile || session.profile,
													account: current.account || session.account,
												})
											})
											.catch((cause) => {
												setCause(
													<errors.UnknownCause
														cause={cause}
														onClick={() => {
															setCause(undefined)
															navigate("/")
														}}
													/>,
												)
											})
									}}
								/>
								<Signup
									authd={authd}
									onChange={(pending) => {
										pending
											.then((current) => {
												session.replace({
													...session,
													unauthenticated: false,
													credentials: api.login(current.token, current!.account!.id),
													profile: current.profile || session.profile,
													account: current.account || session.account,
												})
											})
											.catch((cause) => {
												setCause(
													<errors.UnknownCause
														cause={cause}
														onClick={() => {
															setCause(undefined)
															navigate("/")
														}}
													/>,
												)
											})
									}}
								/>
							</layouts.loading.pending>
						</layouts.containers.flex>
					</errors.overlay>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}

async function oauth2(
	location: Location,
	platform: (code: string, state: string, scope: string) => Promise<api.Authed>,
): Promise<api.Authed> {
	const urlparams = httpx.qs.parse(location.search) as {
		code: string
		state: string
		scope: string
	}

	return platform(urlparams.code, urlparams.state, urlparams.scope).then((resp) => ({
		...resp,
		profiles: resp.profiles || [],
	}))
}

export function gsuite(location: Location): Promise<api.Authed> {
	return oauth2(location, api.authn.gsuite)
}

export function facebook(location: Location): Promise<api.Authed> {
	return oauth2(location, api.authn.facebook)
}
