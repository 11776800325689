import React, { useState, useContext, useEffect } from "react"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as httpx from "httpx"
import * as errors from "errors"
import * as brands from "brands"
import * as adgen from "ads/adgen"
import * as context from "./adgen.link.context"
import * as api from "./api"
import Messages from "./adgen.link.generated.message"
import Instructions from "./adgen.link.generated.instructions"
import { StyledTextArea } from "ads/adgen/adgen.content.layouts"

interface props {
	onChange(...updates: context.mutation[]): void
}

export default function Container(props: React.PropsWithChildren<props> & layouts.containers.FlexProps): JSX.Element {
	const maxGeneratedLength = 4
	const { onChange } = props
	const brand = brands.caching.useCached()
	const [loading, setLoading] = useState(false)
	const [refilling, setRefilling] = useState(false)
	const genctx = useContext(context.Context)
	const bearertoken = sessions.useToken()
	const [genReq, setGenReq] = useState(
		api.ads.link.generate.request({
			query: api.ads.link.query({
				brand: brand.description,
			}),
		}),
	)

	async function getbuffered(): Promise<context.cached> {
		return api.ads.link.generate.get(genReq, bearertoken).then((resp): context.cached => {
			setGenReq({ ...genReq, query: resp.query || genReq.query })
			return resp.items.reduce(
				(content, gen) =>
					context.cache.zero({
						headlines: [...content.headlines, ...gen.names.map((v) => adgen.text.sample(v))],
						descriptions: [...content.descriptions, ...gen.descriptions.map((v) => adgen.text.sample(v))],
						messages: [...content.messages, ...gen.messages.map((v) => adgen.text.sample(v))],
					} as context.cached),
				context.cache.zero(),
			)
		})
	}

	async function refill() {
		return getbuffered().then((content) => {
			onChange(context.mutate.buffered(content))
		})
	}

	function generate() {
		onChange(context.mutate.generated(<layouts.loading.screen loading flex="1" />))
		setLoading(true)
		getbuffered()
			.then((content) => {
				onChange(
					context.mutate.buffered(content),
					context.mutate.generated(<Messages key="messages" onChange={onChange}></Messages>),
				)
			})
			.catch(
				httpx.errors.forbidden((cause) => {
					console.warn("insufficient priviledges to generate an ad", cause)
					onChange(
						context.mutate.generated(
							<layouts.containers.box styled m="auto">
								<errors.Textual onClick={() => onChange(context.mutate.generated(<Instructions />))}>
									you do not have permission to generate ads content
								</errors.Textual>
							</layouts.containers.box>,
						),
					)
				}),
			)
			.catch((cause) => {
				console.error("unable to create ad", cause)
				onChange(
					context.mutate.generated(
						<layouts.containers.flex styled m="auto" width="100%" height="100%">
							<errors.Textual onClick={() => onChange(context.mutate.generated(<Instructions />))}>
								unable to generate content
							</errors.Textual>
						</layouts.containers.flex>,
					),
				)
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		if (refilling || genReq.query?.prompt.length === 0) {
			return
		}

		if (
			genctx.buffered.descriptions.length >= maxGeneratedLength &&
			genctx.buffered.headlines.length >= maxGeneratedLength &&
			genctx.buffered.messages.length >= maxGeneratedLength
		) {
			return
		}

		setRefilling(true)
		refill().finally(() => setRefilling(false))
	}, [genctx.buffered])

	const generateDisabled = (): boolean => genReq.query?.prompt.length === 0

	return (
		<>
			<layouts.containers.flex width="55%">
				<StyledTextArea
					value={genReq.query?.prompt}
					onChange={(evt) =>
						setGenReq({ ...genReq, query: api.ads.link.query({ ...genReq.query, prompt: evt.target.value }) })
					}
				/>
			</layouts.containers.flex>
			<layouts.containers.flex
				width="50%"
				justifyContent="center"
				paddingTop="10px"
				flexDirection="column"
				alignItems="center"
			>
				<layouts.buttons.primary
					disabled={generateDisabled() || loading}
					mt="25px"
					onClick={generate}
					borderRadius="37px"
					width="150px"
					height="50px"
				>
					Generate
				</layouts.buttons.primary>
			</layouts.containers.flex>
		</>
	)
}
