import * as layouts from "layouts"
import * as icons from "icons"
import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

interface locationState {
	name: string
	photo: string
	processes: { name: string; background: string; photo: string; date: string; status: string }[]
}
export default function Processes(): JSX.Element {
	const [items, setItems] = useState([] as locationState["processes"])
	const location = useLocation()
	const infstate = (location.state as locationState) || {
		name: "",
		photo: "",
		processes: { name: "", background: "", photo: "", date: "", status: "" },
	}

	function remove(id: number) {
		setItems((current) =>
			current.filter((item) => {
				return item !== items[id]
			}),
		)
	}
	function cancel(id: number) {
		setItems((current) => {
			return current.map((item) => {
				if (item === items[id] && item.status !== "Canceled") {
					item.status = "Canceled"
					return item
				}
				return item
			})
		})
	}
	useEffect(() => {
		const lc = location.state as locationState
		if (lc) {
			const itemList = lc.processes.map((item) => ({
				...item,
			}))
			setItems(itemList)
		}
	}, [location])

	const Status = (props: { status: string }) => {
		if (props.status === "Completed") {
			return <layouts.containers.span color="green">{props.status}</layouts.containers.span>
		}
		if (props.status === "Canceled") {
			return <layouts.containers.span color="red">{props.status}</layouts.containers.span>
		}
		if (props.status.includes("In Progress")) {
			const progress = props.status.split("(")
			return (
				<div>
					In Progress
					<br />({progress[1]}
				</div>
			)
		}
		return <div>{props.status}</div>
	}
	const Btn = (props: { status: string; id: number }) => {
		if (props.status === "Completed") {
			return (
				<Link to="products" state={{ name: infstate.name, photo: infstate.photo, processes: items[props.id] }}>
					<layouts.buttons.primary width="75%">View</layouts.buttons.primary>
				</Link>
			)
		}
		if (props.status.includes("In Progress") || props.status.includes("Queued")) {
			return (
				<layouts.buttons.danger width="75%" onClick={() => cancel(props.id)}>
					Cancel
				</layouts.buttons.danger>
			)
		}
		return <></>
	}

	return (
		<layouts.containers.box styled width="75%" m="auto">
			<layouts.containers.span fontWeight="bold" fontSize="100%" p="20px">
				Item List
			</layouts.containers.span>
			<layouts.containers.flex p="5vh">
				<img src={infstate.photo} alt="img" />{" "}
				<layouts.containers.box mt="10vh" pl="30px" fontWeight="bold">
					{" "}
					{infstate.name}
				</layouts.containers.box>
			</layouts.containers.flex>
			<layouts.tables.container>
				<table>
					<thead>
						<tr>
							<th>PRODUCT/ITEM</th>
							<th>DATE CREATED</th>
							<th>CREATION STATUS</th>
						</tr>
					</thead>
					<tbody>
						{items.map((item, idx) => (
							<layouts.tables.Row key={idx}>
								<td width="40%">
									<b>{item.name}</b>
								</td>
								<td>
									<div>{item.date}</div>
								</td>
								<td>
									<Status status={item.status} />
								</td>
								<td>
									<Btn id={idx} status={item.status} />
								</td>
								<td onClick={() => remove(idx)}>
									<icons.Trash />
								</td>
							</layouts.tables.Row>
						))}
					</tbody>
				</table>
				<Link to="../upload">
					<div>
						<icons.Plus />
					</div>
				</Link>
			</layouts.tables.container>
		</layouts.containers.box>
	)
}
