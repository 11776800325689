import { useState, useEffect } from "react"
import { Route, Routes, useParams } from "react-router-dom"
import * as sessions from "sessions"
import * as brands from "brands"
import * as layouts from "layouts"
import * as brandguard from "."
import * as routing from "./routing"
import * as display from "./display/"

export function BrandguardBase(): JSX.Element {
	return (
		<brandguard.Container urlgen={routing.root}>
			<display.DisplayImages />
		</brandguard.Container>
	)
}

export function BrandguardImages(): JSX.Element {
	return (
		<brandguard.Container urlgen={routing.brandguard.images}>
			<display.DisplayImages />
		</brandguard.Container>
	)
}

export function BrandguardText(): JSX.Element {
	return (
		<brandguard.Container urlgen={routing.brandguard.text}>
			<display.Texts />
		</brandguard.Container>
	)
}

export default function Routing(): JSX.Element {
	const params = useParams() as {
		bid: string
	}
	const [brand, setBrand] = useState(brands.caching.useCached())
	const bearertoken = sessions.useToken()

	useEffect(() => {
		brands.caching.current(params.bid, bearertoken).then((result) => setBrand(brands.api.zero(result)))
	}, [params.bid])

	return (
		<layouts.backgrounds.Grey flex="0 1 100%" height="100%" overflowY="hidden">
			<brands.caching.Provider value={brand}>
				<Routes>
					<Route path="/images" element={<BrandguardImages />} />
					<Route path="/text" element={<BrandguardText />} />
					<Route path="*" element={<BrandguardBase />} />
				</Routes>
			</brands.caching.Provider>
		</layouts.backgrounds.Grey>
	)
}
