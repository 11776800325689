import React from "react"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as api from "./api"

interface props {
	authd: api.Authed
	onChange(c: Promise<api.CurrentSession>): void
}

export default function Signup(props: React.PropsWithChildren<props>): JSX.Element {
	return (
		<layouts.containers.flex className="signup" marginTop="40px" width="100%">
			<layouts.buttons.outline
				m="10px auto"
				onClick={() => {
					props.onChange(api.signup(httpx.options.bearer(props.authd.signup_token)))
				}}
			>
				Create a New Account
			</layouts.buttons.outline>
		</layouts.containers.flex>
	)
}
