import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import * as layouts from "layouts"
import * as brands from "brands"
import * as pools from "ads/pools"
import { TabPrimaryButton, TabOutlineButton } from "ads/adgen/adgen.content.layouts"
import * as routing from "ads/suggestions/routing"
import { default as Generated } from "./generated"
import * as api from "ads/facebook/api"
import * as textgen from "ads/textgen/api"
import * as context from "./context"
import * as adgen from "ads/adgen"
import * as adgenctxcontext from "ads/facebook/adgen.link.context"
import Form from "ads/facebook/adgen.link.form"
import * as sessions from "sessions"
import { css } from "@emotion/css"
import classnames from "classnames"
import * as typography from "typography"

const readonlycss = css`
	pointer-events: none;
`

export default function FacebookSuggestions(): JSX.Element {
	const brand = brands.caching.useCached()
	const pool = pools.caching.useCached()
	const navigate = useNavigate()
	const bearertoken = sessions.useToken()

	const [selectAll, setSelectAll] = useState(false)
	const [genctx, setGenctx] = useState(context.zero() as context.context)
	const [mutations, setMutations] = useState([] as adgenctxcontext.mutation[])

	const asyncupd = (...m: adgenctxcontext.mutation[]) => {
		setMutations([...mutations, ...m])
	}

	useEffect(() => {
		if (mutations.length === 0) return
		const updated = adgenctxcontext.mutate.apply(genctx.adgenctx, ...mutations)
		const updcontx = context.mutate.apply(genctx, ...[context.mutate.ads.modify(updated)])
		setGenctx({ ...genctx, adgenctx: updated, ads: updcontx.ads })
		setMutations([])
	}, [mutations])

	return (
		<context.Provider value={genctx}>
			<layouts.containers.flex flexDirection="column">
				<layouts.containers.flex flexDirection="row" pl="25px">
					<TabOutlineButton onClick={(evt) => navigate(routing.google(brand.id, pool.id))}>Google</TabOutlineButton>
					<TabPrimaryButton>Facebook</TabPrimaryButton>
				</layouts.containers.flex>
				<layouts.containers.flex
					width="100%"
					background={layouts.theme.colors.white}
					borderRadius="10px"
					flexDirection="column"
				>
					<layouts.containers.flex flexDirection="row" width="100%">
						<layouts.containers.flex p="25px" flex="1">
							<typography.h3 color={layouts.theme.colors.grey.medium}>New Suggestions</typography.h3>
						</layouts.containers.flex>
						<layouts.containers.flex p="25px" flex="2" justifyContent="end">
							<layouts.buttons.outline
								width="115px"
								height="35px"
								borderRadius="37px"
								mr="10px"
								fontSize="12px"
								fontWeight="500"
								onClick={() => {
									const toggled = !selectAll
									const upd = genctx.ads.map((a) => ({ ...a, selected: toggled }))
									setGenctx({ ...genctx, ads: upd })
									setSelectAll(toggled)
								}}
								disabled={genctx.ads.length === 0}
							>
								Select All
							</layouts.buttons.outline>
							<layouts.buttons.primary
								width="115px"
								height="35px"
								borderRadius="37px"
								fontSize="12px"
								fontWeight="500"
								disabled={!genctx.ads.reduce((accum, i) => accum || i.selected, false)}
								onClick={() => {
									pools.caching
										.maybe(pool.id, bearertoken)
										.then(async (pool) => {
											return api.accounts.find(pool.facebook_client_account_id, bearertoken).then((ca) =>
												api.ads.link.metadata({
													facebook_client_id: ca.client_account?.id,
												}),
											)
										})
										.then((md) => {
											const muts = [] as context.mutation[]
											genctx.ads.forEach((item) => {
												if (!item.selected) {
													return
												}
												const d = {
													metadata: api.ads.link.metadata({
														...item.metadata,
														facebook_client_id: md.facebook_client_id,
													}),
													content: api.ads.link.zero({
														...item?.content,
														message: item?.content?.message,
														name: item?.content?.name,
														description: item?.content?.description,
													}),
												}
												const samples = [
													adgen.text.sample(item?.content?.name || ""),
													adgen.text.sample(item?.content?.description || ""),
													adgen.text.sample(item?.content?.message || ""),
													...genctx.adgenctx.rejected,
												]
												textgen.create.record(
													{
														samples: samples.filter((i) => i?.generated !== ""),
													},
													bearertoken,
												)
												muts.push(context.mutate.reject(item))
												return api.ads.link.create.post(d, bearertoken)
											})
											if (muts.length > 0) setGenctx(context.mutate.apply(genctx, ...muts))
										})
								}}
							>
								Publish Selected
							</layouts.buttons.primary>
						</layouts.containers.flex>
					</layouts.containers.flex>
					<layouts.containers.flex width="100%" flexDirection="row">
						<layouts.containers.flex
							className={classnames(genctx.adgenctx.content.id === api.ads.link.zero().id ? readonlycss : undefined)}
							flexDirection="column"
							flex="2"
							p="10px"
							pl="25px"
							pb="25px"
							minWidth="315px"
						>
							<Form genctx={genctx.adgenctx} onChange={asyncupd} />
						</layouts.containers.flex>
						<layouts.containers.flex flexDirection="column" flex="7">
							<Generated
								className="generated"
								verticalAlign="top"
								alignItems="start"
								onChange={(...mset: context.mutation[]) => setGenctx(context.mutate.apply(genctx, ...mset))}
							/>
						</layouts.containers.flex>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</context.Provider>
	)
}
