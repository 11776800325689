import React, { useEffect, useCallback, useState } from "react"
import _ from "lodash"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as brandguard from "brandguard"
import * as api from "ads/google/api"
import { Publisher } from "ads/google/adgen.responsive.search.publisher"
import Published from "ads/google/adgen.responsive.search.published"
import * as context from "ads/google/adgen.responsive.search.context"

export default function Publish(props: Publisher): JSX.Element {
	const { metadata, content, disabled, onChange, onPublish } = props
	const [scored, setScored] = useState(metadata)
	const bearertoken = sessions.useToken()

	const refreshscore = useCallback(
		_.debounce(
			(md, c) => {
				api.ads.responsive
					.score({ metadata: md, content: c }, bearertoken)
					.then((r) => setScored(api.ads.responsive.metadata({ ...scored, ...r.metadata })))
					.catch(console.error)
			},
			500,
			{ trailing: true, leading: false },
		),
		[],
	)

	useEffect(() => {
		if (!api.ads.responsive.content.scorable(content)) {
			return
		}

		refreshscore.cancel()
		refreshscore(metadata, content)
		return () => refreshscore.cancel()
	}, [api.ads.responsive.content.digest(content)])

	return (
		<layouts.containers.flex pb="50px" pt="25px" flexDirection="column">
			<brandguard.Score {...scored} />
			<layouts.buttons.primary
				width="150px"
				height="50px"
				borderRadius="37px"
				m="auto"
				disabled={disabled}
				onClick={async () => {
					const req = api.ads.responsive.request({
						metadata: metadata,
						content: content,
					})
					return onPublish(
						api.ads.responsive.create(req, bearertoken).then(() => {
							onChange(context.mutate.generated(<Published onChange={onChange} />))
						}),
					)
				}}
			>
				Save
			</layouts.buttons.primary>
		</layouts.containers.flex>
	)
}
