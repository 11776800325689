/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandguard.image.proto" (package "brandguard.image", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DateRange } from "./meta.search_pb";
import { Prediction } from "./brandguard_pb";
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageEvent
 */
export interface ImageEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: brandguard.Prediction approved = 4;
     */
    approved: Prediction;
    /**
     * @generated from protobuf field: brandguard.Prediction observation = 5;
     */
    observation: Prediction;
    /**
     * @generated from protobuf field: double confidence = 1000;
     */
    confidence: number;
    /**
     * @generated from protobuf field: double brand_uniqueness = 1004 [json_name = "brand_uniqueness"];
     */
    brand_uniqueness: number;
    /**
     * @generated from protobuf field: double brand_voice = 1005 [json_name = "brand_voice"];
     */
    brand_voice: number;
    /**
     * @generated from protobuf field: double sexually_explicit = 1006 [json_name = "sexually_explicit"];
     */
    sexually_explicit: number;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageMedia
 */
export interface ImageMedia {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string uploaded_by = 4 [json_name = "uploaded_by"];
     */
    uploaded_by: string;
    /**
     * @generated from protobuf field: string md5 = 5;
     */
    md5: string;
    /**
     * @generated from protobuf field: string mimetype = 6;
     */
    mimetype: string;
    /**
     * @generated from protobuf field: string mimetype_md5 = 7 [json_name = "mimetype_md5"];
     */
    mimetype_md5: string;
    /**
     * @generated from protobuf field: string created_at = 8 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 9 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: string tombstoned_at = 10 [json_name = "tombstoned_at"];
     */
    tombstoned_at: string;
    /**
     * @generated from protobuf field: string scored_at = 11 [json_name = "scored_at"];
     */
    scored_at: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageUploadRequest
 */
export interface ImageUploadRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageUploadResponse
 */
export interface ImageUploadResponse {
    /**
     * @generated from protobuf field: brandguard.image.ImageMedia media = 1;
     */
    media?: ImageMedia;
    /**
     * @generated from protobuf field: brandguard.image.ImageEvent event = 2;
     */
    event?: ImageEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageSearchRequest
 */
export interface ImageSearchRequest {
    /**
     * @generated from protobuf field: string offset = 1;
     */
    offset: string;
    /**
     * @generated from protobuf field: string brand_id = 4 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: meta.DateRange created = 5;
     */
    created?: DateRange;
    /**
     * @generated from protobuf field: uint64 limit = 6;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageSearchResponseItem
 */
export interface ImageSearchResponseItem {
    /**
     * @generated from protobuf field: brandguard.image.ImageMedia media = 1;
     */
    media?: ImageMedia;
    /**
     * @generated from protobuf field: brandguard.image.ImageEvent event = 2;
     */
    event?: ImageEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageSearchResponse
 */
export interface ImageSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.image.ImageSearchResponseItem items = 1;
     */
    items: ImageSearchResponseItem[];
    /**
     * @generated from protobuf field: brandguard.image.ImageSearchRequest next = 2;
     */
    next?: ImageSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageFindRequest
 */
export interface ImageFindRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageFindResponse
 */
export interface ImageFindResponse {
    /**
     * @generated from protobuf field: brandguard.image.ImageMedia media = 1;
     */
    media?: ImageMedia;
    /**
     * @generated from protobuf field: brandguard.image.ImageEvent event = 2;
     */
    event?: ImageEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageScoreRequest
 */
export interface ImageScoreRequest {
}
/**
 * @generated from protobuf message brandguard.image.ImageScoreResponse
 */
export interface ImageScoreResponse {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImagePatchRequest
 */
export interface ImagePatchRequest {
    /**
     * @generated from protobuf field: brandguard.image.ImageMedia media = 1;
     */
    media?: ImageMedia;
    /**
     * @generated from protobuf field: brandguard.image.ImageEvent event = 2;
     */
    event?: ImageEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImagePatchResponse
 */
export interface ImagePatchResponse {
    /**
     * @generated from protobuf field: brandguard.image.ImageMedia media = 1;
     */
    media?: ImageMedia;
    /**
     * @generated from protobuf field: brandguard.image.ImageEvent event = 2;
     */
    event?: ImageEvent;
}
// @generated message type with reflection information, may provide speed optimized methods
class ImageEvent$Type extends MessageType<ImageEvent> {
    constructor() {
        super("brandguard.image.ImageEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "approved", kind: "enum", T: () => ["brandguard.Prediction", Prediction] },
            { no: 5, name: "observation", kind: "enum", T: () => ["brandguard.Prediction", Prediction] },
            { no: 1000, name: "confidence", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1004, name: "brand_uniqueness", kind: "scalar", localName: "brand_uniqueness", jsonName: "brand_uniqueness", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1005, name: "brand_voice", kind: "scalar", localName: "brand_voice", jsonName: "brand_voice", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1006, name: "sexually_explicit", kind: "scalar", localName: "sexually_explicit", jsonName: "sexually_explicit", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<ImageEvent>): ImageEvent {
        const message = { id: "", account_id: "", brand_id: "", approved: 0, observation: 0, confidence: 0, brand_uniqueness: 0, brand_voice: 0, sexually_explicit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageEvent): ImageEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* brandguard.Prediction approved */ 4:
                    message.approved = reader.int32();
                    break;
                case /* brandguard.Prediction observation */ 5:
                    message.observation = reader.int32();
                    break;
                case /* double confidence */ 1000:
                    message.confidence = reader.double();
                    break;
                case /* double brand_uniqueness = 1004 [json_name = "brand_uniqueness"];*/ 1004:
                    message.brand_uniqueness = reader.double();
                    break;
                case /* double brand_voice = 1005 [json_name = "brand_voice"];*/ 1005:
                    message.brand_voice = reader.double();
                    break;
                case /* double sexually_explicit = 1006 [json_name = "sexually_explicit"];*/ 1006:
                    message.sexually_explicit = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* brandguard.Prediction approved = 4; */
        if (message.approved !== 0)
            writer.tag(4, WireType.Varint).int32(message.approved);
        /* brandguard.Prediction observation = 5; */
        if (message.observation !== 0)
            writer.tag(5, WireType.Varint).int32(message.observation);
        /* double confidence = 1000; */
        if (message.confidence !== 0)
            writer.tag(1000, WireType.Bit64).double(message.confidence);
        /* double brand_uniqueness = 1004 [json_name = "brand_uniqueness"]; */
        if (message.brand_uniqueness !== 0)
            writer.tag(1004, WireType.Bit64).double(message.brand_uniqueness);
        /* double brand_voice = 1005 [json_name = "brand_voice"]; */
        if (message.brand_voice !== 0)
            writer.tag(1005, WireType.Bit64).double(message.brand_voice);
        /* double sexually_explicit = 1006 [json_name = "sexually_explicit"]; */
        if (message.sexually_explicit !== 0)
            writer.tag(1006, WireType.Bit64).double(message.sexually_explicit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageEvent
 */
export const ImageEvent = new ImageEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageMedia$Type extends MessageType<ImageMedia> {
    constructor() {
        super("brandguard.image.ImageMedia", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "uploaded_by", kind: "scalar", localName: "uploaded_by", jsonName: "uploaded_by", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "md5", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "mimetype", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "mimetype_md5", kind: "scalar", localName: "mimetype_md5", jsonName: "mimetype_md5", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "tombstoned_at", kind: "scalar", localName: "tombstoned_at", jsonName: "tombstoned_at", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "scored_at", kind: "scalar", localName: "scored_at", jsonName: "scored_at", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageMedia>): ImageMedia {
        const message = { id: "", account_id: "", brand_id: "", uploaded_by: "", md5: "", mimetype: "", mimetype_md5: "", created_at: "", updated_at: "", tombstoned_at: "", scored_at: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageMedia>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageMedia): ImageMedia {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string uploaded_by = 4 [json_name = "uploaded_by"];*/ 4:
                    message.uploaded_by = reader.string();
                    break;
                case /* string md5 */ 5:
                    message.md5 = reader.string();
                    break;
                case /* string mimetype */ 6:
                    message.mimetype = reader.string();
                    break;
                case /* string mimetype_md5 = 7 [json_name = "mimetype_md5"];*/ 7:
                    message.mimetype_md5 = reader.string();
                    break;
                case /* string created_at = 8 [json_name = "created_at"];*/ 8:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 9 [json_name = "updated_at"];*/ 9:
                    message.updated_at = reader.string();
                    break;
                case /* string tombstoned_at = 10 [json_name = "tombstoned_at"];*/ 10:
                    message.tombstoned_at = reader.string();
                    break;
                case /* string scored_at = 11 [json_name = "scored_at"];*/ 11:
                    message.scored_at = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageMedia, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string uploaded_by = 4 [json_name = "uploaded_by"]; */
        if (message.uploaded_by !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.uploaded_by);
        /* string md5 = 5; */
        if (message.md5 !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.md5);
        /* string mimetype = 6; */
        if (message.mimetype !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.mimetype);
        /* string mimetype_md5 = 7 [json_name = "mimetype_md5"]; */
        if (message.mimetype_md5 !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mimetype_md5);
        /* string created_at = 8 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 9 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.updated_at);
        /* string tombstoned_at = 10 [json_name = "tombstoned_at"]; */
        if (message.tombstoned_at !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.tombstoned_at);
        /* string scored_at = 11 [json_name = "scored_at"]; */
        if (message.scored_at !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.scored_at);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageMedia
 */
export const ImageMedia = new ImageMedia$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageUploadRequest$Type extends MessageType<ImageUploadRequest> {
    constructor() {
        super("brandguard.image.ImageUploadRequest", []);
    }
    create(value?: PartialMessage<ImageUploadRequest>): ImageUploadRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageUploadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageUploadRequest): ImageUploadRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageUploadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageUploadRequest
 */
export const ImageUploadRequest = new ImageUploadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageUploadResponse$Type extends MessageType<ImageUploadResponse> {
    constructor() {
        super("brandguard.image.ImageUploadResponse", [
            { no: 1, name: "media", kind: "message", T: () => ImageMedia },
            { no: 2, name: "event", kind: "message", T: () => ImageEvent }
        ]);
    }
    create(value?: PartialMessage<ImageUploadResponse>): ImageUploadResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageUploadResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageUploadResponse): ImageUploadResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.image.ImageMedia media */ 1:
                    message.media = ImageMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.image.ImageEvent event */ 2:
                    message.event = ImageEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageUploadResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.image.ImageMedia media = 1; */
        if (message.media)
            ImageMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageEvent event = 2; */
        if (message.event)
            ImageEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageUploadResponse
 */
export const ImageUploadResponse = new ImageUploadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageSearchRequest$Type extends MessageType<ImageSearchRequest> {
    constructor() {
        super("brandguard.image.ImageSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created", kind: "message", T: () => DateRange },
            { no: 6, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ImageSearchRequest>): ImageSearchRequest {
        const message = { offset: "", brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageSearchRequest): ImageSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string offset */ 1:
                    message.offset = reader.string();
                    break;
                case /* string brand_id = 4 [json_name = "brand_id"];*/ 4:
                    message.brand_id = reader.string();
                    break;
                case /* meta.DateRange created */ 5:
                    message.created = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* uint64 limit */ 6:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string offset = 1; */
        if (message.offset !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.offset);
        /* string brand_id = 4 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.brand_id);
        /* meta.DateRange created = 5; */
        if (message.created)
            DateRange.internalBinaryWrite(message.created, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint64 limit = 6; */
        if (message.limit !== 0n)
            writer.tag(6, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageSearchRequest
 */
export const ImageSearchRequest = new ImageSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageSearchResponseItem$Type extends MessageType<ImageSearchResponseItem> {
    constructor() {
        super("brandguard.image.ImageSearchResponseItem", [
            { no: 1, name: "media", kind: "message", T: () => ImageMedia },
            { no: 2, name: "event", kind: "message", T: () => ImageEvent }
        ]);
    }
    create(value?: PartialMessage<ImageSearchResponseItem>): ImageSearchResponseItem {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageSearchResponseItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageSearchResponseItem): ImageSearchResponseItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.image.ImageMedia media */ 1:
                    message.media = ImageMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.image.ImageEvent event */ 2:
                    message.event = ImageEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageSearchResponseItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.image.ImageMedia media = 1; */
        if (message.media)
            ImageMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageEvent event = 2; */
        if (message.event)
            ImageEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageSearchResponseItem
 */
export const ImageSearchResponseItem = new ImageSearchResponseItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageSearchResponse$Type extends MessageType<ImageSearchResponse> {
    constructor() {
        super("brandguard.image.ImageSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageSearchResponseItem },
            { no: 2, name: "next", kind: "message", T: () => ImageSearchRequest }
        ]);
    }
    create(value?: PartialMessage<ImageSearchResponse>): ImageSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageSearchResponse): ImageSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.image.ImageSearchResponseItem items */ 1:
                    message.items.push(ImageSearchResponseItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.image.ImageSearchRequest next */ 2:
                    message.next = ImageSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.image.ImageSearchResponseItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ImageSearchResponseItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageSearchRequest next = 2; */
        if (message.next)
            ImageSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageSearchResponse
 */
export const ImageSearchResponse = new ImageSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageFindRequest$Type extends MessageType<ImageFindRequest> {
    constructor() {
        super("brandguard.image.ImageFindRequest", []);
    }
    create(value?: PartialMessage<ImageFindRequest>): ImageFindRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageFindRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageFindRequest): ImageFindRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageFindRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageFindRequest
 */
export const ImageFindRequest = new ImageFindRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageFindResponse$Type extends MessageType<ImageFindResponse> {
    constructor() {
        super("brandguard.image.ImageFindResponse", [
            { no: 1, name: "media", kind: "message", T: () => ImageMedia },
            { no: 2, name: "event", kind: "message", T: () => ImageEvent }
        ]);
    }
    create(value?: PartialMessage<ImageFindResponse>): ImageFindResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageFindResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageFindResponse): ImageFindResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.image.ImageMedia media */ 1:
                    message.media = ImageMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.image.ImageEvent event */ 2:
                    message.event = ImageEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageFindResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.image.ImageMedia media = 1; */
        if (message.media)
            ImageMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageEvent event = 2; */
        if (message.event)
            ImageEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageFindResponse
 */
export const ImageFindResponse = new ImageFindResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageScoreRequest$Type extends MessageType<ImageScoreRequest> {
    constructor() {
        super("brandguard.image.ImageScoreRequest", []);
    }
    create(value?: PartialMessage<ImageScoreRequest>): ImageScoreRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageScoreRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageScoreRequest): ImageScoreRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageScoreRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageScoreRequest
 */
export const ImageScoreRequest = new ImageScoreRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageScoreResponse$Type extends MessageType<ImageScoreResponse> {
    constructor() {
        super("brandguard.image.ImageScoreResponse", []);
    }
    create(value?: PartialMessage<ImageScoreResponse>): ImageScoreResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageScoreResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageScoreResponse): ImageScoreResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageScoreResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageScoreResponse
 */
export const ImageScoreResponse = new ImageScoreResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImagePatchRequest$Type extends MessageType<ImagePatchRequest> {
    constructor() {
        super("brandguard.image.ImagePatchRequest", [
            { no: 1, name: "media", kind: "message", T: () => ImageMedia },
            { no: 2, name: "event", kind: "message", T: () => ImageEvent }
        ]);
    }
    create(value?: PartialMessage<ImagePatchRequest>): ImagePatchRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImagePatchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImagePatchRequest): ImagePatchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.image.ImageMedia media */ 1:
                    message.media = ImageMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.image.ImageEvent event */ 2:
                    message.event = ImageEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImagePatchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.image.ImageMedia media = 1; */
        if (message.media)
            ImageMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageEvent event = 2; */
        if (message.event)
            ImageEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImagePatchRequest
 */
export const ImagePatchRequest = new ImagePatchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImagePatchResponse$Type extends MessageType<ImagePatchResponse> {
    constructor() {
        super("brandguard.image.ImagePatchResponse", [
            { no: 1, name: "media", kind: "message", T: () => ImageMedia },
            { no: 2, name: "event", kind: "message", T: () => ImageEvent }
        ]);
    }
    create(value?: PartialMessage<ImagePatchResponse>): ImagePatchResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImagePatchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImagePatchResponse): ImagePatchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.image.ImageMedia media */ 1:
                    message.media = ImageMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.image.ImageEvent event */ 2:
                    message.event = ImageEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImagePatchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.image.ImageMedia media = 1; */
        if (message.media)
            ImageMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageEvent event = 2; */
        if (message.event)
            ImageEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImagePatchResponse
 */
export const ImagePatchResponse = new ImagePatchResponse$Type();
