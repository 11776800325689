export interface ExchangeRequest {
	code: string
	state: string
	scope: string
}

export interface AuthorizeRequest {
	cid: string
	scopes: string[]
}

export interface AuthorizeResponse {
	cid: string
	url: string
}

export function pendingauth(req: AuthorizeResponse): Promise<AuthorizeResponse> {
	return new Promise((resolve, reject) => {
		const authwindow = window.open(req.url, "")
		if (authwindow === null) {
			reject("unable to open window")
		}

		const check = () => {
			if (authwindow?.closed) {
				resolve(req)
				return
			}
			setTimeout(check, 200)
		}
		authwindow?.focus()
		check()
	})
}
