import React, { useState } from "react"
import * as layouts from "layouts"
import styled from "@emotion/styled"
import { Link, useLocation } from "react-router-dom"
import * as inputs from "inputs"
const Img = styled.img`
	height: inherit;
	width: inherit;
	margin: auto;
	background: #ededed;
	border-radius: 15px;
	border: none;
`
interface locationState {
	name: string
	photo: string
	processes: { name: string; background: string; photo: string; date: string; status: string }[]
}

export default function Upload(): JSX.Element {
	const [background, setBackgroundInner] = useState("")
	const [projectName, setProjectName] = useState("")
	const location = useLocation()
	var infstate = (location.state as locationState) || {
		name: "",
		photo: "",
		processes: { name: "", background: "", photo: "", date: "", status: "" },
	}
	//console.log(processes)
	function setBackground(f: File | undefined) {
		f && setBackgroundInner(URL.createObjectURL(f))
		console.log(background)
	}
	const BtnGroup = () => {
		console.log(background)

		if (!background) {
			return (
				<inputs.File
					onChange={(e) => {
						setBackground(e.currentTarget.files?.length ? e.currentTarget.files[0] : undefined)
					}}
				>
					{" "}
					<layouts.buttons.primary>Upload</layouts.buttons.primary>
				</inputs.File>
			)
		}
		return (
			<>
				<layouts.containers.box textAlign="right">
					<Link to="uploadProd" state={{ infstate: infstate, name: projectName, background: background }}>
						<layouts.buttons.primary>Confirm</layouts.buttons.primary>
					</Link>
				</layouts.containers.box>
			</>
		)
	}

	return (
		<layouts.containers.box styled width="75%" m="auto">
			<layouts.containers.span fontWeight="bold" fontSize="100%" p="20px">
				Upload Backgrounds
			</layouts.containers.span>
			<layouts.containers.box m="auto" p="10px">
				<inputs.Dropwell
					onDrop={(accepted, rejected, evt) => (accepted.length ? setBackground(accepted[0]) : undefined)}
				>
					<layouts.containers.flex flex="1" flexDirection="column" m="auto" height="50vh" width="50vw">
						<Img src={background} />
					</layouts.containers.flex>
				</inputs.Dropwell>
				<layouts.containers.box m="auto" textAlign="center">
					<input
						type="text"
						placeholder="Name your Project"
						value={projectName}
						onChange={(e) => setProjectName(e.target.value)}
					/>
				</layouts.containers.box>
			</layouts.containers.box>
			<layouts.containers.box m="auto" textAlign="center">
				<BtnGroup />
			</layouts.containers.box>
		</layouts.containers.box>
	)
}
