import React from "react"
import styled from "@emotion/styled"
import classnames from "classnames"
import * as layouts from "layouts"

export interface ToggleProps extends layouts.containers.ContainerProps {
	checked: boolean
}

const ToggleBackground = styled.div<ToggleProps>`
	position: relative;
	display: inline-block;
	width: inherit;
	height: inherit;
	background: ${(props) => (props.checked ? layouts.theme.colors.blue : layouts.theme.colors.grey.dark20)};
	border-radius: 0.5em;
`

const ToggleIcon = styled.div<ToggleProps>`
	position: absolute;
	display: inline-block;
	width: 50%;
	height: inherit;
	justify-self: ${(props) => (props.checked ? "flex-end" : "flex-start")};
	background: ${layouts.theme.colors.white};
	border-radius: 0.5em;
`

interface props {
	checked: boolean
	onClick?(): void
}

export function Display(props: React.PropsWithChildren<props & layouts.containers.ContainerProps>): JSX.Element {
	const { className, checked, onClick, ...rest } = props

	return (
		<layouts.containers.box
			className={classnames("toggle", className)}
			display="inline-block"
			width="inherit"
			onClick={onClick}
			{...rest}
		>
			<layouts.containers.box
				position="relative"
				display="inline-grid"
				height="inherit"
				width="inherit"
				cursor="inherit"
			>
				<ToggleBackground checked={checked} />
				<ToggleIcon checked={checked} />
			</layouts.containers.box>
		</layouts.containers.box>
	)
}

Display.defaultProps = { checked: false, height: "16px", width: "32px" }
