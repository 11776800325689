import { useState, useEffect } from "react"
import * as layouts from "layouts"
import Navigation from "./navigation"
import Edit from "./editor"
import * as context from "./context"

interface props {
	program: Promise<context.Program>
}

export function Display(props: props): JSX.Element {
	const [current, setCurrent] = useState(undefined as undefined | context.UI)
	const [program, setProgram] = useState({
		discounts: [],
		displays: [],
		offers: [],
	} as context.Program)

	useEffect(() => {
		props.program.then(setProgram)
	}, [props.program])

	const ctx = {
		program: program,
		edit: setCurrent,
		displays: {
			insert(d: context.Display): void {
				let replaced = false
				let updated = program.displays.map((old) => {
					if (replaced) return old
					replaced = old.properties.discount.code.digest === d.properties.discount.code.digest
					return replaced ? d : old
				})
				updated = replaced ? updated : [...updated, d]
				setProgram({ ...program, displays: updated.sort() })
			},
			remove(d: context.Display): void {
				const updated = program.displays.filter(
					(c) => c.properties.discount.code.digest !== d.properties.discount.code.digest,
				)
				setProgram({ ...program, displays: updated })
			},
		},
		offers: {
			insert(d: context.Offer): void {
				let replaced = false
				let updated = program.offers.map((old) => {
					if (replaced) return old
					replaced = old.offer.id === d.offer.id
					return replaced ? d : old
				})
				updated = replaced ? updated : [...updated, d]
				setProgram({ ...program, offers: updated.sort() })
			},
			remove(d: context.Offer): void {
				const updated = program.offers.filter((c) => c.offer.id !== d.offer.id)
				setProgram({ ...program, offers: updated })
			},
		},
	} as context.default

	return (
		<layouts.containers.flex className="codegen" width="100%" height="auto" p="20px">
			<Navigation flex="1" ctx={ctx} />
			<Edit flex="4" mx="5px" ctx={ctx} current={current} />
		</layouts.containers.flex>
	)
}
