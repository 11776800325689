import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import * as api from "./api"
import * as httputils from "httpx"
import * as sessions from "sessions"
import * as errors from "errors"
import * as layouts from "layouts"

interface props {
	exchange(req: api.ExchangeRequest, ...options: httputils.option[]): Promise<unknown>
}

export default function Authorized(props: React.PropsWithChildren<props>): JSX.Element {
	const { exchange } = props

	const location = useLocation()
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)
	const [loading, setLoading] = useState(true)
	const bearertoken = sessions.useToken()

	useEffect(() => {
		const req = httputils.qs.parse(location.search) as unknown as api.ExchangeRequest
		exchange(req, bearertoken)
			.catch((cause) => {
				setCause(
					<errors.Display cause={cause} styled width="100%">
						<layouts.containers.flex m="auto" p="50px">
							Authorization Failed. Close this window and try again.
						</layouts.containers.flex>
					</errors.Display>,
				)
			})
			.finally(() => setLoading(false))
	}, [location, exchange])

	return (
		<layouts.containers.flex className="authorization" width="100%" height="100vh">
			<layouts.containers.box styled m="auto">
				<layouts.loading.pending loading={loading}>
					<errors.overlay cause={cause}>
						<layouts.containers.box p="50px">
							<layouts.containers.span textAlign="center">
								Success! You&apos;ve successfully authenticated. Please close this window.
							</layouts.containers.span>
						</layouts.containers.box>
					</errors.overlay>
				</layouts.loading.pending>
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}
