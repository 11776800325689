import React, { useContext } from "react"
import * as httpx from "httpx"
import * as caching from "caching"
import * as api from "./api"
import * as timex from "timex"
import { Brand } from "./account.brand"

export const Context = React.createContext<api.Brand>(api.zero())

export const Provider = Context.Provider
export const Consumer = Context.Consumer

export const cache = new caching.Cache({ namespace: "novacloud.brands", ttl: timex.duration.iso("PT1H").toMillis() })

async function cachable(bid: string, ...options: httpx.option[]): Promise<Brand> {
	return api.get(bid, ...options).then((r) => r.brand)
}

// get or load a brand by id.
export async function current(bid: string, ...options: httpx.option[]): Promise<Brand> {
	return cache.maybe(bid, () => cachable(bid, ...options))
}

export function useCached(): Brand {
	return useContext(Context)
}

export default cache
