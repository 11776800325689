import * as authzcache from "authzcache"
import * as sessions from "sessions"
import * as httpx from "httpx"
import * as mauthz from "authz"
import * as metaproto from "./meta.authz"
import * as profiles from "profiles"

export { default as Modify } from "./authz.modify"

export function authz(req: metaproto.AuthzRequest, ...options: httpx.option[]): Promise<metaproto.AuthzResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/meta/authz`, req, ...options)
}

export function grant(req: metaproto.GrantRequest, ...options: httpx.option[]): Promise<metaproto.GrantResponse> {
	return httpx.put(`${httpx.urlstorage.host()}/meta/authz/${req.profile_id}`, req, ...options)
}

export function profile(req: metaproto.ProfileRequest, ...options: httpx.option[]): Promise<metaproto.ProfileResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/meta/authz/${req.profile_id}`, req, ...options)
}

export interface Token extends mauthz.tokens.Token {
	usermanagement: boolean
}

export namespace tokens {
	export function zero(ctx: Partial<Token> = {}): Token {
		return mauthz.tokens.zero({
			usermanagement: false,
			...ctx,
		})
	}

	export function denied(p: profiles.Profile, bearer: string): Token {
		return zero({
			profile_id: p.id,
			account_id: p.account_id,
			bearer: bearer,
		})
	}

	export function authorization(t: Token): metaproto.Authorization {
		return {
			usermanagement: t.usermanagement,
		}
	}

	export function authorizationFromGrant(g: metaproto.GrantResponse): metaproto.Authorization {
		return (
			g.authorization || {
				usermanagement: false,
			}
		)
	}
}

export function useAuthz(session: sessions.Session): mauthz.tokens.Cached<Token> {
	const { profile, credentials, account } = session
	return authzcache.cached(tokens.denied(profile, credentials), async (cached) => {
		return authz({}, sessions.bearertoken(account.id)).then((r) => {
			return tokens.zero({
				...cached.current,
				...mauthz.tokens.clone(r.token),
			})
		})
	})
}
