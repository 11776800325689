import React from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as selector from "brands/selector"

export default function Container(props: React.PropsWithChildren<selector.props>): JSX.Element {
	const { children, urlgen } = props
	return (
		<layouts.backgrounds.Grey className="brandguard-container" flex="0 1 100%">
			<layouts.containers.flex p="25px" className="brandguard" flex="1" flexDirection="column" overflowY="hidden">
				<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="50px">
					<layouts.containers.flex flex="2">
						<typography.h3 color={layouts.theme.colors.grey.medium}>Asset Training</typography.h3>
					</layouts.containers.flex>
					<layouts.containers.flex flex="2" justifyContent="end" lineHeight="36px">
						<selector.BrandSelector urlgen={urlgen} />
					</layouts.containers.flex>
				</layouts.containers.flex>
				{children}
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}
