import React from "react"
import * as typography from "typography"
import * as layouts from "layouts"
import * as authzfacebook from "authz/facebook"

interface props {
	current: undefined | authzfacebook.api.Credential
	onChange(c: undefined | authzfacebook.api.Credential): void
}

export function Authz(props: props & layouts.containers.FlexProps): JSX.Element {
	const { current, onChange, ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.containers.flex {...rest}>
			<layouts.containers.flex
				styled
				borderRadius={layouts.theme.borders.radius.standard}
				className="facebook client"
				flexDirection="column"
				overflow="auto"
				flex="1"
				p="10px"
				zIndex={1}
			>
				<layouts.containers.flex flexDirection="row">
					<typography.h3 width="18ch">Facebook Credentials</typography.h3>
					<authzfacebook.Search
						mx="auto"
						current={current}
						onChange={onChange}
						onCreate={() => {
							return new Promise((resolve, reject) => {
								mtoggle(
									<authzfacebook.Modal
										onChange={(creds) => {
											if (creds === undefined) return reject("cancelled")
											onChange(creds)
											resolve(creds)
										}}
										scopes={[authzfacebook.api.scopes.ads_management, authzfacebook.api.scopes.page_engagement]}
									/>,
								)
							})
						}}
					/>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export default Authz
