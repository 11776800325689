import React, { useState } from "react"
import * as layouts from "layouts"
import { default as md5 } from "md5"
import * as api from "./api"
import SimulationForm from "./simulation.form"
import Chart from "./chart"

// Promotion Testing Simulation
export default function Demo(props: React.PropsWithChildren<unknown>): JSX.Element {
	let [simulationResults, setSimulationResults] = useState([] as api.Impression[])
	let [impressionSimulation, setImpressionSimulation] = useState({
		count: 1000,
		description: "cuddleclones.com",
		start_date: Date.now() - 60 * 60 * 24 * 10 * 1000, // 10 days ago
		end_date: Date.now(),
		browsers: [
			{
				name: "Mobile Safari",
				major_version: "15",
				weight: 383,
				operating_system: "iOS",
				operating_system_version: "15.6.1",
				useragent:
					"Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6.1 Mobile/15E148 Safari/604.1",
			},
			{
				name: "Mobile Safari",
				major_version: "16",
				weight: 113,
				operating_system: "iOS",
				operating_system_version: "16.0",
				useragent:
					"Mozilla/5.0 (iPhone; CPU iPhone OS 16_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.0 Mobile/15E148 Safari/604.1",
			},
			{
				name: "Chrome",
				major_version: "106",
				weight: 68,
				operating_system: "Windows",
				operating_system_version: "10",
				useragent:
					"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/106.0.0.0 Safari/537.36",
			},
			{
				name: "Mobile Safari",
				major_version: "16",
				weight: 58,
				operating_system: "iOS",
				operating_system_version: "16.0.2",
				useragent:
					"Mozilla/5.0 (iPhone; CPU iPhone OS 16_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.0 Mobile/15E148 Safari/604.1",
			},
			{
				name: "Facebook",
				major_version: "",
				weight: 52,
				operating_system: "iOS",
				operating_system_version: "15.6.1",
				useragent:
					"Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/19G82 [FBAN/FBIOS;FBDV/iPhone12,1;FBMD/iPhone;FBSN/iOS;FBSV/15.6.1;FBSS/2;FBID/phone;FBLC/en_US;FBOP/5]",
			},
		],
		zipcodes: [{ code: "00000", weight: 10 }],
		offers: [
			{
				coupon: "1",
				prediction: -1,
				probability: 0,
				weight: 50,
				gid: "3cac1418f92e3c0a4c6241425bcb2626",
				conversion_rate: 1 / 100,
			},
			{
				coupon: "2",
				prediction: 0,
				probability: 0.2,
				weight: 25,
				gid: "3cac1418f92e3c0a4c6241425bcb2626",
				conversion_rate: 1 / 100,
			},
			{
				coupon: "3",
				prediction: 1,
				probability: 0.25,
				weight: 10,
				gid: "3cac1418f92e3c0a4c6241425bcb2626",
				conversion_rate: 1 / 100,
			},
			{
				coupon: "1",
				prediction: -1,
				probability: 0,
				weight: 40,
				gid: "1f57d03d74df42902b3d1d2ef7368cd9",
				conversion_rate: 1 / 100,
			},
			{
				coupon: "2",
				prediction: 0,
				probability: 0.2,
				weight: 24,
				gid: "1f57d03d74df42902b3d1d2ef7368cd9",
				conversion_rate: 1 / 100,
			},
			{
				coupon: "3",
				prediction: 1,
				probability: 0.25,
				weight: 15,
				gid: "1f57d03d74df42902b3d1d2ef7368cd9",
				conversion_rate: 1 / 100,
			},
			{
				coupon: "1",
				prediction: -1,
				probability: 0.125,
				weight: 35,
				gid: "f28d7a942ea468230dc7167210ec2489",
				conversion_rate: 1 / 100,
			},
			{
				coupon: "2",
				prediction: 0,
				probability: 0.2,
				weight: 25,
				gid: "f28d7a942ea468230dc7167210ec2489",
				conversion_rate: 1 / 100,
			},
			{
				coupon: "3",
				prediction: 1,
				probability: 0.125,
				weight: 12,
				gid: "f28d7a942ea468230dc7167210ec2489",
				conversion_rate: 1 / 100,
			},
		],
		browser_incognito: [
			{ value: false, weight: 22 },
			{ value: true, weight: 1 },
		],
		cid: "",
	} as api.ImpressionSimulation)

	function startSimulation(options: api.ImpressionSimulation): void {
		console.log("Start Simulation")
		options.cid = md5(options.description)
		const results: api.Impression[] = []
		const simulation = api.genimpression(options)
		for (let i = 0; i < options.count; i++) {
			results.push(simulation.next().value)
		}
		setSimulationResults(results)
	}

	return (
		<layouts.containers.flex m="auto" width="80%">
			<layouts.containers.box styled width="100%">
				<fieldset>
					<SimulationForm impression={impressionSimulation} onChange={(upd) => setImpressionSimulation(upd)} />
					<layouts.buttons.primary onClick={() => startSimulation(impressionSimulation)}>
						Submit
					</layouts.buttons.primary>
					{simulationResults.length > 0 && <Chart chartData={api.preparePredictionData(simulationResults)} />}
				</fieldset>
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}
