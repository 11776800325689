import React from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import * as layouts from "layouts"
import { ProfilesDisplay as Profiles, PendingDisplay as Pending } from "./display"
import { Standard as Navigation } from "navigation"

export default function Routing(): JSX.Element {
	return (
		<layouts.containers.flex height="100vh" overflowY="hidden" flex="1">
			<layouts.containers.flex className="navigation-container" flex="0">
				<Navigation></Navigation>
			</layouts.containers.flex>
			<layouts.containers.flex className="body-container" flex="1 1">
				<Routes>
					<Route
						path="/"
						element={
							<layouts.backgrounds.Grey flex="0 1 100%">
								<Profiles />
							</layouts.backgrounds.Grey>
						}
					/>
					<Route
						path="/pending"
						element={
							<layouts.backgrounds.Grey flex="0 1 100%">
								<Pending />
							</layouts.backgrounds.Grey>
						}
					/>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
