import React, { useState, useEffect } from "react"
import classnames from "classnames"
import styled from "@emotion/styled"
import * as errors from "errors"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as icons from "icons"
import * as sessions from "sessions"
import * as uuid from "uuid"
import * as api from "./api"
import { Image } from "./image"
import { UploadBtn } from "./upload.btn"

interface props {
	mediaPath: string
	onImageClick(media: api.Media, url: string): void
	className?: string
}

const defaultProps = {
	className: "",
}

const StyledContainer = styled(layouts.containers.flex)`
	background: ${layouts.theme.colors.white};
`

const StyledImagesContainer = styled(layouts.containers.grid)`
	margin: -1px 18px 9px;
	padding: 9px 6px;
	background: ${layouts.theme.colors.white};
	border: 1px solid ${layouts.theme.colors.grey.light20};
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
	border-radius: 1px;
`
const StyledSearchField = styled(inputs.Text)`
	padding-left: 45px;
	border-radius: 48px;
	height: 48px;
	background: ${layouts.theme.colors.white};
	border: 1px solid ${layouts.theme.colors.grey.light20};
	margin: 0 2px;
`

export function Container(props: React.PropsWithChildren<props & layouts.containers.FlexProps>): JSX.Element {
	const { mediaPath, onImageClick, className, ...rest } = { ...defaultProps, ...props }
	const [loading, setLoading] = useState(false)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)
	const [resultsActive, setResultsActive] = useState(false)
	const bearertoken = sessions.useToken()
	const [mediaRequest, setMediaRequest] = useState({
		offset: uuid.NIL,
		query: "",
	} as api.SearchRequest)

	const [results, setResults] = useState({
		cursor: mediaRequest,
		items: [],
	} as api.SearchResponse)

	const setUploadResult = (result: api.Media[]): void => {
		setResults({ items: [...result, ...(results.items || [])] })
		setResultsActive(true)
	}

	useEffect(() => {
		setLoading(true)
		api
			.search(mediaRequest, mediaPath, bearertoken)
			.then((m) => {
				setResults(m)
			})
			.catch((c: unknown) => {
				setCause(
					<errors.Inline>
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve media</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
	}, [mediaRequest])

	const onDestroyClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, media_id: string): void => {
		e.stopPropagation()
		setLoading(true)
		api
			.destroy(mediaPath, media_id, bearertoken)
			.then(() => setResults({ items: results.items.filter((elem) => elem.id !== media_id) }))
			.catch(console.error)
			.finally(() => setLoading(false))
	}

	return (
		<StyledContainer
			minHeight="40px"
			{...rest}
			className={classnames("media-lib-container", className)}
			flexDirection="column"
		>
			<UploadBtn mediaPath={mediaPath} setUploadResult={setUploadResult} />
			<StyledSearchField
				className="media-lib-search"
				icon={
					<inputs.icons.left top="17px">
						<icons.SearchNew />
					</inputs.icons.left>
				}
				placeholder="Search Library"
				defaultValue={mediaRequest.query}
				onClick={() => !resultsActive && setResultsActive(true)}
				onChange={(evt) => {
					setMediaRequest({ ...mediaRequest, query: evt.currentTarget.value })
				}}
			/>
			{cause}
			<layouts.containers.flex flex="1" overflowY="auto" maxHeight="157px">
				{resultsActive && (
					<StyledImagesContainer
						gap="3px 3px"
						gridTemplateColumns="1fr 1fr 1fr"
						gridTemplateRows="minmax(100px, min-content)"
						height="fit-content"
						flex="1"
					>
						<layouts.loading.pending loading={loading} icon={<></>}>
							{results.items &&
								results.items.map((m) => (
									<Image key={m.id} media={m} onClick={onImageClick} onDestroyClick={onDestroyClick} noImage={<></>} />
								))}
						</layouts.loading.pending>
					</StyledImagesContainer>
				)}
			</layouts.containers.flex>
		</StyledContainer>
	)
}
