import React from "react"
import styled from "@emotion/styled"
import * as system from "styled-system"
import * as layouts from "layouts"
import * as icons from "icons"
import * as theme from "./theme"

export interface PillProps
	extends system.LayoutProps,
		system.TypographyProps,
		system.SpaceProps,
		system.PositionProps,
		system.WidthProps,
		system.BackgroundProps {
	remove?(): void
	disabled?: boolean
}

const PillStyle = styled.button<PillProps>`
	font-weight: bold;
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
	border-radius: 24px;
	height: 40px;
	width: fit-content;
	white-space: nowrap;
	${system.background}
	${system.width}
${system.position}
${system.layout}
${system.typography}
${system.space}
`

const PrimaryPill = styled(PillStyle)`
	background-color: ${(props) => (props.disabled ? theme.colors.grey.lightest : theme.colors.blue)};
	color: ${(props) => (props.disabled ? theme.colors.grey.light30 : theme.colors.white)};
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
	border: none;
`

const StyledGradientPill = styled(PillStyle)`
	background-color: ${theme.colors.gradient.orange};
	color: ${theme.colors.white};
	border: none;
`

const StyledOutlinePill = styled(PillStyle)`
	background-color: transparent;
	color: ${(props) => (props.disabled ? theme.colors.grey.light30 : theme.colors.blue)};
	border: 1px solid ${(props) => (props.disabled ? theme.colors.grey.light30 : theme.colors.blue)};
`

export function Primary(props: React.PropsWithChildren<PillProps>): JSX.Element {
	const { remove, children, ...rest } = props

	return (
		<PrimaryPill {...rest}>
			<layouts.containers.flex justifyContent="center" alignItems="center">
				{children}
				{remove && (
					<layouts.containers.box ml="12px" onClick={remove}>
						<icons.X />
					</layouts.containers.box>
				)}
			</layouts.containers.flex>
		</PrimaryPill>
	)
}

export function Gradient(props: React.PropsWithChildren<PillProps>): JSX.Element {
	const { remove, children, ...rest } = props

	return (
		<StyledGradientPill {...rest}>
			<layouts.containers.flex justifyContent="center" alignItems="center">
				{children}
				{remove && (
					<layouts.containers.box ml="12px" onClick={remove}>
						<icons.X />
					</layouts.containers.box>
				)}
			</layouts.containers.flex>
		</StyledGradientPill>
	)
}

export function Outline(props: React.PropsWithChildren<PillProps>): JSX.Element {
	const { remove, children, ...rest } = props

	return (
		<StyledOutlinePill {...rest}>
			<layouts.containers.flex justifyContent="center" alignItems="center">
				{children}
				{remove && (
					<layouts.containers.box ml="12px" onClick={remove}>
						<icons.X stroke={layouts.theme.colors.blue} />
					</layouts.containers.box>
				)}
			</layouts.containers.flex>
		</StyledOutlinePill>
	)
}
