import React from "react"
import { Route, Routes, useParams } from "react-router-dom"
import { Table } from "./container"
import * as history from "./history"
import * as layouts from "layouts"
import { Standard as Navigation } from "navigation"

function HistoryFromURL(): JSX.Element {
	const params = useParams() as { id: string }
	return <history.Table job={{ id: params.id }} />
}

export default function Routing(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<layouts.containers.flex height="100vh" overflowY="hidden">
			<layouts.containers.flex className="navigation-container" flex="0">
				<Navigation></Navigation>
			</layouts.containers.flex>
			<layouts.containers.flex className="body-container" flex="1 1">
				<Routes>
					<Route path="/" element={<Table />} />
					<Route path="/:id/history" element={<HistoryFromURL />} />
				</Routes>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
