import { useEffect, useState } from "react"
import styled from "@emotion/styled"
import * as Create from "./createjob"
import * as api from "./api"
import * as layouts from "layouts"
import * as errors from "errors"
import * as sessions from "sessions"

const NameTD = styled.td`
	padding-left: 10px;
	padding-top: -10px;
`

const StyledHeader = styled.th`
	padding-left: 12px;
	padding-top: 12px;
`

export function Table(): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const [loading, setLoading] = useState(true)
	const bearertoken = sessions.useToken()
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	const [results, setResults] = useState({
		items: [],
	} as api.IndexResponse)

	useEffect(() => {
		api
			.search({ offset: "00000000-0000-0000-0000-000000000000" }, bearertoken)
			.then((r) => {
				setResults(r)
			})
			.catch((c: unknown) => {
				console.error("unable to retrieve promotions", c)
				setCause(<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve promotions</errors.Textual>)
			})
			.finally(() => setLoading(false))
	}, [loading])

	results.items = results.items === undefined || results.items === null ? [] : results.items

	function runJob(ID: string) {
		api
			.runjob({ id: ID }, bearertoken)
			.then((r) => {})
			.catch((c: unknown) => {
				console.error("unable to run job and save promotions", c)
				setCause(
					<errors.Textual onClick={() => setCause(undefined)}>unable to run job and save promotions</errors.Textual>,
				)
			})
			.finally(() => setLoading(false))
	}

	return (
		<layouts.backgrounds.Splash className="splash" flexDirection="column" width="100%" height="100%">
			<errors.overlay styled cause={cause}>
				<layouts.containers.flex className="actions" width="100%" justifyContent="end" mt="50px">
					<layouts.buttons.primary
						width="100px"
						height="50px"
						mr="20px"
						onClick={(evt) => {
							mtoggle(<Create.Modal setLoading={setLoading} setCause={setCause} />)
						}}
					>
						{" "}
						Create
					</layouts.buttons.primary>
				</layouts.containers.flex>
				<layouts.containers.flex className="competitorpromotions" flexDirection="column" flex="1" p="20px">
					<layouts.loading.pending loading={loading}>
						<layouts.tables.container>
							<table>
								<thead>
									<tr>
										<StyledHeader>Id</StyledHeader>
										<StyledHeader>Associated Product Id</StyledHeader>
										<StyledHeader>Query</StyledHeader>
										<StyledHeader>Last Processed At</StyledHeader>
										<StyledHeader>Duration</StyledHeader>
										<StyledHeader>Run Job</StyledHeader>
									</tr>
								</thead>
								<tbody>
									{results.items.map((g: api.GoogleShoppingIngestion) => (
										<layouts.tables.Row key={g.id}>
											<NameTD>{g.id}</NameTD>
											<NameTD>{g.product_id}</NameTD>
											<NameTD>{g.query}</NameTD>
											<NameTD>{g.processed_at}</NameTD>
											<NameTD>{g.duration}</NameTD>
											<NameTD>
												<layouts.buttons.primary onClick={() => runJob(g.id)}>Run Job</layouts.buttons.primary>
											</NameTD>
										</layouts.tables.Row>
									))}
								</tbody>
							</table>
						</layouts.tables.container>
					</layouts.loading.pending>
				</layouts.containers.flex>
			</errors.overlay>
		</layouts.backgrounds.Splash>
	)
}
