import * as timex from "timex"
import * as msearch from "./meta.search"
export type { DateRange } from "./meta.search"

export namespace dateranges {
	export function everything(): msearch.DateRange {
		return {
			oldest: timex.infinity().toISO(),
			newest: timex.negInfinity().toISO(),
		}
	}

	export function days(n: number): msearch.DateRange {
		const ts = timex.local().startOf("day")
		return {
			oldest: ts.toISO(),
			newest: ts.minus(timex.duration.days(n)).toISO(),
		}
	}
}
