import { default as md5 } from "md5"
import * as uuid from "uuid"

export interface ImpressionSimulation {
	count: number
	description: string
	start_date: number
	end_date: number
	browser_incognito: BrowserIncognitoType[]
	browsers: BrowserType[]
	zipcodes: ZipCodeType[]
	offers: OfferType[]
	cid: string
}
interface impressionsGroupedBy {
	[key: string]: Impression[]
}
interface BrowserIncognitoType {
	value: boolean
	weight: number
}
interface BrowserType {
	name: string
	major_version: string
	useragent: string
	operating_system: string
	operating_system_version: string
	weight: number
}
interface ZipCodeType {
	code: string
	weight: number
}
interface OfferType {
	coupon: string
	gid: string
	prediction: number
	probability: number
	weight: number
	conversion_rate: number
}
interface ConversionType {
	timestamp: string
	applied: boolean
	profit: number
}
interface GeoIpType {
	ip: string
	latitude: number
	longitude: number
	zipcode: string
}
export interface Impression {
	browser_incognito: boolean
	browser_major_version: string
	browser_name: string
	browser_operating_system: string
	browser_operating_system_version: string
	browser_useragent: string
	cid: string
	converted_at: string
	coupon: string
	coupon_applied: boolean
	coupon_md5_digest: string
	description: string
	fpjs_reqid: string
	fpjs_uid: string
	fpjs_uid_md5_digest: string
	geoip_ip: string
	geoip_latitude: number
	geoip_longitude: number
	geoip_zipcode: string
	gid: string
	id: string
	prediction: number
	probability: number
	profit: number
	shopify_order_id: number
}
export interface chartData {
	labels: string[]
	datasets: { label: string; data: number[]; backgroundColor: string }[]
}

export function* genimpression(options: ImpressionSimulation): IterableIterator<Impression> {
	let offer, browser_incognito, browser, conversion, fpjs_uid, geoip
	let weighted_offers = weighted(options.offers)
	let weighted_browsers = weighted(options.browsers)
	let weighted_browser_incognito = weighted(options.browser_incognito)
	while (true) {
		offer = getRandomElement(weighted_offers) as OfferType
		browser = getRandomElement(weighted_browsers) as BrowserType
		browser_incognito = getRandomElement(weighted_browser_incognito) as BrowserIncognitoType
		conversion = genConversion(offer.conversion_rate)
		geoip = genGeoIp()
		fpjs_uid = uuid.v4()
		yield {
			browser_incognito: browser_incognito.value,
			browser_major_version: browser.major_version,
			browser_name: browser.name,
			browser_operating_system: browser.operating_system,
			browser_operating_system_version: browser.operating_system_version,
			browser_useragent: browser.useragent,
			cid: options.cid,
			converted_at: conversion.timestamp,
			coupon: offer.coupon,
			coupon_applied: conversion.applied,
			coupon_md5_digest: md5(offer.coupon),
			description: options.description,
			fpjs_reqid: uuid.v4(),
			fpjs_uid: fpjs_uid,
			fpjs_uid_md5_digest: md5(fpjs_uid),
			geoip_ip: geoip.ip,
			geoip_latitude: geoip.latitude,
			geoip_longitude: geoip.longitude,
			geoip_zipcode: geoip.zipcode,
			gid: offer.gid,
			id: uuid.v4(),
			prediction: offer.prediction,
			probability: offer.probability,
			profit: conversion.profit,
			shopify_order_id: Math.floor(Math.random() * 999_999_999_999) + 4_000_000_000_000,
		}
	}
}

function genGeoIp(): GeoIpType {
	return {
		ip:
			Math.floor(Math.random() * 255) +
			1 +
			"." +
			Math.floor(Math.random() * 255) +
			"." +
			Math.floor(Math.random() * 255) +
			"." +
			Math.floor(Math.random() * 255),
		latitude: Math.random() * 90 * (Math.random() < 0.5 ? -1 : 1),
		longitude: Math.random() * 180 * (Math.random() < 0.5 ? -1 : 1),
		zipcode: (Math.floor(Math.random() * 89999) + 10000).toString(),
	}
}

function genConversion(conversion_rate: number): ConversionType {
	let ret: ConversionType = { timestamp: "infinity", applied: false, profit: 0 }
	if (Math.random() < conversion_rate) {
		ret.timestamp = new Date().toISOString()
		ret.applied = Math.random() < 0.44
		ret.profit = Math.round(Math.random() * 6400) * (Math.random() < 0.5 ? -1 : 1)
	}
	return ret
}

function weighted<Type extends { weight: number }>(elements: Type[]): Type[] {
	let results: Type[] = []
	for (let i = 0; i < elements.length; i++) {
		const element = elements[i]
		results = results.concat(Array(element.weight).fill(element))
	}
	return results
}

function getRandomElement<Type>(elements: Type[]): Type {
	const random = Math.floor(Math.random() * elements.length)
	return elements[random]
}

export function preparePredictionData(data: Impression[]) {
	const colors = ["#47D7AC", "#5461C8", "#C964CF", "#FFBF3F", "#59CBE8", "#FCE300", "#F04E98", "#767899", "#B870B9"]
	let result: chartData = { labels: [], datasets: [] }

	const grouped_by_gid = data.reduce((group: impressionsGroupedBy, impression: Impression) => {
		const { gid } = impression
		group[gid] = group[gid] ?? []
		group[gid].push(impression)
		return group
	}, {})

	Object.keys(grouped_by_gid).forEach((gid) => {
		result.labels.push(gid)
		const grouped_by_prediction = grouped_by_gid[gid].reduce(
			(group: { [key: string]: number }, impression: Impression) => {
				const { prediction } = impression
				group[prediction] = group[prediction] ? group[prediction] + 1 : 1
				return group
			},
			{},
		)
		Object.keys(grouped_by_prediction).forEach((prediction, ind) => {
			const i = result.datasets.findIndex((dataset) => dataset.label === prediction)
			if (i === -1) {
				result.datasets.push({
					label: prediction,
					data: [grouped_by_prediction[prediction]],
					backgroundColor: colors[ind],
				})
			} else {
				result.datasets[i].data.push(grouped_by_prediction[prediction])
			}
		})
	})

	return result
}
