import lscache from "lscache"
import _JSONBig from "json-bigint"

lscache.setExpiryMilliseconds(1) // ensure it treats time as milliseconds

const JSONBig = _JSONBig({ useNativeBigInt: true })

interface option {
	(opt: options): options
}

interface options {
	ttl: number
}

class Cache {
	namespace: string = "" // defaults to the global namespace
	ttl: number = 60000 // milliseconds before expiration, default 1 minute

	constructor(defaults: Partial<Cache> = {}) {
		this.namespace = defaults.namespace ? `${defaults.namespace}.` : this.namespace
		this.ttl = defaults.ttl || this.ttl
	}

	get<T>(key: string): T {
		lscache.setBucket(this.namespace)
		// lscache only supports browser window json; sigh.
		const encoded = lscache.get(key)
		let decoded = encoded
		if (typeof encoded === "string") {
			decoded = JSONBig.parse(encoded)
		}

		return decoded as T
	}

	set<T>(key: string, v: T, ...options: option[]): T {
		const opts = options.reduce((cfg, opt) => opt(cfg), {
			ttl: this.ttl,
		})

		lscache.setBucket(this.namespace)

		// convert the ttl to seconds.
		if (!lscache.set(key, JSONBig.stringify(v), opts.ttl)) {
			console.warn("failed to update cache", `${this.namespace}.${key}`, v)
		}
		return v
	}

	maybeSync<T>(key: string, lookup: () => T, ...options: option[]): T {
		const found = this.get<T>(key)
		if (found) return found
		const v = lookup()
		this.set(key, v, ...options)
		return v
	}

	async maybe<T>(key: string, lookup: () => Promise<T>, ...options: option[]): Promise<T> {
		const found = this.get<T>(key)
		if (found) return Promise.resolve(found)
		return lookup().then((v: T) => {
			this.set(key, v, ...options)
			return v
		})
	}

	remove(key: string) {
		lscache.setBucket(this.namespace)
		lscache.remove(key)
	}

	flush() {
		lscache.setBucket(this.namespace)
		lscache.flush()
	}

	flushExpired() {
		lscache.setBucket(this.namespace)
		lscache.flushExpired()
	}
}

export default Cache
