import * as svg from "./svg"

Icon.defaultProps = {
	fill: "white",
	stroke: "#828282",
}

function Icon(props: svg.SVGProps): JSX.Element {
	const { className, stroke, fill, ...rest } = props
	return (
		<svg.SVG
			viewBox="0 0 45 45"
			width="inherit"
			height="inherit"
			fill={fill}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			verticalAlign="middle"
			{...rest}
		>
			<path
				d="M5.71387 11.6398H9.38119H38.7198"
				stroke={stroke}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.8818 11.6398V7.9725C14.8818 6.99986 15.2682 6.06707 15.956 5.37931C16.6437 4.69155 17.5765 4.30518 18.5492 4.30518H25.8838C26.8564 4.30518 27.7892 4.69155 28.477 5.37931C29.1648 6.06707 29.5511 6.99986 29.5511 7.9725V11.6398M35.0521 11.6398V37.3111C35.0521 38.2837 34.6657 39.2165 33.978 39.9043C33.2902 40.592 32.3574 40.9784 31.3848 40.9784H13.0482C12.0755 40.9784 11.1427 40.592 10.455 39.9043C9.76724 39.2165 9.38086 38.2837 9.38086 37.3111V11.6398H35.0521Z"
				stroke={stroke}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M18.5498 20.8081V31.8101" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M25.8838 20.8081V31.8101" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg.SVG>
	)
}

export default Icon
