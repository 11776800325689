import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { className, fill, ...rest } = props
	return (
		<svg.SVG
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="15px"
			height="15px"
			viewBox="0 0 15 15"
			version="1.1"
			fill="none"
			{...rest}
		>
			<path
				d="M11.7667 1.25C11.8833 1.25 12.0667 1.30833 12.3083 1.50833C12.5417 1.70833 12.7833 2.025 13 2.43333C13.4417 3.25833 13.75 4.4 13.75 5.6H15C15 4.20833 14.6417 2.85833 14.1 1.85C13.825 1.34167 13.5 0.891667 13.125 0.566667C12.75 0.241667 12.2833 0 11.7667 0V1.25V1.25ZM9.78332 5.6C9.78332 4.40833 10.0917 3.25833 10.5333 2.43333C10.7583 2.01667 11 1.70833 11.225 1.50833C11.4583 1.30833 11.6417 1.25 11.7667 1.25V0C11.25 0 10.7833 0.241667 10.4083 0.566667C10.0333 0.891667 9.69999 1.34167 9.43332 1.85C8.89166 2.86667 8.53333 4.20833 8.53333 5.6H9.78332ZM11.7667 9.95C11.65 9.95 11.4667 9.89167 11.225 9.69167C10.9917 9.49167 10.75 9.175 10.5333 8.76667C10.0917 7.94167 9.78332 6.8 9.78332 5.6H8.53333C8.53333 6.99167 8.89166 8.34167 9.43332 9.35C9.70832 9.85833 10.0333 10.3083 10.4083 10.6333C10.7833 10.9583 11.25 11.2 11.7667 11.2V9.95ZM13.75 5.6C13.75 6.79167 13.4417 7.94167 13 8.76667C12.775 9.18333 12.5333 9.49167 12.3083 9.69167C12.075 9.89167 11.8917 9.95 11.7667 9.95V11.2C12.2833 11.2 12.75 10.9583 13.125 10.6333C13.5 10.3083 13.8333 9.85833 14.1 9.35C14.6417 8.33333 15 6.99167 15 5.6H13.75V5.6Z"
				fill={fill}
			/>
			<path
				d="M2.90833 3.12503C3.36667 3.00836 5.6 2.54169 7.74166 2.09169C8.80833 1.86669 9.84166 1.65836 10.6167 1.49169C11 1.40836 11.3167 1.35003 11.5417 1.30003C11.65 1.27503 11.7417 1.25836 11.8 1.25003C11.8333 1.25003 11.85 1.2417 11.8667 1.23336H11.8833C11.8833 1.23336 11.8417 1.03058 11.7583 0.625026C11.675 0.21947 11.6333 0.0166931 11.6333 0.0166931C11.6333 0.0166931 11.625 0.0166931 11.6167 0.0166931C11.6 0.0166931 11.575 0.0166935 11.55 0.0333602C11.4917 0.0416935 11.4 0.0666946 11.2917 0.0833613C11.0667 0.125028 10.75 0.191694 10.3667 0.275028C9.6 0.433361 8.55833 0.650028 7.49166 0.875028C5.36667 1.31669 3.1 1.79169 2.60833 1.91669L2.90833 3.13336V3.12503ZM1.25 5.60003C1.25 4.95836 1.49167 4.37503 1.83333 3.92503C2.18333 3.46669 2.60833 3.20003 2.90833 3.12503L2.60833 1.90836C1.96667 2.06669 1.31667 2.54169 0.841666 3.16669C0.358333 3.80003 0 4.64169 0 5.60003H1.25ZM2.90833 8.07503C2.6 8.00003 2.18333 7.73336 1.83333 7.27503C1.49167 6.82503 1.25 6.25003 1.25 5.60003H0C0 6.55836 0.35 7.40003 0.841666 8.03336C1.31667 8.65836 1.96667 9.12503 2.60833 9.29169L2.90833 8.07503ZM11.7667 10.575C11.85 10.1695 11.8917 9.96669 11.8917 9.96669C11.8917 9.96669 11.8833 9.96669 11.875 9.96669C11.8583 9.96669 11.8333 9.96669 11.8083 9.95003C11.75 9.94169 11.6583 9.91669 11.55 9.90002C11.325 9.85836 11.0083 9.79169 10.625 9.70836C9.85833 9.55003 8.81666 9.33336 7.75 9.10836C5.60833 8.65836 3.375 8.19169 2.91667 8.07503L2.61667 9.29169C3.10833 9.41669 5.375 9.89169 7.5 10.3334C8.56666 10.5584 9.60833 10.7667 10.375 10.9334C10.7583 11.0167 11.075 11.075 11.3 11.125C11.4083 11.15 11.5 11.1667 11.5583 11.175C11.5917 11.175 11.6083 11.1834 11.625 11.1917H11.6417C11.6417 11.1917 11.6833 10.9889 11.7667 10.5834V10.575Z"
				fill={fill}
			/>
			<path
				d="M7.41669 12.475V9.86669H6.16669V12.475H7.41669ZM4.89169 15C6.28336 15 7.41669 13.875 7.41669 12.475H6.16669C6.16669 13.175 5.60003 13.75 4.89169 13.75V15V15ZM4.41669 15H4.89169V13.75H4.41669V15ZM1.89169 12.475C1.89169 13.8667 3.01669 15 4.41669 15V13.75C3.71669 13.75 3.14169 13.1834 3.14169 12.475H1.89169V12.475ZM1.89169 8.91669V12.475H3.14169V8.91669H1.89169V8.91669Z"
				fill={fill}
			/>
		</svg.SVG>
	)
}

export default Icon
