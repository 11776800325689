import { SVG, SVGProps } from "./svg"
import * as layouts from "../layouts"

interface ChevronProps extends SVGProps {
	disabled?: boolean
}

export const left = (props: ChevronProps) => {
	const color = props.disabled ? layouts.theme.colors.grey.lightest : layouts.theme.colors.navy
	return (
		<SVG width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7 13L1 7L7 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</SVG>
	)
}

export const double_left = (props: ChevronProps) => {
	const color = props.disabled ? layouts.theme.colors.grey.lightest : layouts.theme.colors.navy
	return (
		<SVG width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11 17L6 12L11 7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M18 17L13 12L18 7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</SVG>
	)
}

export const arrow_left = (props: ChevronProps) => {
	const color = props.disabled ? layouts.theme.colors.grey.lightest : layouts.theme.colors.blue
	return (
		<SVG width="40px" height="40px" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_d_167_2903)">
				<rect x="32" y="30" width="25" height="25" rx="12.5" transform="rotate(-180 32 30)" fill="#EBE6FA" />
			</g>
			<path d="M21.25 14L17.75 17.5L21.25 21" stroke={color} strokeWidth="2" strokeLinecap="square" />
			<defs>
				<filter
					id="filter0_d_167_2903"
					x="0"
					y="0"
					width="40"
					height="40"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="2.5" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_2903" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_167_2903" result="shape" />
				</filter>
			</defs>
		</SVG>
	)
}

export const right = (props: ChevronProps) => {
	const color = props.disabled ? layouts.theme.colors.grey.lightest : layouts.theme.colors.navy
	return (
		<SVG width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1 13L7 7L1 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</SVG>
	)
}

export const double_right = (props: ChevronProps) => {
	const color = props.disabled ? layouts.theme.colors.grey.lightest : layouts.theme.colors.navy
	return (
		<SVG width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13 17L18 12L13 7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M6 17L11 12L6 7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</SVG>
	)
}

export const arrow_right = (props: ChevronProps) => {
	const color = props.disabled ? layouts.theme.colors.grey.lightest : layouts.theme.colors.navy
	return (
		<SVG width="40px" height="40px" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_d_167_2904)">
				<rect x="7" y="5" width="25" height="25" rx="12.5" fill="#EBE6FA" />
			</g>
			<path d="M17.75 21L21.25 17.5L17.75 14" stroke={color} strokeWidth="2" strokeLinecap="square" />
			<defs>
				<filter
					id="filter0_d_167_2904"
					x="0"
					y="0"
					width="40"
					height="40"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="2.5" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_2904" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_167_2904" result="shape" />
				</filter>
			</defs>
		</SVG>
	)
}
