import { NavLink } from "react-router-dom"
import * as icons from "icons"
import * as layouts from "layouts"
import * as sessions from "sessions/session"

export function Logout(props: layouts.containers.ContainerProps): JSX.Element {
	const current = sessions.useSession()
	return (
		<layouts.containers.flex alignItems="center" {...props}>
			<icons.Person width="24px" fill="white" />
			<NavLink to="/" onClick={() => current.replace(sessions.zero(sessions.options.logout))}>
				<layouts.containers.span color="white">Sign out</layouts.containers.span>
			</NavLink>
		</layouts.containers.flex>
	)
}
