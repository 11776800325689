/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "textgen.training.sample.proto" (package "textgen.training.sample", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * easyjson:json
 *
 * @generated from protobuf message textgen.training.sample.Sample
 */
export interface Sample {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string prompt = 3;
     */
    prompt: string;
    /**
     * @generated from protobuf field: string generated = 4;
     */
    generated: string; // ALL generated text for source, including headline, description, message, ...
    /**
     * @generated from protobuf field: string accepted = 5;
     */
    accepted: string; // final states of ad components accepted by user
    /**
     * @generated from protobuf field: string source = 6;
     */
    source: string; // Google Search Ad Headline, Google Search Ad Description, Facebook Ad Headline, Facebook Ad Description, ... (answers 'where we're coming from' for generated)
    /**
     * @generated from protobuf field: string brand_id = 7 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string pool_id = 8 [json_name = "pool_id"];
     */
    pool_id: string;
    /**
     * @generated from protobuf field: textgen.training.sample.Feedback feedback = 9;
     */
    feedback: Feedback; // signal
}
/**
 * easyjson:json
 *
 * @generated from protobuf message textgen.training.sample.SampleRequest
 */
export interface SampleRequest {
    /**
     * @generated from protobuf field: repeated textgen.training.sample.Sample samples = 1;
     */
    samples: Sample[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message textgen.training.sample.SampleResponse
 */
export interface SampleResponse {
}
/**
 * @generated from protobuf enum textgen.training.sample.Feedback
 */
export enum Feedback {
    /**
     * @generated from protobuf enum value: IGNORED = 0;
     */
    IGNORED = 0,
    /**
     * @generated from protobuf enum value: USED = 1;
     */
    USED = 1,
    /**
     * @generated from protobuf enum value: REJECTED = 2;
     */
    REJECTED = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Sample$Type extends MessageType<Sample> {
    constructor() {
        super("textgen.training.sample.Sample", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "prompt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "generated", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "accepted", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "source", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "pool_id", kind: "scalar", localName: "pool_id", jsonName: "pool_id", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "feedback", kind: "enum", T: () => ["textgen.training.sample.Feedback", Feedback] }
        ]);
    }
    create(value?: PartialMessage<Sample>): Sample {
        const message = { id: "", account_id: "", prompt: "", generated: "", accepted: "", source: "", brand_id: "", pool_id: "", feedback: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Sample>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Sample): Sample {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string prompt */ 3:
                    message.prompt = reader.string();
                    break;
                case /* string generated */ 4:
                    message.generated = reader.string();
                    break;
                case /* string accepted */ 5:
                    message.accepted = reader.string();
                    break;
                case /* string source */ 6:
                    message.source = reader.string();
                    break;
                case /* string brand_id = 7 [json_name = "brand_id"];*/ 7:
                    message.brand_id = reader.string();
                    break;
                case /* string pool_id = 8 [json_name = "pool_id"];*/ 8:
                    message.pool_id = reader.string();
                    break;
                case /* textgen.training.sample.Feedback feedback */ 9:
                    message.feedback = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Sample, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string prompt = 3; */
        if (message.prompt !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.prompt);
        /* string generated = 4; */
        if (message.generated !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.generated);
        /* string accepted = 5; */
        if (message.accepted !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.accepted);
        /* string source = 6; */
        if (message.source !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.source);
        /* string brand_id = 7 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.brand_id);
        /* string pool_id = 8 [json_name = "pool_id"]; */
        if (message.pool_id !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.pool_id);
        /* textgen.training.sample.Feedback feedback = 9; */
        if (message.feedback !== 0)
            writer.tag(9, WireType.Varint).int32(message.feedback);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message textgen.training.sample.Sample
 */
export const Sample = new Sample$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SampleRequest$Type extends MessageType<SampleRequest> {
    constructor() {
        super("textgen.training.sample.SampleRequest", [
            { no: 1, name: "samples", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Sample }
        ]);
    }
    create(value?: PartialMessage<SampleRequest>): SampleRequest {
        const message = { samples: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SampleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SampleRequest): SampleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated textgen.training.sample.Sample samples */ 1:
                    message.samples.push(Sample.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SampleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated textgen.training.sample.Sample samples = 1; */
        for (let i = 0; i < message.samples.length; i++)
            Sample.internalBinaryWrite(message.samples[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message textgen.training.sample.SampleRequest
 */
export const SampleRequest = new SampleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SampleResponse$Type extends MessageType<SampleResponse> {
    constructor() {
        super("textgen.training.sample.SampleResponse", []);
    }
    create(value?: PartialMessage<SampleResponse>): SampleResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SampleResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SampleResponse): SampleResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SampleResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message textgen.training.sample.SampleResponse
 */
export const SampleResponse = new SampleResponse$Type();
