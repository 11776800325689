import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as api from "./api"
import * as routing from "./routing"
import * as caching from "./cache"

export function Brand(props: React.PropsWithChildren<unknown>): JSX.Element {
	const location = useLocation()
	const navigate = useNavigate()
	const bearertoken = sessions.useToken()
	const account = sessions.useAccount()
	const [brand, setBrand] = useState(api.zero())
	const [loading, setLoading] = useState(true) // only care about initial load

	useEffect(() => {
		const bid = api.getLastBrandId(account.id)
		const brandp = bid === undefined ? Promise.resolve(api.zero()) : caching.current(bid, bearertoken)
		brandp
			.catch(
				httpx.errors.notFound((cause) => {
					return api.zero()
				}),
			)
			.then((brand: api.Brand) => {
				// handle detecting if we need to select a brand
				if (brand.id === uuid.NIL && location.pathname !== routing.unknown()) {
					return navigate(routing.unknown())
				}
				setBrand(brand)
			})
			.finally(() => setLoading(false))
	}, [location.pathname])

	return (
		<layouts.loading.pending loading={loading}>
			<caching.Provider value={brand}>{props.children}</caching.Provider>
		</layouts.loading.pending>
	)
}
