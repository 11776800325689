import React from "react"
import * as layouts from "layouts"
import { text as Text } from "ads/adgen/adgen.content.layouts"

export default function Instructions(): JSX.Element {
	return (
		<layouts.containers.flex flexDirection="column" alignItems="center" verticalAlign="middle" flex="1">
			<layouts.containers.flex justifyContent="center" flexDirection="column" alignItems="center">
				<layouts.containers.flex pt="50px" justifyContent="center" flexDirection="column" alignItems="center">
					<Text fontWeight="600" color={layouts.theme.colors.blue} fontSize="22px">
						This is great!<br></br> What do I do now?
					</Text>
				</layouts.containers.flex>
				<layouts.containers.flex px="25px" pt="20px" flexDirection="column" alignItems="center">
					<Text fontWeight="700" color={layouts.theme.colors.grey.medium} fontSize="16px">
						Step One
					</Text>
					<Text fontWeight="400" color={layouts.theme.colors.grey.medium} fontSize="16px">
						Generate headlines <br></br> and select 15
					</Text>
				</layouts.containers.flex>
				<layouts.containers.flex px="25px" pt="20px" flexDirection="column" alignItems="center">
					<Text fontWeight="700" color={layouts.theme.colors.grey.medium} fontSize="16px">
						Step Two
					</Text>
					<Text fontWeight="400" color={layouts.theme.colors.grey.medium} fontSize="16px">
						Select 4 descriptions <br></br> and save to see ad samples
					</Text>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
