import React, { useContext } from "react"
import * as httpx from "httpx"
import * as caching from "caching"
import * as api from "./api"
import * as timex from "timex"
import { Pool } from "./ads.pool"

export const Context = React.createContext(api.pools.zero())
export const Provider = Context.Provider
export const Consumer = Context.Consumer

export const cache = new caching.Cache({ namespace: "novacloud.ads.pool", ttl: timex.duration.iso("PT1H").toMillis() })

export async function cachable(pid: string, ...options: httpx.option[]): Promise<api.Pool> {
	return api.pools.find(pid, ...options)
}

export function update(p: api.Pool): api.Pool {
	return cache.set(p.id, p)
}

export async function maybe(pid: string, ...options: httpx.option[]): Promise<api.Pool> {
	return cache.maybe(pid, () => cachable(pid, ...options))
}

export function useCached(): Pool {
	return useContext(Context)
}

export default cache
