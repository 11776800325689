import React from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as api from "./api"

interface props {
	impression: api.ImpressionSimulation
	onChange(upd: api.ImpressionSimulation): void
}

export default function SimulationForm(props: React.PropsWithChildren<props>): JSX.Element {
	const { impression, onChange } = props

	return (
		<>
			<layouts.forms.Group>
				<layouts.forms.Label>Number of Simulations</layouts.forms.Label>
				<inputs.Text
					defaultValue={impression.count}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...impression, count: parseInt(evt.currentTarget.value, 10) })
					}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label>Description</layouts.forms.Label>
				<inputs.Text
					defaultValue={impression.description}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...impression, description: evt.currentTarget.value })
					}
				/>
			</layouts.forms.Group>
		</>
	)
}
