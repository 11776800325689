import { useEffect, useState } from "react"
import classnames from "classnames"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as profiles from "profiles"
import * as authzc from "authzcached"
import * as authz from "./authz"

interface props extends layouts.containers.FlexProps {
	current: profiles.Profile
	readonly: boolean
	onChange(updated: authz.Token): Promise<authz.Token>
}

export default function Modify(props: props): JSX.Element {
	const { current, onChange, className, readonly, ...rest } = props

	const [auth] = useState(authzc.useCache((cached) => cached.meta))
	const [cause, setCause] = useState(undefined as unknown | undefined)
	const [loading, setLoading] = useState(true)
	const [modified, setModified] = useState(authz.tokens.denied(current, ""))

	useEffect(() => {
		setCause(undefined)
	}, [modified, current.id])

	useEffect(() => {
		setModified(authz.tokens.denied(current, ""))
		setLoading(true)
		auth.token().then(async (token) => {
			authz
				.profile({ profile_id: current.id }, httpx.options.bearer(token.bearer))
				.then((r) => {
					setModified({
						...modified,
						...r.authorization,
					})
				})
				.catch(setCause)
				.finally(() => setLoading(false))
		})
	}, [current.id])

	const update = (upd: Partial<authz.Token>): void => {
		onChange({
			...modified,
			...upd,
		}).then((upd) => setModified(upd))
	}

	return (
		<layouts.forms.Container
			className={classnames("meta authz modify", className)}
			styled
			padding="10px"
			borderRadius={layouts.theme.borders.radius.standard}
			border={layouts.theme.borders.grey.darkestalpha}
			cause={cause}
			readonly={readonly}
			loading={loading}
			{...rest}
		>
			<layouts.forms.Group mb="10px">
				<layouts.forms.Label mr="auto">Usermanagement</layouts.forms.Label>
				<inputs.toggles.Display
					checked={modified.usermanagement}
					onClick={() => update({ usermanagement: !modified.usermanagement })}
				/>
			</layouts.forms.Group>
		</layouts.forms.Container>
	)
}
