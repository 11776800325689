import React from "react"
import classnames from "classnames"
import * as system from "styled-system"
import styled from "@emotion/styled"
import * as layouts from "layouts"

export namespace transforms {
	export namespace strings {
		export function trimStart(s: string): string {
			return s.trimStart()
		}
	}
}

interface props
	extends system.PositionProps,
		system.SpaceProps,
		Omit<React.InputHTMLAttributes<HTMLInputElement>, "value"> {
	className?: string
	disabled?: boolean
	autofocus?: boolean
	placeholder?: string
	icon?: JSX.Element
	outlined?: boolean
	transforms: ((s: string) => string)[]
}

const StyledContent = styled(layouts.containers.flex)`
	position: relative;
	width: 100%;
`

StyledContent.defaultProps = { height: "48px" }

interface input extends layouts.containers.ContainerProps {
	disabled?: boolean
}
export const Input = styled.input<input>`
	box-sizing: border-box;
	width: 100%;
	padding: 0px 10px;
	border-radius: 6px;
	background: ${(props) => (props.disabled ? layouts.theme.colors.grey.light30 : layouts.theme.colors.grey.lightest)};
	// used for Nova new design only
	&.outline {
		padding: 0px 45px;
		border-radius: 30px;
		background: ${(props) => (props.disabled ? layouts.theme.colors.grey.light30 : layouts.theme.colors.white)};
		border: none;
		height: ${(props) => props.height || "unset"};
		&:focus {
			outline: 0.1rem solid;
			outline-color: ${layouts.theme.colors.blue};
			box-shadow: ${layouts.theme.boxshadow.default};
		}

		&:hover {
			color: ${(props) => (props.onClick ? layouts.theme.colors.white : "unset")};
			outline: 0.1rem solid;
			outline-color: ${layouts.theme.colors.blue};
			box-shadow: ${layouts.theme.boxshadow.default};
		}
	}
`

export function text(props: React.PropsWithChildren<props>): JSX.Element {
	const { className, icon, transforms, onChange, ...rest } = props
	function _onChange(evt: React.ChangeEvent<HTMLInputElement>): void {
		evt.target.value = transforms.reduce((v, op) => op(v), evt.target.value)
		onChange && onChange(evt)
	}

	return (
		<StyledContent>
			<Input className={classnames("text-input", className)} onChange={_onChange} {...rest} />
			{icon}
		</StyledContent>
	)
}

text.defaultProps = {
	placeholder: "",
	className: "",
	disabled: false,
	transforms: [transforms.strings.trimStart],
}

export function numeric(props: React.PropsWithChildren<props>): JSX.Element {
	const { className, icon, ...rest } = props

	return (
		<StyledContent>
			<Input type="number" className={classnames("numeric-input", className)} {...rest} />
			{icon}
		</StyledContent>
	)
}

numeric.defaultProps = {
	placeholder: "",
	className: "",
	disabled: false,
	transforms: [],
}
