import React, { useContext, useEffect, useState } from "react"
import * as sessions from "sessions"
import * as authz from "authz"
import * as meta from "meta/authz"

export interface cached {
	meta: authz.tokens.Cached<meta.Token>
}

export function zero(session: sessions.Session = sessions.zero()): cached {
	return {
		meta: meta.useAuthz(session),
	}
}

export const Context = React.createContext(zero())
export const Provider = Context.Provider
export const Consumer = Context.Consumer

export function useCache<T extends authz.tokens.Token>(
	reduce: (cached: cached) => authz.tokens.Cached<T>,
): authz.tokens.Cached<T> {
	const available = useContext(Context)
	return reduce(available)
}

export function Storage(props: React.PropsWithChildren<unknown>): JSX.Element {
	const session = sessions.useSession()
	const [cached, setCached] = useState(zero(session))

	// reset the cache when the profile changes.
	useEffect(() => setCached(zero(session)), [session.profile.id])

	return <Provider value={cached}>{props.children}</Provider>
}
