import React, { useState, useEffect } from "react"
import * as uuid from "uuid"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as brands from "brands"
import * as api from "brandguard/api"
import MainContainer from "./main.container"
import ImagesUpload from "./images.upload"
import DisplayImage from "./image"
import FileDetails from "./image.details"

export default function DisplayImages(props: React.PropsWithChildren<unknown>): JSX.Element {
	const [loading, setLoading] = useState(true)
	const [selectedItem, setSelectedItem] = useState(-1)
	const [images, setImages] = useState({
		items: [],
	} as api.ImageSearchResponse)
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const [searchReq, setSearchReq] = useState({
		offset: uuid.NIL,
		brand_id: brand.id,
	} as api.ImageSearchRequest)

	useEffect(() => {
		if (searchReq.brand_id === uuid.NIL) return
		setLoading(true)
		const piu = api.images.uploads.search(searchReq.brand_id, searchReq, bearertoken)
		const pia = api.images.approvallog.search(searchReq.brand_id, searchReq, bearertoken)
		Promise.all([piu, pia])
			.then((result) => {
				const [uploads, approvallog] = result
				const items = [...(uploads.items || []), ...(approvallog.items || [])].sort((a, b) =>
					a.media?.created_at! > b.media?.created_at! ? -1 : 1,
				)
				setImages({ items })
			})
			.catch(console.error)
			.finally(() => {
				setLoading(false)
			})
	}, [searchReq])

	useEffect(() => {
		setSearchReq({ ...searchReq, brand_id: brand.id })
	}, [brand.id])

	const onImageUpload = (item: api.ImageSearchResponseItem): void => {
		setImages((prevState) => ({
			items: [...([item] || []), ...(prevState.items || [])],
		}))
	}

	const onImageUpdate = (item: api.ImageSearchResponseItem): void => {
		setImages((prevState) => ({
			items: prevState.items.map((i) => (i.media?.id === item.media?.id ? item : i)),
		}))
	}

	return (
		<layouts.loading.screen loading={loading} flex="1" height="100%" icon={<></>}>
			<layouts.containers.flex className="brandguard-working-area" flexDirection="row" height="100%">
				<layouts.containers.flex className="left-sidebar" width="15%" flexDirection="column">
					<layouts.containers.flex
						flexDirection="column"
						height="100%"
						m="30px 8px 70px 0"
						background={layouts.theme.colors.white}
						p="30px 15px"
						borderRadius="10px"
						alignItems="center"
					>
						{images.items.length !== 0 && <ImagesUpload asButton={true} onUpload={onImageUpload} />}
					</layouts.containers.flex>
				</layouts.containers.flex>
				<MainContainer className="main-container" width="67%" mb="70px">
					{images.items.length === 0 ? (
						<ImagesUpload onUpload={onImageUpload} />
					) : (
						<layouts.containers.grid
							justifyContent="center"
							// overflowY="auto"
							display="grid"
							gridGap="50px"
							px="10px"
							py="30px"
							gridTemplateColumns="repeat(auto-fill, 380px)"
							gridTemplateRows="max-content"
						>
							{images.items.map((img, index) => (
								<DisplayImage
									key={img.media?.id}
									item={img}
									active={index === selectedItem}
									onCardClick={() => setSelectedItem(index)}
									onImageUpdate={onImageUpdate}
								/>
							))}
						</layouts.containers.grid>
					)}
				</MainContainer>
				<layouts.containers.flex className="right-sidebar" width="18%" flexDirection="column" p="30px 0 30px 10px">
					<FileDetails item={images.items[selectedItem]} />
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.loading.screen>
	)
}
