import React from "react"
import { Route, Routes } from "react-router-dom"
import * as layouts from "layouts"
import * as discountopt from "."
import * as codegen from "./codegen"
import * as impressions from "./impressions"

export default function Routing(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<Routes>
			<Route
				path="/new"
				element={
					<layouts.backgrounds.Splash height="100vh">
						<codegen.Display
							program={Promise.resolve({
								discounts: [],
								displays: [],
								offers: [],
							})}
						/>
					</layouts.backgrounds.Splash>
				}
			/>
			<Route
				path="/:id/"
				element={
					<layouts.backgrounds.Splash height="100vh">
						<impressions.FromURL />
					</layouts.backgrounds.Splash>
				}
			/>
			<Route path="*" element={<discountopt.Home />} />
		</Routes>
	)
}
