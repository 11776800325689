import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as icons from "icons"
import { Line } from "charts"
import { metric } from "./display"

const chartOptions = {
	responsive: true,
	scales: {
		x: {
			grid: {
				display: false,
			},
			ticks: {
				display: false,
			},
		},

		y: {
			grid: {
				display: false,
			},
			ticks: {
				display: false,
			},
		},
	},
	elements: {
		point: {
			radius: 0,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
	},
}

function chartData(metric: metric) {
	return {
		labels: metric.chart.labels,
		datasets: [
			{
				fill: true,
				data: Object.values(metric.chart.data),
				borderColor: metric.chart.borderColor,
				backgroundColor: metric.chart.backgroundColor,
				tension: 0.2,
			},
		],
	}
}

const Card = styled(layouts.containers.flex)`
	background: ${layouts.theme.colors.white};

	&:hover {
		outline: 0.1rem solid;
		outline-color: ${layouts.theme.colors.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}
`

const Header = styled(layouts.containers.flex)`
	color: ${layouts.theme.colors.black};
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	width: 100%;
	text-transform: uppercase;
`

const ConstrainChart = layouts.css`
	canvas {
		width: 100% !important;
		height: 100% !important;
		max-height: 100% !important;
		max-width: 100% !important;
	}
`

export function card(props: { data: metric; icon: JSX.Element }): JSX.Element {
	const { data, icon } = props
	const difference = data.val - data.prevVal
	const percent = (): number => {
		if (data.val === 0 && data.prevVal === 0) {
			return 0
		}
		if (data.val === 0) {
			return -100
		}
		if (data.prevVal === 0) {
			return 100
		}
		return Number(((data.val - data.prevVal) / data.prevVal) * 100)
	}

	return (
		<Card
			flex="1"
			maxWidth="49%"
			minHeight="250px"
			maxHeight="250px"
			padding="20px"
			textAlign="center"
			flexDirection="column"
		>
			<Header mb="15px">{data.title}</Header>
			<layouts.containers.flex>
				<layouts.containers.flex pr="10px">{icon}</layouts.containers.flex>
				<layouts.containers.flex fontSize="30px" verticalAlign="middle">
					{String(data.val)}
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex pt="5px">
				<layouts.containers.flex p="5px">
					{difference > 0 ? (
						<icons.ArrowUp fill={layouts.theme.colors.success} verticalAlign="middle" />
					) : (
						<icons.ArrowDown fill={layouts.theme.colors.red.light} verticalAlign="middle" />
					)}
				</layouts.containers.flex>
				<layouts.containers.flex
					fontSize="10px"
					fontWeight="600"
					verticalAlign="middle"
					color={difference > 0 ? layouts.theme.colors.success : layouts.theme.colors.red.light}
				>
					<>{percent()} %</>
				</layouts.containers.flex>
				<layouts.containers.flex
					pl="10px"
					fontSize="10px"
					fontWeight="600"
					verticalAlign="middle"
					color={layouts.theme.colors.grey.dark10}
				>
					last month
				</layouts.containers.flex>
				<layouts.containers.flex
					ml="auto"
					textAlign="right"
					fontSize="10px"
					fontWeight="600"
					verticalAlign="middle"
					color={layouts.theme.colors.grey.light30}
				>
					{String(difference)}
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex height="110px" className={ConstrainChart}>
				<Line options={chartOptions} data={chartData(data)} width={300} height={100} />
			</layouts.containers.flex>
			<Header py="15px" color={layouts.theme.colors.grey.dark10}>
				<>Total {data.title}</>
			</Header>
			<layouts.containers.flex fontSize="30px" verticalAlign="middle">
				{/* $ 10,000.00 */}
			</layouts.containers.flex>
		</Card>
	)
}
