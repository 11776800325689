import React, { PropsWithChildren, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import * as uuid from "uuid"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as pools from "ads/pools"

export function Node(props: PropsWithChildren<unknown>): JSX.Element {
	const { children } = props
	const params = useParams() as {
		id: string
	}
	const navigate = useNavigate()
	const [loading, setLoading] = useState(true)
	const bearertoken = sessions.useToken()

	const redirectToCreds = (bid: string, pid: string) => {
		return navigate(pools.routing.root(bid, pid))
	}

	useEffect(() => {
		pools.api.pools
			.find(params.id, bearertoken)
			.then((p) => {
				if (p.google_ads_client_account_id === uuid.NIL) {
					redirectToCreds(p.brand_id, p.id)
				}
				setLoading(false)
			})
			.catch((c: unknown) => {
				console.log("something went wrong...")
				return navigate("/")
			})
	}, [])

	return <layouts.loading.pending loading={loading}>{children}</layouts.loading.pending>
}

export default Node
