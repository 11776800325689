import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { css } from "@emotion/css"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as icons from "icons"
import * as inputs from "inputs"
import * as debugx from "x/debugx"
import * as mbrandguard from "brandguard"
import classnames from "classnames"

export const TabPrimaryButton = styled(layouts.buttons.primary)`
	width: 100px;
	height: 30px;
	font-weight: 700;
	font-size: 12px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	margin-right: 5px;
`

export const TabOutlineButton = styled(layouts.buttons.outline)`
	width: 100px;
	height: 30px;
	font-weight: 700;
	font-size: 12px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	margin-right: 5px;
	color: ${layouts.theme.colors.grey.dark10};
	border-color: ${layouts.theme.colors.white};
	box-shadow: ${layouts.theme.boxshadow.grey.lightest};
	&:hover {
		border-color: ${layouts.theme.colors.blue};
	}
`

export const Container = styled(layouts.containers.flex)`
	width: 100%;
	text-align: center;
	border-spacing: 15px;
	overflow: auto;
`

export const StyledLink = styled.a`
	margin-top: 15px;
	color: ${layouts.theme.colors.blue};
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`

const Text = styled(layouts.containers.flex)<{ fontWeight: string; color: string; fontSize: string }>`
	color: ${(props) => props.color};
	font-size: ${(props) => props.fontSize};
	font-weight: ${(props) => props.fontWeight};
	line-height: 30px;
	padding-top: 10px;
`

const Card = styled(layouts.containers.box)`
	position: relative;
	border: 1px solid ${layouts.theme.colors.grey.light30};
	padding: 10px;
	min-width: 280px;
	min-height: 70px;
`

const Header = styled(layouts.containers.flex)`
	color: ${layouts.theme.colors.link.hover};
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	width: 100%;
	letter-spacing: 1px;
`

const Description = styled(layouts.containers.flex)`
	color: ${layouts.theme.colors.grey.medium};
	font-size 14px;
	letter-spacing: 1px;
	font-weight: 500;
	line-height: 20px;
	text-align: left; 
	width: 100%;
`

const ApproveIcon = styled(icons.checkboxes.approve)`
	fill: ${(props) => props.fill};
	&:hover {
		fill: ${layouts.theme.colors.blue};
	}
	&:hover path {
		stroke: white;
	}
`

const DismissIcon = styled(icons.checkboxes.dismiss)`
	fill: ${(props) => props.fill};
	&:hover {
		fill: ${layouts.theme.colors.blue};
	}
	&:hover path {
		stroke: white;
	}
`

export const FormContainer = styled(layouts.containers.flex)`
	flex-direction: column;
	margin-bottom: 16px;
	padding: 0 16px 16px;
`

export const inlined = css`
	padding: 0px 45px;
	border-radius: 30px;
	border: none;
	&:focus {
		outline: none;
	}
	&:hover {
		outline: none;
	}
`

export const AccordionHeader = styled.span<{ active?: boolean }>`
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 1px;
	color: ${(props) => (props.active ? layouts.theme.colors.blue : "unset")};
`

export const StyledTextArea = styled(inputs.TextArea)`
	padding-top: 10px;
	padding-left: 10px;
`

export function text(
	props: React.PropsWithChildren<{ fontWeight: string; color: string; fontSize: string }>,
): JSX.Element {
	const { children, fontWeight, color, fontSize, ...rest } = props

	return (
		<Text fontWeight={fontWeight} color={color} fontSize={fontSize} {...rest}>
			{children}
		</Text>
	)
}

export function card(props: React.PropsWithChildren<layouts.containers.ContainerProps>): JSX.Element {
	const { children, ...rest } = props
	return <Card {...rest}>{children}</Card>
}

export function header(props: React.PropsWithChildren<unknown>): JSX.Element {
	const { children, ...rest } = props
	return <Header {...rest}>{children}</Header>
}

export function description(props: React.PropsWithChildren<unknown>): JSX.Element {
	const { children, ...rest } = props
	return <Description {...rest}>{children}</Description>
}

export function Approve(props: { clickApprove(): void; disabled?: boolean }): JSX.Element {
	const { clickApprove, disabled } = props

	return (
		<layouts.containers.flex>
			<layouts.containers.box onClick={() => (disabled ? undefined : clickApprove())}>
				<ApproveIcon fill={disabled ? layouts.theme.colors.grey.light30 : layouts.theme.colors.grey.dark10} />
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}

export function Reject(props: {
	clickReject(evt: React.MouseEvent<HTMLDivElement, MouseEvent>): void
	disabled?: boolean
}): JSX.Element {
	const { clickReject, disabled } = props

	return (
		<layouts.containers.flex>
			<layouts.containers.box onClick={(evt) => (disabled ? evt.stopPropagation() : clickReject(evt))} mr="8px">
				<DismissIcon fill={disabled ? layouts.theme.colors.grey.light30 : layouts.theme.colors.grey.dark10} />
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}

export function BackLink(): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const navigate = useNavigate()
	return (
		<layouts.containers.flex
			width="50%"
			justifyContent="center"
			paddingTop="10px"
			paddingBottom="50px"
			flexDirection="column"
			alignItems="center"
		>
			<StyledLink
				onClick={(evt) => {
					mtoggle(
						<layouts.containers.flex
							styled
							width="200px"
							height="273px"
							borderRadius="10px"
							background={layouts.theme.colors.white}
						>
							<layouts.containers.flex justifyContent="center" flexDirection="column" alignItems="center">
								<layouts.containers.flex
									py="10px"
									justifyContent="center"
									flexDirection="column"
									alignItems="center"
									textAlign="center"
								>
									<layouts.containers.flex
										width="80%"
										py="10px"
										fontWeight="600"
										color={layouts.theme.colors.blue}
										fontSize="12px"
										lineHeight="16px"
									>
										Are you sure you want to cancel?
									</layouts.containers.flex>
									<layouts.containers.flex
										width="80%"
										py="10px"
										fontWeight="400"
										color={layouts.theme.colors.grey.medium}
										fontSize="10px"
										lineHeight="16px"
									>
										By hitting cancel you will be brought back to the dashboard and all the information you have added
										will be removed.
									</layouts.containers.flex>
								</layouts.containers.flex>
								<layouts.containers.flex
									p="15px"
									justifyContent="center"
									flexDirection="column"
									alignItems="center"
								></layouts.containers.flex>

								<layouts.containers.flex flexDirection="row" justifyContent="center" alignItems="center" width="100%">
									<layouts.containers.flex p="5px">
										<layouts.buttons.primary
											width="65px"
											height="35px"
											borderRadius="37px"
											onClick={() => {
												mtoggle(undefined)
												navigate(-1)
											}}
										>
											Yes
										</layouts.buttons.primary>
									</layouts.containers.flex>
									<layouts.containers.flex>
										<layouts.buttons.outline
											width="65px"
											height="35px"
											padding="0"
											borderRadius="37px"
											onClick={() => {
												mtoggle(undefined)
											}}
										>
											No
										</layouts.buttons.outline>
									</layouts.containers.flex>
								</layouts.containers.flex>
							</layouts.containers.flex>
						</layouts.containers.flex>,
					)
				}}
			>
				Cancel
			</StyledLink>
		</layouts.containers.flex>
	)
}

interface editFieldProps {
	content: string
	maxLength: number
	onChange(val: string): void
	required?: boolean
	index?: number
}

const inline = css`
	border: none;
	background: ${layouts.theme.colors.white} !important;
	border-bottom: 2px solid ${layouts.theme.colors.grey.lightest};
	border-radius: 0px !important;
	&:focus {
		outline: none;
	}
	&:hover {
		outline: none;
	}
`

export function EditField(props: editFieldProps): JSX.Element {
	const { content, maxLength, required, onChange } = props

	return (
		<layouts.forms.Container className="gen-edit-field" flex="1">
			<layouts.forms.Group>
				<layouts.containers.flex flexDirection="column" flex="1">
					<inputs.Text
						className={classnames(inline)}
						defaultValue={content}
						onChange={(evt) => onChange(evt.target.value)}
					/>
					<inputs.TextFieldValidation required={required} length={content.length} maxLength={maxLength} />
				</layouts.containers.flex>
			</layouts.forms.Group>
		</layouts.forms.Container>
	)
}

export const GuardScoreStyle = styled(layouts.containers.box)`
	width: 130px;
	height: 15px;
	font-weight: 500;
	font-size: 10px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background: ${layouts.theme.colors.blue};
	color: ${layouts.theme.colors.white};
	text-align: center;

	&.focused {
		cursor: pointer;
		&:before {
			content: "";
			display: block;
			position: fixed;
			width: 100vw;
			height: 100vh;
			background: rgba(0, 0, 0, 0.15);
			top: 0;
			left: 0;
			z-index: 1;
			cursor: initial;
		}
	}
`

interface guardScoreProps {
	grammar: number
	profanity: number
	racism: number
	onbrand: number
	brandguard: number
}

export function GuardScore(props: guardScoreProps): JSX.Element {
	const { grammar, profanity, racism, brandguard, onbrand } = props
	const [focused, setFocused] = useState<boolean>(false)
	const containerRef = React.createRef<HTMLInputElement>()
	const bgs = mbrandguard.api.human(props)
	layouts.events.ClickOutside(containerRef, () => (focused ? setFocused(false) : undefined))
	return (
		<layouts.containers.absolute bottom="0" left="0" ref={containerRef}>
			<GuardScoreStyle onClick={(event) => setFocused(!focused)} className={focused ? "focused" : ""}>
				<layouts.containers.span verticalAlign="sub">BrandGuard {bgs}%</layouts.containers.span>

				<layouts.containers.span paddingLeft="5px" verticalAlign="bottom">
					{focused ? (
						<layouts.accordions.FeatherUp width="5px" stroke={layouts.theme.colors.white} />
					) : (
						<layouts.accordions.FeatherDown width="5px" stroke={layouts.theme.colors.white} />
					)}
				</layouts.containers.span>
			</GuardScoreStyle>
			<layouts.containers.absolute display={focused ? "block" : "none"} width="100%" zIndex="1">
				{debugx.alpha.enabled() && (
					<layouts.containers.flex
						flexDirection="row"
						background={layouts.theme.colors.white}
						fontSize="10px"
						fontWeight="500"
						color={layouts.theme.colors.grey.dark10}
						borderBottom={layouts.theme.borders.grey.light_thin}
					>
						<layouts.containers.flex p="5px" flex="3">
							Brandguard
						</layouts.containers.flex>
						<layouts.containers.flex p="5px" flex="2" justifyContent="end">
							<>{Math.round(brandguard * 10000) / 100} %</>
						</layouts.containers.flex>
					</layouts.containers.flex>
				)}
				<layouts.containers.flex
					flexDirection="row"
					background={layouts.theme.colors.white}
					fontSize="10px"
					fontWeight="500"
					color={layouts.theme.colors.grey.dark10}
					borderBottom={layouts.theme.borders.grey.light_thin}
				>
					<layouts.containers.flex p="5px" flex="3">
						On Brand
					</layouts.containers.flex>
					<layouts.containers.flex p="5px" flex="2" justifyContent="end">
						<>{Math.round(onbrand * 10000) / 100} %</>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex
					flexDirection="row"
					background={layouts.theme.colors.white}
					fontSize="10px"
					fontWeight="500"
					color={layouts.theme.colors.grey.dark10}
					borderBottom={layouts.theme.borders.grey.light_thin}
				>
					<layouts.containers.flex p="5px" flex="3">
						Grammar
					</layouts.containers.flex>
					<layouts.containers.flex p="5px" flex="2" justifyContent="end">
						<>{Math.round(grammar * 10000) / 100} %</>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex
					flexDirection="row"
					background={layouts.theme.colors.white}
					fontSize="10px"
					fontWeight="500"
					color={layouts.theme.colors.grey.dark10}
					borderBottom={layouts.theme.borders.grey.light_thin}
				>
					<layouts.containers.flex p="5px" flex="3">
						Profanity
					</layouts.containers.flex>
					<layouts.containers.flex p="5px" flex="2" justifyContent="end">
						<>{Math.round(profanity * 10000) / 100} %</>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex
					flexDirection="row"
					background={layouts.theme.colors.white}
					fontSize="10px"
					fontWeight="500"
					color={layouts.theme.colors.grey.dark10}
				>
					<layouts.containers.flex p="5px" flex="3">
						Racism
					</layouts.containers.flex>
					<layouts.containers.flex p="5px" flex="2" justifyContent="end">
						<>{Math.round(racism * 10000) / 100} %</>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.absolute>
		</layouts.containers.absolute>
	)
}
