import React from "react"
import * as layouts from "layouts"

export default function Heading(props: React.PropsWithChildren<layouts.containers.SpanProps>): JSX.Element {
	const { children, ...rest } = props
	return (
		<layouts.containers.span marginY="auto" {...rest}>
			{children}
		</layouts.containers.span>
	)
}
