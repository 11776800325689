import * as layouts from "layouts"
import Image from "images/file.loading.gif"
import styled from "@emotion/styled"
import * as typography from "typography"

const Img = styled.img`
	width: 288px;
	height: 212px;
	margin: auto;
`

const Text = styled(typography.h3)`
	padding-top: 50px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: ${layouts.theme.colors.grey.dark10};
`

export default function Inprogress(): JSX.Element {
	return (
		<layouts.containers.flex flexDirection="column" className="processing-component">
			<layouts.containers.flex
				width="1250px"
				minHeight="600px"
				background={layouts.theme.colors.white}
				borderRadius="10px"
				flexDirection="column"
				pt="60px"
			>
				<layouts.containers.flex flexDirection="column" p="50px" my="20px" mx="100px">
					<Img src={Image}></Img>
					<Text>Your PDF file is being processed. Please be patient while we work our magic.</Text>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
