import React from "react"
import { useDropzone, FileRejection, DropEvent } from "react-dropzone"
import * as containers from "layouts/containers"

Dropwell.defaultProps = {
	multiple: false,
}

interface props extends containers.ContainerProps {
	onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
	accept?: { [fileType: string]: string[] }
	multiple: boolean
}

export function Dropwell(props: React.PropsWithChildren<props>): JSX.Element {
	const { children, multiple, onDrop, accept, ...rest } = props

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: onDrop,
		accept: accept,
		multiple: multiple,
	})

	return (
		<containers.box cursor="pointer" {...getRootProps({})} {...rest}>
			<input {...getInputProps()} />
			{children}
		</containers.box>
	)
}
