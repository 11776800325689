import React, { useEffect, useContext } from "react"
import { useLocation, Location } from "react-router-dom"
import classNames from "classnames"
import * as layouts from "layouts"
import * as context from "./context"

export namespace alternates {
	export function by_path(...s: string[]): (_: Location) => boolean {
		return (l: Location): boolean => s.includes(l.pathname)
	}
}

function Inner(
	props: React.PropsWithChildren<
		{ href: string; alternates: (l: Location) => boolean; className?: string; color?: string } & layouts.links.LinkProps
	>,
): JSX.Element {
	const { className, children, href, alternates, ...linkprops } = props

	const { collapsed, path, activated, focus } = useContext(context.Context)
	const location = useLocation()

	useEffect(() => {
		;(location.pathname.startsWith(props.href) || alternates(location)) && focus(path)
	}, [location.pathname, href])

	return (
		<layouts.links.navlink
			to={href}
			height="fit-content"
			background={layouts.theme.colors.white}
			disabled={activated}
			className={classNames({ collapsed: collapsed }, className)}
			{...linkprops}
		>
			<layouts.containers.flex flexDirection="row" width="150px">
				{children}
			</layouts.containers.flex>
		</layouts.links.navlink>
	)
}

export default function Link(
	props: React.PropsWithChildren<
		{ href: string; alternates: (l: Location) => boolean; className?: string } & layouts.links.LinkProps & {
				color?: string
			}
	>,
): JSX.Element {
	const { children, ...linkprops } = props
	return (
		<context.Navigable>
			<Inner {...linkprops}>{children}</Inner>
		</context.Navigable>
	)
}

Link.defaultProps = {
	alternates: (_: Location) => false,
}
