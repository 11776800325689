import * as codes from "./codes"

export function zero(id: string, c: codes.Code): Discount {
	return {
		id: id,
		code: c,
	}
}

export default interface Discount {
	id: string
	code: codes.Code
}
