import * as layouts from "layouts"
import * as icons from "icons"
import * as inputs from "inputs"
import { FileRejection } from "react-dropzone"

interface props {
	onUpload(acceptedFiles: File[], rejectedFiles: FileRejection[]): void
	acceptedTypes: { [key: string]: string[] }
}

export default function Upload(props: props): JSX.Element {
	const { onUpload, acceptedTypes } = props

	return (
		<layouts.containers.flex flexDirection="column" className="upload-component">
			<layouts.containers.flex
				width="1250px"
				minHeight="600px"
				background={layouts.theme.colors.white}
				borderRadius="10px"
				flexDirection="column"
				pt="60px"
			>
				<layouts.containers.flex m="25px 70px" flex="1">
					<inputs.Dropwell
						accept={acceptedTypes}
						onDrop={(accepted, rejected, evt) => onUpload(accepted, rejected)}
						width="100%"
						multiple
					>
						<layouts.dnd.Container>
							<icons.FileUpload />
							<layouts.dnd.Textual>Drag & drop your files here or choose files</layouts.dnd.Textual>
						</layouts.dnd.Container>
					</inputs.Dropwell>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
