import React from "react"
import styled from "@emotion/styled"
import * as system from "styled-system"

interface props extends system.SizeProps, system.FlexProps, system.MarginProps {}

const Img = styled.img<props>`
	${system.margin}
	${system.size}
	${system.flex}
`

export function Base(
	props: React.ImgHTMLAttributes<HTMLImageElement> & system.SizeProps & system.FlexProps & system.MarginProps,
): JSX.Element {
	const { src, alt, ...rest } = props

	return <Img src={src} alt={alt} {...rest}></Img>
}
