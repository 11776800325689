import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as icons from "icons"
import * as api from "brandguard/api"

interface propsItem {
	item: api.ImageSearchResponseItem | api.TextSearchResponseItem
}

export namespace uploads {
	export const DragAndDropArea = styled(layouts.containers.flex)`
		flex-direction: column;
		border: 2px dotted ${layouts.theme.colors.grey.medium};
		border-radius: 8px;
		padding: 50px;
		color: ${layouts.theme.colors.grey.medium};
	`

	export const StyledBtn = styled(layouts.buttons.outline)`
		background: ${layouts.theme.colors.white};
		border: 1px solid ${layouts.theme.colors.grey.light20};
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
		height: 48px;
		border-radius: 48px;
		padding: 0 23px;
		&:hover {
			border: 1px solid ${layouts.theme.colors.blue};
			background: ${layouts.theme.colors.white};
			color: ${layouts.theme.colors.blue};
			box-shadow: 2px 2px 4px ${layouts.theme.colors.blue}7E;
		}
	`
}

export namespace fileDetails {
	export const AccordionHeader = styled.span<{ active?: boolean }>`
		font-weight: 500;
		font-size: 12pt;
		line-height: 20px;
		letter-spacing: 1px;
		color: ${layouts.theme.colors.grey.light40};
	`

	export const InformationGroup = styled(layouts.containers.flex)`
		padding-top: 20px;
		padding-bottom: 30px;
		flex-direction: column;
		&:not(:last-of-type) {
			border-bottom: 2px solid ${layouts.theme.colors.grey.light20};
		}
	`

	export const InformationGroupTitle = styled(layouts.containers.span)`
		margin-bottom: 10px;
		font-weight: 500;
		font-size: 12pt;
		line-height: 20px;
		color: ${layouts.theme.colors.grey.light40};
	`

	export const ScoreBoxContainerStyle = styled(layouts.containers.flex)`
		flex-direction: column;
		align-items: center;
		margin-bottom: 025px;
	`

	export const ScoreBoxStyle = styled(layouts.containers.flex)<{ backgroundColor?: string }>`
		justify-content: center;
		align-items: center;
		width: 51px;
		height: 33px;
		border-radius: 5px;
		font-size: 20px;
		font-weight: 800;
		line-height: 33px;
		color: ${layouts.theme.colors.white};
		background-color: ${(props) => props.backgroundColor || layouts.theme.colors.blue};
	`

	export const ScoreBoxTitle = styled(layouts.containers.flex)`
		font-weight: 600;
		font-size: 9pt;
		line-height: 11px;
		color: ${layouts.theme.colors.grey.dark5};
		margin-top: 3px;
	`

	interface scoreBoxProps {
		originalScore?: number
		score?: string
		title?: string
	}

	function ScoreBoxGood(props: scoreBoxProps): JSX.Element {
		const { score, title } = props
		return (
			<ScoreBoxContainerStyle>
				<ScoreBoxStyle>{score || <icons.marks.check />}</ScoreBoxStyle>
				{title && <ScoreBoxTitle>{title}</ScoreBoxTitle>}
			</ScoreBoxContainerStyle>
		)
	}

	function ScoreBoxAverage(props: scoreBoxProps): JSX.Element {
		const { score, title } = props
		return (
			<ScoreBoxContainerStyle>
				<ScoreBoxStyle backgroundColor={layouts.theme.colors.red.mandarin}>{score || <icons.marks.x />}</ScoreBoxStyle>
				{title && <ScoreBoxTitle>{title}</ScoreBoxTitle>}
			</ScoreBoxContainerStyle>
		)
	}

	function ScoreBoxBad(props: scoreBoxProps): JSX.Element {
		const { score, title } = props
		return (
			<ScoreBoxContainerStyle>
				<ScoreBoxStyle backgroundColor={layouts.theme.colors.red.cinnabar}>{score || <icons.marks.x />}</ScoreBoxStyle>
				{title && <ScoreBoxTitle>{title}</ScoreBoxTitle>}
			</ScoreBoxContainerStyle>
		)
	}

	// weights for score board
	// > 75% good
	// 35 - 74% average
	// < 35% is bad
	export function ScoreBox(props: scoreBoxProps): JSX.Element {
		const { originalScore, title } = props
		const scorePercent = Math.round(originalScore! * 100)
		if (scorePercent >= 75) return <ScoreBoxGood score={`${scorePercent}%`} title={title} />
		if (scorePercent >= 35) return <ScoreBoxAverage score={`${scorePercent}%`} title={title} />
		return <ScoreBoxBad score={`${scorePercent}%`} title={title} />
	}
}

export const Card = styled(layouts.containers.box)<{ active?: boolean }>`
	// margin-top: auto; // to align by bottom
	margin-bottom: auto; // to align by top
	min-width: 380px;
	min-height: 70px;
	border: ${(props) =>
		props.active ? `2px solid ${layouts.theme.colors.blue}` : `2px solid ${layouts.theme.colors.white}`};
	box-shadow: ${(props) => (props.active ? layouts.theme.boxshadow.black.wide : "unset")};
	font-weight: 400;
	font-size: 12pt;
	line-height: 16px;
	background-color: ${layouts.theme.colors.grey.lightest};
	color: ${layouts.theme.colors.black};
`

export const CardTextContent = styled(layouts.containers.flex)`
	padding: 58px;
	font-weight: 400;
	font-size: 12pt;
	line-height: 16pt;
	text-align: left;
	color: ${layouts.theme.colors.black};
`

export const ActionPanelStyle = styled(layouts.containers.flex)<{ backgroundColor?: string }>`
	justify-content: space-between;
	height: 25px;
	bottom: 0px;
	padding: 2px 9px;
	background-color: ${(props) => props.backgroundColor || layouts.theme.colors.blue};
	color: ${layouts.theme.colors.white};
`

export const StatusTextStyle = styled(layouts.containers.flex)`
	align-items: center;
	text-transform: capitalize;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	color: ${layouts.theme.colors.white};
`

export const FeedbackContainerStyle = styled(layouts.containers.flex)`
	align-items: center;
	font-size: 12px;
	font-weight: 400;
`

export const FeedbackOption = styled(layouts.containers.box)<{ active?: boolean }>`
	position: relative;
	width: 25px;
	height: 15px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	border: 0.5px solid ${(props) => (props.active ? layouts.theme.colors.space_cadet : layouts.theme.colors.white)};
	color: ${(props) => (props.active ? layouts.theme.colors.space_cadet : layouts.theme.colors.white)};
	&:not(:last-of-type) {
		margin-right: 10px;
	}
	transition: 0.5s;
	&:hover {
		border-color: ${layouts.theme.colors.space_cadet};
		color: ${layouts.theme.colors.space_cadet};
	}
	&:before {
		position: absolute;
		width: 55px;
		height: 13px;
		left: -50%;
		background: ${layouts.theme.colors.grey.dark5};
		border-radius: 3px;
		bottom: 19px;
		color: ${layouts.theme.colors.white};
		content: attr(data-tooltip);
		display: block;
		white-space: nowrap;
		text-transform: none;
		font-weight: 400;
		font-size: 10px;
		line-height: 13px;
		opacity: 0;
		transition: 0.5s;
	}
	&:hover:before {
		opacity: 1;
	}
`

export function IconStatus(props: propsItem): JSX.Element {
	const { item } = props
	if (item.event?.approved === api.Prediction.REJECTED) {
		return <icons.prediction.rejected />
	}

	if (item.event?.approved === api.Prediction.APPROVED) {
		return <icons.prediction.approved />
	}

	return <icons.prediction.processing />
}

export function StatusColor(props: propsItem): string {
	const { item } = props
	if (item.event?.approved === api.Prediction.REJECTED) return layouts.theme.colors.red.cinnabar
	if (item.event?.approved === api.Prediction.APPROVED) return layouts.theme.colors.blue

	return layouts.theme.colors.grey.dark10
}

export function FeedbackYes(props: { active?: boolean } & React.DOMAttributes<HTMLDivElement>): JSX.Element {
	const { ...rest } = props
	return (
		<FeedbackOption data-tooltip="I agree" {...rest}>
			YES
		</FeedbackOption>
	)
}

export function FeedbackNo(props: { active?: boolean } & React.DOMAttributes<HTMLDivElement>): JSX.Element {
	const { ...rest } = props
	return (
		<FeedbackOption data-tooltip="I disagree" {...rest}>
			NO
		</FeedbackOption>
	)
}
