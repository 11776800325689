import React from "react"
import * as layouts from "layouts"
import * as api from "./api"

interface props extends layouts.containers.FlexProps {
	current: api.Pool
}

// general display for a pool.
export default function Display(props: props): JSX.Element {
	const { current, ...rest } = props
	return (
		<layouts.containers.flex flexDirection="column" {...rest}>
			<layouts.forms.Group>
				<layouts.forms.Label width="14ch">Impressions</layouts.forms.Label>
				<layouts.containers.span ml="auto">{(current.impressions || 0n).toString(10)}</layouts.containers.span>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label width="14ch">Conversions</layouts.forms.Label>
				<layouts.containers.span ml="auto">{(current.conversions || 0n).toString(10)}</layouts.containers.span>
			</layouts.forms.Group>
		</layouts.containers.flex>
	)
}
