import React, { useState } from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as api from "./api"
import * as errors from "errors"
import * as sessions from "sessions"

interface props {
	setLoading(b: boolean): void
	setCause(x?: JSX.Element): void
}

export function Modal(props: props): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const [searchTerm, setSearchTerm] = useState("aquaglide chelan 120")
	const [intervalTime, setIntervalTime] = useState(1200000000000)
	const [productId, setProductIdTerm] = useState("00000000-0000-0000-0000-000000000000")
	const bearertoken = sessions.useToken()

	const [results, setResults] = useState({
		items: [],
	} as api.CreateResponse)

	function handleSubmit() {
		props.setLoading(true)
		api
			.create({ query: searchTerm, product_id: productId, duration: intervalTime }, bearertoken)
			.then((r) => {
				setResults(r)
				console.log(results)
			})
			.catch((c: unknown) => {
				console.error("unable to submit promotions", c)
				props.setCause(
					<errors.Textual onClick={() => props.setCause(undefined)}>unable to submit promotions</errors.Textual>,
				)
			})
			.finally(() => {
				mtoggle(undefined)
				props.setLoading(false)
			})
	}

	return (
		<layouts.containers.box styled width="500px">
			<Create
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				interval={intervalTime}
				setInterval={setIntervalTime}
				productId={productId}
				setProductId={setProductIdTerm}
			/>
			<layouts.containers.flex mt="20px">
				<layouts.buttons.outline mx="5px" ml="auto" onClick={() => mtoggle(undefined)}>
					Cancel
				</layouts.buttons.outline>
				<layouts.buttons.primary mx="5px" onClick={() => handleSubmit()}>
					Save
				</layouts.buttons.primary>
			</layouts.containers.flex>
		</layouts.containers.box>
	)
}

interface createProps {
	searchTerm: string
	setSearchTerm(s: string): void
	interval: number
	setInterval(n: number): void
	productId: string
	setProductId(p: string): void
}

export function Create(props: createProps): JSX.Element {
	const { searchTerm, setSearchTerm, interval, setInterval, productId, setProductId } = props

	return (
		<layouts.forms.Container className="competitor-promotions-create-form">
			<fieldset>
				<layouts.forms.Group>
					<layouts.forms.Label>Search Term</layouts.forms.Label>
					<inputs.Text defaultValue={searchTerm} onChange={(evt) => setSearchTerm(evt.currentTarget.value)} />
				</layouts.forms.Group>
				<layouts.forms.Group>
					<layouts.forms.Label>Run Interval</layouts.forms.Label>
					<inputs.Numeric
						defaultValue={interval}
						onChange={(evt) => setInterval(parseInt(evt.currentTarget.value, 10))}
					/>
				</layouts.forms.Group>
				<layouts.forms.Group>
					<layouts.forms.Label>Associated Product Id</layouts.forms.Label>
					<inputs.Text defaultValue={productId} onChange={(evt) => setProductId(evt.currentTarget.value)} />
				</layouts.forms.Group>
			</fieldset>
		</layouts.forms.Container>
	)
}
