import React from "react"
import classnames from "classnames"
import styled from "@emotion/styled"

interface props {
	onChange?(e: React.ChangeEvent<HTMLTextAreaElement>): void
	height?: string
	className?: string
	disabled?: boolean
	default?: string
	value?: string
	placeholder?: string
}

const Area = styled.textarea<props>`
	box-sizing: border-box;
	width: 100%;
	height: ${(props) => (props.height ? props.height : "200px")};
	top: 26px;
	gap: 10px;
	border-radius: 6px;
	background: ${(props) => props.disabled && "#F5F5F5"};
	background-color: ${(props) => props.disabled && "#F5F5F5"};
`

export function TextArea(props: props): JSX.Element {
	const { className, disabled, default: defaulted, ...rest } = props

	return (
		<Area className={classnames("text-area-content", className)} disabled={disabled} {...rest}>
			{defaulted}
		</Area>
	)
}
