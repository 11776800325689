import React from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as api from "./api"

import Metrics from "./pool.metrics"

interface props extends layouts.containers.FlexProps {
	current: api.Pool
}

// Highlevel overview display for a ad pool.
export default function Overview(props: props): JSX.Element {
	const { current, ...rest } = props
	return (
		<layouts.containers.flex
			styled
			flexDirection="column"
			px="10px"
			borderRadius={layouts.theme.borders.radius.standard}
			{...rest}
		>
			<typography.h3 mx="auto">{current.description}</typography.h3>
			<Metrics mx="auto" current={current} />
		</layouts.containers.flex>
	)
}
