import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as context from "./context"
import * as discounts from "./discounts"
import * as offers from "./offers"

interface props extends layouts.containers.ContainerProps {
	ctx: context.default
}

const ListItem = styled.li`
	margin-top: 10px;
	font-family: monospace;
`

export default function Navigation(props: props): JSX.Element {
	const { ctx, ...rest } = props

	return (
		<layouts.containers.flex className="navigation" {...rest}>
			<layouts.containers.box styled flex="1">
				<layouts.containers.flex flexDirection="column">
					<layouts.containers.flex flexDirection="column">
						<layouts.containers.flex width="100%">
							<layouts.containers.span ml="auto">Displays</layouts.containers.span>
							<layouts.buttons.primary
								ml="auto"
								onClick={() => {
									ctx.edit({
										preview: (props: { ctx: context.default }) => <></>,
										properties: (props: { ctx: context.default }) => (
											<discounts.NewDiscount
												onChange={(d) => {
													props.ctx.displays.insert(d)
												}}
											/>
										),
									})
								}}
							>
								New
							</layouts.buttons.primary>
						</layouts.containers.flex>
						<ul>
							{ctx.program.displays.map((z) => (
								<ListItem key={z.properties.discount.id}>
									<discounts.Display current={z} />
								</ListItem>
							))}
						</ul>
					</layouts.containers.flex>
					<layouts.containers.flex flexDirection="column" mt="10px">
						<layouts.containers.flex width="100%">
							<layouts.containers.span ml="auto">Experiments</layouts.containers.span>
							<layouts.buttons.primary
								ml="auto"
								onClick={() => {
									ctx.edit(offers.zero())
								}}
							>
								New
							</layouts.buttons.primary>
						</layouts.containers.flex>
						<ul>
							{ctx.program.offers.map((z) => (
								<ListItem
									key={z.offer.id}
									onClick={() => {
										ctx.edit({
											preview: (props: { ctx: context.default }) => <></>,
											properties: (props: { ctx: context.default }) => z.display({ ctx: props.ctx, current: z }),
										})
									}}
								>
									<layouts.containers.span>
										{z.offer.type} - {z.offer.name || z.offer.id} - {z.offer.weight}
									</layouts.containers.span>
								</ListItem>
							))}
						</ul>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.box>
		</layouts.containers.flex>
	)
}
