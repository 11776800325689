import * as luxon from "luxon"
import * as authz from "authz"

export function token<T extends authz.tokens.Token>(t: authz.tokens.Cached<T>): Promise<T> {
	const ts = luxon.DateTime.local().minus(10000)
	if (authz.tokens.expired(t.current, ts)) {
		t.pending =
			t.pending ||
			new Promise((resolve, reject) => {
				t.refresh()
					.then((next) => {
						t.current = next
						t.pending = undefined
						resolve(next)
					})
					.catch(reject)
			})
		return t.pending
	}

	return Promise.resolve(t.current)
}

export function cached<T extends authz.tokens.Token>(
	t: T,
	refresh: (c: authz.tokens.Cached<T>) => Promise<T>,
): authz.tokens.Cached<T> {
	const cached = {
		current: t,
		pending: undefined,
		refresh: () => refresh(cached),
		token: (): Promise<T> => token(cached),
	}

	return cached
}
