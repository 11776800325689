import React, { ReactNode, useState } from "react"
import * as layouts from "layouts"
import { TabOutlineButton, TabPrimaryButton } from "../display/layouts"

type props = {
	disabled: boolean
	children: ReactNode[]
	titles: string[]
}

export default function Tabs(props: props): JSX.Element {
	const { children, titles, disabled } = props
	const [selectedTab, setSelectedTab] = useState(0)

	return (
		<layouts.containers.flex flexDirection="column" pt="25px" className="styleguard-tabs">
			<layouts.containers.flex flexDirection="row" pl="50px">
				{titles.map((item, index) =>
					selectedTab === index || disabled ? (
						<TabPrimaryButton disabled={disabled} key={index}>
							{item}
						</TabPrimaryButton>
					) : (
						<TabOutlineButton key={index} onClick={() => setSelectedTab(index)}>
							{item}
						</TabOutlineButton>
					),
				)}
			</layouts.containers.flex>
			{children[selectedTab]}
		</layouts.containers.flex>
	)
}

Tabs.defaultProps = {
	disabled: false,
}
