import * as system from "styled-system"
import styled from "@emotion/styled"

interface RingProps
	extends system.SpaceProps,
		system.LayoutProps,
		system.PositionProps,
		system.OpacityProps,
		system.BorderProps {}

const Container = styled.svg`
	${system.layout}
	${system.space}
	${system.position}
	${system.opacity}
`

export const Ring1 = styled.div<{ foreground?: string } & RingProps>`
	animation: ring 1.2s linear infinite;
	border-color: ${(props) => props.foreground} ${(props) => props.foreground} ${(props) => props.foreground} transparent;
	border-style: solid;
	border-width: 8px;
	border-radius: 50%;
	aspect-ratio: 1/1;

	@keyframes ring {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	${system.layout}
	${system.space}
	${system.position}
	${system.opacity}
	${system.border}
`

export function Ring2(
	props: { foreground?: string; className?: string; duration?: string } & system.SpaceProps & system.PositionProps,
) {
	const { foreground, className, duration, ...rest } = props

	return (
		<Container
			viewBox="0 0 217 25"
			fill={foreground}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				fill="url(#paint0_angular_5_98)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.7816 9.60074C23.3294 9.53007 23.8307 9.91682 23.9014 10.4646C23.9671 10.9738 24 11.4866 24 12C24 12.5523 23.5523 13 23 13C22.4477 13 22 12.5523 22 12C22 11.5722 21.9726 11.1448 21.9178 10.7205C21.8471 10.1727 22.2339 9.67141 22.7816 9.60074Z"
				fill="#16133F"
			>
				<animateTransform
					attributeType="xml"
					attributeName="transform"
					type="rotate"
					from="0 12 12"
					to="360 12 12"
					dur={duration || "1.5s"}
					repeatCount="indefinite"
				/>
			</path>
			<defs>
				<radialGradient
					id="paint0_angular_5_98"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12 12) scale(12)"
				>
					<stop offset="0.0001" stopColor="#667080" stopOpacity="0" />
					<stop offset="0.631363" stopColor="#667080" />
					<stop offset="1" stopColor="#667080" />
				</radialGradient>
			</defs>
		</Container>
	)
}
