import * as pools from "ads/pools"

export namespace creative {
	export function google(bid: string, pid: string): string {
		return `${pools.routing.root(bid, pid)}/studio/c/google/responsive`
	}

	export function facebook(bid: string, pid: string): string {
		return `${pools.routing.root(bid, pid)}/studio/c/facebook/adlink`
	}
}

export namespace approvals {
	export function google(bid: string, pid: string): string {
		return `${pools.routing.root(bid, pid)}/studio/q/google`
	}

	export function facebook(bid: string, pid: string): string {
		return `${pools.routing.root(bid, pid)}/studio/q/facebook`
	}
}
