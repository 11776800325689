import * as md5x from "md5x"
import * as fingerprint from "../../fingerprint"
import Client from "../../client"
import impression from "../../impression"
import { Offer, Display } from "../offer"

export function offer(name: string, weight: number, dop: Display): Offer {
	const idhash = md5x.string(name)
	const op = {
		discounts: () => dop.discounts(),
		async impression(ctx: Client, imp: impression, uid: fingerprint.ID): Promise<impression> {
			const gid = md5x.string(ctx.cid + idhash)
			const mergedimp = {
				...imp,
				gid: gid,
				prediction: -1,
				probability: 0.0,
			}

			return dop.impression(ctx, mergedimp, uid)
		},
	}

	return {
		type: "constant",
		id: idhash,
		name: name,
		weight: weight,
		display: op,
	}
}
