import React, { useEffect, useState } from "react"
import * as sessions from "sessions"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import * as mediaapi from "media/api"
import styled from "@emotion/styled"

interface props {
	item: api.TextSearchResponseItem
	active?: boolean
	onCardClick(): void
	onTextUpdate(hitem: api.TextSearchResponseItem): void
}

export const Card = styled(layouts.containers.box)<{ active?: boolean }>`
	margin-bottom: auto;
	min-width: 380px;
	min-height: 70px;
	border: ${(props) =>
		props.active ? `2px solid ${layouts.theme.colors.blue}` : `2px solid ${layouts.theme.colors.white}`};
	box-shadow: ${(props) => (props.active ? layouts.theme.boxshadow.black.wide : "unset")};
	font-weight: 400;
	font-size: 12pt;
	line-height: 16px;
	background-color: ${layouts.theme.colors.grey.bg};
	color: ${layouts.theme.colors.black};
`

export const CardTextContent = styled(layouts.containers.flex)`
	padding: 58px;
	font-weight: 400;
	font-size: 12pt;
	line-height: 16pt;
	text-align: left;
	color: ${layouts.theme.colors.black};
`

export default function DisplayText(props: props & React.PropsWithChildren<unknown>): JSX.Element {
	const { item, active, onCardClick } = props
	const bearertoken = sessions.useToken()
	const [loading, setLoading] = useState(true)
	const [response, setResponse] = useState("" as string)

	useEffect(() => {
		setLoading(true)
		mediaapi
			.url(item.media?.md5!, bearertoken)
			.then((result) => fetch(result.url))
			.then((resp) => resp.text())
			.then((data) => setResponse(data))
			.catch(console.error)
			.finally(() => setLoading(false))
	}, [item.media?.md5])

	return (
		<Card key={item.media?.id} active={active} onClick={onCardClick}>
			<layouts.loading.screen loading={loading}>
				<CardTextContent>{response}</CardTextContent>
			</layouts.loading.screen>
		</Card>
	)
}
