import React, { useContext, useState } from "react"
import classnames from "classnames"
import styled from "@emotion/styled"
import * as containers from "./containers"
import * as theme from "./theme"
import * as svg from "icons/svg"

export const AccordionContext = React.createContext({
	open: false,
	toggle: (o: boolean) => {},
})
export const AccordionProvider = AccordionContext.Provider

const StyledContainer = styled(containers.flex)`
	background: #ffffff;
	border-bottom: ${(props) => props.borderBottom || `2px solid ${theme.colors.grey.lightest}`};
	padding: 8px 16px;
`

const Title = styled.span`
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: -0.02em;
	font-feature-settings: "calt" off;
`

const StyledContent = styled(containers.flex)`
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 22px;
	letter-spacing: -0.02em;
	font-feature-settings: "calt" off;
	color: #616161;
	overflow-y: auto;
`

interface props {
	initOpen?: boolean
	className?: string
}

export function Container(props: React.PropsWithChildren<props & containers.FlexProps>): JSX.Element {
	const { initOpen, className, children, ...rest } = props
	const [isOpen, setIsOpen] = useState(initOpen!)

	const ctx = {
		open: isOpen,
		toggle: setIsOpen,
	}

	return (
		<AccordionProvider value={ctx}>
			<StyledContainer minHeight="40px" {...rest} className={classnames("accordion", className)} flexDirection="column">
				{children}
			</StyledContainer>
		</AccordionProvider>
	)
}

Container.defaultProps = {
	initOpen: false,
	className: "",
}

export function Description(props: React.PropsWithChildren<props & containers.FlexProps>): JSX.Element {
	const { open, toggle } = useContext(AccordionContext)
	const { children, ...rest } = { ...props }

	return (
		<containers.flex
			width="100%"
			justifyContent="space-between"
			onClick={() => {
				toggle(!open)
			}}
			cursor="pointer"
			className="accordion-description"
			{...rest}
		>
			<containers.flex>
				<Title>{children}</Title>
			</containers.flex>
			<containers.span>{open ? <FeatherUp /> : <FeatherDown />}</containers.span>
		</containers.flex>
	)
}

export function Content(props: React.PropsWithChildren<props>): JSX.Element {
	const { open } = useContext(AccordionContext)

	return open ? <StyledContent>{props.children}</StyledContent> : <></>
}

export const FeatherDown = (props: svg.SVGProps) => {
	const { stroke, ...rest } = props
	return (
		<svg.SVG width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path d="M1 1L7 7L13 1" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg.SVG>
	)
}

FeatherDown.defaultProps = {
	stroke: theme.colors.grey.light40,
}

export const FeatherUp = (props: svg.SVGProps) => {
	const { stroke, ...rest } = props
	return (
		<svg.SVG width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path d="M13 7L7 1L1 7" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg.SVG>
	)
}

FeatherUp.defaultProps = {
	stroke: theme.colors.grey.light40,
}
