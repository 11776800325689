import * as uuid from "uuid"
import { DateTime } from "luxon"
import * as httpx from "httpx"
import * as proto from "./account.brand"

export type { Brand, SearchRequest, SearchResponse } from "./account.brand"

const brand_id_key = "nsuite.brand.id"

export function getLastBrandId(account_id: string): string {
	return window.localStorage.getItem(`${brand_id_key}.${account_id}`) || ""
}

// this should only be invoked in response to a *direct* user action.
// it should never be set implicitly from urls or other sources.
export function setLastBrandId(account_id: string, s: string): string {
	window.localStorage.setItem(`${brand_id_key}.${account_id}`, s)
	return s
}

export function zero(b: Partial<proto.Brand> = {}): proto.Brand {
	let ts = DateTime.now()
	return {
		id: uuid.NIL,
		description: "",
		domain: "",
		created_at: ts.toISO(),
		updated_at: ts.toISO(),
		account_id: uuid.NIL,
		...b,
	}
}

export function search(req: proto.SearchRequest, ...options: httpx.option[]): Promise<proto.SearchResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/brands`, req, ...options)
}

export function get(id: string, ...options: httpx.option[]): Promise<{ brand: proto.Brand }> {
	return httpx
		.get<{ brand: proto.Brand }>(`${httpx.urlstorage.host()}/brands/${id}`, {}, ...options)
		.then((r) => ({ ...r, brand: zero(r.brand) }))
}

export function create(req: proto.CreateRequest, ...options: httpx.option[]): Promise<proto.CreateResponse> {
	return httpx.post(`${httpx.urlstorage.host()}/brands`, req, ...options)
}

export function update(req: proto.UpdateRequest, ...options: httpx.option[]): Promise<proto.UpdateResponse> {
	return httpx.put(`${httpx.urlstorage.host()}/brands/${req.brand?.id}`, req, ...options)
}
