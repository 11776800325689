import React, { useEffect } from "react"
import classnames from "classnames"
import styled from "@emotion/styled"
import { css } from "@emotion/css"
import * as api from "./api"

export type Cause = JSX.Element | undefined

const defaultProps = {
	className: "",
	logging: console.error,
}

export function Display(props: React.PropsWithChildren<api.Props>): JSX.Element {
	props = { ...defaultProps, ...props }
	useEffect(() => {
		props.logging && props.cause && props.logging(props.cause)
	})

	const { className, onClick, ...rest } = props

	return (
		<api.Flex className={classnames("error", className)} onClick={onClick} {...rest}>
			{props.children}
		</api.Flex>
	)
}

export function Textual(props: React.PropsWithChildren<api.Props>): JSX.Element {
	const { children, className, ...rest } = props

	const cssstyle = css`
		width: inherit;
		height: inherit;
	`

	const Text = styled.h1`
		margin: auto;
	`

	return (
		<Display className={classnames(cssstyle, className)} {...rest}>
			<Text>{children}</Text>
		</Display>
	)
}

export function UnknownCause(props: api.Props): JSX.Element {
	return <Textual {...props}>Something went wrong. Click to reload.</Textual>
}

export function NotAuthorized(props: api.Props): JSX.Element {
	return <Textual {...props}>You&apos;re not authorized to view this content</Textual>
}
