import React from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as profiles from "profiles"
import * as permissions from "meta/permissions"
import styled from "@emotion/styled"

interface props {
	current: profiles.Profile
	focused?: boolean
	onChange(updated: profiles.Profile | undefined): Promise<profiles.Profile | undefined>
	onClick?: React.MouseEventHandler<HTMLTableRowElement>
}

const TableRow = styled(layouts.tables.Row)`
	&:hover {
		color: unset;
		background: ${layouts.theme.colors.grey.light10};
	}
`

export default function Row(props: props): JSX.Element {
	const { current, focused, onChange, ...rest } = props

	return (
		<>
			<TableRow {...rest}>
				<td>{current.display}</td>
				<td>{current.email}</td>
				<td>
					<inputs.toggles.Display checked={profiles.enabled(current)} />
				</td>
				<td>{current.created_at}</td>
				<td>{current.updated_at}</td>
			</TableRow>
			{focused && (
				<layouts.tables.inline>
					<layouts.containers.flex flex="1">
						<layouts.containers.box ml="auto" />
						<layouts.containers.grid gridGap="10px" flex="1" width="100%">
							<profiles.Edit
								styled
								padding="10px"
								borderRadius={layouts.theme.borders.radius.standard}
								border={layouts.theme.borders.grey.darkestalpha}
								cancel={<span>Cancel</span>}
								submit={<span>Update</span>}
								current={current}
								onChange={onChange}
							/>
							<permissions.Modify current={current} onChange={(p) => onChange(p).then((p) => p || current)} />
						</layouts.containers.grid>
						<layouts.containers.box mr="auto" />
					</layouts.containers.flex>
				</layouts.tables.inline>
			)}
		</>
	)
}
