import { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/css"
import classnames from "classnames"
import md5 from "md5"
import * as layouts from "layouts"
import * as api from "./api"

const Graph = css`
	padding: 10px;
`

const Bar = styled.div`
	width: 2em;
	height: inherit;
	background: #f3f3f3;
	border-top-left-radius: 51px;
	border-top-right-radius: 51px;
`

const BarInverted = styled.div`
	display: flex;
	width: 2em;
	height: inherit;
	background: #f3f3f3;
	padding-bottom: 1em;
	border-bottom-left-radius: 51px;
	border-bottom-right-radius: 51px;
`

const BarPadded = styled.div`
	position: relative;
	margin: 1em 0.25em;
	height: calc(100% - 2em);
	width: inherit;
`

const StyledText = css`
	margin: 0px auto;
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	text-overflow: ellipsis;
	overflow: hidden;
`

const Nominal = styled.span`
	position: absolute;
	width: 100%;
	border-style: solid;
	border-width: 1px;
	border-radius: 1em;
	border-color: ${layouts.theme.colors.black};
	top: 50%;
`

const Measurement = styled.span<{ offset?: string; background: string }>`
	position: absolute;
	width: 1.5em;
	height: 1.5em;
	background-color: ${(props) => props.background};
	bottom: calc(${(props) => props.offset} - 0.85em);
	border-radius: 50%;
	border-color: ${layouts.theme.colors.black};
	border-width: 3px;
	border-style: solid;
`

const Brand = styled.span``

const Legend = styled.span<{ background: string }>`
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: ${(props) => props.background};
	margin-right: 5px;
`

const Title = styled.span`
	font-style: normal;
	font-weight: 800;
	font-size: 36px;
	line-height: 48px;

	/* identical to box height, or 133% */
	letter-spacing: -0.02em;
	font-feature-settings: "calt" off;

	/* White */
	color: ${layouts.theme.colors.grey.medium};
`

interface brand extends api.Grade {
	color: string
}

export default function Display(props: unknown): JSX.Element {
	const [grading, setGrading] = useState([] as brand[])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		api
			.scoring(0)
			.then((grading) => {
				let brands = grading.map(
					(grade) =>
						({
							...grade,
							color: `#${parseInt(md5(grade.brand), 16).toString(16).slice(0, 6)}`,
						} as brand),
				)
				setGrading(brands)
			})
			.finally(() => setLoading(false))
	}, [])

	let brands = Array.from(
		grading.reduce((accum, g) => {
			accum.set(g.brand, g)
			return accum
		}, new Map<string, brand>()),
	).map(([k, v]) => {
		return (
			<Brand key={k}>
				<Legend background={v.color} />
				{k}
			</Brand>
		)
	})

	let cats = grading.reduce((accum, g) => {
		let cat = accum.get(g.emotion) || []
		accum.set(g.emotion, cat.concat(g))
		return accum
	}, new Map<string, brand[]>())

	let elements = Array.from(cats.entries()).map(([k, v]) => {
		const midpoint = v.reduce((max, g) => (max < g.value ? g.value : max), -Infinity)
		return (
			<layouts.containers.flex key={k} height="100%" mx="10px" className="category">
				<Bar className="bar">
					<BarPadded>
						{v.map((b) => (
							<Measurement
								key={b.brand}
								className="measurement"
								background={b.color}
								offset={midpoint === 0 ? "50%" : `${((b.value / midpoint) * 100).toFixed(2)}%`}
							/>
						))}
					</BarPadded>
				</Bar>
			</layouts.containers.flex>
		)
	})
	let emotions = Array.from(cats.entries()).map(([k, _]) => {
		return (
			<layouts.containers.flex key={k} m="0px 10px">
				<BarInverted>
					<layouts.containers.span className={StyledText}>{k}</layouts.containers.span>
				</BarInverted>
			</layouts.containers.flex>
		)
	})
	return (
		<layouts.containers.flex p="50px" width="100%" flexDirection="column">
			<layouts.containers.flex m="10px auto" mt="20px">
				<Title>Competitor Driver Score</Title>
			</layouts.containers.flex>
			<layouts.containers.flex width="80%" flex="1" m="10px auto" className="grading">
				<layouts.loading.pending loading={loading}>
					<layouts.containers.box styled width="100%">
						<layouts.containers.flex className={classnames("graph", Graph)} height="100%" flexDirection="column">
							<layouts.containers.flex width="100%" flex="5" position="relative" justifyContent="space-around">
								{elements}
								<Nominal />
							</layouts.containers.flex>
							<layouts.containers.flex width="100%" flex="1" mt="10px" justifyContent="space-around">
								{emotions}
							</layouts.containers.flex>
						</layouts.containers.flex>
						<layouts.containers.flex justifyContent="space-between" px="10px">
							{brands}
						</layouts.containers.flex>
					</layouts.containers.box>
				</layouts.loading.pending>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
