import React, { useState } from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"

interface props {
	onChange(keywords: string[]): void
}

// onChange will receive an empty string array when cancelled.
export default function Keywordgen(props: props & layouts.containers.FlexProps): JSX.Element {
	const { onChange, ...rest } = props
	const [current, setCurrent] = useState("")
	const [keywords, setKeywords] = useState([] as string[])

	function addKeyword(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key !== "Enter") return
		setKeywords([...keywords, e.currentTarget.value])
		e.currentTarget.value = ""
	}

	function removeKeyword(i: number) {
		let tmp = [...keywords]
		tmp.splice(i, 1)
		setKeywords(tmp)
	}

	return (
		<layouts.containers.flex className="keywordgen" flex="1" flexDirection="column" {...rest}>
			<inputs.Text
				placeholder="add keywords"
				defaultValue={current}
				onChange={(evt) => setCurrent(evt.target.value)}
				onKeyPress={addKeyword}
			/>
			<layouts.containers.flex flexWrap="wrap" overflowY="auto" overflowX="hidden">
				{keywords.map((u, idx) => (
					<layouts.pills.Primary key={idx} mr="8px" mt="10px" remove={() => removeKeyword(idx)}>
						{u}
					</layouts.pills.Primary>
				))}
			</layouts.containers.flex>
			<layouts.containers.flex pt="10px" justifyContent="space-between">
				<layouts.buttons.outline onClick={() => onChange([])}>Cancel</layouts.buttons.outline>
				<layouts.buttons.primary onClick={() => onChange(keywords)}>Submit</layouts.buttons.primary>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
