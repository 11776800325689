import * as system from "styled-system"
import styled from "@emotion/styled"

export interface SVGProps extends system.PositionProps, system.SpaceProps {
	fill?: string
	stroke?: string
	className?: string
	display?: string | number | undefined
	height?: string | number | undefined
	width?: string | number | undefined
	verticalAlign?: string | undefined
}

export const SVG = styled.svg<SVGProps>`
	${system.display}
	${system.layout}
	${system.space}
	${system.position}
`
