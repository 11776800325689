import { useEffect, useState } from "react"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as authzc from "authzcached"
import * as profiles from "profiles"
import * as meta from "meta"

interface props {
	readonly?: boolean
	current: profiles.Profile
	onChange(upd: profiles.Profile): Promise<profiles.Profile>
}

export default function Edit(props: props & layouts.containers.FlexProps): JSX.Element {
	const { readonly: _readonly, current, onChange, ...rest } = props

	const session = sessions.useSession()
	const [loaded, setLoaded] = useState(false) // eslint-disable-line no-unused-vars,@typescript-eslint/no-unused-vars
	const [auth] = useState(authzc.useCache((cached) => cached.meta))
	const readonly = _readonly || current.id === session.profile.id || !auth.current.usermanagement

	useEffect(() => {
		auth.token().finally(() => setLoaded(true))
	}, [])

	return (
		<layouts.containers.flex flex="1" flexDirection="column" {...rest}>
			<profiles.DisableToggle mb="10px" current={current} readonly={readonly} onChange={onChange} />
			<meta.authz.Modify
				flex="1"
				readonly={readonly}
				current={current}
				onChange={async (upd: meta.authz.Token) => {
					return auth.token().then(async (token) => {
						return meta.authz
							.grant(
								{
									authorization: { usermanagement: upd.usermanagement },
									profile_id: current.id,
								},
								httpx.options.bearer(token.bearer),
							)
							.then((r) => ({
								...upd,
								...meta.authz.tokens.authorizationFromGrant(r),
							}))
					})
				}}
			/>
		</layouts.containers.flex>
	)
}
