import impression from "../impression"
import * as fingerprint from "../fingerprint"
import * as md5x from "md5x"
import * as uuidx from "x/uuidx"
import { Offer, Display } from "./offer"
import Client from "../client"
import * as codes from "../codes"
import * as discounts from "../discount"

export * as dynamic from "./dynamic"
export * as constant from "./constant"
export type { Offer, Display } from "./offer"

export function determine(uid: string, ...offers: Offer[]): Promise<Offer> {
	return Promise.resolve(md5x.rendezvous.Max(uid, (x: Offer) => x.id, ...offers))
}

export function noop(): Display {
	return {
		discounts: () => [discounts.zero(uuidx.v4(), codes.none())],
		impression: (ctx: Client, imp: impression, uid: fingerprint.ID): Promise<impression> => {
			console.warn("noop offer used; set a fallback for proper operation")
			return Promise.resolve({
				...imp,
				prediction: -1,
				probability: 0.0,
				coupon: "",
				coupon_applied: false,
			} as impression)
		},
	} as Display
}

export function unspecified(): Offer {
	return {
		type: "unspecified",
		id: "",
		name: "",
		weight: 0,
		display: noop(),
	}
}
