import * as uuid from "uuid"
import * as httpx from "httpx"
import * as metasso from "./meta.sso"
export type {
	Identity,
	Authed,
	Authn,
	AccountWithToken,
	AvailableRequest,
	AvailableResponse,
	JoinResponse,
} from "./meta.sso"

const sessionstoragekey = "nsuite.session"
const sessionlastloginkey = "nsuite.last.login"

export function localtoken(account_id: string): string {
	return window.localStorage.getItem(`${sessionstoragekey}.${account_id}`) || ""
}

export function lasttoken(): string {
	return window.localStorage.getItem(sessionlastloginkey) || ""
}

export function storelocaltoken(account_id: string, s: string): string {
	window.localStorage.setItem(`${sessionstoragekey}.${account_id}`, s)
	return s
}

export function login(s: string, account_id: string): string {
	storelasttoken(s)
	window.localStorage.setItem(`${sessionstoragekey}.${account_id}`, s)
	return s
}

export function storelasttoken(s: string): string {
	window.localStorage.setItem(sessionlastloginkey, s)
	return s
}

/**
 * @returns the current session information
 */
export function current(...options: httpx.option[]): Promise<CurrentSession> {
	return httpx.get(`${httpx.urlstorage.host()}/authn/current`, {}, ...options)
}

/**
 * @returns the current session information after signup
 */
export function signup(...options: httpx.option[]): Promise<CurrentSession> {
	return httpx.post(`${httpx.urlstorage.host()}/authn/signup`, {}, ...options)
}

export namespace accounts {
	/**
	 * @returns a list of accounts that the end user has outstanding invitations for.
	 */
	export async function available(
		req: metasso.AvailableRequest,
		...options: httpx.option[]
	): Promise<metasso.AvailableResponse> {
		return httpx
			.get<metasso.AvailableResponse>(`${httpx.urlstorage.host()}/authn/available`, req, ...options)
			.then((resp) => ({
				...resp,
				cursor: resp.cursor || { offset: uuid.NIL },
				items: resp.items || [],
			}))
	}

	/**
	 * accept an invitation to join an account.
	 * @returns
	 */
	export function accept(...options: httpx.option[]): Promise<metasso.JoinResponse> {
		return httpx.post<metasso.JoinResponse>(`${httpx.urlstorage.host()}/authn/accept`, {}, ...options)
	}
}

/**
 * authentication endpoints.
 */
export const authn = {
	gsuite: (code: string, state: string, scope: string): Promise<metasso.Authed> => {
		return httpx.post(`${httpx.urlstorage.host()}/authn/gsuite`, {
			code: code,
			state: state,
			scope: scope,
		})
	},
	facebook: (code: string, state: string, scope: string): Promise<metasso.Authed> => {
		return httpx.post(`${httpx.urlstorage.host()}/authn/facebook`, {
			code: code,
			state: state,
			scope: scope,
		})
	},
}

export interface CurrentSession extends metasso.Authn {
	redirect: string
}
