import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as brands from "brands"
import { Table } from "./table"
import * as typography from "typography"
import * as selector from "brands/selector"

export function Container(props: selector.props) {
	const { urlgen } = props
	const params = useParams() as {
		bid: string
	}
	const bearertoken = sessions.useToken()

	const [loading, setLoading] = useState(true)
	const [brand, setBrand] = useState(brands.api.zero())

	useEffect(() => {
		brands.caching
			.current(params.bid, bearertoken)
			.then((b) => setBrand(b))
			.finally(() => {
				setLoading(false)
				return
			})
	}, [])

	return (
		<layouts.backgrounds.Grey flex="0 1 100%">
			<layouts.loading.pending loading={loading}>
				<brands.caching.Provider value={brand}>
					<layouts.modals.Root>
						<layouts.containers.flex
							p="50px"
							pt="25px"
							className="brandguard"
							flex="1"
							flexDirection="column"
							overflowY="hidden"
						>
							<layouts.containers.flex display="flex" flexDirection="row" width="100%" textAlign="center">
								<layouts.containers.flex flex="2" justifyContent="left">
									<typography.h3 color={layouts.theme.colors.grey.medium}>Ad Pools</typography.h3>
								</layouts.containers.flex>
								<layouts.containers.flex flex="2" justifyContent="end">
									<selector.BrandSelector urlgen={urlgen} />
								</layouts.containers.flex>
							</layouts.containers.flex>
							<Table />
						</layouts.containers.flex>
					</layouts.modals.Root>
				</brands.caching.Provider>
			</layouts.loading.pending>
		</layouts.backgrounds.Grey>
	)
}
