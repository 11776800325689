import React, { useEffect, useState } from "react"
import * as sessions from "sessions"
// import * as brands from "brands"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import * as bg_layouts from "./layouts"
import * as mediaapi from "media/api"

interface props {
	item: api.TextSearchResponseItem
	active?: boolean
	onCardClick(): void
	onTextUpdate(item: api.TextSearchResponseItem): void
}

interface propsUpdatableItem {
	item: api.TextSearchResponseItem
	onChange(item: api.TextSearchResponseItem): void
}

function ActionPanel(props: propsUpdatableItem): JSX.Element {
	const { item } = props
	const [loading] = useState(false)
	const prediction = item.event?.approved || api.Prediction.NONE
	const observation = item.event?.observation || api.Prediction.NONE
	const status = api.predictionString(prediction)
	const agreement = prediction === observation

	return (
		<bg_layouts.ActionPanelStyle backgroundColor={bg_layouts.StatusColor(props)}>
			<bg_layouts.StatusTextStyle>
				<bg_layouts.IconStatus item={item} />
				<layouts.containers.span ml="10px">{status}</layouts.containers.span>
			</bg_layouts.StatusTextStyle>
			{!api.isProcessing(item) && (
				<layouts.loading.screen loading={loading} cursor="not-allowed" opacity="0" icon={<></>}>
					<bg_layouts.FeedbackContainerStyle>
						<layouts.containers.span mr="10px">Do you agree?</layouts.containers.span>
						<bg_layouts.FeedbackYes
							active={observation === api.Prediction.NONE ? false : agreement}
							onClick={(evt) => console.log("Feedback YES")}
						/>
						<bg_layouts.FeedbackNo
							active={observation === api.Prediction.NONE ? false : !agreement}
							onClick={(evt) => console.log("Feedback NO")}
						/>
					</bg_layouts.FeedbackContainerStyle>
				</layouts.loading.screen>
			)}
		</bg_layouts.ActionPanelStyle>
	)
}

export default function DisplayImage(props: props & React.PropsWithChildren<unknown>): JSX.Element {
	const { item, active, onTextUpdate, onCardClick } = props
	const [loading, setLoading] = useState(true)
	const [response, setResponse] = useState("" as string)
	const bearertoken = sessions.useToken()
	// const brand = brands.caching.useCached()

	// useEffect(() => {
	// 	if (api.isProcessing(item)) {
	// 		setTimeout(() => {
	// 			api.text.uploads
	// 				.find(item.media?.id!, brand.id, bearertoken)
	// 				.then((result) => {
	// 					onTextUpdate(result)
	// 				})
	// 				.catch((e) => console.log(e))
	// 		}, 5000)
	// 	}
	// }, [item])

	useEffect(() => {
		setLoading(true)
		mediaapi
			.url(item.media?.md5!, bearertoken)
			.then((result) => fetch(result.url))
			.then((resp) => resp.text())
			.then((data) => setResponse(data))
			.catch(console.error)
			.finally(() => setLoading(false))
	}, [item.media?.md5])

	return (
		<bg_layouts.Card key={item.media?.id} active={active} onClick={onCardClick}>
			<ActionPanel item={item} onChange={onTextUpdate}></ActionPanel>
			<layouts.loading.screen loading={loading}>
				<bg_layouts.CardTextContent opacity={api.isProcessing(item) ? 0.3 : 1}>{response}</bg_layouts.CardTextContent>
			</layouts.loading.screen>
		</bg_layouts.Card>
	)
}
