import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import { Link } from "react-router-dom"

// menu

export const StyledNavList = styled.ul`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
`

export function NavList(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<layouts.containers.flex>
			<StyledNavList>{props.children}</StyledNavList>
		</layouts.containers.flex>
	)
}

export const StyledNavItem = styled.li`
	position: relative;
	font-size: 24px;
	font-weight: 600;
	margin-right: 36px;

	a {
		display: block;
		font-size: inherit;
		font-weight: inherit;
		color: ${layouts.theme.colors.white};
		text-decoration: none;
	}
`

interface itemProps {
	route: string
}

export function NavItem(props: React.PropsWithChildren<itemProps>): JSX.Element {
	const { route, children } = props

	return (
		<StyledNavItem>
			<Link to={route}>{children}</Link>
		</StyledNavItem>
	)
}

//Sub Menu
