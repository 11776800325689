import React from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as context from "./adgen.responsive.search.context"
import Instructions from "./adgen.responsive.search.instructions"

interface props {
	onChange(...mut: context.mutation[]): void
}

export default function Container(props: props): JSX.Element {
	const { onChange, ...rest } = props

	return (
		<layouts.containers.flex alignItems="center" verticalAlign="middle" flex="1" m="auto" {...rest}>
			<layouts.containers.flex flex="1" flexDirection="column">
				<typography.h3>Your ad is being published</typography.h3>
				<layouts.buttons.primary
					onClick={() => {
						onChange(
							context.mutate.content(context.form.zero()),
							context.mutate.generated(<Instructions />),
							context.mutate.rejected_reset,
						)
					}}
				>
					Start another?
				</layouts.buttons.primary>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
