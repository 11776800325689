import React from "react"
import * as layouts from "layouts"
import * as typography from "typography"

export default function Display(props: React.PropsWithChildren<unknown>): JSX.Element {
	const { children } = props
	return (
		<layouts.backgrounds.Grey className="studio" flex="0 1 100%">
			<layouts.containers.flex p="50px" className="ad queue" flex="1" flexDirection="column" overflowY="auto">
				<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="20px">
					<layouts.containers.flex flex="2">
						<typography.h3 color={layouts.theme.colors.grey.medium}>Studio</typography.h3>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex flex="1">{children}</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}
