import * as md5x from "md5x"
import * as httputils from "httpx"
import * as fingerprint from "../../fingerprint"
import Client from "../../client"
import Discount from "../../discount"
import impression from "../../impression"

import { Offer, Display } from "../offer"

interface cfg {
	id: string
	displays: Display[]
}

interface option {
	(cfg: cfg): cfg
}

export function zero(name: string, ...options: option[]): cfg {
	return options.reduce((cfg: cfg, opt: option) => opt(cfg), {
		id: md5x.string(name),
		displays: [],
	})
}

// rendezvous offer is a dynamic offer strategy that deterministically
// returns the same offer for the given user.
export function rendezvous(name: string, weight: number, ...options: option[]): Offer {
	const cfg = zero(name, ...options)
	let op = {
		discounts: () => cfg.displays.reduce((dis: Discount[], d) => [...dis, ...d.discounts()], []),
		async impression(ctx: Client, imp: impression, uid: fingerprint.ID): Promise<impression> {
			const gid = md5x.string(ctx.cid + cfg.id)
			const choose = async function (uid: fingerprint.ID): Promise<impression> {
				const idx = md5x.rendezvous.Max(
					uid.visitorId,
					(x: number) => x.toString(),
					...cfg.displays.map((d, idx) => idx),
				)
				return {
					prediction: idx,
					probability: 1.0 / cfg.displays.length,
				} as impression
			}

			return choose(uid).then((chosen): Promise<impression> => {
				const display = cfg.displays[chosen.prediction] || undefined
				if (display === undefined) {
					return Promise.reject(chosen)
				}

				const mergedimp = {
					...imp,
					gid: gid,
					prediction: chosen.prediction,
					probability: chosen.probability,
				}

				return display.impression(ctx, mergedimp, uid)
			})
		},
	}

	return {
		type: "dynamic",
		id: cfg.id,
		name: name,
		weight: weight,
		display: op,
	}
}

export function offer(name: string, weight: number, ...options: option[]): Offer {
	const cfg = zero(name, ...options)
	let arms = Array.from(cfg.displays.filter((x) => x !== undefined).keys())

	let op = {
		discounts: () => cfg.displays.reduce((dis: Discount[], d) => [...dis, ...d.discounts()], []),
		async impression(ctx: Client, imp: impression, uid: fingerprint.ID): Promise<impression> {
			const gid = md5x.string(ctx.cid + cfg.id)
			const choose = async function (uid: fingerprint.ID): Promise<impression> {
				return httputils.post<impression>(
					`${ctx.endpoint}/ecomm/promos/${gid}`,
					{
						...uid,
						config: { arms: arms, number_of_actions: arms.length },
					},
					httputils.modes.cors,
				)
			}

			return choose(uid).then((chosen): Promise<impression> => {
				const display = cfg.displays[chosen.prediction] || undefined
				if (display === undefined) {
					return Promise.reject(chosen)
				}

				const mergedimp = {
					...imp,
					gid: gid,
					prediction: chosen.prediction,
					probability: chosen.probability,
				}

				return display.impression(ctx, mergedimp, uid)
			})
		},
	}

	return {
		type: "dynamic",
		id: cfg.id,
		name: name,
		weight: weight,
		display: op,
	}
}

export const options = {
	choices(...displays: Display[]): option {
		return (cfg: cfg): cfg => ({
			...cfg,
			displays: displays,
		})
	},
}
