// min asserts at least a minimum number of charaters.
export function min(n: number, invalid: JSX.Element = <>too short</>): (i: string) => JSX.Element | undefined {
	return (i: string) => (i.length >= n ? undefined : invalid)
}

// max asserts at no more than a maximum number of charaters.
export function max(n: number, invalid: JSX.Element = <>too long</>): (i: string) => JSX.Element | undefined {
	return (i: string) => (i.length <= n ? undefined : invalid)
}

// email validates the string as an email address, its intentionally extremely lax
// only checking if the string contains an @ symbol.
export function email(invalid: JSX.Element = <>invalid email</>): (i: string) => JSX.Element | undefined {
	return (i: string) => (i.includes("@") ? undefined : invalid)
}

export function url(invalid: JSX.Element = <>invalid url</>): (i: string) => JSX.Element | undefined {
	return (i: string) => {
		const valid = (i.startsWith("http://") || i.startsWith("https://")) && !i.endsWith("://")
		return valid ? undefined : invalid
	}
}

export function ads_path(invalid: JSX.Element = <>invalid path</>): (i: string) => JSX.Element | undefined {
	return (i: string) => {
		if (i === "") return undefined
		const url = (i.startsWith("http://") || i.startsWith("https://")) && !i.endsWith("://")
		const invalidCharsPattern = /[^a-zA-Z0-9-_.]/
		const withSlash = i.startsWith("/") || i.endsWith("/")
		return !url && !invalidCharsPattern.test(i) && !withSlash ? undefined : invalid
	}
}
