import * as httpx from "httpx"
import * as uuid from "uuid"
import { Sample, SampleRequest, SampleResponse, Feedback } from "ads/textgen/textgen.training.sample"

export namespace create {
	export function SampleData(d: Partial<Sample> = {}): Sample {
		return {
			id: uuid.NIL,
			account_id: uuid.NIL,
			prompt: "",
			generated: "", // ALL generated text for source, including headline, description, message, ...
			accepted: "", // final states of ad components accepted by user
			source: "", // Google Search Ad Headline, Google Search Ad Description, Facebook Ad Headline, Facebook Ad Description, ... (answers 'where we're coming from' for generated)
			brand_id: "",
			pool_id: "",
			feedback: Feedback.IGNORED,
			...d,
		}
	}
	export function request(d: Partial<SampleRequest> = {}): SampleRequest {
		return {
			samples: [],
			...d,
		}
	}

	export async function record(req: SampleRequest, ...options: httpx.option[]): Promise<SampleResponse> {
		return httpx.post(`${httpx.urlstorage.host()}/textgen`, req, ...options)
	}

	export async function RecordRejectedSamples(req: SampleRequest, ...options: httpx.option[]): Promise<SampleResponse> {
		return httpx.post(`${httpx.urlstorage.host()}/textgen`, req, ...options)
	}
}
