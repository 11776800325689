import * as uuid from "uuid"
import * as httpx from "httpx"
import * as timex from "timex"

import {
	OptimizationGoal,
	Pool,
	SearchRequest,
	SearchResponse,
	CreateRequest,
	CreateResponse,
	UpdateRequest,
	UpdateResponse,
} from "./ads.pool"
import {
	Region,
	Location,
	RegionSearchRequest,
	RegionSearchResponse,
	RegionCreateResponse,
	RegionCreateRequest,
} from "./ads.pool.geographic.region"

import {
	Demographic,
	Gender,
	DemographicSearchRequest,
	DemographicSearchResponse,
	DemographicCreateRequest,
	DemographicCreateResponse,
} from "./ads.pool.demographics"

export { OptimizationGoal } from "./ads.pool"

export type {
	Pool,
	SearchRequest,
	SearchResponse,
	CreateRequest,
	CreateResponse,
	UpdateRequest,
	UpdateResponse,
} from "./ads.pool"
export type {
	Region,
	RegionSearchRequest,
	RegionSearchResponse,
	RegionCreateRequest,
	RegionCreateResponse,
} from "./ads.pool.geographic.region"
export type {
	Demographic,
	Gender,
	DemographicSearchRequest,
	DemographicSearchResponse,
	DemographicCreateRequest,
	DemographicCreateResponse,
} from "./ads.pool.demographics"

export namespace budgets {
	export function human(n: bigint): string {
		return (BigInt(n) / 100n || 0n).toString(10)
	}
}

export namespace pools {
	export function zero(partial: Partial<Pool> = {}): Pool {
		const ts = timex.local().toISO()
		return {
			id: uuid.NIL,
			account_id: uuid.NIL,
			brand_id: uuid.NIL,
			description: "",
			budget: 0n,
			daily_events_target: 0n,
			optimization_goal: OptimizationGoal.Conversions,
			impressions: 0n,
			conversions: 0n,
			created_at: ts,
			updated_at: ts,
			disabled_at: timex.infinity().toISO(),
			google_ads_client_account_id: uuid.NIL,
			facebook_client_account_id: uuid.NIL,
			...partial,
		}
	}

	export function search(req: SearchRequest, ...options: httpx.option[]): Promise<SearchResponse> {
		return httpx
			.get<SearchResponse>(`${httpx.urlstorage.host()}/ad/pool/`, req, ...options)
			.then((resp): PromiseLike<SearchResponse> => {
				resp.items = (resp.items || []).map((i) => pools.zero(i))
				return Promise.resolve(resp)
			})
	}

	export function find(id: string, ...options: httpx.option[]): Promise<Pool> {
		return httpx.get<Pool>(`${httpx.urlstorage.host()}/ad/pool/${id}/`, {}, ...options).then((p) => pools.zero(p))
	}

	export function create(req: CreateRequest, ...options: httpx.option[]): Promise<CreateResponse> {
		return httpx.post(`${httpx.urlstorage.host()}/ad/pool/`, req, ...options)
	}

	export function update(id: string, req: UpdateRequest, ...options: httpx.option[]): Promise<UpdateResponse> {
		return httpx.patch(`${httpx.urlstorage.host()}/ad/pool/${id}`, req, ...options)
	}
}

export namespace demographics {
	export function zero(partial: Partial<Demographic> = {}): Demographic {
		const ts = timex.local().toISO()
		return {
			id: uuid.NIL,
			account_id: uuid.NIL,
			pool_id: uuid.NIL,
			description: "",
			created_at: ts,
			updated_at: ts,
			gender: Gender.UNSPECIFIED,
			age_min: 0,
			age_max: 200,
			income_min: 0,
			income_max: 2147483647,
			...partial,
		}
	}

	export async function search(
		pid: string,
		req: DemographicSearchRequest,
		...options: httpx.option[]
	): Promise<DemographicSearchResponse> {
		return httpx
			.get<DemographicSearchResponse>(`${httpx.urlstorage.host()}/ad/pool/${pid}/demographic/`, req, ...options)
			.then((resp): PromiseLike<DemographicSearchResponse> => {
				resp.items = (resp.items || []).map((i) => demographics.zero(i))
				return Promise.resolve(resp)
			})
	}

	export async function create(
		pid: string,
		req: DemographicCreateRequest,
		...options: httpx.option[]
	): Promise<DemographicCreateResponse> {
		return httpx
			.post<DemographicCreateResponse>(`${httpx.urlstorage.host()}/ad/pool/${pid}/demographic/`, req, ...options)
			.then((resp) => {
				resp.demographic = demographics.zero(resp.demographic || {})
				return Promise.resolve(resp)
			})
	}

	export namespace requests {
		export function search(partial: Partial<DemographicSearchRequest> = {}): DemographicSearchRequest {
			return {
				offset: uuid.NIL,
				query: "",
				...partial,
			}
		}

		export function create(partial: Partial<DemographicCreateRequest> = {}): DemographicCreateRequest {
			return {
				demographic: demographics.zero(partial?.demographic || {}),
			}
		}
	}

	export namespace responses {
		export function search(partial: Partial<DemographicSearchResponse> = {}): DemographicSearchResponse {
			return {
				cursor: undefined,
				items: [],
				...partial,
			}
		}

		export function create(partial: Partial<DemographicCreateRequest> = {}): DemographicCreateResponse {
			return {
				demographic: demographics.zero(partial?.demographic || {}),
			}
		}
	}
}

export namespace regions {
	export function zero(partial: Partial<Region> = {}): Region {
		const ts = timex.local().toISO()
		return {
			id: uuid.NIL,
			account_id: uuid.NIL,
			pool_id: uuid.NIL,
			description: "",
			created_at: ts,
			updated_at: ts,
			location: Location.GLOBAL,
			...partial,
		}
	}

	export async function search(
		pid: string,
		req: RegionSearchRequest,
		...options: httpx.option[]
	): Promise<RegionSearchResponse> {
		return httpx
			.get<RegionSearchResponse>(`${httpx.urlstorage.host()}/ad/pool/${pid}/region/`, req, ...options)
			.then((resp): PromiseLike<RegionSearchResponse> => {
				resp.items = (resp.items || []).map((i) => regions.zero(i))
				return Promise.resolve(resp)
			})
	}

	export async function create(
		pid: string,
		req: RegionCreateRequest,
		...options: httpx.option[]
	): Promise<RegionCreateResponse> {
		return httpx
			.post<RegionCreateResponse>(`${httpx.urlstorage.host()}/ad/pool/${pid}/region/`, req, ...options)
			.then((resp) => {
				resp.region = regions.zero(resp.region || {})
				return Promise.resolve(resp)
			})
	}

	export namespace requests {
		export function search(partial: Partial<RegionSearchRequest> = {}): RegionSearchRequest {
			return {
				offset: uuid.NIL,
				query: "",
				...partial,
			}
		}

		export function create(partial: Partial<RegionCreateRequest> = {}): RegionCreateRequest {
			return {
				region: regions.zero(partial?.region || {}),
			}
		}
	}

	export namespace responses {
		export function search(partial: Partial<RegionSearchResponse> = {}): RegionSearchResponse {
			return {
				cursor: undefined,
				items: [],
				...partial,
			}
		}

		export function create(partial: Partial<RegionCreateResponse> = {}): RegionCreateResponse {
			return {
				region: regions.zero(partial?.region || {}),
			}
		}
	}
}
