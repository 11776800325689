import React from "react"
import * as layouts from "layouts"
import * as api from "./api"
import * as inputs from "inputs"

interface props {
	current: api.CropRequest
	onChange(upd: api.CropRequest): void
}

// properties for cropping images
export default function Edit(props: React.PropsWithChildren<props & layouts.containers.FlexProps>): JSX.Element {
	const { current, onChange, ...containerprops } = props

	return (
		<layouts.containers.flex flexDirection="column" {...containerprops}>
			<layouts.forms.Group>
				<layouts.forms.Label>Image URL</layouts.forms.Label>
				<inputs.Text
					defaultValue={current.image_url}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...current, image_url: evt.currentTarget.value })
					}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label>Background URL</layouts.forms.Label>
				<inputs.Text
					defaultValue={current.background_url}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...current, background_url: evt.currentTarget.value })
					}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label>Product ID</layouts.forms.Label>
				<inputs.Text
					defaultValue={current.product_id.toString()}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...current, product_id: parseInt(evt.currentTarget.value, 10) })
					}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label>Product Variant ID</layouts.forms.Label>
				<inputs.Text
					defaultValue={current.product_variant_id.toString()}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...current, product_variant_id: parseInt(evt.currentTarget.value, 10) })
					}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label>Order ID</layouts.forms.Label>
				<inputs.Text
					defaultValue={current.order_id.toString()}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...current, order_id: parseInt(evt.currentTarget.value, 10) })
					}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label>Line Item ID</layouts.forms.Label>
				<inputs.Text
					defaultValue={current.line_item_id.toString()}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...current, line_item_id: parseInt(evt.currentTarget.value, 10) })
					}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label>Effect</layouts.forms.Label>
				<inputs.Text
					defaultValue={current.effect.toString()}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...current, effect: parseInt(evt.currentTarget.value, 10) })
					}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label>Edges Smooth</layouts.forms.Label>
				<inputs.Text
					defaultValue={current.smooth_edges_threshold.toString()}
					onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
						onChange({ ...current, smooth_edges_threshold: parseInt(evt.currentTarget.value, 10) || 0 })
					}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label>Crop Type</layouts.forms.Label>
				<inputs.Select
					value={current.crop_type}
					onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
						onChange({ ...current, crop_type: evt.currentTarget.value })
					}
				>
					<option value="head">Head</option>
					<option value="bust">Bust</option>
					<option value="square-bust">Square Bust</option>
				</inputs.Select>
			</layouts.forms.Group>
		</layouts.containers.flex>
	)
}
