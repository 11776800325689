import React from "react"
import { Route, Routes } from "react-router-dom"
import List from "./list"
import ViHome from "./home"
//import * as products from "products"
import UploadBackground from "./uploadBackground"
import UploadProd from "./uploadProd"
import Processes from "./processes"
import DragNscale from "./dragNscale"
import Products from "products/list"
import * as layouts from "layouts"
import { Standard as Navigation } from "navigation"

export default function Routing(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<layouts.containers.flex height="100vh" overflowY="hidden">
			<layouts.containers.flex className="navigation-container" flex="0">
				<Navigation></Navigation>
			</layouts.containers.flex>
			<layouts.containers.flex className="body-container" flex="1 1">
				<Routes>
					<Route path="/" element={<ViHome />}>
						<Route index element={<List />} />
						<Route path="/upload" element={<UploadBackground />} />
						<Route path="/upload/uploadProd" element={<UploadProd />} />
						<Route path="/upload/uploadProd/processes" element={<Processes />} />
						<Route path="/upload/uploadProd/processes/products" element={<Products />} />
						<Route path="/upload/uploadProd/processes/products/DragNscale" element={<DragNscale />} />
					</Route>
				</Routes>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
