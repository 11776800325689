import { Route, Routes } from "react-router-dom"
import { default as Display } from "./display"
import { useEffect, useState } from "react"
import * as layouts from "layouts"
import { Brand } from "brands/account.brand"
import * as sessions from "sessions"
import { useParams } from "react-router-dom"
import * as typography from "typography"
import * as brands from "brands"
import * as routing from "./routing"
import * as selector from "brands/selector"

export interface cached {
	brands: Brand[]
}

export function zero(c: Partial<cached> = {}): cached {
	return {
		brands: [],
		...c,
	}
}

export function CachedRoot(): JSX.Element {
	const bearertoken = sessions.useToken()

	const params = useParams() as {
		bid: string
	}

	const [loading, setLoading] = useState(true)
	const [brand, setBrand] = useState(brands.api.zero())

	useEffect(() => {
		brands.caching
			.current(params.bid, bearertoken)
			.then((b) => setBrand(b))
			.finally(() => {
				setLoading(false)
				return
			})
	}, [params.bid])

	return (
		<layouts.loading.pending loading={loading}>
			<brands.caching.Provider value={brand}>
				<layouts.containers.flex
					className="styleguide-display"
					p="50px"
					pt="25px"
					width="100%"
					flex="1"
					flexDirection="column"
					background={layouts.theme.colors.grey.bg}
				>
					<layouts.containers.flex display="flex" flexDirection="row" width="100%" textAlign="center" mb="50px">
						<layouts.containers.flex flex="2" justifyContent="left">
							<typography.h3 color={layouts.theme.colors.grey.medium}>StyleGuide</typography.h3>
						</layouts.containers.flex>
						<layouts.containers.flex flex="2" justifyContent="end">
							<selector.BrandSelector urlgen={routing.root} />
						</layouts.containers.flex>
					</layouts.containers.flex>
					<Display />
				</layouts.containers.flex>
			</brands.caching.Provider>
		</layouts.loading.pending>
	)
}

export default function Routing(): JSX.Element {
	return (
		<Routes>
			<Route path="*" element={<CachedRoot />}></Route>
		</Routes>
	)
}
