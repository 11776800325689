import React from "react"
import { Routes, Route } from "react-router-dom"
import * as login from "./login"

export default function Routing(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<Routes>
			<Route path="/login" element={<login.Container />} />
			<Route path="/logout" element={<login.Logout />} />
			<Route path="*" element={props.children} />
		</Routes>
	)
}
