/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "ads.pool.geographic.region.proto" (package "ads.pool", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message ads.pool.Region
 */
export interface Region {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string pool_id = 3 [json_name = "pool_id"];
     */
    pool_id: string;
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 6 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: ads.pool.Location location = 7;
     */
    location: Location;
}
/**
 * @generated from protobuf message ads.pool.RegionSearchRequest
 */
export interface RegionSearchRequest {
    /**
     * @generated from protobuf field: string offset = 1;
     */
    offset: string;
    /**
     * @generated from protobuf field: string query = 2;
     */
    query: string;
}
/**
 * @generated from protobuf message ads.pool.RegionSearchResponse
 */
export interface RegionSearchResponse {
    /**
     * @generated from protobuf field: ads.pool.RegionSearchRequest cursor = 1 [json_name = "next"];
     */
    cursor?: RegionSearchRequest;
    /**
     * @generated from protobuf field: repeated ads.pool.Region items = 2;
     */
    items: Region[];
}
/**
 * @generated from protobuf message ads.pool.RegionCreateRequest
 */
export interface RegionCreateRequest {
    /**
     * @generated from protobuf field: ads.pool.Region region = 1;
     */
    region?: Region;
}
/**
 * @generated from protobuf message ads.pool.RegionCreateResponse
 */
export interface RegionCreateResponse {
    /**
     * @generated from protobuf field: ads.pool.Region region = 1;
     */
    region?: Region;
}
/**
 * location to target
 * we reserve them in blocks of 100k for future extension.
 *
 * @generated from protobuf enum ads.pool.Location
 */
export enum Location {
    /**
     * @generated from protobuf enum value: GLOBAL = 0;
     */
    GLOBAL = 0,
    /**
     * @generated from protobuf enum value: UNITED_STATES = 1;
     */
    UNITED_STATES = 1,
    /**
     * @generated from protobuf enum value: CANADA = 100000;
     */
    CANADA = 100000,
    /**
     * @generated from protobuf enum value: MEXICO = 200000;
     */
    MEXICO = 200000
}
// @generated message type with reflection information, may provide speed optimized methods
class Region$Type extends MessageType<Region> {
    constructor() {
        super("ads.pool.Region", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "pool_id", kind: "scalar", localName: "pool_id", jsonName: "pool_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "location", kind: "enum", T: () => ["ads.pool.Location", Location] }
        ]);
    }
    create(value?: PartialMessage<Region>): Region {
        const message = { id: "", account_id: "", pool_id: "", description: "", created_at: "", updated_at: "", location: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Region>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Region): Region {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string pool_id = 3 [json_name = "pool_id"];*/ 3:
                    message.pool_id = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 6 [json_name = "updated_at"];*/ 6:
                    message.updated_at = reader.string();
                    break;
                case /* ads.pool.Location location */ 7:
                    message.location = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Region, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string pool_id = 3 [json_name = "pool_id"]; */
        if (message.pool_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pool_id);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 6 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.updated_at);
        /* ads.pool.Location location = 7; */
        if (message.location !== 0)
            writer.tag(7, WireType.Varint).int32(message.location);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.Region
 */
export const Region = new Region$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegionSearchRequest$Type extends MessageType<RegionSearchRequest> {
    constructor() {
        super("ads.pool.RegionSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RegionSearchRequest>): RegionSearchRequest {
        const message = { offset: "", query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegionSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegionSearchRequest): RegionSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string offset */ 1:
                    message.offset = reader.string();
                    break;
                case /* string query */ 2:
                    message.query = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegionSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string offset = 1; */
        if (message.offset !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.offset);
        /* string query = 2; */
        if (message.query !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.query);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.RegionSearchRequest
 */
export const RegionSearchRequest = new RegionSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegionSearchResponse$Type extends MessageType<RegionSearchResponse> {
    constructor() {
        super("ads.pool.RegionSearchResponse", [
            { no: 1, name: "cursor", kind: "message", jsonName: "next", T: () => RegionSearchRequest },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Region }
        ]);
    }
    create(value?: PartialMessage<RegionSearchResponse>): RegionSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegionSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegionSearchResponse): RegionSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ads.pool.RegionSearchRequest cursor = 1 [json_name = "next"];*/ 1:
                    message.cursor = RegionSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.cursor);
                    break;
                case /* repeated ads.pool.Region items */ 2:
                    message.items.push(Region.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegionSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ads.pool.RegionSearchRequest cursor = 1 [json_name = "next"]; */
        if (message.cursor)
            RegionSearchRequest.internalBinaryWrite(message.cursor, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated ads.pool.Region items = 2; */
        for (let i = 0; i < message.items.length; i++)
            Region.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.RegionSearchResponse
 */
export const RegionSearchResponse = new RegionSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegionCreateRequest$Type extends MessageType<RegionCreateRequest> {
    constructor() {
        super("ads.pool.RegionCreateRequest", [
            { no: 1, name: "region", kind: "message", T: () => Region }
        ]);
    }
    create(value?: PartialMessage<RegionCreateRequest>): RegionCreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegionCreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegionCreateRequest): RegionCreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ads.pool.Region region */ 1:
                    message.region = Region.internalBinaryRead(reader, reader.uint32(), options, message.region);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegionCreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ads.pool.Region region = 1; */
        if (message.region)
            Region.internalBinaryWrite(message.region, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.RegionCreateRequest
 */
export const RegionCreateRequest = new RegionCreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegionCreateResponse$Type extends MessageType<RegionCreateResponse> {
    constructor() {
        super("ads.pool.RegionCreateResponse", [
            { no: 1, name: "region", kind: "message", T: () => Region }
        ]);
    }
    create(value?: PartialMessage<RegionCreateResponse>): RegionCreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegionCreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegionCreateResponse): RegionCreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ads.pool.Region region */ 1:
                    message.region = Region.internalBinaryRead(reader, reader.uint32(), options, message.region);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegionCreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ads.pool.Region region = 1; */
        if (message.region)
            Region.internalBinaryWrite(message.region, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.RegionCreateResponse
 */
export const RegionCreateResponse = new RegionCreateResponse$Type();
