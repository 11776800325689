import React, { useEffect } from "react"

export function ClickOutside(ref: React.RefObject<HTMLElement>, handler: (event: MouseEvent | TouchEvent) => void) {
	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent) => {
			if (!event.target || !("nodeType" in event.target)) {
				throw new Error("Node expected")
			}

			if (!ref.current || ref.current.contains(event.target)) {
				return
			}

			handler(event)
		}

		document.addEventListener("mousedown", listener)
		document.addEventListener("touchstart", listener)

		return () => {
			document.removeEventListener("mousedown", listener)
			document.removeEventListener("touchstart", listener)
		}
	}, [ref, handler])
}
