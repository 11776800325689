import React, { useState } from "react"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import * as sessions from "sessions"
import * as sso from "sso"
import * as brands from "brands"
import * as vi from "vi"
import * as layouts from "layouts"
import * as httpx from "httpx"
import * as u12t from "meta/usermanagement"
import * as demos from "demos"
import * as competitorpromotions from "competitorpromotions"
import * as authz from "authz"
import * as debugx from "x/debugx"
import * as brandcached from "brands/brandcached"
import * as caching from "caching"
import * as authzcached from "authzcached"

function App(): JSX.Element {
	const [_session, setSession] = useState(sessions.zero(sessions.options.latestlogincreds))
	const session = sessions.zero(sessions.options.clone(_session), sessions.options.replacer(setSession))

	// detect alpha flag from location
	debugx.alpha.autodetecturl(window.location)

	httpx // global request handlers.
		.intercept(
			// httputils.debugrequest,
			httpx.unauthenticatedrequest((cause: unknown) => {
				console.log("unauthenticated response", cause)
				setSession(sessions.zero())
				return cause as Response
			}),
		)

	if (process.env.REACT_APP_SENTRY_DSN) {
		Sentry.init({
			environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
			release: process.env.REACT_APP_SENTRY_RELEASE,
			dsn: process.env.REACT_APP_SENTRY_DSN,
			tracesSampleRate: 1.0,
			replaysOnErrorSampleRate: 1.0,
			integrations: [new BrowserTracing()],
		})
	}

	return (
		<caching.Autoflush>
			<sessions.Provider value={session}>
				<Router>
					<sso.Routes>
						<sessions.Authenticated>
							<authzcached.Storage>
								<sessions.Appcues>
									<React.StrictMode>
										<layouts.modals.Root className="global-modal" width="100vw" height="100vh">
											<brandcached.Brand>
												<Routes>
													<Route path="/me/debug" element={<debugx.Debug />} />
													<Route path="/demos/*" element={<demos.Routes />} />
													<Route path="/brands/*" element={<brands.Routes />} />
													<Route path="/vi/*" element={<vi.Routes />} />
													<Route path="/u12t/*" element={<u12t.Routes />} />
													<Route path="/compeditorpromotions/*" element={<competitorpromotions.Routes />} />
													<Route path="/authz/*" element={<authz.Routes />} />
													<Route path="/*" element={<brands.Routes />} />
													<Route path="*" element={<Navigate to="/" replace />} />
												</Routes>
											</brandcached.Brand>
										</layouts.modals.Root>
									</React.StrictMode>
								</sessions.Appcues>
							</authzcached.Storage>
						</sessions.Authenticated>
					</sso.Routes>
				</Router>
			</sessions.Provider>
		</caching.Autoflush>
	)
}

export default App
