import React, { useState } from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as icons from "icons"
import * as sessions from "sessions"
import * as api from "./api"

interface props {
	mediaPath: string
	setUploadResult(result: api.Media[]): void
}

const StyledUploadBtn = styled(layouts.buttons.outline)`
	background: ${layouts.theme.colors.white};
	border: 1px solid ${layouts.theme.colors.grey.light20};
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
	height: 48px;
	border-radius: 48px;
	padding: 0 23px;
	&:hover {
		border: 1px solid ${layouts.theme.colors.blue};
		background: ${layouts.theme.colors.white};
		color: ${layouts.theme.colors.blue};
		box-shadow: 2px 2px 4px ${layouts.theme.colors.blue}7E;
	}
`

export function UploadBtn(props: React.PropsWithChildren<props>): JSX.Element {
	const { mediaPath, setUploadResult } = props
	const [loading, setLoading] = useState(false)
	const bearertoken = sessions.useToken()

	return (
		<layouts.containers.flex className="media-lib-input-container" justifyContent="center" mb="30px">
			<inputs.File
				className="media-lib-input-file"
				multiple={true}
				onChange={(e) => {
					if (!e.currentTarget.files?.length) return

					setLoading(true)
					const promises = Array.from(e.currentTarget.files).map((file) =>
						api.uploadMedia(file, mediaPath, bearertoken),
					)
					Promise.allSettled(promises)
						.then((results) => {
							const media = [] as api.Media[]
							results.forEach((result) => {
								if (result.status === "fulfilled") {
									media.push(result.value.media!)
								} else {
									// TODO refine error behavior
									console.log(result.reason)
								}
							})
							setUploadResult(media)
						})
						.finally(() => setLoading(false))
				}}
			>
				<StyledUploadBtn disabled={loading}>
					<icons.Upload fill={layouts.theme.colors.blue} width="20" height="15" mr="8px" mb="-2px" />
					Upload Image
				</StyledUploadBtn>
			</inputs.File>
		</layouts.containers.flex>
	)
}
