import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as errors from "errors"
import * as sessions from "sessions"
import { Container, BackLink } from "ads/adgen/adgen.content.layouts"
import BrandDetails from "ads/adgen/adgen.brand.details"
import * as textgen from "ads/textgen/api"
import * as pools from "ads/pools"
import * as brands from "brands"
import * as api from "./api"
import * as context from "./adgen.link.context"
import * as generated from "./adgen.link.generated"
import Form from "./adgen.link.form"
import Instructions from "./adgen.link.generated.instructions"

export function Display(): JSX.Element {
	const linkzero = api.ads.link.zero()
	const pool = pools.caching.useCached()
	const brand = brands.caching.useCached()
	const bearertoken = sessions.useToken()
	const [loading, setLoading] = useState(false)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [mutations, setMutations] = useState([] as context.mutation[])
	const [genctx, setGenctx] = useState(
		context.zero({
			content: context.form.zero({
				call_to_action: {
					type: "",
					value: { link: brand.domain },
				},
			}),
			generated: <Instructions />,
		}),
	)

	const asyncupd = (...m: context.mutation[]) => {
		setMutations([...mutations, ...m])
	}

	useEffect(() => {
		if (mutations.length === 0) return
		const updated = context.mutate.apply(genctx, ...mutations)
		setGenctx(updated)
		setMutations([])
	}, [mutations])

	const saveDisabled = (): boolean =>
		genctx.content.name.accepted === linkzero.name ||
		genctx.content.description.accepted === linkzero.description ||
		genctx.content.message.accepted === linkzero.message ||
		genctx.content.call_to_action === linkzero.call_to_action

	return (
		<layouts.backgrounds.Grey className="facebook" flex="0 1 100%">
			<context.Provider value={genctx}>
				<layouts.containers.flex
					p="50px"
					className="ad-generation"
					width="100%"
					height="100%"
					overflow="hidden"
					flexDirection="column"
					overflowY="auto"
				>
					{cause}
					<layouts.containers.flex width="100%">
						<layouts.containers.flex>
							<typography.h3 color={layouts.theme.colors.grey.medium}>Facebook Ad Generation</typography.h3>
						</layouts.containers.flex>
					</layouts.containers.flex>
					<Container flexDirection="row" flex="1">
						<layouts.containers.flex flex="2" flexDirection="column" pl="1px">
							<BrandDetails />
							<generated.default onChange={asyncupd}></generated.default>
							<BackLink />
						</layouts.containers.flex>
						<layouts.containers.flex
							flexDirection="column"
							flex="5"
							mx="10px"
							verticalAlign="top"
							background={layouts.theme.colors.white}
							overflowY="auto"
						>
							{genctx.generated}
						</layouts.containers.flex>
						<layouts.containers.flex
							overflowY="auto"
							flex="2"
							p="5px"
							borderRadius="10px"
							background={layouts.theme.colors.white}
						>
							<layouts.containers.flex flexDirection="column" width="100%" height="fit-content" flex="1 1 auto">
								<Form genctx={genctx} onChange={asyncupd} />
								<layouts.containers.flex alignItems="center" pb="50px" pt="25px" flexDirection="column">
									<layouts.buttons.primary
										width="150px"
										height="50px"
										borderRadius="37px"
										disabled={saveDisabled() || loading}
										onClick={() => {
											setLoading(true)
											const req = api.ads.link.create.request({
												metadata: api.ads.link.metadata({
													description: genctx.content.name.accepted,
													facebook_client_id: pool.facebook_client_account_id,
													pool_id: pool.id,
												}),
												content: api.ads.link.zero({
													description: genctx.content.description.accepted,
													message: genctx.content.message.accepted,
													link: brand.domain,
													call_to_action: genctx.content.call_to_action,
													media_id: genctx.content.media.id,
													name: genctx.content.name.accepted,
												}),
											})

											api.ads.link.create
												.post(req, bearertoken)
												.then(() => {
													setCause(undefined)
													asyncupd(context.mutate.content(context.form.zero()), context.mutate.rejected_reset)
												})
												.catch((cause) =>
													setCause(
														<errors.Inline>
															<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
																unable to publish facebook ads
															</errors.Textual>
														</errors.Inline>,
													),
												)
												.finally(() => setLoading(false))

											textgen.create.record(
												{
													samples: [
														genctx.content.name,
														genctx.content.description,
														genctx.content.message,
														...genctx.rejected,
													],
												},
												bearertoken,
											)
										}}
									>
										Publish
									</layouts.buttons.primary>
								</layouts.containers.flex>
							</layouts.containers.flex>
						</layouts.containers.flex>
					</Container>
				</layouts.containers.flex>
			</context.Provider>
		</layouts.backgrounds.Grey>
	)
}
