/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "ads.pool.proto" (package "ads.pool", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message ads.pool.Pool
 */
export interface Pool {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string created_at = 4 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 5 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: string disabled_at = 6 [json_name = "disabled_at"];
     */
    disabled_at: string;
    /**
     * @generated from protobuf field: int64 impressions = 7;
     */
    impressions: bigint;
    /**
     * @generated from protobuf field: int64 conversions = 8;
     */
    conversions: bigint;
    /**
     * @generated from protobuf field: int64 budget = 10;
     */
    budget: bigint;
    /**
     * @generated from protobuf field: string google_ads_client_account_id = 11 [json_name = "google_ads_client_account_id"];
     */
    google_ads_client_account_id: string;
    /**
     * @generated from protobuf field: string facebook_client_account_id = 12 [json_name = "facebook_client_account_id"];
     */
    facebook_client_account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 13 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: int64 daily_events_target = 14 [json_name = "daily_events_target"];
     */
    daily_events_target: bigint;
    /**
     * @generated from protobuf field: ads.pool.OptimizationGoal optimization_goal = 15 [json_name = "optimization_goal"];
     */
    optimization_goal: OptimizationGoal;
}
/**
 * @generated from protobuf message ads.pool.SearchRequest
 */
export interface SearchRequest {
    /**
     * @generated from protobuf field: string offset = 1;
     */
    offset: string;
    /**
     * @generated from protobuf field: string query = 2;
     */
    query: string;
    /**
     * @generated from protobuf field: string pool_id = 3 [json_name = "pool_id"];
     */
    pool_id: string;
    /**
     * @generated from protobuf field: string brand_id = 4 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: int32 limit = 5;
     */
    limit: number;
}
/**
 * @generated from protobuf message ads.pool.SearchResponse
 */
export interface SearchResponse {
    /**
     * @generated from protobuf field: ads.pool.SearchRequest cursor = 1 [json_name = "next"];
     */
    cursor?: SearchRequest;
    /**
     * @generated from protobuf field: repeated ads.pool.Pool items = 2;
     */
    items: Pool[];
}
/**
 * @generated from protobuf message ads.pool.CreateRequest
 */
export interface CreateRequest {
    /**
     * @generated from protobuf field: ads.pool.Pool pool = 1;
     */
    pool?: Pool;
}
/**
 * @generated from protobuf message ads.pool.CreateResponse
 */
export interface CreateResponse {
    /**
     * @generated from protobuf field: ads.pool.Pool pool = 1;
     */
    pool?: Pool;
}
/**
 * @generated from protobuf message ads.pool.UpdateRequest
 */
export interface UpdateRequest {
    /**
     * @generated from protobuf field: ads.pool.Pool pool = 1;
     */
    pool?: Pool;
}
/**
 * @generated from protobuf message ads.pool.UpdateResponse
 */
export interface UpdateResponse {
    /**
     * @generated from protobuf field: ads.pool.Pool pool = 1;
     */
    pool?: Pool;
}
/**
 * @generated from protobuf enum ads.pool.OptimizationGoal
 */
export enum OptimizationGoal {
    /**
     * @generated from protobuf enum value: Unspecified = 0;
     */
    Unspecified = 0,
    /**
     * @generated from protobuf enum value: Conversions = 1;
     */
    Conversions = 1,
    /**
     * @generated from protobuf enum value: Impressions = 2;
     */
    Impressions = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Pool$Type extends MessageType<Pool> {
    constructor() {
        super("ads.pool.Pool", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "disabled_at", kind: "scalar", localName: "disabled_at", jsonName: "disabled_at", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "impressions", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "conversions", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 10, name: "budget", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 11, name: "google_ads_client_account_id", kind: "scalar", localName: "google_ads_client_account_id", jsonName: "google_ads_client_account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "facebook_client_account_id", kind: "scalar", localName: "facebook_client_account_id", jsonName: "facebook_client_account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "daily_events_target", kind: "scalar", localName: "daily_events_target", jsonName: "daily_events_target", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 15, name: "optimization_goal", kind: "enum", localName: "optimization_goal", jsonName: "optimization_goal", T: () => ["ads.pool.OptimizationGoal", OptimizationGoal] }
        ]);
    }
    create(value?: PartialMessage<Pool>): Pool {
        const message = { id: "", account_id: "", description: "", created_at: "", updated_at: "", disabled_at: "", impressions: 0n, conversions: 0n, budget: 0n, google_ads_client_account_id: "", facebook_client_account_id: "", brand_id: "", daily_events_target: 0n, optimization_goal: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Pool>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Pool): Pool {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string created_at = 4 [json_name = "created_at"];*/ 4:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 5 [json_name = "updated_at"];*/ 5:
                    message.updated_at = reader.string();
                    break;
                case /* string disabled_at = 6 [json_name = "disabled_at"];*/ 6:
                    message.disabled_at = reader.string();
                    break;
                case /* int64 impressions */ 7:
                    message.impressions = reader.int64().toBigInt();
                    break;
                case /* int64 conversions */ 8:
                    message.conversions = reader.int64().toBigInt();
                    break;
                case /* int64 budget */ 10:
                    message.budget = reader.int64().toBigInt();
                    break;
                case /* string google_ads_client_account_id = 11 [json_name = "google_ads_client_account_id"];*/ 11:
                    message.google_ads_client_account_id = reader.string();
                    break;
                case /* string facebook_client_account_id = 12 [json_name = "facebook_client_account_id"];*/ 12:
                    message.facebook_client_account_id = reader.string();
                    break;
                case /* string brand_id = 13 [json_name = "brand_id"];*/ 13:
                    message.brand_id = reader.string();
                    break;
                case /* int64 daily_events_target = 14 [json_name = "daily_events_target"];*/ 14:
                    message.daily_events_target = reader.int64().toBigInt();
                    break;
                case /* ads.pool.OptimizationGoal optimization_goal = 15 [json_name = "optimization_goal"];*/ 15:
                    message.optimization_goal = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Pool, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string created_at = 4 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 5 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.updated_at);
        /* string disabled_at = 6 [json_name = "disabled_at"]; */
        if (message.disabled_at !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.disabled_at);
        /* int64 impressions = 7; */
        if (message.impressions !== 0n)
            writer.tag(7, WireType.Varint).int64(message.impressions);
        /* int64 conversions = 8; */
        if (message.conversions !== 0n)
            writer.tag(8, WireType.Varint).int64(message.conversions);
        /* int64 budget = 10; */
        if (message.budget !== 0n)
            writer.tag(10, WireType.Varint).int64(message.budget);
        /* string google_ads_client_account_id = 11 [json_name = "google_ads_client_account_id"]; */
        if (message.google_ads_client_account_id !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.google_ads_client_account_id);
        /* string facebook_client_account_id = 12 [json_name = "facebook_client_account_id"]; */
        if (message.facebook_client_account_id !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.facebook_client_account_id);
        /* string brand_id = 13 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.brand_id);
        /* int64 daily_events_target = 14 [json_name = "daily_events_target"]; */
        if (message.daily_events_target !== 0n)
            writer.tag(14, WireType.Varint).int64(message.daily_events_target);
        /* ads.pool.OptimizationGoal optimization_goal = 15 [json_name = "optimization_goal"]; */
        if (message.optimization_goal !== 0)
            writer.tag(15, WireType.Varint).int32(message.optimization_goal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.Pool
 */
export const Pool = new Pool$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRequest$Type extends MessageType<SearchRequest> {
    constructor() {
        super("ads.pool.SearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "pool_id", kind: "scalar", localName: "pool_id", jsonName: "pool_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchRequest>): SearchRequest {
        const message = { offset: "", query: "", pool_id: "", brand_id: "", limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRequest): SearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string offset */ 1:
                    message.offset = reader.string();
                    break;
                case /* string query */ 2:
                    message.query = reader.string();
                    break;
                case /* string pool_id = 3 [json_name = "pool_id"];*/ 3:
                    message.pool_id = reader.string();
                    break;
                case /* string brand_id = 4 [json_name = "brand_id"];*/ 4:
                    message.brand_id = reader.string();
                    break;
                case /* int32 limit */ 5:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string offset = 1; */
        if (message.offset !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.offset);
        /* string query = 2; */
        if (message.query !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.query);
        /* string pool_id = 3 [json_name = "pool_id"]; */
        if (message.pool_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pool_id);
        /* string brand_id = 4 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.brand_id);
        /* int32 limit = 5; */
        if (message.limit !== 0)
            writer.tag(5, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.SearchRequest
 */
export const SearchRequest = new SearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchResponse$Type extends MessageType<SearchResponse> {
    constructor() {
        super("ads.pool.SearchResponse", [
            { no: 1, name: "cursor", kind: "message", jsonName: "next", T: () => SearchRequest },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Pool }
        ]);
    }
    create(value?: PartialMessage<SearchResponse>): SearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchResponse): SearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ads.pool.SearchRequest cursor = 1 [json_name = "next"];*/ 1:
                    message.cursor = SearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.cursor);
                    break;
                case /* repeated ads.pool.Pool items */ 2:
                    message.items.push(Pool.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ads.pool.SearchRequest cursor = 1 [json_name = "next"]; */
        if (message.cursor)
            SearchRequest.internalBinaryWrite(message.cursor, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated ads.pool.Pool items = 2; */
        for (let i = 0; i < message.items.length; i++)
            Pool.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.SearchResponse
 */
export const SearchResponse = new SearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
    constructor() {
        super("ads.pool.CreateRequest", [
            { no: 1, name: "pool", kind: "message", T: () => Pool }
        ]);
    }
    create(value?: PartialMessage<CreateRequest>): CreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateRequest): CreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ads.pool.Pool pool */ 1:
                    message.pool = Pool.internalBinaryRead(reader, reader.uint32(), options, message.pool);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ads.pool.Pool pool = 1; */
        if (message.pool)
            Pool.internalBinaryWrite(message.pool, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateResponse$Type extends MessageType<CreateResponse> {
    constructor() {
        super("ads.pool.CreateResponse", [
            { no: 1, name: "pool", kind: "message", T: () => Pool }
        ]);
    }
    create(value?: PartialMessage<CreateResponse>): CreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateResponse): CreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ads.pool.Pool pool */ 1:
                    message.pool = Pool.internalBinaryRead(reader, reader.uint32(), options, message.pool);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ads.pool.Pool pool = 1; */
        if (message.pool)
            Pool.internalBinaryWrite(message.pool, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.CreateResponse
 */
export const CreateResponse = new CreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
    constructor() {
        super("ads.pool.UpdateRequest", [
            { no: 1, name: "pool", kind: "message", T: () => Pool }
        ]);
    }
    create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRequest): UpdateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ads.pool.Pool pool */ 1:
                    message.pool = Pool.internalBinaryRead(reader, reader.uint32(), options, message.pool);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ads.pool.Pool pool = 1; */
        if (message.pool)
            Pool.internalBinaryWrite(message.pool, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateResponse$Type extends MessageType<UpdateResponse> {
    constructor() {
        super("ads.pool.UpdateResponse", [
            { no: 1, name: "pool", kind: "message", T: () => Pool }
        ]);
    }
    create(value?: PartialMessage<UpdateResponse>): UpdateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateResponse): UpdateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ads.pool.Pool pool */ 1:
                    message.pool = Pool.internalBinaryRead(reader, reader.uint32(), options, message.pool);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ads.pool.Pool pool = 1; */
        if (message.pool)
            Pool.internalBinaryWrite(message.pool, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ads.pool.UpdateResponse
 */
export const UpdateResponse = new UpdateResponse$Type();
